import PropTypes from "prop-types";
import React, { memo } from "react";

import { ReactComponent as RefreshIcon } from "../assets/refreshIcon.svg";
import { AnimatedButton } from "../common/components";
import DashboardWelcome from "../common/components/DashboardWelcome";
import {
  ANALYTICS_EVENTS_JSON,
  PRO,
  TEAM_MEMBER_STATUS,
} from "../common/constants";
import TRANSLATIONS from "../translations/en.json";
import { getTeamName, logAnalyticsEvent } from "../utils";
import "./WelcomeTitle.css";

function WelcomeTitle({
  allTeamMembers,
  handleActions,
  isDisabled,
  isHideActions,
  refreshTeamSettings,
  isSeeAll,
  setIsSeeAll,
  teamName,
  userDetails,
}) {
  function renderTitle() {
    return localStorage.getItem("subscription") !== PRO ? (
      <p className="tech-team-title">
        {TRANSLATIONS.WELCOME_SCREEN.WELCOME_HEADER_1}{" "}
        <span className="font-weight-bold">{teamName || getTeamName()}</span>
        {TRANSLATIONS.WELCOME_SCREEN.WELCOME_HEADER_2}
      </p>
    ) : (
      <p className="tech-team-title">
        {TRANSLATIONS.HAPPY_PRO.WELCOME_HEADER_1}{" "}
        <span className="font-weight-bold">{userDetails?.name}!</span>
      </p>
    );
  }
  function renderActions() {
    return (
      <>
        <AnimatedButton
          className={isSeeAll ? "filled-btn mr-2" : "filled-btn-outline mr-2"}
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEE_ALL_MEMBERS_BTN);
            handleActions(false);
            setIsSeeAll(true);
          }}
          disabled={isDisabled || isHideActions}
        >
          {TRANSLATIONS.WELCOME_SCREEN.ALL}
        </AnimatedButton>
        <AnimatedButton
          className={isSeeAll ? "filled-btn-outline mr-2" : "filled-btn mr-2"}
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEE_PENDING_MEMBERS_BTN);
            handleActions(true);
            setIsSeeAll(false);
          }}
          disabled={
            isDisabled ||
            isHideActions ||
            (allTeamMembers &&
              !allTeamMembers.filter(
                (e) => e.status === TEAM_MEMBER_STATUS.PENDING
              )?.length)
          }
        >
          {TRANSLATIONS.TEAM_SETINGS.PENDING}
        </AnimatedButton>
        <AnimatedButton
          className="btn refresh-icon"
          disabled={isDisabled}
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.TEAM_MEMBERS_REFRESH);
            refreshTeamSettings();
          }}
        >
          <RefreshIcon />
        </AnimatedButton>
      </>
    );
  }
  return (
    <DashboardWelcome actions={renderActions()} headerTitle={renderTitle()} />
  );
}
WelcomeTitle.propTypes = {
  allTeamMembers: PropTypes.array.isRequired,
  handleActions: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isHideActions: PropTypes.bool,
  refreshTeamSettings: PropTypes.func.isRequired,
  isSeeAll: PropTypes.bool,
  setIsSeeAll: PropTypes.func,
  teamName: PropTypes.string.isRequired,
  userDetails: PropTypes.object.isRequired,
};
export default memo(WelcomeTitle);
