import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import {
  DELETE_MEMBER_REQUEST,
  TEAM_SETTINGS_REQUEST,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_QUESTIONS_REQUEST,
  NOTIFICATIONS_SETTINGS_REQUEST,
} from "./actionTypes";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  deleteMemberError,
  deleteMemberSuccess,
  teamSettingsError,
  teamSettingsSuccess,
  deleteTeamError,
  deleteTeamSuccess,
  deleteTeamQuestionsError,
  deleteTeamQuestionsSuccess,
  notificationSettingsSuccess,
  notificationSettingsError,
} from "./actions";

import { checkHttpStatus } from "../../api/apiUtils";

function* teamSettings(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/team_settings`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(teamSettingsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(teamSettingsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(teamSettingsError(errorData));
  }
}

function* deleteMember(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/revoke`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.data) {
      const responseData = {
        data: action.payload.email || action.payload.phoneNumber,
      };
      yield put(deleteMemberSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(deleteMemberError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteMemberError(errorData));
  }
}

function* deleteTeam(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/delete_team_request`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(deleteTeamSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(deleteTeamError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteTeamError(errorData));
  }
}

function* deleteTeamQuestions(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/delete_team_questions`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(deleteTeamQuestionsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(deleteTeamQuestionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(deleteTeamQuestionsError(errorData));
  }
}

function* notificationSettings(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/update_notification_settings`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(notificationSettingsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(notificationSettingsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(notificationSettingsError(errorData));
  }
}

export function* watchTeamSettings() {
  yield takeEvery(DELETE_MEMBER_REQUEST, deleteMember);
  yield takeEvery(TEAM_SETTINGS_REQUEST, teamSettings);
  yield takeEvery(DELETE_TEAM_REQUEST, deleteTeam);
  yield takeEvery(DELETE_TEAM_QUESTIONS_REQUEST, deleteTeamQuestions);
  yield takeEvery(NOTIFICATIONS_SETTINGS_REQUEST, notificationSettings);
}

function* TeamSettingsSaga() {
  yield all([fork(watchTeamSettings)]);
}
export default TeamSettingsSaga;
