import PropTypes from "prop-types";
import React, { Suspense, lazy, memo } from "react";

import TRANSLATIONS from "../../../translations/en.json";
const QuestionComponent = lazy(() => import("./QuestionComponent"));

function Activty({
  inclusivityOptions,
  handleSelectOption,
  selectedInclusivityOption,
  selectedTimeOption,
  timeOptions,
}) {
  return (
    <Suspense fallback="">
      <div className="pb-3">
        <QuestionComponent
          className="custom-card-inclusivity-options"
          handleSelectOption={handleSelectOption}
          options={inclusivityOptions}
          selectedInclusivityOption={selectedInclusivityOption}
          subQuestion={TRANSLATIONS.CUSTOM_CARD_CREATION.SUB_QUESTION_1}
          question={TRANSLATIONS.CUSTOM_CARD_CREATION.QUESTON_1}
        />
      </div>
      <div className="">
        <QuestionComponent
          className="custom-card-time-options"
          handleSelectOption={handleSelectOption}
          options={timeOptions}
          selectedTimeOption={selectedTimeOption}
          subQuestion={TRANSLATIONS.CUSTOM_CARD_CREATION.SUB_QUESTION_2}
          question={TRANSLATIONS.CUSTOM_CARD_CREATION.QUESTON_2}
        />
      </div>
    </Suspense>
  );
}

Activty.propTypes = {
  inclusivityOptions: PropTypes.array.isRequired,
  handleSelectOption: PropTypes.func,
  selectedInclusivityOption: PropTypes.array,
  selectedTimeOption: PropTypes.object,
  timeOptions: PropTypes.array.isRequired,
};

export default memo(Activty);
