import {
  ADMIN_PROFILE_ACTIVITY_ERROR,
  ADMIN_PROFILE_ACTIVITY_REQUEST,
  ADMIN_PROFILE_ACTIVITY_SUCCESS,
} from "./actionTypes";

const initialState = {};

const adminProfile = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_PROFILE_ACTIVITY_REQUEST:
      return {
        ...state,
        isAdminProfileActivityLoading: true,
        adminProfileRequestData: false,
        adminProfileRequestError: false,
      };
    case ADMIN_PROFILE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isAdminProfileActivityLoading: false,
        adminProfileRequestData: action.data?.response,
        adminProfileRequestError: false,
      };
    case ADMIN_PROFILE_ACTIVITY_ERROR:
      return {
        ...state,
        isAdminProfileActivityLoading: false,
        adminProfileRequestData: false,
        adminProfileRequestError: action.error.error,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default adminProfile;
