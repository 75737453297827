import React, { memo } from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "./Confirmation.css";
import TRANSLATIONS from "../../translations/en.json";

function Confirmation({ isOpen, handleSubmit, message, toggle }) {
  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="bg-yellow-modal share-code-popup"
        id="confirmation-popup"
      >
        <ModalHeader toggle={toggle}></ModalHeader>

        <ModalBody>
          <h5 className="text-center model-head font-family-bold">
            {" "}
            {TRANSLATIONS.CONFIRMATION.HEADER}
          </h5>
          <p className="text-center font-family-regular">
            {message || TRANSLATIONS.CONFIRMATION.MESSAGE}
          </p>
        </ModalBody>
        <ModalFooter>
          <button className="enterprise-btn-outline" onClick={toggle}>
            {TRANSLATIONS.CONFIRMATION.CANCEL}
          </button>
          <button className="btn enterprise-btn" onClick={handleSubmit}>
            {TRANSLATIONS.CONFIRMATION.YES}
          </button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}
Confirmation.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  message: PropTypes.string,
  toggle: PropTypes.func,
};

export default memo(Confirmation);
