import React, { memo, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, UncontrolledTooltip } from "reactstrap";
import PropTypes from "prop-types";

import { getNameInitials, getOrderedItems, orderingByAdmin } from "../utils";
import Plus from "../assets/Dashboard/Plus.svg";
import TRANSLATIONS from "../translations/en.json";
import Scrollbar from "react-custom-scrollbars-2";
import UsersCircle from "../assets/Dashboard/UsersCircle.svg";
import "./Users.css";

function Users({ dailyUsersData }) {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (dailyUsersData?.length) {
      //unshifting admin on top of array
      const modifiedData = orderingByAdmin(
        getOrderedItems(dailyUsersData, "name", "asc")
      );
      setUsers(modifiedData);
    } else {
      setUsers([]);
    }
  }, [dailyUsersData]);

  const renderItem = (item, index) => {
    if (item.profileImageURL) {
      return (
        <>
          <img
            id={`user-${index}`}
            src={item.profileImageURL}
            alt="profile"
            className="user-avatar"
            key={item.userUUID}
          />
          <UncontrolledTooltip placement="top" target={`user-${index}`}>
            {item.name}
          </UncontrolledTooltip>
        </>
      );
    }

    return (
      <>
        <p
          key={item.userUUID}
          data-letters={getNameInitials(item.name)}
          className="mb-0"
          id={`user-${index}`}
        ></p>
        <UncontrolledTooltip placement="top" target={`user-${index}`}>
          {item.name}
        </UncontrolledTooltip>
      </>
    );
  };
  return (
    <Card className="users-card">
      <CardHeader className="users-header">
        <span className="user-title">{TRANSLATIONS.USER_DASHBOARD.USERS}</span>
        <span className="daily-users">
          {users?.length || "-"}{" "}
          {users?.length > 1
            ? TRANSLATIONS.USER_DASHBOARD.DAILY_USERS
            : TRANSLATIONS.USER_DASHBOARD.DAILY_USER}
        </span>
      </CardHeader>
      <div className="users">
        <Scrollbar hideTracksWhenNotNeeded>
          <CardBody className="users-list">
            {users?.length ? (
              users.map((item, index) => {
                return renderItem(item, index);
              })
            ) : (
              <>
                <img src={UsersCircle} alt="profile" className="user-circle" />
                <img src={Plus} alt="profile" className="plus-icon" />
              </>
            )}
          </CardBody>
        </Scrollbar>
      </div>
    </Card>
  );
}
Users.propTypes = {
  isEmptyDashboard: PropTypes.bool,
  dailyUsersData: PropTypes.array,
};
export default memo(Users);
