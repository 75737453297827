import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Home from "../../home";
import Footer from "./Footer";
import { PAGE_TITLES, ROUTE_PATH } from "../../common/constants";
import { updateDocumentTitle } from "../../utils";
function ConsumerWebLayout({ component: Component, pageTitle }) {
  /*update app title based on route */
  useEffect(() => {
    if (!window.location.pathname.includes(ROUTE_PATH.DOSE)) {
      window.scrollTo(0, 0);
    }
    if (window.location.pathname.includes(ROUTE_PATH.RESULTS)) {
      updateDocumentTitle(PAGE_TITLES.RESULTS);
    } else {
      updateDocumentTitle(pageTitle);
    }
  }, [pageTitle, window.location.pathname]);

  return (
    <>
      <Header />
      {Component ? <Component /> : <Home />}
      {window.location.pathname === ROUTE_PATH.FAQS ? (
        <div className="mt-4">
          <Footer />
        </div>
      ) : (
        <Footer />
      )}
    </>
  );
}
ConsumerWebLayout.propTypes = {
  component: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};
export default ConsumerWebLayout;
