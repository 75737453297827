export const QUESTIONS = [
  {
    question: "What would bring you the most joy today?",
    options: [
      { id: "dopamine", label: "Finishing something on my to do list" },
      { id: "oxytocin", label: "Connecting with a loved one" },
      { id: "serotonin", label: "Getting some fresh air" },
      { id: "endorphin", label: "Doing a physical activity" },
    ],
  },
  {
    question: "Which of these activities would help you relax?",
    options: [
      { id: "dopamine", label: "Eating at my favorite restaurant" },
      { id: "oxytocin", label: "Laying out in the sun" },
      { id: "serotonin", label: "Dancing to my favorite playlist" },
      { id: "endorphin", label: "Meditation and/or yoga" },
    ],
  },
  {
    question: "Where would you be happiest?",
    options: [
      { id: "dopamine", label: "Enjoying a night out" },
      { id: "oxytocin", label: "With company of a loved one" },
      { id: "serotonin", label: "In the comfort of my bed" },
      { id: "endorphin", label: "Somewhere out in nature" },
    ],
  },
];
