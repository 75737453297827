export const CREATE_CHECKIN_REQUEST = "CREATE_CHECKIN_REQUEST";
export const CREATE_CHECKIN_SUCCESS = "CREATE_CHECKIN_SUCCESS";
export const CREATE_CHECKIN_ERROR = "CREATE_CHECKIN_ERROR";

// GET VOICE NOTES
export const GET_VOICE_NOTES_REQUEST = "GET_VOICE_NOTES_REQUEST";
export const GET_VOICE_NOTES_SUCCESS = "GET_VOICE_NOTES_SUCCESS";
export const GET_VOICE_NOTES_ERROR = "GET_VOICE_NOTES_ERROR";

// UPDATE VOICE NOTES
export const UPDATE_VOICE_NOTES_REQUEST = "UPDATE_VOICE_NOTES_REQUEST";
export const UPDATE_VOICE_NOTES_SUCCESS = "UPDATE_VOICE_NOTES_SUCCESS";
export const UPDATE_VOICE_NOTES_ERROR = "UPDATE_VOICE_NOTES_ERROR";

// SEND VOICE NOTES
export const SEND_VOICE_NOTES_REQUEST = "SEND_VOICE_NOTES_REQUEST";
export const SEND_VOICE_NOTES_SUCCESS = "SEND_VOICE_NOTES_SUCCESS";
export const SEND_VOICE_NOTES_ERROR = "SEND_VOICE_NOTES_ERROR";
