import React from "react";
import PropTypes from "prop-types";
import ComingSoon from "../comingSoon";
import TRANSLATIONS from "../translations/en.json";
import WelcomeTitle from "./WelcomeTitle";
import "./index.css";
function Messages() {
  return (
    <div>
      <WelcomeTitle isHideActions={true} />
      <div className="messages">
        <ComingSoon
          description={TRANSLATIONS.MESSAGES.COMING_SOON_DESCRIPTION}
        />
      </div>
    </div>
  );
}
Messages.propTypes = {
  userDetails: PropTypes.object,
};
export default Messages;
