import { useEffect } from "react";
import Dose from "./Dose";
import GetStarted from "./GetStarted";
import Happyplace from "./Happyplace";
import Insights from "./Insights";
import Main from "./Main";
import { PAGE_TITLES } from "../common/constants";
import { updateDocumentTitle } from "../utils";
import "./Main.css";

function Home() {
  /*update app title based on route */
  useEffect(() => {
    updateDocumentTitle(PAGE_TITLES.HOME);
  }, [window.location.pathname]);

  return (
    <div className="App">
      <Main />
      <Dose />
      <div className=" hpy-bg mt-5">
        <Happyplace />
        <Insights />
      </div>
      <GetStarted />
    </div>
  );
}

export default Home;
