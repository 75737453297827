import {
  UPDATE_ENTERPRISE_LOGO_ERROR,
  UPDATE_ENTERPRISE_LOGO_REQUEST,
  UPDATE_ENTERPRISE_LOGO_SUCCESS,
} from "./actionTypes";

const initialState = {};

const updateEnterpriseLogo = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ENTERPRISE_LOGO_REQUEST:
      return {
        ...state,
        isUpdateEnterpriseLogoLoading: true,
        updateEnterpriseLogoData: false,
        updateEnterpriseLogoError: false,
      };
    case UPDATE_ENTERPRISE_LOGO_SUCCESS:
      return {
        ...state,
        isUpdateEnterpriseLogoLoading: false,
        updateEnterpriseLogoData: action.data?.response,
        updateEnterpriseLogoError: false,
      };
    case UPDATE_ENTERPRISE_LOGO_ERROR:
      return {
        ...state,
        isUpdateEnterpriseLogoLoading: false,
        updateEnterpriseLogoData: false,
        updateEnterpriseLogoError: action.error.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default updateEnterpriseLogo;
