import React, { memo } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "reactstrap";
import "./ActivityCard.css";

function ActivityCard({
  description,
  header,
  Icon: DashboardIcon,
  isEmptyDashboard,
  value,
  iconClassName,
}) {
  return (
    <Card className="activity-card h-100">
      <h2 className="activity-header">{header}</h2>
      <Row className="w-100 activity-content">
        <Col className="px-0">
          <h6 className="activity-subheader">
            {(!isEmptyDashboard && value) || "-"}
          </h6>
          <p className="rank-value">
            {(!isEmptyDashboard && description) || "-"}
          </p>
        </Col>
        <Col className="activity-icon-col px-0">
          <DashboardIcon
            width="32"
            height="33"
            className={`activity-icon ${iconClassName}`}
          />
        </Col>
      </Row>
    </Card>
  );
}
ActivityCard.propTypes = {
  description: PropTypes.string,
  header: PropTypes.string,
  iconClassName: PropTypes.string,
  Icon: PropTypes.Node,
  isEmptyDashboard: PropTypes.bool,
  value: PropTypes.string,
};
export default memo(ActivityCard);
