import React, { memo } from "react";
import { Col, Row, Spinner } from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import PropTypes from "prop-types";

import TRANSLATIONS from "../../translations/en.json";
function ImageCropper({
  aspectRatio,
  image,
  isLoading,
  onCropComplete,
  setCropper,
  setSelectedImage,
}) {
  return (
    <div className="cropContainer">
      <Cropper
        style={{ height: 200, width: "100%" }}
        preview=".img-preview"
        src={image}
        viewMode={1}
        minCropBoxHeight={70}
        minCropBoxWidth={70}
        background={false}
        responsive={true}
        initialAspectRatio={1 / 1}
        aspectRatio={aspectRatio}
        checkOrientation={false}
        onInitialized={(instance) => {
          setCropper(instance);
        }}
        modal={false}
        guides={true}
        highlight={true}
      />
      <Row className="cropper-actions">
        <Col lg={6} md={6} sm={6} xs={6}>
          <button
            type="button"
            className="cropper-btn cropper-cancel-btn"
            onClick={() => setSelectedImage(false)}
          >
            {TRANSLATIONS.DASHBOARD_SETUP.DISMISS}
          </button>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6}>
          <button
            type="button"
            className="cropper-btn cropper-next-btn d-flex"
            onClick={() => {
              onCropComplete(true);
            }}
          >
            {TRANSLATIONS.DASHBOARD_SETUP.SAVE_BTN}
            {isLoading && (
              <Spinner
                size="sm"
                color="outline secondary"
                className="loader-fixed mx-2"
              />
            )}
          </button>
        </Col>
      </Row>
    </div>
  );
}
ImageCropper.propTypes = {
  aspectRatio: PropTypes.number,
  image: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isHideAspectRatio: PropTypes.bool,
  onCropComplete: PropTypes.func.isRequired,
  setCropper: PropTypes.func.isRequired,
  setSelectedImage: PropTypes.func.isRequired,
};
export default memo(ImageCropper);
