import TRANSLATIONS from "../../translations/en.json";
import dopamine from "../../assets/WebpImages/dopamine.webp";
import oxytocin from "../../assets/WebpImages/oxytocin.webp";
import serotonin from "../../assets/WebpImages/serotonin.webp";
import endorphin from "../../assets/WebpImages/endorphin.webp";

export * as ANALYTICS_EVENTS_JSON from "./firebaseEvents.json";
export * as PAGE_TITLES from "./pageTitles.json";
export * as QUESTIONS from "./faqs";

export const EXTERNAL_URLS = {
  IOS_URL:
    "https://apps.apple.com/us/app/happy-dose/id1529219157?itsct=apps_box&amp;itscg=30200",
  GOOGLE_URL:
    "https://play.google.com/store/apps/details?id=app.happydose.happydoseapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
  BLOG_URL: "http://thinkhpy.substack.com",
  LINKEDIN_URL: "https://www.linkedin.com/company/think-do-happy/",
  THINK_HPY_URL: "www.thinkhpy.com",
};

export const ROUTE_PATH = {
  CONTACT: "/contact",
  DOSE: "/what-is-dose",
  DASHBOARD_SETUP: "/dashboard-setup",
  ENTERPRISE: "/enterprise",
  HOME: "/",
  DASHBOARD: "/moods-and-emotions",
  PRIVACY: "/privacy",
  TEAM_MEMBERS: "/team-members",
  FAQS: "/faqs",
  SCIENCE: "/science",
  HOW_IT_WORKS: "/how-it-works",
  RESULTS: "/results",
  GETSTARTED: "/takeme-to-mydashboard",
  VERIFY_EMAIL: "/verify-email",
  ADMIN_PROFILE: "/admin-profile",
  MESSAGES: "/messages",
  LIFE_COACHES: "/life-coach",
  ACCOUNT_SETTINGS: "/account-settings",
  MEMBER_PROFILE: "/member-profile",
  TASK_ASSIGMENT: "/task-assignment",
  CUSTOM_CARD_CREATION: "/custom-card-creation",
  PRO_HOME: "/pro-home",
};

export const NUMBERS = {
  OTP_LENGTH: 6,
};

export const TEAM_MEMBER_STATUS = {
  ACCEPTED: "ACCEPTED",
  INVALID_MEMBER: "INVALID_MEMBER",
  PENDING: "PENDING",
  REQUESTED: "REQUESTED",
  SUSPENDED: "SUSPENDED",
  LOADING_ACCEPT: "LOADING_ACCEPT",
  LOADING_DENY: "LOADING_DENY",
  LOADING_ACCEPTALL: "LOADING_ACCEPTALL",
  LOADING_DENYALL: "LOADING_DENYALL",
};

export const STRIPE_SUBSCRIPTION_STATUS = {
  ACTIVE: "ACTIVE",
  INCOMPLETE: "INCOMPLETE",
  INCOMPLETE_EXPIRED: "INCOMPLETE_EXPIRED",
  CANCELLED: "CANCELLED",
  PAST_DUE: "PAST_DUE",
  TRIALING: "TRIALING",
  TRIAL_END: "TRIAL_END",
  INACTIVE: "INACTIVE",
};

export const SUBSCRIPTION_LABELS = {
  SUBSCRIBED: "Subscribed",
  UNSUBSCRIBED: "Unsubscribed",
  AMOUNTDUE: "Amount due",
};

export const MOODS_BREAKDOWN_LABELS = [
  TRANSLATIONS.MOODS.MOOD_7,
  TRANSLATIONS.MOODS.MOOD_6,
  TRANSLATIONS.MOODS.MOOD_5,
  TRANSLATIONS.MOODS.MOOD_4,
  TRANSLATIONS.MOODS.MOOD_3,
  TRANSLATIONS.MOODS.MOOD_2,
  TRANSLATIONS.MOODS.MOOD_1,
];
export const MOODS_BREAKDOWN_COLORS = [
  "#FF7F52",
  "#FF7F52",
  "#33A0D7",
  "#33A0D7",
  "#00CEC3",
  "#09C28E",
  "#ffdb43",
];

export const VALID_INPUT_STYLE = {
  width: "3rem",
  height: "3rem",
  marginLeft: "5px",
  fontSize: "2rem",
  borderRadius: 10,
  border: "none",
  boxShadow: "1px 2px 4px rgba(0, 0, 0, 0.25)",
  background:
    "repeating-linear-gradient(90deg, rgb(188, 188, 188) 0px, rgb(188, 188, 188) 1ch, transparent 0px, transparent 3.5ch) 18px 86% / 10ch 2px no-repeat",
  backgroundColor: "#fff",
};

export const CHEMICALS = [
  {
    src: dopamine,
    alt: "dopamine",
  },
  {
    src: oxytocin,
    alt: "oxytocin",
  },
  {
    src: serotonin,
    alt: "serotonin",
  },
  {
    src: endorphin,
    alt: "endorphin",
  },
];
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const NAME_REGEX = /^[0-9]+$/;
export const MOBILE_REGEX =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/i;

export const GLOBAL_DOCUMENT_TITLES = {
  HPY: "Hpy-web",
  ENT: "Ent-web",
  DASH: "Dash-web",
  DEFAULT: "Hpy",
};
export const ENTERPRISE_ADMIN = "ENTERPRISE_ADMIN";
export const PRIMARY_COLOR = "#ffdb43";
export const DASHBOARD_EMPTY_STATE_COLOR = "#F4F4F3";
export const NAME_INITIALS_REGEX = /(\p{L}{1})\p{L}+/;
export const TIMEPERIODS = {
  WEEKLY: "weekly",
  MONTHLY: "monthly",
  YEARLY: "yearly",
};
export const ACCEPTED_IMAGE_FORMATS = ".png,.jpeg,.jpg";

export const ADMIN_PROFILE_REQUEST_TYPES = {
  CUSTOM_DASHBOARD_REPORT: "CUSTOM_DASHBOARD_REPORT",
  LIFE_COACHES: "LIFE_COACHES",
  UPGRADE_ACCOUNT: "UPGRADE_ACCOUNT",
};
export const NO_OF_TEAM_MEMBERS = ["0-10", "10-50", "50-100", ">100"];
export const COUNTRIES_JSON_LIST = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const COMPONENT_NAME = "faq";

export const VOICE_NOTES_STATUS = {
  READ: "READ",
  UNREAD: "UNREAD",
};
export const TIMELINE_FILTER_OPTIONS = [
  {
    name: "All",
    label: "All",
  },
  {
    name: "Today",
    label: "Today",
  },
  {
    name: "This Week",
    label: "This Week",
  },
  {
    name: "Check-Ins",
    label: "Check-Ins",
  },
  {
    name: "Emotions",
    label: "Emotions",
  },
  {
    name: "Tasks",
    label: "Tasks",
  },
];

export const TASK_TABLE_HEADERS = [
  {
    name: "",
    label: "",
  },
  {
    name: "Task",
    label: "Task",
  },
  {
    name: "Activity duration",
    label: "Activity duration",
  },
  {
    name: "Created By",
    label: "Created By",
  },
  {
    name: "Scheduled Date/Time",
    label: "Scheduled Date/Time",
  },
  {
    name: "",
    label: "",
  },
];

export const FILTERS_SETTINGS = [
  {
    title: "",
    filters: [
      {
        name: "MY_CARDS",
        label: "My cards",
        type: "filter",
      },
      {
        name: "NEW",
        label: "New",
        type: "filter",
      },
      {
        name: "RANDOM_ACT_OF_KINDNESS",
        label: "Random acts of kindness",
        type: "filter",
      },
      {
        name: "INCLUSIVE",
        label: "Inclusive",
        type: "filter",
      },
      {
        name: "MEMBER_FAVORITES",
        label: "Member favorites",
        type: "filter",
      },
    ],
  },
  {
    title: "By Chemical",
    filters: [
      {
        name: "dopamine",
        label: "Dopamine",
        type: "chemical",
      },
      {
        name: "oxytocin",
        label: "Oxytocin",
        type: "chemical",
      },
      {
        name: "serotonin",
        label: "Serotonin",
        type: "chemical",
      },
      {
        name: "endorphin",
        label: "Endorphin",
        type: "chemical",
      },
    ],
  },
  {
    title: "By Time",
    filters: [
      {
        name: "<=1",
        label: "≤1 minute",
        type: "time",
      },
      {
        name: "<=5",
        label: "≤5 minutes",
        type: "time",
      },
      {
        name: "<=10",
        label: "≤10 minutes",
        type: "time",
      },
      {
        name: "<=20",
        label: "≤20 minutes",
        type: "time",
      },
      {
        name: "<=30",
        label: "≤30 minutes",
        type: "time",
      },
      {
        name: "<=45",
        label: "≤45 minutes",
        type: "time",
      },
      {
        name: "60",
        label: ">60 minutes",
        type: "time",
      },
    ],
  },
];
export const PRO = "PRO";
export const SUBSCRIPTION_PLAN = {
  FREE: "FREE",
  PRO: "PRO",
};

export const START_INDEX = 0;
export const RESULTS_PER_PAGE = 25;
export const USER_DEFINED_CARDS = "CUSTOM_CARDS";
export const CREATE_NEW = "NEW";
export const SHOW_ALL = "ALL";
export const TIME_FILTER = "time";
export const DATA_FILTER = "filter";
export const CHEMICAL_INFO = "chemical";
export const FILTER_BY_CHEMICAL = "BY_CHEMICAL";
export const FILTER_BY_TIME = "BY_TIME";
export const ASSIGN_TASK = "assign";
export const REMOVE_ITEM = "remove";

export const TIME_FORMAT = "HH:mm";
export const TIME_INTERVAL = 15;
export const DATE_FORMAT = "MMMM d, yyyy h:mm aa";

export const INCLUSIVITY = "inclusivity";
export const JOURNAL = "journal";
export const PURPOSE = "purpose";

export const CHECK_IN_EMOJI = {
  Sad: { src: "Sad", alt: "Not so great" },
  Annoyed: { src: "Annoyed", alt: "Could be better" },
  Confused: { src: "Confused", alt: "Survived" },
  Neutral: { src: "Neutral", alt: "Neutral" },
  Content: { src: "Content", alt: "Content" },
  Proud: { src: "Proud", alt: "Thriving" },
  Excited: { src: "Excited", alt: "Living my best life" },
};

export const PRO_HEADER_LINKS = [
  { name: TRANSLATIONS.ENT_HEADER.HPY, pathname: ROUTE_PATH.HOME },
  {
    name: TRANSLATIONS.COMMON.HAPPY_FOR_PRO,
    pathname: ROUTE_PATH.PRO_HOME,
  },
  { name: TRANSLATIONS.COMMON.THE_SCIENCE, pathname: ROUTE_PATH.SCIENCE },
  {
    name: TRANSLATIONS.COMMON.HOW_IT_WORKS,
    pathname: ROUTE_PATH.HOW_IT_WORKS,
  },
];

export const TEAMS_HEADER_LINKS = [
  { name: TRANSLATIONS.ENT_HEADER.HPY, pathname: ROUTE_PATH.HOME },
  {
    name: TRANSLATIONS.COMMON.HAPPY_FOR_TEAMS,
    pathname: ROUTE_PATH.ENTERPRISE,
  },
  { name: TRANSLATIONS.COMMON.THE_SCIENCE, pathname: ROUTE_PATH.SCIENCE },
  {
    name: TRANSLATIONS.COMMON.HOW_IT_WORKS,
    pathname: ROUTE_PATH.HOW_IT_WORKS,
  },
];
