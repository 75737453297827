import { AXIOS_INSTANCE, CONTACT_US_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { loginError, loginSuccess } from "./actions";

import { LOGIN_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* login(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CONTACT_US_API}/recover`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(loginSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(loginError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(loginError(errorData));
  }
}

export function* watchLogin() {
  yield takeEvery(LOGIN_REQUEST, login);
}

function* LoginSaga() {
  yield all([fork(watchLogin)]);
}
export default LoginSaga;
