import { getCookiesValue } from "./authHelper";
import _ from "lodash";
import moment from "moment/moment";
import "moment-timezone";

export * from "./authHelper";
export * from "./dashboard";
export * from "./toastify";
export * from "./analyticsHelpers";

export const getStartDateOfWeek = (today) => {
  const firstDayOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay())
  );
  return firstDayOfWeek.toISOString().slice(0, 10);
};

export const getEndDateOfWeek = (today) => {
  const lastDayOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay() + 6)
  );
  return lastDayOfWeek.toISOString().slice(0, 10);
};

export const getTeamName = () => {
  return getCookiesValue("teamName");
};

export const getDifferenceBtwnArrays = function (array2) {
  let ret = [];
  for (let i in array2) {
    if (array2.indexOf(this[i].email) > -1) {
      ret.push(this[i]);
    }
  }
  return ret;
};
export const isDashboardDataAvailable = () => {
  return getCookiesValue("dashboardDataAvailable") === "true";
};
export function srcToFile(src, fileName, mimeType) {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

function areEquals(a, b) {
  const keys1 = Object.keys(a);
  const keys2 = Object.keys(b);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key in a) {
    if (a[key] !== b[key]) return false;
  }
  return true;
}
export function checkArrayHasSameKeys(arr) {
  for (let i = 1; i < arr.length; i++) {
    if (!areEquals(arr[0], arr[i])) return false;
  }
  return true;
}

export const sortArrayWithCustomOrder = (arr, order, key) => {
  arr.sort((a, b) => order.indexOf(a[key]) - order.indexOf(b[key]));
  return arr;
};
export const getRoundedPercentage = (divident, divisor) => {
  const percentage = (divident / divisor) * 100;
  return percentage > 0 ? Math.round(percentage) : 0;
};

export const getBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.onload = () => {
    cb(reader.result);
  };
  reader.readAsDataURL(file);
};
/*function for get ordered(asc) array items*/
export const getOrderedItems = (rawArray, conditionName, order) => {
  if (conditionName) {
    return _.orderBy(rawArray, [conditionName.toLowerCase()], [order]); // Use Lodash to sort array by 'condition'
  } else {
    return _.orderBy(rawArray, [(item) => item], [order]); // Use Lodash to sort array by 'Label'
  }
};
export const capitalize = (str) => {
  if (typeof str === "string") {
    return str.replace(/^\w/, (c) => c.toUpperCase());
  } else {
    return "";
  }
};

export const capitalizeFirstLetter = (word) => {
  if (!word) return word; // return the original word if it's empty
  const trimmedWord = word.trim();
  const words = trimmedWord?.replace(/_/g, "");
  return words.charAt(0).toUpperCase() + words.slice(1).toLowerCase();
};

export const getGeneratedDateFormat = (date) => {
  return date
    ? new Date(date).toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      })
    : "";
};
/**
 * Toggle the class on body
 * @param {*} cssClass
 */
export function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}
export const bodyContainsCollapseClass = () => {
  return document.body.classList.contains("vertical-menu-collapsed");
};
export function requestDateFormat(date, format) {
  let formatedDate = new Date(date);
  formatedDate = format
    ? moment(formatedDate).format(format)
    : moment(formatedDate).format("YYYY-MM-DD");
  return formatedDate;
}
export const orderingByAdmin = (data) => {
  let modifiedData = data?.length ? data : [];
  if (modifiedData) {
    modifiedData.forEach(function (item, i) {
      if (item.isAdmin) {
        modifiedData.splice(i, 1);
        modifiedData.unshift(item);
      }
    });
  }
  return modifiedData;
};
export const renderOptions = (options, label, value) => {
  return options.map((item) => {
    return (
      <option key={item[value] || item} value={item[value] || item}>
        {item[label] || item}
      </option>
    );
  });
};

export const deleteModalSelectedClassName = (selectedOption, option, type) => {
  if (["reasons", "issues"].includes(type)) {
    return selectedOption.includes(option)
      ? "selected-options delete-options"
      : "delete-options";
  } else if (["desicions", "useAgain"].includes(type)) {
    return selectedOption === option
      ? "selected-options delete-options"
      : "delete-options";
  }
};

export const deleteModalSelectionStyling = (selectedOption, option, type) => {
  if (["reasons"].includes(type)) {
    return selectedOption?.length === 2 && !selectedOption.includes(option)
      ? { pointerEvents: "none" }
      : {};
  } else if (["issues"].includes(type)) {
    return selectedOption?.length === 3 && !selectedOption.includes(option)
      ? { pointerEvents: "none" }
      : {};
  }
};

export const getFormattedTimeStamp = (timestamp) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

export const formatSeconds = (seconds) => {
  return seconds < 10 ? `0${seconds}` : seconds;
};

export const MAX_RECORDING_TIME = 45;
export const getFormatedTime = (timestamp) => {
  const date = new Date(timestamp);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
};

export const getDateFormatWithoutYear = (date) => {
  return date
    ? new Date(date).toLocaleString("en-US", {
        month: "long",
        day: "numeric",
      })
    : "";
};

export const formatDateAndExtractYear = (timestampStr) => {
  // Convert the timestamp to a moment-timezone object and determine the user's timezone
  const timestamp = moment.tz(timestampStr, moment.tz.guess());

  // Extract the day of the week
  const dayOfWeek = timestamp.format("ddd");

  // Format the time as "hh:mm A"
  const formattedTime = timestamp.format("hh:mm A");

  // Format the Date object as "Month Day"
  const formattedDate = timestamp.format("MMMM D");

  // Extract the year from the Date object
  const year = timestamp.year();

  const fulltimeFormat = `${dayOfWeek}, ${formattedDate}, ${year}, ${formattedTime}`;

  // Return an object with the formatted date and year
  return {
    formattedDate: formattedDate,
    year: year,
    formattedTime,
    dayOfWeek,
    fulltimeFormat,
  };
};

export const autoAdjustHeight = (event, setState) => {
  const textarea = event.target;
  textarea.style.height = "auto"; // Reset the height to auto
  textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to match the content
  setState(textarea.style.height); // Update the state with the new height
};

export const filterObjectsByType = (arrayOfObjects, targetType) => {
  return arrayOfObjects.filter((obj) => obj.type === targetType);
};

export const getColorBasedOnType = (type) => {
  switch (type) {
    case "dopamine":
      return "#09C28E";
    case "oxytocin":
      return "#FF7F52";
    case "serotonin":
      return "#00CEC3";
    case "endorphin":
      return "#33A0D7";
    default:
      return "#FFFFFF"; // Default color if type doesn't match any case
  }
};

export const handleSortDataBasedOnDate = (data) => {
  return data.sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    return dateB - dateA;
  });
};

export const handleMaxAndMinTime = (date) => {
  const minTime = new Date(date);
  const maxTime = new Date(date);
  minTime.setHours(0, 0, 0, 0); // Set minTime to 12:00 AM of the selected day
  maxTime.setHours(23, 59, 59, 999); // Set maxTime to 11:59:59 PM of the selected day

  return { minTime, maxTime };
};

export const handleDateTimeStamp = (dateString, userTimezone) => {
  const inputDate = new Date(dateString);

  // Convert input date to moment object
  const momentDate = moment(inputDate);

  // Set the user's timezone
  momentDate.tz(userTimezone);

  // Format the date for PostgreSQL timestamp
  const formattedDate = momentDate.format("YYYY-MM-DD HH:mm:ssZ");

  return formattedDate;
};


// when chunk load error exists, then lazyretry will reload the page if necessary
export const lazyRetry = function(componentImport, name) {
  return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
          window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
      );
      // try to import the component
      componentImport().then((component) => {
          window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false'); // success so reset the refresh
          resolve(component);
      }).catch((error) => {
          if (!hasRefreshed) { // not been refreshed yet
              window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true'); // we are now going to refresh
              return window.location.reload(); // refresh the page
          }
          reject(error); // Default error behaviour as already tried refresh
      });
  });
};
