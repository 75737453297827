import { LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS } from "./actionTypes";

export const loginAction = (user) => {
  return {
    type: LOGIN_REQUEST,
    payload: user,
  };
};

export const loginSuccess = (user, history) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user, history },
  };
};

export const loginError = (history) => {
  return {
    type: LOGIN_ERROR,
    payload: { history },
  };
};
