/* eslint-disable max-len */
import React, { memo, Suspense, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, Spinner } from "reactstrap";

import BackArrow from "../../assets/arrow-left.svg";
// import { ReactComponent as Close } from "../../assets/closeIcon.svg";
import { ReactComponent as RefreshIcon } from "../../assets/refreshIcon.svg";
// import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
// import { ReactComponent as BotIcon } from "../../assets/Bot.svg";
import { AnimatedButton } from "../../common/components";
import { ROUTE_PATH } from "../../common/constants";
import TRANSLATIONS from "../../translations/en.json";
import CheckInSection from "./CheckInSection";
import NotesSection from "./NotesSection";
import Timeline from "./Timeline";
import UserProfile from "./UserProfile";
import "./index.css";
// import { createChatbotAction } from "../../redux/actions";

function MembersProfile() {
  const history = useHistory();
  // const dispatch = useDispatch();
  const [isRefresh, setIsRefresh] = useState(false);
  // const [isChatOpen, setIsChatOpen] = useState(false);
  // const [chatText, setChatText] = useState("");
  // const [chatThread, setChatTheard] = useState("");
  // const [userTexts, setUserTexts] = useState([]);
  const {
    checkInUserRequestData,
    sendVoiceNotesRequestData,
    voiceNotesRequestData,
  } = useSelector((state) => state.CheckIn);
  const {
    isCreateUserNotesLoading,
    isUpdateUserNotesLoading,
    isGetUserNotesLoading,
  } = useSelector((state) => state.UserNotes);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  //CHAT BOT FEATURE IS NOT FULLY IMPLEMENTED. SO COMMENING OUT

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const payload = { user: chatText, thread: chatThread };
  //   setUserTexts((prev) => [...prev, { message: chatText, type: "user" }]);
  //   dispatch(createChatbotAction(payload));
  //   setChatText("");
  // };

  // const handleChats = (e) => {
  //   e.preventDefault();
  //   setChatText(e.target.value);
  // };

  // const { ChatbotUserRequestData, ChatbotUserData, ChatbotUserError } =
  //   useSelector((state) => state.ChatBot);

  // const isThirdRun = useRef(true);
  // useEffect(() => {
  //   if (isThirdRun.current) {
  //     isThirdRun.current = false;
  //     return;
  //   }
  //   if (!ChatbotUserRequestData && ChatbotUserData) {
  //     setUserTexts((prev) => [
  //       ...prev,
  //       { message: ChatbotUserData.assistant, type: "bot" },
  //     ]);
  //     setChatTheard(ChatbotUserData.thread);
  //   } else if (!ChatbotUserRequestData && ChatbotUserError) {
  //     setUserTexts((prev) => [...prev, ChatbotUserData.assistant]);
  //   }
  // }, [ChatbotUserRequestData, ChatbotUserData]);

  // useEffect(() => {
  //   if (isChatOpen) {
  //     setUserTexts([]);
  //     setChatTheard("");
  //   }
  // }, [isChatOpen]);

  return (
    <Suspense fallback="">
      {(checkInUserRequestData ||
        isGetUserNotesLoading ||
        isUpdateUserNotesLoading ||
        isCreateUserNotesLoading ||
        voiceNotesRequestData ||
        sendVoiceNotesRequestData) && (
        <div className="m-auto">
          <Spinner className="loader-container dashboard-loader overlay-adminScreen" />
        </div>
      )}
      <div className="members-profile-container">
        <Row>
          <Col
            md={8}
            sm={12}
            xs={12}
            className="px-0 px-md-1 spaceing-top-profile"
          >
            <Col
              md={12}
              className="px-0 d-flex align-items-center justify-content-between"
            >
              <span
                className="cursor-pointer members-back-btn"
                onClick={() => history.push(ROUTE_PATH.TEAM_MEMBERS)}
              >
                <img src={BackArrow} alt="back-arrow" />{" "}
                {TRANSLATIONS.MEMBER_PROFILE.BACK_TO_MEMBERS}
              </span>
              <AnimatedButton
                className="btn refresh-icon margin-referesh-icon"
                onClick={() => setIsRefresh(!isRefresh)}
              >
                <RefreshIcon />
              </AnimatedButton>
            </Col>
            <Col xs={12} className="px-0">
              <UserProfile />
            </Col>
            <Row className="px-0">
              <Col xs={12} lg={6} className="mb-3 mb-md-3">
                <CheckInSection isRefresh={isRefresh} />
              </Col>
              <Col xs={12} lg={6} className="mb-3 mb-md-3">
                <NotesSection isRefresh={isRefresh} />
              </Col>
            </Row>
          </Col>
          <Col
            md={4}
            sm={12}
            xs={12}
            className="timeline-col px-0 d-none d-md-block"
          >
            <Timeline />
          </Col>
          {isMobile && (
            <Col md={4} sm={12} xs={12} className="d-md-none pb-2">
              <div className="timeline-col px-md-1">
                <Timeline />
              </div>
            </Col>
          )}
        </Row>
        {/* <div className="chatbot-icon-container">
          <button
            className="chatbot-icon"
            type="button"
            aria-haspopup="dialog"
            aria-expanded="false"
            data-state="closed"
            onClick={() => setIsChatOpen(!isChatOpen)}
          >
            <svg
              xmlns=" http://www.w3.org/2000/svg"
              width="30"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-white block border-gray-200 align-middle"
            >
              <path
                d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"
                className="border-gray-200"
              ></path>
            </svg>
          </button>
          {isChatOpen ? (
            <div className="chatbot-text-container">
              <div className="header">
                <h2 className="font-semibold text-lg tracking-tight">
                  Chatbot
                </h2>
                <Close
                  className="cursor-pointer"
                  fill="#000"
                  stroke="#000"
                  onClick={() => {
                    setUserTexts([]);
                    setChatTheard("");
                    setIsChatOpen(!isChatOpen);
                  }}
                />
              </div>

              <div className="messages">
                <div className="message">
                  <div className="user-icon">
                    <div>
                      <svg
                        stroke="none"
                        fill="black"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        height="20"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="message-content">
                    <span className="block font-bold text-gray-700">AI </span>
                    Hi, how can I help you today?
                  </div>
                </div>

                {userTexts?.length
                  ? userTexts.map((text, index) => (
                      <div className="message" key={index}>
                        <div className="user-icon">
                          <div>
                            {text?.type === "user" ? <UserIcon /> : <BotIcon />}
                          </div>
                        </div>
                        <div className="message-content">
                          <span className="user-text">{text?.message}</span>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>

              <div className="input-section">
                <form onSubmit={handleSubmit}>
                  <input
                    className="input-field"
                    placeholder="Type your message"
                    value={chatText}
                    onChange={handleChats}
                  />
                  <button
                    className="send-button"
                    type="submit"
                    disabled={chatText === ""}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
        </div> */}
      </div>
    </Suspense>
  );
}

export default memo(MembersProfile);
