import _ from "lodash";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import TRANSLATION from "../.././../translations/en.json";
import "../index.css";
import "./Time.css";

function Time({
  setActiveStep,
  handleSelectOption,
  options,
  selectedTimeOption,
}) {
  const handleSelectedOption = (info) => {
    return selectedTimeOption?.display_label === info.display_label
      ? "selected-option-background"
      : "";
  };

  return (
    <div>
      <h2 className="time-header">
        {TRANSLATION.CUSTOM_CARD_CREATION.QUESTON_2}{" "}
        {TRANSLATION.CUSTOM_CARD_CREATION.SUB_QUESTION_2}
      </h2>
      <div className="time-grid-container">
        {options?.length
          ? options?.map((option, index) => (
              <div
                key={index}
                className={`mb-time-options ${handleSelectedOption(option)}`}
                onClick={() => handleSelectOption(option, option.type)}
              >
                <p className="m-0">{option.display_label}</p>
              </div>
            ))
          : ""}
      </div>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 0px" }}
      >
        <div
          className="stepper-back-btn cursor-pointer"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          <BackArrow />
        </div>{" "}
        <button
          className="stepper-continue-btn"
          onClick={() => setActiveStep((prev) => prev + 1)}
          disabled={_.isEmpty(selectedTimeOption)}
        >
          {TRANSLATION.CUSTOM_CARD_CREATION.CONTINUE} <BackArrow />
        </button>
      </div>
    </div>
  );
}

Time.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func,
  options: PropTypes.array,
  selectedTimeOption: PropTypes.array,
};

export default memo(Time);
