import {
  CREATE_CHECKIN_REQUEST,
  CREATE_CHECKIN_SUCCESS,
  CREATE_CHECKIN_ERROR,
  GET_VOICE_NOTES_ERROR,
  GET_VOICE_NOTES_REQUEST,
  GET_VOICE_NOTES_SUCCESS,
  UPDATE_VOICE_NOTES_ERROR,
  UPDATE_VOICE_NOTES_REQUEST,
  UPDATE_VOICE_NOTES_SUCCESS,
  SEND_VOICE_NOTES_ERROR,
  SEND_VOICE_NOTES_REQUEST,
  SEND_VOICE_NOTES_SUCCESS,
} from "./actionTypes";

const initialState = {};

const CheckIn = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CHECKIN_REQUEST:
      return {
        ...state,
        checkInUserRequestData: true,
        checkInUserData: false,
        checkInUserError: false,
      };
    case CREATE_CHECKIN_SUCCESS:
      return {
        ...state,
        checkInUserRequestData: false,
        checkInUserData: action.payload.user.data,
        checkInUserError: false,
      };
    case CREATE_CHECKIN_ERROR:
      return {
        ...state,
        checkInUserRequestData: false,
        checkInUserData: false,
        checkInUserError: action.payload.history.error,
      };
    case GET_VOICE_NOTES_REQUEST:
      return {
        ...state,
        voiceNotesRequestData: true,
        voiceNotesData: false,
        voiceNotesError: false,
      };
    case GET_VOICE_NOTES_SUCCESS:
      return {
        ...state,
        voiceNotesRequestData: false,
        voiceNotesData: action.payload.user.data,
        voiceNotesError: false,
      };
    case GET_VOICE_NOTES_ERROR:
      return {
        ...state,
        voiceNotesRequestData: false,
        voiceNotesData: false,
        voiceNotesError: action.payload.history.error,
      };
    case UPDATE_VOICE_NOTES_REQUEST:
      return {
        ...state,
        updatedVoiceNotesRequestData: true,
        updatedVoiceNotesData: false,
        updatedVoiceNotesError: false,
      };
    case UPDATE_VOICE_NOTES_SUCCESS:
      return {
        ...state,
        updatedVoiceNotesRequestData: false,
        updatedVoiceNotesData: action.payload.user.data,
        updatedVoiceNotesError: false,
      };
    case UPDATE_VOICE_NOTES_ERROR:
      return {
        ...state,
        updatedVoiceNotesRequestData: false,
        updatedVoiceNotesData: false,
        updatedVoiceNotesError: action.payload.history.error,
      };
    case SEND_VOICE_NOTES_REQUEST:
      return {
        ...state,
        sendVoiceNotesRequestData: true,
        sendVoiceNotesData: false,
        sendVoiceNotesError: false,
      };
    case SEND_VOICE_NOTES_SUCCESS:
      return {
        ...state,
        sendVoiceNotesRequestData: false,
        sendVoiceNotesData: action.payload.user.data,
        sendVoiceNotesError: false,
      };
    case SEND_VOICE_NOTES_ERROR:
      return {
        ...state,
        sendVoiceNotesRequestData: false,
        sendVoiceNotesData: false,
        sendVoiceNotesError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CheckIn;
