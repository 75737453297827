export const SETTING_TIMELINE_DATE = [
  "All",
  "This Week",
  "Check-Ins",
  "Emotions",
  "Tasks",
];

export const CHECK_IN_CARD = ["All", "This Week", "Check-Ins", "Today"];
export const EMOTON_CARD = ["All", "This Week", "Emotions", "Today"];
export const TASK_CARD = ["All", "This Week", "Tasks", "Today"];
export const MICRO_JOURNAL_CARD = ["Check-Ins", "Emotions"];
export const ALL = "All";
export const TODAY = "Today";
export const THIS_WEEK = "This Week";
export const EMOTIONS = "Emotions";
export const TASKS = "Tasks";
export const CHECK_IN = "Check-Ins";
export const OTHERS = ["All", "This Week", "Today"];
export const NOT_COMPLETED = "NOT_COMPLETED";
