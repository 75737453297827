import _ from "lodash";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Input } from "reactstrap";

import AddIcon from "../../../assets/AddIcon.svg";
import DownArrow from "../../../assets/Down-arrow.svg";
import UpArrow from "../../../assets/Up-arrow.svg";
import { VOICE_NOTES_STATUS } from "../../../common/constants";
import {
  createUserNotesAction,
  getUserNotesAction,
} from "../../../redux/actions";
import TRANSLATIONS from "../../../translations/en.json";
import { autoAdjustHeight, showError, showSuccess } from "../../../utils";
import "./index.css";

// lazy loading components
const NotesUserSection = lazy(() => import("./NotesUserSection"));

function NotesSection({ isRefresh }) {
  const dispatch = useDispatch();
  const locationData = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [notesData, setNotesData] = useState([]);
  const [notesText, setNotesText] = useState("");
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);

  const {
    userNotesData,
    isCreateUserNotesLoading,
    createNotesData,
    createNotesError,
    isUpdateUserNotesLoading,
    updateNotesData,
    updatenotesError,
  } = useSelector((state) => state.UserNotes);

  useEffect(() => {
    if (!_.isEmpty(locationData?.state)) {
      setUserDetails(locationData?.state);
      dispatch(
        getUserNotesAction({ memberUUID: locationData?.state?.userUUID })
      );
    }
  }, [locationData, isRefresh]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (userNotesData) {
      setNotesData(userNotesData?.notes);
    }
  }, [userNotesData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (!isCreateUserNotesLoading && createNotesData) {
      dispatch(getUserNotesAction({ memberUUID: userDetails?.userUUID }));
      showSuccess(TRANSLATIONS.MEMBER_PROFILE.NEW_NOTES_ADD_SUCCESS_MSG);
      setNotesText("");
    } else if (!isCreateUserNotesLoading && !createNotesError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
      setNotesText("");
    }
  }, [isCreateUserNotesLoading, createNotesData]);

  const isThirdRun = useRef(true);
  useEffect(() => {
    if (isThirdRun.current) {
      isThirdRun.current = false;
      return;
    }
    if (!isUpdateUserNotesLoading && updateNotesData) {
      dispatch(getUserNotesAction({ memberUUID: userDetails?.userUUID }));
    } else if (!isUpdateUserNotesLoading && !updatenotesError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [isUpdateUserNotesLoading, updateNotesData]);

  const handleAddNotes = () => {
    const payload = {
      memberUUID: userDetails?.userUUID,
      text: notesText,
    };
    if (payload?.text !== "") {
      dispatch(createUserNotesAction(payload));
    }
  };

  const handleclick = () => {
    setIsAccordianOpen(!isAccordianOpen);
  };

  return (
    <Suspense fallback="">
      <div
        className={`notes-accordian d-md-none ${
          isAccordianOpen ? "notes-is-open" : ""
        }`}
      >
        <div
          className="notes-accordian-header-container"
          onClick={() => handleclick()}
        >
          <div className="notes-header-inner-container">
            <span className="notes-checkin-accordain-header">
              {TRANSLATIONS.MEMBER_PROFILE.CHECKIN_NOTES}
            </span>
            {notesData?.length &&
            notesData?.filter(
              (voice) => voice?.status === VOICE_NOTES_STATUS?.UNREAD
            )?.length ? (
              <span className="notes-new-checkin-msg">
                {`${
                  notesData?.length &&
                  notesData?.filter(
                    (voice) => voice?.status === VOICE_NOTES_STATUS?.UNREAD
                  )?.length
                } ${TRANSLATIONS.MEMBER_PROFILE.NEW_CHECKIN_NOTES}`}
              </span>
            ) : (
              ""
            )}
          </div>
          <img
            src={isAccordianOpen ? UpArrow : DownArrow}
            alt={isAccordianOpen ? "up_icon" : "down_icon"}
            className="cursor-pointer notes-accordion-arrow-icon"
          />
        </div>
        <div
          className={
            isAccordianOpen ? "notes-section-mb notes-in" : "notes-section-mb"
          }
        >
          <div className="notes-header-container">
            <Input
              type="textarea"
              rows="1"
              placeholder={TRANSLATIONS.MEMBER_PROFILE.ADD_KEY_NOTES}
              className="notes-section-header mb-0 mr-3"
              value={notesText}
              onChange={(e) => setNotesText(e.target.value)}
              style={{ height: textareaHeight }}
              onInput={(e) => autoAdjustHeight(e, setTextareaHeight)}
            />
            <img
              src={AddIcon}
              alt="Seacrch_icon"
              className={
                notesText !== ""
                  ? "cursor-pointer note-add-icon"
                  : " note-add-icon"
              }
              onClick={handleAddNotes}
              style={notesText?.length > 3000 ? { pointerEvents: "none" } : {}}
            />
          </div>
          <hr className="notes-hr m-0" />
          <div className="notes-list-scroll">
            <NotesUserSection notesData={notesData} userDetails={userDetails} />
          </div>
        </div>
      </div>
      <Card className="notes-section-card d-none d-md-block">
        <CardBody className="p-0">
          <div className="notes-header-container">
            <Input
              type="textarea"
              rows="1"
              placeholder={TRANSLATIONS.MEMBER_PROFILE.ADD_KEY_NOTES}
              className="notes-section-header mb-0 mr-3"
              value={notesText}
              onChange={(e) => setNotesText(e.target.value)}
              style={{ height: textareaHeight }}
              onInput={(e) => autoAdjustHeight(e, setTextareaHeight, 50)}
              maxLength={3000}
            />
            <img
              src={AddIcon}
              alt="Seacrch_icon"
              className={
                notesText !== ""
                  ? "cursor-pointer note-add-icon"
                  : " note-add-icon"
              }
              onClick={handleAddNotes}
              style={notesText?.length > 3000 ? { pointerEvents: "none" } : {}}
            />
          </div>
          <hr className="notes-hr m-0" />
          <div className="notes-list-scroll">
            <NotesUserSection notesData={notesData} userDetails={userDetails} />
          </div>
        </CardBody>
      </Card>
    </Suspense>
  );
}

NotesSection.propTypes = {
  isRefresh: PropTypes.bool.isRequired,
};
export default NotesSection;
