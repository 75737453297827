import React from "react";
import { Col, Row } from "reactstrap";
import Reflections from "../../src/assets/EnterpriseHomePageWebps/Reflections.webp";
import ImgLazyLoadComponent from "../common/components/ImageLazyLoad";
import TRANSLATIONS from "../translations/en.json";

export default function EntPgeReflections() {
  return (
    <Row className="image-container pt-container ml-md-5">
      <Col md={6} sm={6} xs={6}>
        <p className="image-header mb-0">
          {TRANSLATIONS.ENT_PGE_REFLECTION.REF_TITLE}
        </p>
        <p className="image-description mb-0">
          {TRANSLATIONS.ENT_PGE_REFLECTION.REF_TEXT}
        </p>
      </Col>
      <Col md={6} sm={6} xs={6} className="pr-0">
        <ImgLazyLoadComponent
          src={Reflections}
          alt="reminderLogo"
          className="w-100"
        />
      </Col>
    </Row>
  );
}
