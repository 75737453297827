import _ from "lodash";
import PropTypes from "prop-types";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";

import {
  CHEMICAL_INFO,
  DATA_FILTER,
  FILTER_BY_CHEMICAL,
  FILTER_BY_TIME,
  RESULTS_PER_PAGE,
  SHOW_ALL,
  START_INDEX,
  TIME_FILTER,
  USER_DEFINED_CARDS,
} from "../common/constants";
import { getTasksAction } from "../redux/task/actions";
import TRANSLATIONS from "../translations/en.json";
import { filterObjectsByType } from "../utils";
import Filter from "./Filter";
import TaskScreen from "./TaskScreen";
import WelcomeTitle from "./WelcomeTitle";

function AssignTask({ scrollbarsRef }) {
  const dispatch = useDispatch();
  const [isSeeAll, setIsSeeAll] = useState(true);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCustomCardSelected, setIsCustomCardSelected] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [assignedTask, setAssignedTask] = useState([]);
  const [taskListData, setTaskListData] = useState([]);
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [selectedFiltersPayload, setSelectedFiltersPayload] = useState({});
  const [isFilterSave, setIsFilterSave] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { assignTaskRequestData, getTasksRequestData } = useSelector(
    (state) => state.Tasks
  );

  useEffect(() => {
    if (scrollbarsRef.current) {
      const internalElement = scrollbarsRef.current.container.firstChild;
      if (JSON.parse(isFilterOpen)) {
        window.scrollTo(0, 0);
        internalElement.style.overflow = "hidden";
      } else {
        internalElement.style.overflow = "scroll"; // Reset to default
      }
    }
  }, [isFilterOpen]);

  const userDetails = JSON.parse(localStorage.getItem("teamUserDetails"));
  const getListingApi = () => {
    const payload = {
      memberUUID: userDetails?.userUUID,
      filters: [],
      pageLimit: RESULTS_PER_PAGE,
      offset: START_INDEX,
    };
    if (!_.isEmpty(userDetails)) {
      dispatch(getTasksAction(payload));
    }
  };

  /**
   * WHEN CLICKED ON ALL, CUSTOM CARDS
   */
  const handleActions = (action) => {
    if (action === USER_DEFINED_CARDS) {
      setTaskListData([]);
      const payload = {
        memberUUID: userDetails?.userUUID,
        filters: ["MY_CARDS"],
        byChemicalValue: [],
        byTimeValue: "",
        pageLimit: RESULTS_PER_PAGE,
        offset: START_INDEX,
        searchText: searchText?.length ? searchText : "",
      };
      setSelectedFiltersPayload(payload);

      if (!_.isEmpty(userDetails)) {
        dispatch(getTasksAction(payload));
      }
    } else if (action === SHOW_ALL) {
      getListingApi();
      setSelectedFiltersPayload({});
      setSearchText("");
    }
  };

  /**
   * REFRESHING THE PAGE TO NORMAL
   */
  const refreshTaskSettings = () => {
    setFilterOptions([]);
    setAssignedTask([]);
    setTaskListData([]);
    getListingApi();
    setSelectedFiltersPayload({});
    setIsFilterSave(false);
    setIsTimeSelected(false);
    setSearchText("");
  };

  /**
   * FUNCTION FOR WHEN FILETER OPTIONS
   * ARE SELCETED
   */
  const handleSelectOptions = (event, filter) => {
    const { checked } = event.target;

    if (filter.type === TIME_FILTER) {
      setIsTimeSelected(checked);
    }

    if (checked) {
      setFilterOptions([...filterOptions, filter]);
    } else {
      setFilterOptions(
        filterOptions.filter((item) => item.name !== filter.name)
      );
    }
  };

  /**
   * WHEN FILTER IS OPEN AND CLICKED ON SAVE
   *
   */
  const handleCallFiltersApi = () => {
    setIsFilterOpen(false);
    setIsFilterSave(true);
    setTaskListData([]);

    let chemicalsArray = [];
    chemicalsArray = filterObjectsByType(filterOptions, CHEMICAL_INFO)?.map(
      (item) => item?.name
    );

    let filtersArray = [];
    filtersArray = filterObjectsByType(filterOptions, DATA_FILTER)?.map(
      (item) => item?.name
    );

    const timeArray = filterObjectsByType(filterOptions, TIME_FILTER)?.map(
      (item) => item?.name
    );

    if (chemicalsArray?.length) {
      filtersArray.push(FILTER_BY_CHEMICAL);
    } else {
      filtersArray.unshift();
    }

    if (timeArray?.length) {
      filtersArray.push(FILTER_BY_TIME);
    } else {
      filtersArray.unshift();
    }

    const payload = {
      memberUUID: userDetails?.userUUID,
      filters: filtersArray,
      byChemicalValue: chemicalsArray,
      byTimeValue: timeArray?.length ? timeArray[0] : "",
      pageLimit: RESULTS_PER_PAGE,
      offset: START_INDEX,
      searchText: searchText?.length ? searchText : "",
    };
    setSelectedFiltersPayload(payload);

    if (!_.isEmpty(userDetails)) {
      dispatch(getTasksAction(payload));
    }
  };

  // After typing completed a delay of 500millisecs later API search will happen
  const delayedAPICall = useMemo(() => {
    return _.debounce((searchTerm, selectedPayload) => {
      setTaskListData([]);
      const payload = {
        memberUUID: userDetails?.userUUID,
        filters: [],
        pageLimit: RESULTS_PER_PAGE,
        offset: START_INDEX,
        searchText: searchTerm,
        byChemicalValue: [],
        byTimeValue: "",
      };

      const filtersPayload = { ...selectedPayload };
      filtersPayload.offset = taskListData?.length + 1 || 0;
      filtersPayload.pageLimit = RESULTS_PER_PAGE;
      filtersPayload.searchText = searchTerm;

      if (!_.isEmpty(userDetails)) {
        if (!_.isEmpty(selectedPayload)) {
          setSelectedFiltersPayload(filtersPayload);
          dispatch(getTasksAction(filtersPayload));
        } else {
          setSelectedFiltersPayload(payload);
          dispatch(getTasksAction(payload));
        }
      }
    }, 500);
  }, []); // Empty dependency array ensures the debounce function is created only once

  const handleSearchText = (text) => {
    setSearchText(text);
    delayedAPICall(text, selectedFiltersPayload);
  };

  return (
    <Suspense fallback="">
      {(getTasksRequestData || assignTaskRequestData) && (
        <Spinner className="loader-container dashboard-loader overlay-admin-screen" />
      )}
      {isFilterOpen ? (
        <Filter
          filterOptions={filterOptions}
          handleCallFiltersApi={handleCallFiltersApi}
          handleSelectOptions={handleSelectOptions}
          isFilterOpen={isFilterOpen}
          isTimeSelected={isTimeSelected}
          setIsFilterOpen={setIsFilterOpen}
        />
      ) : (
        ""
      )}
      <div>
        <WelcomeTitle
          handleActions={handleActions}
          isCustomCardSelected={isCustomCardSelected}
          isFilterOpen={isFilterOpen}
          isFilterSave={isFilterSave}
          isHideActions={false}
          isSeeAll={isSeeAll}
          refreshTaskSettings={refreshTaskSettings}
          setIsCustomSelected={setIsCustomCardSelected}
          setIsFilterOpen={setIsFilterOpen}
          setIsFilterSave={setIsFilterSave}
          setIsSeeAll={setIsSeeAll}
          title={TRANSLATIONS.ASSIGN_TASK.BACK_TO_MEMBER_PROFILE}
        />
        <TaskScreen
          assignedTask={assignedTask}
          getListingApi={getListingApi}
          handleSearchText={handleSearchText}
          searchText={searchText}
          setAssignedTask={setAssignedTask}
          selectedFiltersPayload={selectedFiltersPayload}
          setTaskListData={setTaskListData}
          taskListData={taskListData}
        />
      </div>
    </Suspense>
  );
}

AssignTask.propTypes = {
  scrollbarsRef: PropTypes.node.isRequired,
};
export default AssignTask;
