import React, { memo } from "react";
import "./TheScience.css";
import TRANSLATIONS from "../translations/en.json";

function TheScience() {
  return (
    <div className="scmain-container">
      <div className="sc-main-textarea">
        <p className="scmain-title mb-0">
          {TRANSLATIONS.SCIENCE_PAGE.WHATS_THE_SCIENCE}
        </p>
        <p className="science-desc primary-chemicals mb-0">
          {TRANSLATIONS.SCIENCE_PAGE.FOUR_CHEMICALS}
        </p>
        <p className="science-desc hpy-studies mb-0">
          {TRANSLATIONS.SCIENCE_PAGE.HAPPINESS}
        </p>
        <p className="science-desc exercise-text mb-0">
          <b> {TRANSLATIONS.SCIENCE_PAGE.EXERCISE} </b>
        </p>
      </div>
    </div>
  );
}

export default memo(TheScience);
