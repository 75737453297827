import React, { memo } from "react";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./Dose.css";
import AnimatedButton from "../common/components/AnimatedButton";
import {
  ANALYTICS_EVENTS_JSON,
  CHEMICALS,
  ROUTE_PATH,
} from "../common/constants";
import { logAnalyticsEvent } from "../utils";
import Righticon from "../assets/RightArrow.svg";
import TRANSLATIONS from "../translations/en.json";

const Dose = () => {
  let history = useHistory();
  const handleDose = () => {
    window.scrollTo(0, 0);
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.WHAT_IS_YOUR_DOSE_BTN);
    history.push(ROUTE_PATH.FAQS);
  };
  return (
    <div className="container-fluid p-0 dose-section">
      <div className="col-lg-9 col-md-12 offset-2 how-it-works">
        <h1 className="spacing-dose">
          {TRANSLATIONS.HOME_PAGE.HOW_DOES_IT_WORK}
        </h1>
        <h2 className="dose-text-para">
          {TRANSLATIONS.HOME_PAGE.CHEMICALS_TEXT}
        </h2>
        <Row className="col-lg-12  col-sm-12 chemicals-row mx-auto">
          {CHEMICALS.map(({ src, alt, key, className }) => (
            <Col
              lg={2}
              md={3}
              sm={3}
              xs={3}
              className={`chem dose-chem  ${className}`}
              key={key}
            >
              <div className="chem-anchor">
                <img src={src} alt={alt} className="chemical" />
                <h3 className="chemical-name">{alt}</h3>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="justify-align-center">
        <AnimatedButton
          onClick={() => handleDose()}
          color="secondary"
          className="flow-button"
        >
          {TRANSLATIONS.HOME_PAGE.WHAT_IS_DOSE}
          <img src={Righticon} alt="icon" className="" />
        </AnimatedButton>
      </div>
    </div>
  );
};

export default memo(Dose);
