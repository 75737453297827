import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import {
  ANALYTICS_EVENTS_JSON,
  ENTERPRISE_ADMIN,
  NUMBERS,
  ROUTE_PATH,
  VALID_INPUT_STYLE,
} from "../common/constants";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  activitiesPerTeamMemberRequest,
  getStartedRequest,
  verifyAccessAction,
  verifyOtpAction,
} from "../redux/actions";
import {
  getEndDateOfWeek,
  getStartDateOfWeek,
  logAnalyticsEvent,
  manageBodyClass,
  removeCookiesData,
  showError,
  showSuccess,
  storeCookies,
  storeToken,
} from "../utils";

import { AnimatedButton, ButtonLoader } from "../common/components";
import OtpInput from "react-otp-input";
import RightIcon from "../assets/RightArrow.svg";
import TRANSLATIONS from "../translations/en.json";

function VerifyCode() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const email = location?.state?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [validOtp, setValidOtp] = useState(true);

  const {
    verifyOtpApiData,
    verifyAccessApiData,
    activitiesPerTeamMemberData,
    isActivitiesPerTeamMemberDataLoading,
    activitiesPerTeamMemberError,
    isGetStartedRequesting,
    getStartedData,
    getStartedError,
  } = useSelector((state) => ({
    verifyOtpApiData: state.VerifyOtp,
    verifyAccessApiData: state.VerifyAccess,
    isGetStartedRequesting: state.GetStarted.isGetStartedRequesting,
    getStartedData: state.GetStarted.getStartedData,
    getStartedError: state.GetStarted.getStartedError,
    activitiesPerTeamMemberData:
      state.ActivitiesPerTeamMember.activitiesPerTeamMemberData,
    activitiesPerTeamMemberError:
      state.ActivitiesPerTeamMember.activitiesPerTeamMemberError,
    isActivitiesPerTeamMemberDataLoading:
      state.ActivitiesPerTeamMember.isActivitiesPerTeamMemberDataLoading,
  }));

  const FirstRun = useRef(true);
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (verifyOtpApiData.verifyOtpRequestData) {
      setIsLoading(true);
    } else if (verifyOtpApiData.verifyOtpData) {
      storeToken(verifyOtpApiData.verifyOtpData.jwtToken, false);
      dispatch(verifyAccessAction({}));
    } else {
      setIsLoading(false);
      setValidOtp(false);
    }
  }, [verifyOtpApiData.verifyOtpRequestData, verifyOtpApiData.verifyOtpData]);

  const isResendCode = useRef(true);
  useEffect(() => {
    if (isResendCode.current) {
      isResendCode.current = false;
      return;
    }
    if (getStartedData?.email) {
      showSuccess(TRANSLATIONS.COMMON.OTP_SENT);
    } else if (getStartedError) {
      if (getStartedError.data) {
        showError(getStartedError.data);
      }
    }
  }, [isGetStartedRequesting, getStartedData, getStartedError]);

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (verifyAccessApiData.verifyAccessData) {
      setIsLoading(false);
      if (verifyAccessApiData.verifyAccessData.enterpriseRoles) {
        if (localStorage.getItem("subscription") === "FREE") {
          if (
            verifyAccessApiData.verifyAccessData.enterpriseRoles ===
            ENTERPRISE_ADMIN
          ) {
            const currentDate = new Date();
            dispatch(
              activitiesPerTeamMemberRequest({
                start_date: getStartDateOfWeek(currentDate),
                end_date: getEndDateOfWeek(currentDate),
              })
            );
          } else {
            showError(TRANSLATIONS.COMMON.NO_ADMIN_ACCESS);
            removeCookiesData();
          }
        } else if (localStorage.getItem("subscription") === "PRO") {
          if (verifyAccessApiData.verifyAccessData.subscription === "PRO") {
            if (
              verifyAccessApiData.verifyAccessData.enterpriseRoles ===
              ENTERPRISE_ADMIN
            ) {
              const currentDate = new Date();
              dispatch(
                activitiesPerTeamMemberRequest({
                  start_date: getStartDateOfWeek(currentDate),
                  end_date: getEndDateOfWeek(currentDate),
                })
              );
            } else {
              history.push(ROUTE_PATH.DASHBOARD_SETUP, {
                email: email,
              });
            }
          } else {
            history.push(ROUTE_PATH.DASHBOARD_SETUP, {
              email: email,
            });
          }
        }
      } else {
        history.push(ROUTE_PATH.DASHBOARD_SETUP, {
          email: email,
        });
      }
    }
  }, [verifyAccessApiData.verifyAccessData, dispatch]);

  const isActivitiesLoading = useRef(true);
  useEffect(() => {
    if (isActivitiesLoading.current) {
      isActivitiesLoading.current = false;
      return;
    }
    if (
      !isActivitiesPerTeamMemberDataLoading &&
      activitiesPerTeamMemberData?.length
    ) {
      storeToken(verifyOtpApiData.verifyOtpData.jwtToken, true);
      if (
        activitiesPerTeamMemberData?.length === 1 &&
        activitiesPerTeamMemberData[0]?.completed_activities === 0 &&
        activitiesPerTeamMemberData[0]?.incompleted_activities === 0
      ) {
        manageBodyClass("vertical-menu-collapsed", "add");
        history.push(ROUTE_PATH.TEAM_MEMBERS);
      } else {
        storeCookies("dashboardDataAvailable", true);
        manageBodyClass("vertical-menu-collapsed", "add");
        localStorage.getItem("subscription") === "PRO"
          ? history.push(ROUTE_PATH.TEAM_MEMBERS)
          : history.push(ROUTE_PATH.DASHBOARD);
      }
    } else {
      if (!isActivitiesPerTeamMemberDataLoading) {
        showError(TRANSLATIONS.COMMON.NO_ADMIN_ACCESS);
        removeCookiesData();
      }
    }
  }, [
    isActivitiesPerTeamMemberDataLoading,
    activitiesPerTeamMemberData,
    activitiesPerTeamMemberError,
  ]);

  const fifthRun = useRef(true);
  useEffect(() => {
    if (fifthRun.current) {
      fifthRun.current = false;
      return;
    }
    if (otp.length < NUMBERS.OTP_LENGTH) {
      setValidOtp(true);
    }
  }, [otp]);

  const handleVerifyOtp = (e) => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.VERIFY_EMAIL);
    e.preventDefault();
    if (otp.length === NUMBERS.OTP_LENGTH) {
      dispatch(
        verifyOtpAction({
          email: email,
          token: otp,
          subscription: localStorage.getItem("subscription"),
        })
      );
    }
  };

  const handleResendCode = () => {
    const formData = {
      email: email,
      subscription: localStorage.getItem("subscription"),
    };
    dispatch(getStartedRequest(formData));
  };

  return (
    <>
      <div className="bg-login-dashBoard">
        {isGetStartedRequesting && (
          <div className="m-auto">
            <Spinner className="loader-container overlay-adminScreen" />
          </div>
        )}
        <div className="auth-grey-section p-2">
          <h1 className="auth-header-text">{TRANSLATIONS.HEADER.VERIFY_OTP}</h1>
          <form>
            <Row>
              <Col md={6} lg={6} sm={12} xs={12} className="mx-auto">
                <div className="input-block">
                  <h1 className="otp-text text-center">
                    {TRANSLATIONS.HEADER.SIX_DIGIT_CODE}
                  </h1>
                  <div className="d-flex flex-row justify-content-center box-shaping mt-3 mb-4">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={NUMBERS.OTP_LENGTH}
                      otpType="number"
                      isInputNum={true}
                      inputStyle={
                        validOtp
                          ? { ...VALID_INPUT_STYLE }
                          : {
                              ...VALID_INPUT_STYLE,
                              background: "#EAB7B7",
                              border: "2px solid #FF0000",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            }
                      }
                    />
                  </div>
                </div>
                <div className="justify-align-center finish-setup-btn">
                  <AnimatedButton
                    type="submit"
                    className="auth-btn"
                    onClick={handleVerifyOtp}
                    disabled={!(otp?.length === NUMBERS.OTP_LENGTH)}
                  >
                    {TRANSLATIONS.OTP_SCREEN.VERIFY_EMAIL}

                    {isLoading ? (
                      <ButtonLoader />
                    ) : (
                      <img src={RightIcon} alt="icon" />
                    )}
                  </AnimatedButton>
                </div>
              </Col>
            </Row>
          </form>
          <p className="auth-footer-text mt-4 mb-4 auth-page-bottom ">
            {TRANSLATIONS.OTP_SCREEN.RECEIVE_CODE}
            <span
              className="ml-1 auth-footer-link"
              onClick={() => handleResendCode()}
            >
              {TRANSLATIONS.OTP_SCREEN.REQUEST_HERE}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}
export default VerifyCode;
