import React, { memo } from "react";
import PropTypes from "prop-types";

import TRANSLATION from ".././../translations/en.json";
import "./CustomCardCategories.css";

function CustomCardCategories({
  handleSelectOption,
  journalOptions,
  selectedJournalOption,
}) {
  const handleSetSelectedOption = (info) => {
    return selectedJournalOption?.some((data) => info?.name === data.name)
      ? "active-custom-category-option"
      : "";
  };

  return (
    <>
      <h2 className="custom-card-categories-header">
        {TRANSLATION.CUSTOM_CARD_CREATION.QUESTION_3}
      </h2>
      <div className="custom-card-categories-conatiner">
        {journalOptions.length
          ? journalOptions.map((option) => (
              <span
                key={option.uuid}
                className={`${handleSetSelectedOption(
                  option
                )} custom-card-category-option`}
                onClick={() => handleSelectOption(option, option.type)}
              >
                {option.name}
              </span>
            ))
          : ""}
      </div>
    </>
  );
}

CustomCardCategories.propTypes = {
  handleSelectOption: PropTypes.func,
  journalOptions: PropTypes.array.isRequired,
  selectedJournalOption: PropTypes.array,
};

export default memo(CustomCardCategories);
