import _ from "lodash";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { Input, Modal, ModalBody, ModalFooter } from "reactstrap";

import { ReactComponent as CheckIcon } from "../../../assets/CheckIcon.svg";
import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import TRANSLATIONS from "../../../translations/en.json";
import "../index.css";
import "./PurposeModal.css";

function PurposeModal({
  modal,
  options,
  handleSelectOption,
  setActiveStep,
  selectedCategoryOption,
  setModal,
}) {
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      style={{ position: "relative", top: "20%" }}
    >
      <ModalBody>
        <h2 className="purpose-header">
          {TRANSLATIONS.CUSTOM_CARD_CREATION.QUESTION_4}
        </h2>
        <hr />
        <div>
          {options?.length
            ? options.map((option, index) => {
                return (
                  <div
                    key={index}
                    className="py-3 d-flex justify-content-between align-items-center purpose-modal"
                  >
                    <p
                      className={`purpose-label m-0 ${
                        selectedCategoryOption?.title === option?.title
                          ? "selected-purpose-label"
                          : ""
                      }`}
                    >
                      {option?.description}
                    </p>
                    <div className="d-flex align-items-center">
                      {selectedCategoryOption?.title === option?.title ? (
                        <div
                          className="selected-radio-icon-mb"
                          onClick={() =>
                            handleSelectOption(option, option.type)
                          }
                        >
                          <CheckIcon width={16} height={16} />
                        </div>
                      ) : (
                        <Input
                          type="radio"
                          value={option?.title}
                          className="cursor-pointer"
                          checked={
                            selectedCategoryOption?.title === option?.title
                          }
                          onChange={() =>
                            handleSelectOption(option, option.type)
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <button
          className="stepper-continue-btn"
          onClick={() => {
            setModal(true);
            setActiveStep((prev) => prev + 1);
          }}
          disabled={_.isEmpty(selectedCategoryOption)}
        >
          {TRANSLATIONS.CUSTOM_CARD_CREATION.CONTINUE} <BackArrow />
        </button>
      </ModalFooter>
    </Modal>
  );
}

PurposeModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func,
  setActiveStep: PropTypes.func.isRequired,
  options: PropTypes.array,
  selectedCategoryOption: PropTypes.object,
};

export default memo(PurposeModal);
