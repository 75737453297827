import {
  CREATE_CHECKIN_ERROR,
  CREATE_CHECKIN_REQUEST,
  CREATE_CHECKIN_SUCCESS,
  GET_VOICE_NOTES_ERROR,
  GET_VOICE_NOTES_REQUEST,
  GET_VOICE_NOTES_SUCCESS,
  UPDATE_VOICE_NOTES_ERROR,
  UPDATE_VOICE_NOTES_REQUEST,
  UPDATE_VOICE_NOTES_SUCCESS,
  SEND_VOICE_NOTES_ERROR,
  SEND_VOICE_NOTES_REQUEST,
  SEND_VOICE_NOTES_SUCCESS,
} from "./actionTypes";

export const createCheckInAction = (user) => {
  return {
    type: CREATE_CHECKIN_REQUEST,
    payload: user,
  };
};

export const createCheckInSuccess = (user, history) => {
  return {
    type: CREATE_CHECKIN_SUCCESS,
    payload: { user, history },
  };
};

export const createCheckInError = (history) => {
  return {
    type: CREATE_CHECKIN_ERROR,
    payload: { history },
  };
};

export const getVoiceNotesAction = (user) => {
  return {
    type: GET_VOICE_NOTES_REQUEST,
    payload: user,
  };
};

export const getVoiceNotesSuccess = (user, history) => {
  return {
    type: GET_VOICE_NOTES_SUCCESS,
    payload: { user, history },
  };
};

export const getVoiceNotesError = (history) => {
  return {
    type: GET_VOICE_NOTES_ERROR,
    payload: { history },
  };
};

export const updateVoiceNotesAction = (user) => {
  return {
    type: UPDATE_VOICE_NOTES_REQUEST,
    payload: user,
  };
};

export const updateVoiceNotesSuccess = (user, history) => {
  return {
    type: UPDATE_VOICE_NOTES_SUCCESS,
    payload: { user, history },
  };
};

export const updateVoiceNotesError = (history) => {
  return {
    type: UPDATE_VOICE_NOTES_ERROR,
    payload: { history },
  };
};

export const sendVoiceNotesAction = (user) => {
  return {
    type: SEND_VOICE_NOTES_REQUEST,
    payload: user,
  };
};

export const sendVoiceNotesSuccess = (user, history) => {
  return {
    type: SEND_VOICE_NOTES_SUCCESS,
    payload: { user, history },
  };
};

export const sendVoiceNotesError = (history) => {
  return {
    type: SEND_VOICE_NOTES_ERROR,
    payload: { history },
  };
};
