import React, { memo, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { change, Field, reduxForm } from "redux-form";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import PropTypes from "prop-types";

import "./InviteCodeModel.css";
import {
  mobileValidation,
  phoneNumberField,
  inputField,
} from "../common/reduxFields";
import CloseIconBlack from "../assets/CloseIconBlack.svg";
import { inviteTeamRequest, teamSettingsRequest } from "../redux/actions";
import {
  checkArrayHasSameKeys,
  logAnalyticsEvent,
  showError,
  showSuccess,
} from "../utils";
import RightIcon from "../assets/RightArrow.svg";
import {
  ANALYTICS_EVENTS_JSON,
  EMAIL_REGEX,
  PRO,
  TEAM_MEMBER_STATUS,
} from "../common/constants";
import TRANSLATIONS from "../translations/en.json";
import { ButtonLoader } from "../common/components";

const InviteCodeModel = function ({ modal, toggle }) {
  const dispatch = useDispatch();

  const [allTeamMembers, setAllTeamMembers] = useState([]);
  const [isEmailField, setIsEmailField] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inviteTeam, setInviteTeam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enteredPhoneNumber, setEnteredPhoneNumber] = useState("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredEmailError, setEnteredEmailError] = useState("");
  const [enteredMobileError, setEnteredMobileError] = useState("");

  const {
    inviteTeamMembersData,
    inviteTeamMembersRequestData,
    inviteTeamMembersError,
  } = useSelector((state) => state.InviteTeamMembers);

  const { teamSettigsRequestData, teamSettigsData } = useSelector(
    (state) => state.teamSettings
  );

  //to get the data of the team members
  useEffect(() => {
    if (!teamSettigsRequestData && teamSettigsData?.length) {
      let validTeamMembersData = teamSettigsData.filter((item) =>
        [TEAM_MEMBER_STATUS.PENDING, TEAM_MEMBER_STATUS.ACCEPTED].includes(
          item.status
        )
      );
      if (validTeamMembersData?.length) {
        setAllTeamMembers(validTeamMembersData);
      } else {
        setAllTeamMembers([]);
      }
    }
  }, [teamSettigsRequestData, teamSettigsData]);

  const renderNotification = (item, name) => {
    if (item?.Success) {
      showSuccess(name ? `For  ${name} ${item.Success}` : item.Success);
    } else if (item?.Error) {
      if (item.Error === TRANSLATIONS.COMMON.NOT_FOUND_ERROR) {
        showError(TRANSLATIONS.COMMON.NOT_FOUND_ERROR);
      } else if (item.Error === TRANSLATIONS.COMMON.INVALID_EMAIL) {
        showError(TRANSLATIONS.COMMON.INVALID_EMAIL_NOTIFIER_TEXT);
      } else {
        showError(name ? `For ${name} ${item.Error}` : item.Error);
      }
    }
  };
  const FirstRun = useRef(true);
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (inviteTeamMembersRequestData) {
      setIsLoading(true);
    } else if (inviteTeamMembersData) {
      setIsLoading(false);
      if (inviteTeamMembersData?.length) {
        if (
          inviteTeamMembersData?.length < 2 ||
          (inviteTeamMembersData.length > 1 &&
            checkArrayHasSameKeys(inviteTeamMembersData))
        ) {
          renderNotification(inviteTeamMembersData[0]);
        } else {
          inviteTeamMembersData.forEach((item, index) => {
            renderNotification(item, inviteTeam[index]);
          });
        }
      }
      dispatch(teamSettingsRequest({ isMember: false }));
      toggle();
    } else if (inviteTeamMembersError) {
      setIsLoading(false);
      if (inviteTeamMembersError?.length) {
        showError(inviteTeamMembersError[0]?.Error);
      } else {
        showError(TRANSLATIONS.POPUP.FAILED_TO_SENT);
      }
    }
  }, [inviteTeamMembersRequestData, inviteTeamMembersData]);

  function handleFormSubmit(e) {
    e.preventDefault();
    if (isEmailField && !enteredEmailError) {
      if (inviteTeam.includes(enteredEmail)) {
        showError(TRANSLATIONS.COMMON.EXISTENT_EMAIL);
      } else {
        setInviteTeam([...inviteTeam, enteredEmail]);
      }
      setEnteredEmail("");
      dispatch(change("InviteCodeModel", "Email", ""));
    } else if (enteredPhoneNumber && !enteredMobileError) {
      dispatch(change("InviteCodeModel", "phoneNumber", ""));

      if (inviteTeam.includes(enteredPhoneNumber)) {
        showError(TRANSLATIONS.COMMON.EXISTENT_PHONE_NUMBER);
      } else {
        const parsedNumberObj = parsePhoneNumber(enteredPhoneNumber);
        if (isValidPhoneNumber(enteredPhoneNumber)) {          
          setInviteTeam([...inviteTeam, enteredPhoneNumber]);
          setCountryCodeList([...countryCodeList, { phone: enteredPhoneNumber, countryCode: parsedNumberObj.country}])
        } else {
          showError(TRANSLATIONS.COMMON.INVALID_PHONE_NUMBER);
        }
      }
      setEnteredPhoneNumber("");
    }
  }
  const handleDelete = (index) => {
    const removedElement = inviteTeam[index];
    inviteTeam.splice(index, 1);
    const codeIndex = countryCodeList.findIndex(i=> i.phone === removedElement);
    if(codeIndex >= 0)
    countryCodeList.splice(codeIndex, 1);
    setInviteTeam([...inviteTeam]);
    setCountryCodeList(countryCodeList);
  };

  const handlePhoneNumber = (e) => {
    setEnteredPhoneNumber(e);
  };

  const handleSubmitData = () => {
    let requestPayload = [...inviteTeam];
    if (enteredEmail) {
      requestPayload.push(enteredEmail);
    } else if (enteredPhoneNumber) {
      requestPayload.push(enteredPhoneNumber);
    }

    let requestData = { invitees: requestPayload };

    if (countryCodeList.length) requestData.countryCodes = countryCodeList;

    dispatch(inviteTeamRequest(requestData));
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEND_TO_TEAM_MEMBER_BTN);
  };

  useEffect(() => {
    if (
      (isEmailField && !enteredEmail) ||
      (!isEmailField && !enteredPhoneNumber) ||
      (isEmailField && enteredEmail && enteredEmailError) ||
      (!isEmailField && enteredPhoneNumber && enteredMobileError)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [
    enteredMobileError,
    enteredEmail,
    isEmailField,
    enteredPhoneNumber,
    enteredEmailError,
  ]);

  const validateInviteMobileNumber = (number) => {
    const teamMembers = allTeamMembers?.filter(
      (e) =>
        e.status === TEAM_MEMBER_STATUS.ACCEPTED ||
        e.status === TEAM_MEMBER_STATUS.PENDING
    );
    if (number) {
      if (!isValidPhoneNumber(number)) {
        setEnteredMobileError(TRANSLATIONS.COMMON.INVALID_NUMBER);
      } else if (teamMembers?.find((e) => e.phone === number)) {
        setEnteredMobileError(TRANSLATIONS.DASHBOARD_SETUP.MOBILE_NUMBER_ERROR);
      } else {
        handlePhoneNumber(number);
        setEnteredMobileError("");
      }
    } else {
      setEnteredMobileError("");
    }
  };
  const validateInviteEmail = (email) => {
    const teamMembers = allTeamMembers?.filter(
      (e) =>
        e.status === TEAM_MEMBER_STATUS.ACCEPTED ||
        e.status === TEAM_MEMBER_STATUS.PENDING
    );
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setEnteredEmailError(TRANSLATIONS.COMMON.INVALID_EMAIL);
      } else if (teamMembers?.find((e) => e.email === email)) {
        setEnteredEmailError(TRANSLATIONS.TEAM_MEMBERS.EMAIL_ERROR);
      } else {
        setEnteredEmail(email);
        setEnteredEmailError("");
      }
    } else {
      setEnteredEmailError("");
    }
  };
  const resetForm = () => {
    dispatch(change("InviteCodeModel", "phoneNumber", ""));
    dispatch(change("InviteCodeModel", "Email", ""));
    setIsEmailField(!isEmailField);
    setEnteredEmailError("");
    setEnteredMobileError("");
    setEnteredPhoneNumber("");
    setEnteredEmail("");
  };
  return (
    <Modal
      isOpen={modal}
      id="share-team-code-popup"
      className="bg-yellow-modal share-code-popup"
      modalTransition={{ timeout: 1000 }}
    >
      <ModalHeader
        className="bg-yellow-modal"
        toggle={toggle}
        style={{ border: "none" }}
      />
      <ModalBody className="bg-yellow-modal">
        <h5 className="text-center mt-0 model-head mb-4">
          {" "}
          {localStorage.getItem("subscription") === PRO
            ? TRANSLATIONS.POPUP.SEND_CODE_CLIENT
            : TRANSLATIONS.POPUP.SEND_CODE_TEAM}
        </h5>

        <form id="InviteCodeModel" onSubmit={handleFormSubmit}>
          <p className="text-center mb-4">
            {" "}
            {isEmailField
              ? TRANSLATIONS.POPUP.HIT_ENTER
              : TRANSLATIONS.POPUP.HIT_ENTER_PHONE}
          </p>

          {isEmailField ? (
            <>
              <Row className="mb-2">
                <Col md={10} sm={10} xs={12} className="mb-3  mx-auto px-0">
                  <Field
                    type="email"
                    name="Email"
                    component={inputField}
                    placeholder={TRANSLATIONS.COMMON.EMAIL_TEXT}
                    isError={true}
                    isShowError={enteredEmailError && "error-phone-input"}
                    className={`input-email-dashboard control ${
                      enteredEmailError && "error-phone-input"
                    }`}
                    value=""
                    required
                    props={allTeamMembers}
                    onChange={(e) => validateInviteEmail(e.target.value)}
                  />
                  <span className="mr-5 form-error d-flex">
                    {enteredEmailError}
                  </span>
                </Col>
              </Row>
              <p
                className="text-center tap-text"
                onClick={() => {
                  resetForm();
                  logAnalyticsEvent(
                    ANALYTICS_EVENTS_JSON.TAP_HERE_BY_PHONE_BTN
                  );
                }}
              >
                {TRANSLATIONS.POPUP.TAP_TO_ADD_PHONE_NUMBER}
              </p>
            </>
          ) : (
            <>
              <Row className="mb-2">
                <Col lg={8} md={8} sm={8} xs={12} className="mb-3 mx-auto px-0">
                  <div
                    className={`phone-input-2 ${
                      enteredMobileError && "error-inputs"
                    }`}
                  >
                    <Field
                      countryCallingCodeEditable={false}
                      component={phoneNumberField}
                      defaultCountry="US"
                      value={enteredPhoneNumber}
                      international={true}
                      name="phoneNumber"
                      id="PhoneNumber"
                      country="US"
                      placeholder="Phone Number "
                      validate={[mobileValidation]}
                      className={`pb-1 pl-2`}
                      onChange={(e) => validateInviteMobileNumber(e)}
                    />
                  </div>
                  <span className="form-error">{enteredMobileError}</span>
                </Col>
              </Row>
              <p
                className="text-center tap-text"
                onClick={() => {
                  logAnalyticsEvent(
                    ANALYTICS_EVENTS_JSON.TAP_HERE_BY_EMAIL_BTN
                  );
                  resetForm();
                }}
              >
                {TRANSLATIONS.POPUP.TAP_TO_ADD_EMAIL}
              </p>
            </>
          )}
        </form>
        <Row>
          <Col md={9} sm={9} xs={12} className="mx-auto member-col">
            {inviteTeam.map((member, index) => (
              <Row key={member} className="ml-md-3">
                <Col md={10} sm={10} xs={10} className="member-col">
                  <p className="member-email">{member}</p>
                </Col>
                <Col
                  md={2}
                  sm={2}
                  xs={2}
                  className="member-col delete-icon text-right"
                >
                  <img
                    className="cursor-pointer"
                    src={CloseIconBlack}
                    alt="close"
                    width={15}
                    onClick={() => handleDelete(index)}
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
        <div className="d-flex flex-row justify-content-center">
          <button
            className="send-code-button justify-align-center mt-4"
            onClick={() => {
              logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEND_TO_TEAM_MEMBER_BTN);
              handleSubmitData();
            }}
            disabled={!inviteTeam.length && isDisabled}
          >
            {" "}
            <span className="d-none d-md-block">
              {TRANSLATIONS.POPUP.SEND_CODE}
            </span>
            <span className="d-md-none d-sm-block">
            {TRANSLATIONS.POPUP.SEND_CODE}
            </span>
            {isLoading ? (
              <ButtonLoader />
            ) : (
              <img src={RightIcon} alt="icon" className="right-icon" />
            )}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

InviteCodeModel.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  members: PropTypes.array,
  handleSubmit: PropTypes.func,
};

export default connect()(
  reduxForm({
    form: "InviteCodeModel",
    // touchOnChange: true,
  })(memo(InviteCodeModel))
);
