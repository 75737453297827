import { AXIOS_INSTANCE, CONTACT_US_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { downloadError, downloadSuccess } from "./actions";

import { DOWNLOAD_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* download(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CONTACT_US_API}/download_app`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(downloadSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(downloadError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(downloadError(errorData));
  }
}

export function* watchDownload() {
  yield takeEvery(DOWNLOAD_REQUEST, download);
}

function* DownloadSaga() {
  yield all([fork(watchDownload)]);
}
export default DownloadSaga;
