import { SIGN_UP_ERROR, SIGN_UP_REQUEST, SIGN_UP_SUCCESS } from "./actionTypes";

const initialState = {};

const SignUp = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signUpRequestData: true,
        signUpData: false,
        signUpError: false,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpRequestData: false,
        signUpData: action.payload.user.data,
        signUpError: false,
      };
    case SIGN_UP_ERROR:
      return {
        ...state,
        signUpRequestData: false,
        signUpData: false,
        signUpError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default SignUp;
