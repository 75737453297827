import React, { memo } from "react";
import "./ScEndNote.css";
import TRANSLATIONS from "../translations/en.json";
import ScSwoosh from "../../src/assets/Science/ScSwoosh.webp";

function ScienceEndNote() {
  return (
    <div className="sc-endnote-container">
      <div className="sc-endnote-textarea">
        <p className="sc-endnote-text mb-0">
          {TRANSLATIONS.SCIENCE_PAGE.END_NOTE_1}
        </p>
        <p className="sc-endnote-text mb-0">
          {TRANSLATIONS.SCIENCE_PAGE.END_NOTE_2}
        </p>
      </div>
      <div id="sc-endnote-image">
        <img src={ScSwoosh} alt="ScEndNoteLogo" className="sc-endnote-logo" />
      </div>
    </div>
  );
}

export default memo(ScienceEndNote);
