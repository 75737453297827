import React from "react";

import "./index.css";
import ClockSupport from "./ClockSupport";
import GetStarted from "../home/GetStarted";
import PersonalizedSection from "./PersonalizedSection";
import RectangleScience from "../../src/assets/RectangleScience.svg";
import RectangleMobile from "../../src/assets/HowItWorks/reactangle-mobile-version.svg";
import RequestDemo from "../science/RequestDemo";
import WithYouSection from "./WithYouSection";

function HowItWorks() {
  return (
    <>
      <div className="how-it-works-screen">
        <div className="mobile-main-img d-lg-none">
          <img
            alt="rectangle"
            className="how-it-works-mobile-logo-overlay d-lg-none"
            src={RectangleMobile}
          />
          <img
            alt="rectangle"
            className="how-it-works-logo-overlay d-none"
            src={RectangleScience}
          />
        </div>
        <div className="how-it-works-background-img">
          <WithYouSection />
          <PersonalizedSection />
        </div>
        <ClockSupport />
        <RequestDemo />
        <GetStarted />
      </div>
    </>
  );
}
export default HowItWorks;
