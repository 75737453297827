import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";

import { ANALYTICS_EVENTS_JSON, TIMEPERIODS } from "../common/constants";
import { AnimatedButton } from "../common/components";
import DashboardWelcome from "../common/components/DashboardWelcome";
import { getTeamName, logAnalyticsEvent, storeCookies } from "../utils";
import { ReactComponent as RefreshIcon } from "../assets/refreshIcon.svg";
import TRANSLATIONS from "../translations/en.json";
import "../teamMembers/WelcomeTitle.css";

function WelcomeTitle({
  handleActions,
  isDisabled,
  refreshDashboard,
  teamName,
  timePeriod,
}) {
  useEffect(() => {
    if (teamName) {
      storeCookies("teamName", teamName);
    }
  }, [teamName]);
  function renderTitle() {
    return (
      <p className="tech-team-title">
        {TRANSLATIONS.WELCOME_SCREEN.WELCOME_HEADER_1}{" "}
        <span className="font-weight-bold">{teamName || getTeamName()}</span>
        {TRANSLATIONS.USER_DASHBOARD.WELCOME_TITLE}
      </p>
    );
  }

  function renderActions() {
    return (
      <>
        <AnimatedButton
          className={
            timePeriod === TIMEPERIODS.WEEKLY
              ? "filled-btn mr-2"
              : "filled-btn-outline mr-2"
          }
          onClick={() => {
            handleActions(TIMEPERIODS.WEEKLY);
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.WEEKLY_BTN);
          }}
          disabled={isDisabled}
        >
          {TRANSLATIONS.USER_DASHBOARD.WEEKLY}
        </AnimatedButton>
        <AnimatedButton
          className={
            timePeriod === TIMEPERIODS.MONTHLY
              ? "filled-btn mr-2"
              : "filled-btn-outline mr-2"
          }
          onClick={() => {
            handleActions(TIMEPERIODS.MONTHLY);
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.MONTHLY_BTN);
          }}
          disabled={isDisabled}
        >
          {TRANSLATIONS.USER_DASHBOARD.MONTHLY}
        </AnimatedButton>
        <AnimatedButton
          className={
            timePeriod === TIMEPERIODS.YEARLY
              ? "filled-btn mr-2"
              : "filled-btn-outline mr-2"
          }
          onClick={() => {
            handleActions(TIMEPERIODS.YEARLY);
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.YEARLY_BTN);
          }}
          disabled={isDisabled}
        >
          {TRANSLATIONS.USER_DASHBOARD.YEARLY}
        </AnimatedButton>
        <AnimatedButton
          className="btn refresh-icon"
          onClick={() => {
            refreshDashboard();
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DASHBOARD_REFRESH_BTN);
          }}
        >
          <RefreshIcon />
        </AnimatedButton>
      </>
    );
  }
  return (
    <DashboardWelcome actions={renderActions()} headerTitle={renderTitle()} />
  );
}
WelcomeTitle.propTypes = {
  teamName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  refreshDashboard: PropTypes.func,
  handleActions: PropTypes.func,
  timePeriod: PropTypes.string,
};
export default memo(WelcomeTitle);
