import { AXIOS_INSTANCE, CONTACT_US_API } from "../../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { verifyAccessError, verifyAccessSuccess } from "./actions";

import { VERIFY_ACCESS_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../../api/apiUtils";

function* verifyAccess(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CONTACT_US_API}/`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(verifyAccessSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(verifyAccessError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(verifyAccessError(errorData));
  }
}

export function* watchverifyAccess() {
  yield takeEvery(VERIFY_ACCESS_REQUEST, verifyAccess);
}

function* VerifyAccessSaga() {
  yield all([fork(watchverifyAccess)]);
}
export default VerifyAccessSaga;
