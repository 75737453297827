import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import "./Faqs.css";
import { ANALYTICS_EVENTS_JSON } from "../../common/constants";
import { AnimatedButton } from "../../common/components";
import Card from "./QuestionCard";
import Circle from "../../assets/circleIcon.svg";
import { logAnalyticsEvent } from "../../utils";
import { QUESTIONS } from "../../common/constants/faqs";
import Righticon from "../../assets/RightArrow.svg";
import { ReactComponent as WhiteSwoosh } from "../../assets/white_swoosh.svg";
import TRANSLATIONS from "../../translations/en.json";

function FAQs() {
  const history = useHistory();
  const [questionCount, setQuestionCount] = useState(0);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [label, setLabel] = useState(false);
  const [chemicalCounts, setChemicalCounts] = useState({
    dopamine: 0,
    oxytocin: 0,
    serotonin: 0,
    endorphin: 0,
  });

  const getHighestCountChemical = (counts) => {
    let max = 0;
    let high = "dopamine";
    for (const [key, value] of Object.entries(counts)) {
      if (value >= max) {
        max = value;
        high = key;
      }
    }
    return high;
  };

  const handleCardClick = (id) => {
    window.scrollTo(0, 0);
    if (showResults) {
      logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEE_RESULTS_BTN);
    } else {
      logAnalyticsEvent(ANALYTICS_EVENTS_JSON.NEXT_QUESTION_BTN);
    }
    setShowSubmit(false);
    setChemicalCounts((prev) => {
      return {
        ...prev,
        [id]: prev[id] + 1,
      };
    });

    if (questionCount < 2) {
      setQuestionCount(questionCount + 1);
      setShowResults(questionCount === 1);
    } else {
      const resultChemical = getHighestCountChemical(chemicalCounts);
      history.push(`./results/${resultChemical}`);
    }
  };

  const onCardSelection = (card) => () => {
    setShowSubmit(true);
    setSelectedCard(card.id);
    setLabel(card.label);
  };

  const renderCard = () => {
    return QUESTIONS[questionCount]?.options.map((option) => {
      return (
        <Card
          className={option.label === label ? "card-border" : ""}
          label={option.label}
          key={option.label}
          handleClick={onCardSelection(option)}
        />
      );
    });
  };
  return (
    <div>
      <div className="funnel " id="funnel">
        <div className="row">
          <div className="col-lg-9 col-md-12 col-sm-12">
            <h1 className="headText">{TRANSLATIONS.FAQS.HEADER}</h1>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 ">
            <img
              className="float-right faq-img"
              src={Circle}
              alt="doseCircle"
            />
          </div>
          <h2 className="col-lg-6 col-md-8 col-sm-12 questionText">
            {" "}
            {QUESTIONS[questionCount]?.question}
          </h2>
        </div>
        <div className="row spacing">
          {renderCard()}
          <div className="mobile-section mx-auto pr-5">
            <AnimatedButton
              disabled={!showSubmit}
              className={" flow-button mt-5 mx-auto "}
              onClick={() => {
                handleCardClick(selectedCard);
                if (showResults) {
                  logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEE_RESULTS_BTN);
                } else {
                  logAnalyticsEvent(ANALYTICS_EVENTS_JSON.NEXT_QUESTION_BTN);
                }
              }}
              component="faq"
            >
              {showResults ? "See Results" : "Next Question"}
              <img src={Righticon} className="" />
            </AnimatedButton>
          </div>
        </div>
        <WhiteSwoosh className="faqs-white-swoosh-bottom" />
      </div>
      <div className="web-section row flow-entry-result flow-entry-mv mvb-1 mb-5">
        <AnimatedButton
          disabled={!showSubmit}
          className={" flow-button "}
          onClick={() => {
            handleCardClick(selectedCard);
          }}
          component="faq"
        >
          {showResults
            ? TRANSLATIONS.FAQS.SEE_RESULTS
            : TRANSLATIONS.FAQS.NEXT_QUESTION}
          <img src={Righticon} className="" />
        </AnimatedButton>
      </div>
    </div>
  );
}

export default FAQs;
