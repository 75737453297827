import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { AXIOS_INSTANCE, CHATBOT_API } from "../../api/apiEndPoint";
import { createChatbotError, createChatbotSuccess } from "./actions";

import { checkHttpStatus } from "../../api/apiUtils";
import { CREATE_CHATBOT_REQUEST } from "./actionTypes";

function* createChatbotInForUser(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CHATBOT_API}/chat`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(createChatbotSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(createChatbotError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(createChatbotError(errorData));
  }
}

export function* watchChatbot() {
  yield takeLatest(CREATE_CHATBOT_REQUEST, createChatbotInForUser);
}

function* ChatBotSaga() {
  yield all([fork(watchChatbot)]);
}
export default ChatBotSaga;
