import {
  VERIFY_OTP_ERROR,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";

const initialState = {};

const VerifyOtp = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        verifyOtpRequestData: true,
        verifyOtpData: false,
        verifyOtpError: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verifyOtpRequestData: false,
        verifyOtpData: action.payload.user.data,
        verifyOtpError: false,
      };
    case VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyOtpRequestData: false,
        verifyOtpData: false,
        verifyOtpError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VerifyOtp;
