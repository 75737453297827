import ScDopamine from "../../assets/Science/ScDopamine.webp";
import ScEndorphin from "../../assets/Science/ScEndorphin.webp";
import ScOxytocin from "../../assets/Science/ScOxytocin.webp";
import ScSerotonin from "../../assets/Science/ScSerotonin.webp";
import ScDopamineMobile from "../../assets/Science/ScDopamine-Mobile.webp";
import ScEndorphinMobile from "../../assets/Science/ScEndorphin-Mobile.webp";
import ScOxytocinMobile from "../../assets/Science/ScOxytocin-Mobile.webp";
import ScSerotoninMobile from "../../assets/Science/ScSerotonin-Mobile.webp";
export const SCIENCE_CHEMICALS = [
  {
    CLASSNAME: "no-border-right-xs",
    DESC_1:
      "Often called the “reward center” of your brain, the high we get after beating a tough level of candy crush and the need to beat the next are the effects of dopamine.",
    DESC_2:
      "Most often linked to short-term pleasure and gratification, dopamine is responsible for creating feedback loops in the brain that make us want to do more dopamine-inducing activities over and over again. That mood bump you get after someone likes your instagram post or enjoying that tasty second piece of cake.",
    IMAGE: ScDopamine,
    MOBILE_IMAGE: ScDopamineMobile,
  },
  {
    CLASSNAME: "no-border-left-xs",
    DESC_1:
      "Known fondly as the “love hormone,” oxytocin is often released when we bond with another person. The warm fuzzies you get from hugging a friend or sharing an intimate moment with someone are the effects of oxytocin.",
    DESC_2:
      "Produced by the hypothalamus which is largely responsible for our feelings of social connectedness, Oxytocin can assist in strong feelings of trust, intimacy and love, often experienced during childbirth, when showing empathy, and during sex.",
    IMAGE: ScOxytocin,
    MOBILE_IMAGE: ScOxytocinMobile,
  },
  {
    CLASSNAME: "no-border-right-xs",
    DESC_1:
      "This chemical is seen as a full-body stabilizer, playing a key role in regulating your mood and ensuring healthy digestion. It also helps with sleep and overall brain function.",
    DESC_2:
      "As a key chemical to improve your sense of well being, treatments for mood disorders such as depression or anxiety involve increasing serotonin levels in the brain. That feeling of accomplishment, the calm after a deep meditation, and the feeling from a great night’s sleep can be traced to serotonin.",
    IMAGE: ScSerotonin,
    MOBILE_IMAGE: ScSerotoninMobile,
  },
  {
    CLASSNAME: "no-border-left-xs",
    DESC_1:
      "These chemicals are similar to the drug morphine, endorphins are considered natural painkillers because they activate opioid receptors in the brain that help minimize discomfort.",
    DESC_2:
      "When activated by activities like cardio exercise, eating spicy food, laughing, sex or simple random acts of kindness, they can also help bring about feelings of euphoria and general well-being.",
    IMAGE: ScEndorphin,
    MOBILE_IMAGE: ScEndorphinMobile,
  },
];
