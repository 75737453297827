import { initializeApp } from "firebase/app";
import "firebase/firestore";
import { EXTERNAL_URLS } from "./common/constants";

let MEASUREMENT_ID, APP_ID;
if (window.location.hostname === EXTERNAL_URLS.THINK_HPY_URL) {
  MEASUREMENT_ID = process.env.REACT_APP_PROD_MEASUREMENT_ID;
  APP_ID = process.env.REACT_APP_PROD_FOREBASE_APP_ID;
} else {
  MEASUREMENT_ID = process.env.REACT_APP_DEV_MEASUREMENT_ID;
  APP_ID = process.env.REACT_APP_DEV_FOREBASE_APP_ID;
}
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  measurementId: MEASUREMENT_ID,
  appId: APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
