import React, { memo } from "react";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import { ANALYTICS_EVENTS_JSON } from "../../common/constants";
import { AnimatedButton } from "../../common/components";
import { getGeneratedDateFormat, logAnalyticsEvent } from "../../utils";
import TRANSLATIONS from "../../translations/en.json";

function SendCodeCard({ createdAt, inviteModaltoggle, teamCode }) {
  return (
    <Card className="send-code-card">
      <Row className="code-actions">
        <Col lg={7} md={7} sm={8} xs={6} className="px-0">
          <div className="code">{teamCode}</div>
          <AnimatedButton
            className="send-code-btn"
            onClick={() => {
              logAnalyticsEvent(
                ANALYTICS_EVENTS_JSON.SHARE_WITH_TEAM_POPUP_OPEN_BTN
              );
              inviteModaltoggle();
            }}
          >
            {TRANSLATIONS.WELCOME_SCREEN.SEND_CODE}
          </AnimatedButton>
        </Col>
        <Col lg={5} md={5} sm={4} xs={6} className="text-right">
          <div className="generated-text">
            {TRANSLATIONS.WELCOME_SCREEN.GENERATED_ON}
            <br className="mobile-section" />
            {getGeneratedDateFormat(createdAt)}
          </div>
        </Col>
      </Row>
    </Card>
  );
}
SendCodeCard.propTypes = {
  createdAt: PropTypes.string.isRequired,
  inviteModaltoggle: PropTypes.func.isRequired,
  teamCode: PropTypes.string.isRequired,
};
export default memo(SendCodeCard);
