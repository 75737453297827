export const GET_TIMELINE_REQUEST = "GET_TIMELINE_REQUEST";
export const GET_TIMELINE_SUCCESS = "GET_TIMELINE_SUCCESS";
export const GET_TIMELINE_ERROR = "GET_TIMELINE_ERROR";

export const GET_ASSIGNED_TASK_TIMELINE_REQUEST =
  "GET_ASSIGNED_TASK_TIMELINE_REQUEST";
export const GET_ASSIGNED_TASK_TIMELINE_SUCCESS =
  "GET_ASSIGNED_TASK_TIMELINE_SUCCESS";
export const GET_ASSIGNED_TASK_TIMELINE_ERROR =
  "GET_ASSIGNED_TASK_TIMELINE_ERROR";

export const REMIND_TASK_REQUEST = "REMIND_TASK_REQUEST";
export const REMIND_TASK_SUCCESS = "REMIND_TASK_SUCCESS";
export const REMIND_TASK_ERROR = "REMIND_TASK_ERROR";

export const GET_EMOJIS_REQUEST = "GET_EMOJIS_REQUEST";
export const GET_EMOJIS_SUCCESS = "GET_EMOJIS_SUCCESS";
export const GET_EMOJIS_ERROR = "GET_EMOJIS_ERROR";
