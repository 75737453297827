import {
  GET_STARTED_ERROR,
  GET_STARTED_REQUEST,
  GET_STARTED_SUCCESS,
} from "./actionTypes";

export const getStartedRequest = (data) => {
  return {
    type: GET_STARTED_REQUEST,
    payload: data,
  };
};

export const getStartedSuccess = (data) => {
  return {
    type: GET_STARTED_SUCCESS,
    response: data,
  };
};

export const getStartedError = (data) => {
  return {
    type: GET_STARTED_ERROR,
    error: data,
  };
};
