import {
  DAILY_USERS_REQUEST,
  DAILY_USERS_SUCCESS,
  DAILY_USERS_ERROR,
} from "./actionTypes";

export const getAllDailyUsers = (payload) => {
  return {
    type: DAILY_USERS_REQUEST,
    payload,
  };
};

export const getAllDailyUsersSuccess = (response) => {
  return {
    type: DAILY_USERS_SUCCESS,
    response,
  };
};

export const getAllDailyUsersError = (error) => {
  return {
    type: DAILY_USERS_ERROR,
    error,
  };
};
