import {
  CONTACT_US_ERROR,
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
} from "./actionTypes";

export const contactUsRequest = (user) => {
  return {
    type: CONTACT_US_REQUEST,
    payload: user,
  };
};

export const contactUsSuccess = (user, history) => {
  return {
    type: CONTACT_US_SUCCESS,
    payload: { user, history },
  };
};

export const contactUsError = (history) => {
  return {
    type: CONTACT_US_ERROR,
    payload: { history },
  };
};
