import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import ActivitiesPerChemical from "./activitiesPerChemical/reducer";
import ActivitiesPerTeamMember from "./activitiesPerTeamMember/reducer";
import AdminProfile from "./adminProfile/reducer";
import CheckIn from "./checkIn/reducer";
import GetStarted from "./combineLoginSignup/reducer";
import ContactUs from "./contactUs/reducer";
import DailyUsers from "./dailyUsers/reducer";
import DashBoardSetup from "./dashBoardSetup/reducer";
import DashboardActivities from "./dashboardActivities/reducer";
import Download from "./download/reducer";
import EnterpriseLogo from "./enterpriseLogo/reducer";
import HpyScore from "./hpyScore/reducer";
import InviteTeamMembers from "./inviteTeam/reducer";
import UserNotes from "./keyNotes/reducer";
import Login from "./login/reducer";
import { LOGOUT_REQUEST } from "./logout/actionTypes";
import UpdateProfile from "./myProfile/reducer";
import VerifyOtp from "./otp/reducer";
import VerifyAccess from "./otp/userOrAdmin/reducer";
import UserDetails from "./overallScreen/reducer";
import ProfilePicture from "./profilePicture/reducer";
import RequestAccess from "./requestAccess/reducer";
import RequestDemo from "./requestDemo/reducer";
import SignUp from "./signUp/reducer";
import Tasks from "./task/reducer";
import teamSettings from "./teamSettings/reducers";
import Timeline from "./timeline/reducer";
import ChatBot from "./chatbot/reducer";

const appReducer = combineReducers({
  AdminProfile,
  ActivitiesPerChemical,
  ActivitiesPerTeamMember,
  ContactUs,
  CheckIn,
  DailyUsers,
  Download,
  DashBoardSetup,
  DashboardActivities,
  EnterpriseLogo,
  GetStarted,
  HpyScore,
  InviteTeamMembers,
  Login,
  ProfilePicture,
  RequestAccess,
  RequestDemo,
  SignUp,
  Tasks,
  teamSettings,
  Timeline,
  UserDetails,
  UserNotes,
  UpdateProfile,
  VerifyAccess,
  VerifyOtp,
  ChatBot,

  form: formReducer,
});

const rootReducer = (state, action) => {
  //To clear store data while logging out
  if (action.type === LOGOUT_REQUEST) {
    return appReducer({}, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
