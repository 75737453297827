import {
  CREATE_NOTES_ERROR,
  CREATE_NOTES_REQUEST,
  CREATE_NOTES_SUCCESS,
  GET_NOTE_ERROR,
  GET_NOTE_REQUEST,
  GET_NOTE_SUCCESS,
  UPDATE_NOTES_ERROR,
  UPDATE_NOTES_REQUEST,
  UPDATE_NOTES_SUCCESS,
} from "./actionTypes";

export const getUserNotesAction = (payload) => {
  return {
    type: GET_NOTE_REQUEST,
    payload,
  };
};

export const getNotesSuccess = (data) => {
  return {
    type: GET_NOTE_SUCCESS,
    data,
  };
};

export const getNotesError = (error) => {
  return {
    type: GET_NOTE_ERROR,
    error,
  };
};

export const createUserNotesAction = (payload) => {
  return {
    type: CREATE_NOTES_REQUEST,
    payload,
  };
};

export const createNotesSuccess = (data) => {
  return {
    type: CREATE_NOTES_SUCCESS,
    data,
  };
};

export const createNotesError = (error) => {
  return {
    type: CREATE_NOTES_ERROR,
    error,
  };
};

export const updateUserNotesAction = (payload) => {
  return {
    type: UPDATE_NOTES_REQUEST,
    payload,
  };
};

export const updateNotesSuccess = (data) => {
  return {
    type: UPDATE_NOTES_SUCCESS,
    data,
  };
};

export const updateNotesError = (error) => {
  return {
    type: UPDATE_NOTES_ERROR,
    error,
  };
};
