import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  ACTIVTY_API,
  AXIOS_INSTANCE,
  CHEMICAL_API,
  ENTERPRISE_API,
  INCLUSIVITY_API,
  JOURNAL_API,
  USER_ACTIVTY_API,
} from "../../api/apiEndPoint";
import {
  assignTaskError,
  assignTaskSuccess,
  createCustomCardError,
  createCustomCardSuccess,
  getAllInclusivityError,
  getAllInclusivitySuccess,
  getAllJournalOptionsError,
  getAllJournalOptionsSuccess,
  getAllPurposeOptionsError,
  getAllPurposeOptionsSuccess,
  getAllTimeOptionsError,
  getAllTimeOptionsSuccess,
  getTasksError,
  getTasksSuccess,
} from "./actions";

import { checkHttpStatus } from "../../api/apiUtils";
import {
  ASSIGN_TASKS_REQUEST,
  CREATE_CUSTOM_CARD_REQUEST,
  GET_ALL_INCLUSIVITY_REQUEST,
  GET_ALL_JOURNAL_REQUEST,
  GET_ALL_PURPOSE_REQUEST,
  GET_ALL_TIME_REQUEST,
  GET_TASKS_REQUEST,
} from "./actionTypes";

function* tasksRequest(action) {
  const payload = {
    filters: action.payload.filters,
    memberUUID: action.payload.memberUUID,
    byChemicalValue: action.payload.byChemicalValue,
    byTimeValue: action.payload.byTimeValue,
    searchText: action.payload.searchText,
  };

  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/activities?offset=${action.payload.offset}&pageLimit=${action.payload.pageLimit}`,
      payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getTasksSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getTasksError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getTasksError(errorData));
  }
}

function* assignTask(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/assign_task`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(assignTaskSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(assignTaskError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(assignTaskError(errorData));
  }
}

function* getAllInclusivityOptions(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${INCLUSIVITY_API}/get_all`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getAllInclusivitySuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getAllInclusivityError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getAllInclusivityError(errorData));
  }
}

function* getAllTimeOptions(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ACTIVTY_API}/get_all`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getAllTimeOptionsSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getAllTimeOptionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getAllTimeOptionsError(errorData));
  }
}

function* getAllJournalOptions(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${JOURNAL_API}/get_journal_tag`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getAllJournalOptionsSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getAllJournalOptionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getAllJournalOptionsError(errorData));
  }
}

function* getAllPurposeOptions(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CHEMICAL_API}/get_all`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getAllPurposeOptionsSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getAllPurposeOptionsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getAllPurposeOptionsError(errorData));
  }
}

function* customCardCustom(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${USER_ACTIVTY_API}/custom_activity_create`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(createCustomCardSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(createCustomCardError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(createCustomCardError(errorData));
  }
}

export function* watchTasks() {
  yield takeEvery(GET_TASKS_REQUEST, tasksRequest);
  yield takeEvery(ASSIGN_TASKS_REQUEST, assignTask);
  yield takeEvery(GET_ALL_INCLUSIVITY_REQUEST, getAllInclusivityOptions);
  yield takeEvery(GET_ALL_TIME_REQUEST, getAllTimeOptions);
  yield takeEvery(GET_ALL_JOURNAL_REQUEST, getAllJournalOptions);
  yield takeEvery(GET_ALL_PURPOSE_REQUEST, getAllPurposeOptions);
  yield takeEvery(CREATE_CUSTOM_CARD_REQUEST, customCardCustom);
}

function* TasksSaga() {
  yield all([fork(watchTasks)]);
}
export default TasksSaga;
