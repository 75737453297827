import { NAME_INITIALS_REGEX, TIMEPERIODS } from "../common/constants";
import { requestDateFormat } from ".";
import TRANSLATIONS from "../translations/en.json";

export const calcHappinessRatingTextIndex = (ratingLabelsLength, value) => {
  const index = Math.floor(ratingLabelsLength * (value / 100));
  return Math.min(index, ratingLabelsLength - 1);
};

export const floatNumberWithTwoDecimal = (chemical, total) => {
  if (chemical > 0) {
    return parseFloat(((chemical / total) * 100).toFixed(0));
  } else {
    return 0;
  }
};

export const getPerDay = (total) => {
  let perDay = parseFloat((total / 7).toFixed(2));
  return perDay;
};
export const getPercentageByDataComparision = (current, past) => {
  const total = past === 0 ? current : past;
  if (current === 0 && past === 0) {
    return 0;
  } else if (current >= past) {
    let difference = current - past;
    let percentage = parseInt((difference / total) * 100);
    return percentage;
  } else if (current < past) {
    let difference = past - current;
    let percentage = parseInt((difference / total) * 100);
    return percentage;
  } else {
    return 0;
  }
};

export const getTotalChemicalsData = (currentchemicalData) => {
  let Dopamine, Endorphin, Oxytocin, Serotonin;
  Dopamine = Endorphin = Oxytocin = Serotonin = 0;
  for (const item of currentchemicalData) {
    Dopamine += item?.dopamine;
    Endorphin += item?.endorphin;
    Oxytocin += item?.oxytocin;
    Serotonin += item?.serotonin;
  }

  let total = Dopamine + Endorphin + Oxytocin + Serotonin;
  return { chemicals: { Dopamine, Endorphin, Oxytocin, Serotonin }, total };
};

export const comparePopularChemical = (pastchemicalData, currentPopular) => {
  let pastHighestChemical = 0;
  for (let item of pastchemicalData) {
    if (item && currentPopular) {
      pastHighestChemical += item[currentPopular.toLowerCase()];
    }
  }

  return pastHighestChemical;
};

export const getTotalDailyReflections = (currentchemicalData) => {
  let totalReflections = 0;
  for (let item of currentchemicalData) {
    totalReflections += item?.total_reflections;
  }

  return totalReflections;
};

export const getPercentageText = (currentData, pastData, key) => {
  const data = { percentageText: `-`, icon: "" };
  if (currentData && pastData && (currentData[key] || currentData[key] === 0)) {
    if (currentData[key] > pastData[key]) {
      return {
        percentageText: `% ${TRANSLATIONS.USER_DASHBOARD.HIGHER}`,
        icon: "+",
      };
    } else if (currentData[key] < pastData[key]) {
      return {
        percentageText: `% ${TRANSLATIONS.USER_DASHBOARD.LOWER}`,
        icon: "-",
      };
    }
  }
  return data;
};

export const getEndDateByPeriod = (date, period) => {
  const lastDayOfWeek = new Date(
    date.setDate(date.getDate() - date.getDay() + period)
  );
  return lastDayOfWeek.toISOString().slice(0, 10);
};

export const getDates = (period) => {
  const currentDate = new Date();
  if (period === TIMEPERIODS.WEEKLY) {
    const currentStartDate = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay())
    );
    const previousStartDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    );
    return {
      currentStartDate: requestDateFormat(currentStartDate),
      currentEndDate: requestDateFormat(new Date()),
      previousStartDate: requestDateFormat(previousStartDate),
      previousEndDate: getEndDateByPeriod(previousStartDate, 6),
    };
  }
  if (period === TIMEPERIODS.MONTHLY) {
    const previousStartDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    return {
      currentStartDate: requestDateFormat(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      ),
      previousStartDate: requestDateFormat(previousStartDate),
      currentEndDate: requestDateFormat(new Date()),
      previousEndDate: requestDateFormat(
        new Date(
          previousStartDate.getFullYear(),
          previousStartDate.getMonth() + 1,
          0
        )
      ),
    };
  }
  if (period === TIMEPERIODS.YEARLY) {
    return {
      currentStartDate: requestDateFormat(
        new Date(currentDate.getFullYear(), 0, 1)
      ),
      currentEndDate: requestDateFormat(new Date()),
      previousStartDate: requestDateFormat(
        new Date(currentDate.getFullYear() - 1, 0, 1)
      ),
      previousEndDate: requestDateFormat(
        new Date(currentDate.getFullYear() - 1, 12, 0)
      ),
    };
  }
};
export const getNameInitials = (name) => {
  const rgx = new RegExp(NAME_INITIALS_REGEX, "gu");
  if (name) {
    let initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();
    return initials;
  }
};
export const getMonthName = new Intl.DateTimeFormat("en-US", { month: "short" })
  .format;
export function getAverageMood(list) {
  let sum = 0;
  let totalCount = 0;
  list.forEach((element) => {
    sum += element.average_mood;
    totalCount++;
  });

  if (totalCount == 0) {
    return 0; // prevent division by 0
  }
  let avg = sum / totalCount;
  return avg;
}
export const getChartDateFormats = (timePeriod, createdDate) => {
  switch (timePeriod) {
    case TIMEPERIODS.WEEKLY:
      return `${getMonthName(createdDate)} ${createdDate.getDate()}`;
    case TIMEPERIODS.MONTHLY:
      return [getMonthName(createdDate), createdDate.getDate()];
    case TIMEPERIODS.YEARLY:
      return `${getMonthName(createdDate)}`;
    default:
      return createdDate.getDate();
  }
};
export const compareMoodValues = (moodValues) => {
  const initialValue = moodValues[0];
  return {
    hasSameMood: moodValues.every((obj) => obj === initialValue),
    value: initialValue,
  };
};
