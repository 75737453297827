import PropTypes from "prop-types";
import React, { memo, useState } from "react";

import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import TRANSLATION from "../.././../translations/en.json";
import "../index.css";
import PurposeModal from "./PurposeModal";
import "./Tags.css";

function Tags({
  setActiveStep,
  handleSelectOption,
  journalOptions,
  options,
  selectedCategoryOption,
  selectedJournalOption,
}) {
  const [modal, setModal] = useState(false);

  const handleSetSelectedOption = (info) => {
    return selectedJournalOption?.some((data) => info?.name === data.name)
      ? "active-tags-option"
      : "";
  };

  return (
    <>
      {modal ? (
        <PurposeModal
          handleSelectOption={handleSelectOption}
          modal={modal}
          setModal={setModal}
          options={options}
          setActiveStep={setActiveStep}
          selectedCategoryOption={selectedCategoryOption}
        />
      ) : (
        ""
      )}
      <h2 className="tags-header">
        {TRANSLATION.CUSTOM_CARD_CREATION.MB_QUESTION_3}
      </h2>
      <div className="tags-conatiner">
        {journalOptions.length
          ? journalOptions.map((option) => (
              <span
                key={option.uuid}
                className={`${handleSetSelectedOption(option)} tags-option`}
                onClick={() => handleSelectOption(option, option.type)}
              >
                {option.name}
              </span>
            ))
          : ""}
      </div>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 0px" }}
      >
        <div
          className="stepper-back-btn cursor-pointer"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          <BackArrow />
        </div>{" "}
        <button
          className="stepper-continue-btn"
          onClick={() => setModal(true)}
          disabled={!(selectedJournalOption?.length >= 3)}
        >
          {TRANSLATION.CUSTOM_CARD_CREATION.CONTINUE} <BackArrow />
        </button>
      </div>
    </>
  );
}

Tags.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func,
  journalOptions: PropTypes.array.isRequired,
  options: PropTypes.array,
  selectedCategoryOption: PropTypes.object,
  selectedJournalOption: PropTypes.array,
};

export default memo(Tags);
