import PropTypes from "prop-types";
import React, { memo } from "react";
import { Col, Row } from "reactstrap";

import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import { ReactComponent as CheckIcon } from "../../../assets/CheckIcon.svg";
import TRANSLATION from "../.././../translations/en.json";
import "../index.css";
import "./Inclusivity.css";

function Inclusivity({
  setActiveStep,
  handleSelectOption,
  options,
  selectedInclusivityOption,
}) {
  const handleSelectedOption = (info) => {
    return selectedInclusivityOption?.some((data) => data?.title === info.title)
      ? "selected-option-background"
      : "";
  };

  return (
    <div>
      <h2 className="inclusivity-header">
        {TRANSLATION.CUSTOM_CARD_CREATION.QUESTON_1}{" "}
        {TRANSLATION.CUSTOM_CARD_CREATION.SUB_QUESTION_1}
      </h2>
      <Row>
        {options?.length
          ? options?.map((option, index) => (
              <Col
                xs={12}
                key={index}
                className={`mb-inclu-options ${handleSelectedOption(option)}`}
              >
                <p className="m-0">{option.title}</p>
                {handleSelectedOption(option) ? (
                  <div
                    className="selected-icon"
                    onClick={() => handleSelectOption(option, option.type)}
                  >
                    <CheckIcon width={16} height={16} />
                  </div>
                ) : (
                  <div
                    className="select-icon"
                    onClick={() => handleSelectOption(option, option.type)}
                  />
                )}
              </Col>
            ))
          : ""}
      </Row>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 0px" }}
      >
        <div
          className="stepper-back-btn cursor-pointer"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          <BackArrow />
        </div>{" "}
        <button
          className="stepper-continue-btn"
          onClick={() => setActiveStep((prev) => prev + 1)}
          disabled={!selectedInclusivityOption?.length}
        >
          {TRANSLATION.CUSTOM_CARD_CREATION.CONTINUE} <BackArrow />
        </button>
      </div>
    </div>
  );
}

Inclusivity.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  handleSelectOption: PropTypes.func,
  options: PropTypes.array,
  selectedInclusivityOption: PropTypes.array,
};

export default memo(Inclusivity);
