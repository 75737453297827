//External packages
import React, { useEffect, useState, useRef } from "react";
import ApexChart from "react-apexcharts";
import { Card, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import _ from "lodash";

//local files
import {
  calcHappinessRatingTextIndex,
  compareMoodValues,
  getAverageMood,
  getChartDateFormats,
} from "../../utils";
import { LineChartOptions } from "./LineChartOptions";
import {
  MOODS_BREAKDOWN_LABELS,
  PRO,
  TIMEPERIODS,
} from "../../common/constants";
import TRANSLATIONS from "../../translations/en.json";
import "./Charts.css";

function LineChart({ timePeriod }) {
  const [XAxisValues, setXAxisValues] = useState([]);
  const [XAxisValuesForMobile, setXAxisValuesForMobile] = useState([]);
  const [moodValues, setMoodValues] = useState([]);

  const { isMoodsBreakdownDataLoading, moodsBreakdownData } = useSelector(
    (state) => state.DashboardActivities
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (isMoodsBreakdownDataLoading || !moodsBreakdownData?.length) {
      setXAxisValues([]);
      setMoodValues([]);
    } else if (!isMoodsBreakdownDataLoading && moodsBreakdownData?.length) {
      let moodsData = moodsBreakdownData;
      if (timePeriod === TIMEPERIODS.YEARLY) {
        moodsData = moodsBreakdownData.map((item) => {
          return {
            ...item,
            month: new Date(item.created_date).getMonth(),
          };
        });
        const groupedByMonthData = Object.values(_.groupBy(moodsData, "month"));
        moodsData = groupedByMonthData.map((item) => {
          return {
            average_mood: getAverageMood(item),
            created_date: item?.[0].created_date,
          };
        });
      }
      let valuesData = [];
      let datesData = [];
      let mobileViewDatesData = [];

      for (let item of moodsData) {
        valuesData.push(
          calcHappinessRatingTextIndex(
            MOODS_BREAKDOWN_LABELS.length,
            item.average_mood
          )
        );

        const createdDate = new Date(item.created_date);
        const category = getChartDateFormats(timePeriod, createdDate);

        datesData.push(category);

        const mobileViewMonthWithDate = getChartDateFormats(
          TIMEPERIODS.WEEKLY === timePeriod ? TIMEPERIODS.MONTHLY : timePeriod,
          createdDate
        );
        mobileViewDatesData.push(mobileViewMonthWithDate);
      }
      setXAxisValues(datesData);
      setXAxisValuesForMobile(mobileViewDatesData);
      setMoodValues(valuesData);
    }
  }, [isMoodsBreakdownDataLoading, moodsBreakdownData]);

  const LineChartData = {
    series: [
      {
        name: "",
        data: moodValues,
      },
    ],
    options: {
      ...LineChartOptions,
      xaxis: {
        ...LineChartOptions.xaxis,
        categories: XAxisValues,
      },
      responsive: [
        {
          breakpoint: 768,
          options: {
            xaxis: {
              categories: XAxisValuesForMobile,
            },
          },
        },
      ],
      fill: compareMoodValues(moodValues)?.hasSameMood
        ? { type: "solid" }
        : {
            ...LineChartOptions.fill,
          },
      colors: ["#FF7F52"],
      yaxis: {
        ...LineChartOptions.yaxis,
        labels: {
          show: true,
          align: "left",
          formatter: function (val) {
            return MOODS_BREAKDOWN_LABELS[val];
          },
        },
      },
    },
  };
  return (
    <Card className=" dashboard-graph-card">
      <h1 className="graph-title">
        {localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.USER_DASHBOARD.OVERALL_MOODS_GROUP
          : TRANSLATIONS.USER_DASHBOARD.OVERALL_MOODS}
      </h1>
      <Row>
        <Col md={12} lg={12} sm={12} xs={12} className="pr-2 pl-3 ">
          <div className="line-chart">
            {" "}
            <ApexChart
              options={LineChartData.options}
              series={LineChartData.series}
              type="line"
              height="250"
              width={"99%"}
              className="web-section"
            />
          </div>
          <ApexChart
            className={`line-chart mobile-section ${
              moodValues?.length > 3 ? "" : "overflow-hidden"
            }`}
            options={{
              ...LineChartData.options,
              xaxis: {
                ...LineChartData.options.xaxis,
                categories: XAxisValues,
              },
            }}
            series={LineChartData.series}
            type="line"
            height="250"
            width={moodValues.length > 3 ? "150%" : "100%"}
          />
        </Col>
      </Row>
    </Card>
  );
}
LineChart.propTypes = {
  timePeriod: PropTypes.string.isRequired,
};
export default LineChart;
