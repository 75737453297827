import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getStartedError, getStartedSuccess } from "./actions";

import { GET_STARTED_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* getStarted(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/signup_login`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response?.data) {
      const responseData = {
        data: { ...response.data, email: action.payload.email },
      };
      yield put(getStartedSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(getStartedError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(getStartedError(errorData));
  }
}

export function* watchGetStarted() {
  yield takeEvery(GET_STARTED_REQUEST, getStarted);
}

function* GetStartedSaga() {
  yield all([fork(watchGetStarted)]);
}
export default GetStartedSaga;
