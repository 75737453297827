import PropTypes from "prop-types";
import React, { memo } from "react";
import { Menu, MenuItem } from "react-pro-sidebar";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { ReactComponent as LogoutIcon } from "../../../assets/AdminSideIcons/LogoutIcon.svg";
import {
  ANALYTICS_EVENTS_JSON,
  PRO,
  ROUTE_PATH,
  EXTERNAL_URLS
} from "../../../common/constants";
import { logout } from "../../../redux/actions";
import {
  dashboardFooterRoutes,
  hpyProDashboardFooterRoutes,
} from "../../../routes";
import TRANSLATIONS from "../../../translations/en.json";
import { logAnalyticsEvent, removeCookiesData } from "../../../utils";
import "./SidebarFooter.css";

function SidebarFooter({ inviteModaltoggle, toggleSidebar }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const routes =
    localStorage.getItem("subscription") === PRO
      ? hpyProDashboardFooterRoutes
      : dashboardFooterRoutes;

  const handleLogout = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.LOGOUT_PROFILE_BTN);
    dispatch(logout());
    removeCookiesData();
    // Redirect to thinkhpy Page
    console.log(process.env.NODE_ENV)
    if(process.env.NODE_ENV === 'development') {
      history.push(ROUTE_PATH.GETSTARTED);
    } else {
      window.location.href = 'https://'+EXTERNAL_URLS.THINK_HPY_URL;
    }
  };

  return (
    <>
      <Menu className="bottom-links pb-4">
        <MenuItem className="mobile-section">
          <hr className="separation-line mobile-section" />
        </MenuItem>
        {routes.map(({ path, icon: Icon, name }) => {
          return (
            <MenuItem
              key={path}
              className={
                window.location.pathname === path
                  ? "menu-link active-link"
                  : "menu-link"
              }
              icon={<Icon className={"menu-icon"} />}
              component={<Link to={path} />}
            >
              {name}
            </MenuItem>
          );
        })}
        <MenuItem
          className="menu-link"
          icon={<LogoutIcon className={"menu-icon"} />}
          onClick={() => handleLogout()}
        >
          {TRANSLATIONS.SIDE_BAR.LOG_OUT}
        </MenuItem>
        <button
          type="button"
          className="upgrade-btn share-team-code-btn"
          onClick={() => {
            inviteModaltoggle();
            logAnalyticsEvent(
              ANALYTICS_EVENTS_JSON.SHARE_WITH_TEAM_POPUP_OPEN_BTN
            );
            toggleSidebar();
          }}
        >
          {localStorage.getItem("subscription") === PRO
            ? TRANSLATIONS.POPUP.SEND_COACH_CODE
            : TRANSLATIONS.POPUP.SEND_TEAM_CODE}{" "}
        </button>
      </Menu>
    </>
  );
}
SidebarFooter.propTypes = {
  inviteModaltoggle: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};
export default memo(SidebarFooter);
