import { LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS } from "./actionTypes";

const initialState = {};

const Login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginRequestData: true,
        loginData: false,
        loginError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginRequestData: false,
        loginData: action.payload.user.data,
        loginError: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginRequestData: false,
        loginData: false,
        loginError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Login;
