import {
  GET_STARTED_ERROR,
  GET_STARTED_REQUEST,
  GET_STARTED_SUCCESS,
} from "./actionTypes";

const initialState = {};

const getStarted = (state = initialState, action) => {
  switch (action.type) {
    case GET_STARTED_REQUEST:
      return {
        ...state,
        isGetStartedRequesting: true,
        getStartedData: false,
        getStartedError: false,
      };
    case GET_STARTED_SUCCESS:
      return {
        ...state,
        isGetStartedRequesting: false,
        getStartedData: action.response.data,
        getStartedError: false,
      };
    case GET_STARTED_ERROR:
      return {
        ...state,
        isGetStartedRequesting: false,
        getStartedData: false,
        getStartedError: action.error.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default getStarted;
