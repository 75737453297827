import PropTypes from "prop-types";
import React, { lazy, memo, Suspense } from "react";
import { useHistory } from "react-router-dom";

import { ReactComponent as PlusIcon } from "../assets/PlusIcon.svg";
import { ReactComponent as BackArrow } from "../assets/BoldBackArrow.svg";
import { ReactComponent as MbBackArrow } from "../assets/blackArrow.svg";
import { ReactComponent as FiltersIcon } from "../assets/FiltersIcon.svg";
import { ReactComponent as RefreshIcon } from "../assets/refreshIcon.svg";
import { AnimatedButton } from "../common/components";
import {
  ANALYTICS_EVENTS_JSON,
  ROUTE_PATH,
  SHOW_ALL,
  USER_DEFINED_CARDS,
} from "../common/constants";
import TRANSLATIONS from "../translations/en.json";
import { logAnalyticsEvent } from "../utils";
import "./WelcomeTitle.css";

// lazy loading components
const DashboardWelcome = lazy(() =>
  import("../common/components/DashboardWelcome")
);

function WelcomeTitle({
  handleActions,
  isBack,
  isCustomCardSelected,
  isFilterOpen,
  isFilterSave,
  isHideActions,
  isSeeAll,
  refreshTaskSettings,
  setIsCustomSelected,
  setIsFilterOpen,
  setIsFilterSave,
  setIsSeeAll,
  style,
  title,
}) {
  function renderTitle() {
    const history = useHistory();
    return (
      <p
        className="task-title cursor-pointer m-0"
        onClick={() =>
          history.push({
            pathname: !isHideActions
              ? ROUTE_PATH.MEMBER_PROFILE
              : isBack
              ? ROUTE_PATH.TEAM_MEMBERS
              : ROUTE_PATH.TASK_ASSIGMENT,
            state: JSON.parse(localStorage.getItem("teamUserDetails")),
          })
        }
      >
        <BackArrow width={24} height={24} className="mr-3 d-none d-md-block" />{" "}
        <MbBackArrow
          width={24}
          height={24}
          className="mr-3 d-block d-md-none back-btn-mb"
        />{" "}
        {title}
      </p>
    );
  }
  function renderActions() {
    const history = useHistory();
    return !isHideActions ? (
      <Suspense fallback="">
        <AnimatedButton
          className="mr-3 add-custom-card-btn"
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.CREATE_CUSTOM_CARD);
            setIsSeeAll(false);
            setIsFilterOpen(false);
            setIsFilterSave(false);
            setIsCustomSelected(false);
            history.push({
              pathname: ROUTE_PATH.CUSTOM_CARD_CREATION,
              state: JSON.parse(localStorage.getItem("teamUserDetails")),
            });
          }}
          disabled={isHideActions}
        >
          <PlusIcon />
        </AnimatedButton>
        <AnimatedButton
          className={
            isSeeAll ? "task-filled-btn mr-3" : "task-filled-btn-outline mr-3"
          }
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SEE_ALL_TASKS_BTN);
            handleActions(SHOW_ALL);
            setIsSeeAll(true);
            setIsFilterOpen(false);
            setIsFilterSave(false);
            setIsCustomSelected(false);
          }}
          disabled={isHideActions}
        >
          {TRANSLATIONS.WELCOME_SCREEN.ALL}
        </AnimatedButton>
        <AnimatedButton
          className={
            isCustomCardSelected
              ? "task-filled-btn mr-3"
              : "task-filled-btn-outline mr-3"
          }
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.MY_CUSTOM_CARD_BTN);
            handleActions(USER_DEFINED_CARDS);
            setIsSeeAll(false);
            setIsFilterOpen(false);
            setIsFilterSave(false);
            setIsCustomSelected(true);
          }}
        >
          {TRANSLATIONS.ASSIGN_TASK.MY_CUSTOM_CARD}
        </AnimatedButton>
        <AnimatedButton
          className={
            isFilterOpen
              ? "task-filled-btn mr-3"
              : isFilterSave
              ? "task-filled-btn mr-3"
              : "task-filled-btn-outline mr-3"
          }
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.FILTERS_BTN);
            setIsFilterOpen(true);
            setIsSeeAll(false);
            setIsCustomSelected(false);
          }}
        >
          {TRANSLATIONS.ASSIGN_TASK.FILTERS}
          <FiltersIcon
            width={16}
            height={16}
            className={`ml-1 ${
              isFilterOpen || isFilterSave ? "filter-icon-color" : ""
            }`}
          />
        </AnimatedButton>
        <AnimatedButton
          className="btn task-refresh-icon"
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.ASSIGN_TASK_REFRESH);
            refreshTaskSettings();
          }}
        >
          <RefreshIcon />
        </AnimatedButton>
      </Suspense>
    ) : (
      ""
    );
  }
  return (
    <DashboardWelcome
      actions={renderActions()}
      headerTitle={renderTitle()}
      isTask={true}
      isMobile={style}
    />
  );
}
WelcomeTitle.propTypes = {
  handleActions: PropTypes.func.isRequired,
  isBack: PropTypes.bool.isRequired,
  isCustomCardSelected: PropTypes.bool,
  isFilterOpen: PropTypes.bool,
  isFilterSave: PropTypes.bool,
  isHideActions: PropTypes.bool,
  isSeeAll: PropTypes.bool,
  refreshTaskSettings: PropTypes.func.isRequired,
  setIsCustomSelected: PropTypes.func,
  setIsFilterOpen: PropTypes.func,
  setIsFilterSave: PropTypes.func,
  setIsSeeAll: PropTypes.func,
  style: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
export default memo(WelcomeTitle);
