import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import DeleteTeam from "./DeleteTeam";
import { manageBodyClass } from "../utils";
import DeleteTeamModal from "./DeleteTeamModal";
import Notifications from "./NotificationsCard";
import RequestSupportCard from "./RequestSupportCard";
import TRANSLATIONS from "../translations/en.json";
import WelcomeTitle from "./WelcomeTitle";
import { deleteTeamQuestionsRequest } from "../redux/actions";
import { showError, showSuccess } from "../utils";
import "./index.css";
function AccountSettings() {
  const dispatch = useDispatch();
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);

  const handleConfirmToggle = () => {
    setIsOpenConfirmation(!isOpenConfirmation);
  };

  const {
    deleteTeamQuestionsData,
    deleteTeamData,
    isDeleteTeaRequestDataLoading,
    deleteTeamError,
    notificationSettigsRequestData,
    notificationSettigsData,
    notificationSettigsError,
  } = useSelector((state) => state.teamSettings);

  useEffect(() => {
    dispatch(deleteTeamQuestionsRequest({}));
    manageBodyClass("team-members-screen", "add");
    return () => {
      manageBodyClass("team-members-screen", "remove");
    };
  }, []);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!isDeleteTeaRequestDataLoading && deleteTeamData) {
      showSuccess(deleteTeamData);
    } else if (!isDeleteTeaRequestDataLoading && deleteTeamError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [isDeleteTeaRequestDataLoading, deleteTeamData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (!notificationSettigsRequestData && notificationSettigsData) {
      showSuccess(notificationSettigsData);
    } else if (!notificationSettigsRequestData && notificationSettigsError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [notificationSettigsRequestData, notificationSettigsData]);

  return (
    <>
      {isOpenConfirmation && (
        <DeleteTeamModal
          isOpen={isOpenConfirmation}
          toggle={handleConfirmToggle}
          questionsData={deleteTeamQuestionsData}
        />
      )}
      <WelcomeTitle isHideActions={true} />
      <div
        className="account-settings"
        style={{ height: document.documentElement.clientHeight - 160 }}
      >
        <Row>
          <Col md={6} lg={6} sm={12} xs={12} className="left-col">
            <RequestSupportCard />
          </Col>
          <Col md={6} lg={6} sm={12} xs={12} className="right-col">
            <Notifications />
          </Col>
        </Row>
        <DeleteTeam
          handleDelete={() => setIsOpenConfirmation(!isOpenConfirmation)}
        />
      </div>
    </>
  );
}
AccountSettings.propTypes = {
  userDetails: PropTypes.object,
};
export default AccountSettings;
