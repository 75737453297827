import PropTypes from "prop-types";
import React, { memo, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import DefaultImage from "../../../assets/AdminProfile/DefaultProfile.svg";
import { ReactComponent as AssignedIcon } from "../../../assets/AssignedIcon.svg";
import { ReactComponent as CalenderIcon } from "../../../assets/CalendarIcon.svg";
import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import {
  CHEMICALS,
  DATE_FORMAT,
  TIME_FORMAT,
  TIME_INTERVAL,
} from "../../../common/constants";
import {
  formatDateAndExtractYear,
  getColorBasedOnType,
  handleMaxAndMinTime,
} from "../../../utils";
import TRANSLATION from "../.././../translations/en.json";
import "../index.css";
import "./DateTime.css";

function DateTime({
  setActiveStep,
  startDate,
  setStartDate,
  userDetails,
  selectedCategoryOption,
}) {
  const datePickerRef = useRef(null);
  const [isDateRange, setIsDateRange] = useState(false);
  const { minTime, maxTime } = handleMaxAndMinTime(startDate);
  const handleShowDateRange = () => {
    setIsDateRange(!isDateRange);
    if (datePickerRef.current) {
      datePickerRef.current.onInputClick();
    }
  };

  const chemicalIcon = CHEMICALS.filter(
    (chem) => chem.alt === selectedCategoryOption?.title
  );

  const chemicalDescriptions = {
    dopamine: {
      Title: "The Reward Chemical",
    },
    oxytocin: {
      Title: "The Love Hormone",
    },
    serotonin: {
      Title: "The Mood Stabilizer",
    },
    endorphin: {
      Title: "The Relief Chemical",
    },
  };

  return (
    <div>
      <div
        className="h-100 card-chem-container"
        style={{
          backgroundColor: "#EAF6FB",
          border: `4px solid ${getColorBasedOnType(
            selectedCategoryOption?.title
          )}`,
        }}
      >
        <img
          src={chemicalIcon[0]?.src}
          alt={chemicalIcon[0]?.alt}
          width={64}
          height={64}
          className="img-mixin-background"
        />
        <h2 className="chem-name">{selectedCategoryOption?.title}</h2>
        <p className="chem-desc px-5 mb-5">
          {chemicalDescriptions[chemicalIcon[0]?.alt]?.Title}
        </p>
        <div className="assigned-user-details mb-3">
          <AssignedIcon width={24} height={24} />
          <div className="assigned-user">
            <img
              src={
                userDetails?.profileImageURL
                  ? userDetails?.profileImageURL
                  : DefaultImage
              }
              alt="user_image"
              className="assigned-user-profile-img"
              title={userDetails?.name}
            />
          </div>
        </div>
        <div className="assigned-date-container mb-3">
          <CalenderIcon
            width={28}
            height={28}
            onClick={handleShowDateRange}
            className="cursor-pointer"
          />
          <div className="datetime-date-picker">
            <DatePicker
              showPopperArrow={false}
              ref={datePickerRef}
              className="date-picker"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showTimeSelect
              minDate={new Date()}
              minTime={startDate <= new Date() ? new Date() : minTime} // Update minTime based on selected date
              maxTime={
                startDate <= new Date()
                  ? maxTime
                  : new Date("9999-12-31T23:59:59")
              } // Update maxTime based on selected date
              timeFormat={TIME_FORMAT}
              timeIntervals={TIME_INTERVAL}
              timeCaption={TRANSLATION.CUSTOM_CARD_CREATION.TIME}
              dateFormat={DATE_FORMAT}
              onClickOutside={() => setIsDateRange(!isDateRange)}
            />
          </div>
          <span className="selected-time-text">{`${
            formatDateAndExtractYear(startDate)?.fulltimeFormat
          }`}</span>
        </div>
      </div>
      <p className="mb-text-note">
        {TRANSLATION.CUSTOM_CARD_CREATION.DATE_TIME_TEXT}
      </p>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 0px" }}
      >
        <div
          className="stepper-back-btn cursor-pointer"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          <BackArrow />
        </div>{" "}
        <button
          className="stepper-continue-btn"
          onClick={() => setActiveStep((prev) => prev + 1)}
          disabled={startDate?.length}
        >
          {TRANSLATION.CUSTOM_CARD_CREATION.CONTINUE} <BackArrow />
        </button>
      </div>
    </div>
  );
}

DateTime.propTypes = {
  setActiveStep: PropTypes.func.isRequired,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  userDetails: PropTypes.object,
  selectedCategoryOption: PropTypes.object,
};

export default memo(DateTime);
