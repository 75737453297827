import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner } from "reactstrap";

import { TEAM_MEMBER_STATUS, PRO } from "../common/constants";
import { adminProfileActivity } from "../redux/actions";
import InviteCodeModel from "../teamMembers/InviteCodeModel";
import { lazyRetry, showError, showSuccess } from "../utils";
import "./index.css";

// lazy loading components
const AccountStatusCard = lazy(() => lazyRetry(()=> import("./AccountStatusCard"), "accountStatusCard"));
const AssignTeam = lazy(() => lazyRetry(() => import("./AssignTeams"), "assignTeams"));
const DashboardReportCard = lazy(() => lazyRetry(() => import("./DashboardReportCard"), "dashboardReportCard"));
const Feedback = lazy(() => lazyRetry(() => import("./Feedback"), "feedback") );
// const LifeCoachesCard = lazy(() => import("./LifeCoachesCard"));
const MyProfile = lazy(() => lazyRetry(()=> import("./MyProfile"), "myProfile"));
const WelcomeTitle = lazy(() =>  lazyRetry(() => import("./WelcomeTitle"), "WelcomeTitle"));

function AdminProfile({ userDetails }) {
  const dispatch = useDispatch();
  const [allTeamMembers, setAllTeamMembers] = useState([]);
  /*invite team modal toggle */
  const [modal, setModal] = useState(false);
  const inviteModaltoggle = () => setModal(!modal);

  const {
    isAdminProfileActivityLoading,
    adminProfileRequestData,
    adminProfileRequestError,
  } = useSelector((state) => state.AdminProfile);
  const { teamSettigsRequestData, teamSettigsData } = useSelector(
    (state) => state.teamSettings
  );

  //to get the data of the team members
  useEffect(() => {
    if (!teamSettigsRequestData && teamSettigsData?.length) {
      let validTeamMembersData = teamSettigsData.filter((item) =>
        [TEAM_MEMBER_STATUS.PENDING, TEAM_MEMBER_STATUS.ACCEPTED].includes(
          item.status
        )
      );
      if (validTeamMembersData?.length) {
        setAllTeamMembers(validTeamMembersData);
      } else {
        setAllTeamMembers([]);
      }
    }
  }, [teamSettigsRequestData, teamSettigsData]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!isAdminProfileActivityLoading && adminProfileRequestData) {
      showSuccess(adminProfileRequestData);
    } else if (!isAdminProfileActivityLoading && adminProfileRequestError) {
      showError(adminProfileRequestError);
    }
  }, [isAdminProfileActivityLoading, adminProfileRequestData]);

  const handleRequest = (requestType) => {
    dispatch(adminProfileActivity({ requestType, description: "" }));
  };
  return (
    <Suspense fallback="">
      <div className="admin-profile">
        {(isAdminProfileActivityLoading || teamSettigsRequestData) && (
          <div className="m-auto">
            <Spinner className="loader-container dashboard-loader overlay-adminScreen" />
          </div>
        )}
        {modal && (
          <InviteCodeModel
            modal={modal}
            setModal={setModal}
            toggle={inviteModaltoggle}
          />
        )}
        <Row>
          <Col md={8} sm={12} xs={12} className="px-0 px-md-1 pr-md-4">
            <Col md={12} className="px-0">
              <WelcomeTitle userName={userDetails?.name} />
            </Col>
            <Row className="admin-cards">
              <Col md={4} sm={12} xs={12} className="account-status-card-col">
                <DashboardReportCard
                  handleRequest={handleRequest}
                  isDisabled={allTeamMembers?.length <= 1}
                />
              </Col>
              {/* <Col md={4} sm={12} xs={12} className="account-status-card-col">
                <LifeCoachesCard
                  handleRequest={handleRequest}
                  isDisabled={allTeamMembers?.length <= 1}
                />
              </Col> */}
              <Col md={4} sm={12} xs={12} className="account-status-card-col">
                <AccountStatusCard handleRequest={handleRequest} />
              </Col>
            </Row>
            <Row>
              {userDetails?.subscription === PRO ? (
                <Col md={12} className="px-0 px-md-3">
                  <AssignTeam
                    userDetails={userDetails}
                    inviteModaltoggle={inviteModaltoggle}
                  />
                </Col>
              ) : (
                ""
              )}

              <Col md={12} className="px-0 px-md-3">
                <Feedback />
              </Col>
            </Row>
          </Col>
          <Col
            md={4}
            sm={12}
            xs={12}
            className="profile-col px-0"
            style={{ height: document.documentElement.clientHeight }}
          >
            <MyProfile userDetails={userDetails} />
          </Col>
        </Row>
      </div>
    </Suspense>
  );
}
AdminProfile.propTypes = {
  userDetails: PropTypes.object,
};
export default AdminProfile;
