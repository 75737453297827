import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { updateProfileError, updateProfileSuccess } from "./actions";

import { UPDATE_PROFILE_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* updateProfile(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/update`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(updateProfileSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(updateProfileError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error?.response?.data,
        netWorkError: true,
      },
    };
    yield put(updateProfileError(errorData));
  }
}

export function* watchUpdateProfile() {
  yield takeEvery(UPDATE_PROFILE_REQUEST, updateProfile);
}

function* UpdateProfileSaga() {
  yield all([fork(watchUpdateProfile)]);
}
export default UpdateProfileSaga;
