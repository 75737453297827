//External packages
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { Field, change, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";

//local files
import { dashBoardSetupRequest } from "../redux/actions";
import { manageBodyClass, showError, storeToken } from "../utils";
import { PRO, ROUTE_PATH } from "../common/constants";
import {
  inputField,
  phoneNumberField,
  validateName,
} from "../common/reduxFields";
import Righticon from "../assets/RightArrow.svg";
import TRANSLATIONS from "../translations/en.json";
import {
  AnimatedButton,
  ButtonLoader,
  UploadProfileModal,
} from "../common/components";
import "./MyProfile.css";

const DashBoardSetUp = (props) => {
  const { handleSubmit } = props;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState("US");
  const [url, setUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [isUpdatePic, setIsUpdatePic] = useState(false);
  const [number, setNumber] = useState("");
  const [isInValidNumber, setIsInValidNumber] = useState(false);
  const [focused, setFocused] = useState(false);
  const [errorResponse, setErrorResponse] = useState(false);

  const userSignupEmail = location?.state?.email;

  const [formData, setFormData] = useState({
    email: userSignupEmail,
    teamName: "",
    name: "",
    teamGoals: [],
    phoneNumber: "",
    countryCode: countryCode,
    subscription: localStorage.getItem("subscription"),
  });

  useEffect(() => {
    if (
      formData?.email?.trim() !== "" &&
      formData?.teamName?.trim() !== "" &&
      formData?.name?.trim() !== ""
    ) {
      setDisableButton(false);
    }
  }, [formData, number]);

  useEffect(() => {
    if (isValidPhoneNumber(number)) {
      setIsInValidNumber(false);
      setErrorResponse("");
    } else {
      setErrorResponse(TRANSLATIONS.COMMON.INVALID_NUMBER);
      setIsInValidNumber(true);
    }
  }, [number]);

  const toggle = () => setModal((prev) => !prev);

  const { dashBoardSetupData, dashBoardSetupRequestData, dashBoardSetupError } =
    useSelector((state) => state.DashBoardSetup);

  const onSubmit = () => {
    dispatch(dashBoardSetupRequest(formData));
  };
  const FirstRun = useRef(true);
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (dashBoardSetupRequestData) {
      setIsLoading(true);
    } else if (dashBoardSetupData) {
      storeToken(dashBoardSetupData.jwtToken, true);
      setIsLoading(false);
      window.scrollTo(0, 0);
      manageBodyClass("vertical-menu-collapsed", "add");
      history.push(ROUTE_PATH.TEAM_MEMBERS);
    } else {
      setIsLoading(false);
      if (
        dashBoardSetupError?.errorResponse &&
        typeof dashBoardSetupError?.errorResponse === "string"
      ) {
        if (
          dashBoardSetupError.errorResponse ===
          TRANSLATIONS.DASHBOARD_SETUP.API_MOBILE_ERROR
        ) {
          setErrorResponse(TRANSLATIONS.DASHBOARD_SETUP.MOBILE_NUMBER_ERROR);
        } else {
          setFormData((prev) => {
            return {
              ...prev,
              phoneNumber: "",
            };
          });
          setValue("");
          dispatch(change("DashBoardSetUp", "phoneNumber", ""));
          showError(dashBoardSetupError.errorResponse);
        }
      }
    }
  }, [dashBoardSetupError, dashBoardSetupRequestData]);

  const handleName = (value) => {
    formData.name = value;
    setFormData({ ...formData });
  };
  const handleTeamName = (value) => {
    formData.teamName = value;
    setFormData({ ...formData });
  };

  const handleChange = (value) => {
    let mobileNumber;
    if (value) {
      setFocused(true);
      setNumber(value);
      mobileNumber = parsePhoneNumber(value);
      formData.phoneNumber = mobileNumber?.number;
      setFormData({ ...formData });
    }
    if (mobileNumber) {
      formData.countryCode = mobileNumber?.country;

      setCountryCode(mobileNumber.country);
      setFormData({ ...formData });
    }
    setValue(formatPhoneNumber(value));
  };

  return (
    <>
      {modal && (
        <UploadProfileModal
          modal={modal}
          setModal={setModal}
          toggle={toggle}
          url={url}
          setUrl={setUrl}
          setIsUpdatePic={setIsUpdatePic}
        />
      )}
      <div className="bg-login-dashBoard ">
        <div className="auth-grey-section p-2">
          <form
            id="dashboard-setup-form"
            onSubmit={handleSubmit(onSubmit)}
            className="dashboard-setup"
          >
            <div className="form-group input-email-dashboard">
              <div className="">
                <h1 className="auth-header-text">
                  {" "}
                  {TRANSLATIONS.DASHBOARD_SETUP.DASHBOARD_SETUP_HEADER}
                </h1>
                <p className="dashBoardSetup-para">
                  {TRANSLATIONS.DASHBOARD_SETUP.LETS_GET_YOUR_DASHBOARD_SETUP}
                </p>
                <Row>
                  <Col
                    lg={6}
                    md={10}
                    sm={10}
                    xs={12}
                    className="mx-auto mobile-col "
                  >
                    <Row>
                      <Col
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="m-auto justify-align-center"
                      >
                        <div
                          id="outline-frame-dark"
                          className="mt-4 cursor-pointer"
                          onClick={() => toggle()}
                        >
                          {url && isUpdatePic ? (
                            <>
                              <span className="profile-centerer"></span>
                              <img
                                src={url}
                                alt="profile"
                                className="uploadedImg"
                              />
                            </>
                          ) : (
                            <>
                              <span className="profile-middle"></span>
                              <p className="click-to-add">
                                {TRANSLATIONS.DASHBOARD_SETUP.CLICK_ADD}
                              </p>
                            </>
                          )}{" "}
                        </div>
                      </Col>
                      <Col lg={9} md={9} sm={12} xs={12}>
                        <div className="">
                          <h5 className="dashboardSetUp-header d-none d-md-block text-left">
                            {TRANSLATIONS.DASHBOARD_SETUP.FULL_NAME}
                          </h5>
                          <h5 className="dashboardSetUp-header d-md-none d-sm-block text-left">
                            {TRANSLATIONS.DASHBOARD_SETUP.MOBILE_FULL_NAME_TEXT}
                          </h5>

                          <Field
                            name="name"
                            id="Name"
                            type="text"
                            component={inputField}
                            className="input-email-dashboard fullName-dashboard"
                            placeholder={
                              TRANSLATIONS.DASHBOARD_SETUP.FULL_NAME_HERE
                            }
                            onChange={(e) => handleName(e.target.value)}
                            required
                            validate={[validateName]}
                          />
                        </div>
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        <div className="mt-2 align-row-lg">
                          <div className="col-lg-8 col-12 mb-3 px-0">
                            <h1 className="dashboardSetUp-header text-left">
                              {
                                TRANSLATIONS.DASHBOARD_SETUP
                                  .OPTIONAL_PHONE_NUMBER
                              }
                            </h1>
                            <div
                              className={`phone-input-2 ${
                                focused && errorResponse ? "error-inputs" : ""
                              }`}
                            >
                              <Field
                                international={true}
                                countryCallingCodeEditable={false}
                                name="phoneNumber"
                                placeholder={
                                  TRANSLATIONS.DASHBOARD_SETUP
                                    .PHONE_NUMBER_WITH_CODE
                                }
                                defaultCountry="US"
                                value={value}
                                component={phoneNumberField}
                                onChange={handleChange}
                              />
                            </div>
                            {errorResponse && focused && (
                              <span className="form-error">
                                {errorResponse}
                              </span>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <hr className="hr-line" />
                  <Col
                    lg={6}
                    md={10}
                    sm={10}
                    xs={12}
                    className="mx-auto mobile-col"
                  >
                    <Row>
                      <Col md={12} sm={12} xs={12}>
                        <div className="">
                          <h1 className="dashboardSetUp-header text-left team-name">
                            {localStorage.getItem("subscription") === PRO
                              ? TRANSLATIONS.DASHBOARD_SETUP.PRO_NAME
                              : TRANSLATIONS.DASHBOARD_SETUP.TEAM_NAME}
                          </h1>

                          <Field
                            name="teamName"
                            component={inputField}
                            placeholder={
                              localStorage.getItem("subscription") === PRO
                                ? TRANSLATIONS.DASHBOARD_SETUP
                                    .PRO_NAME_PLACEHOLDER
                                : TRANSLATIONS.DASHBOARD_SETUP.TEAM_NAME_HERE
                            }
                            required
                            validate={[validateName]}
                            onChange={(e) => handleTeamName(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md={12} sm={12} xs={12}>
                        <div className="justify-align-center finish-setup-btn auth-page-bottom ">
                          <AnimatedButton
                            type="submit"
                            className="auth-btn"
                            disabled={
                              number?.length
                                ? isInValidNumber || disableButton
                                : disableButton
                            }
                          >
                            {TRANSLATIONS.DASHBOARD_SETUP.FINISH_TEAM_SETUP}

                            {isLoading ? (
                              <ButtonLoader />
                            ) : (
                              <img
                                src={Righticon}
                                alt="icon"
                                className=" right-arrow-btn mt-1"
                              />
                            )}
                          </AnimatedButton>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

DashBoardSetUp.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.obj,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
};

export default connect()(
  reduxForm({
    form: "DashBoardSetUp",
    enableReinitialize: true,
    touchOnChange: true,
  })(DashBoardSetUp)
);
