import PropTypes from "prop-types";
import React, { memo } from "react";
import { Input, Label } from "reactstrap";

import { ReactComponent as CheckIcon } from "../../assets/CheckIcon.svg";
import TRANSLATION from "../../translations/en.json";
import "./PurposeComponent.css";

function PurposeComponent({
  handleSelectOption,
  options,
  selectedCategoryOption,
}) {
  return (
    <>
      <h2 className="purpose-header mb-3">
        {TRANSLATION.CUSTOM_CARD_CREATION.QUESTION_4}
      </h2>
      <div className="caregory-option-container">
        {options?.length
          ? options.map((option, index) => {
              return (
                <div key={index} className="category-radio-container my-3">
                  <div className="label-container">
                    <Label
                      className="category-radio-option-label"
                      style={
                        selectedCategoryOption?.title === option?.title
                          ? { fontWeight: 700 }
                          : {}
                      }
                    >
                      {option?.description}
                    </Label>
                  </div>
                  <div className="radio-icon-container">
                    {selectedCategoryOption?.title === option?.title ? (
                      <div
                        className="selected-radio-icon"
                        onClick={() => handleSelectOption(option, option.type)}
                      >
                        <CheckIcon width={16} height={16} />
                      </div>
                    ) : (
                      <Input
                        type="radio"
                        value={option?.title}
                        className="radio-input cursor-pointer"
                        checked={
                          selectedCategoryOption?.title === option?.title
                        }
                        onChange={() => handleSelectOption(option, option.type)}
                      />
                    )}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
}

PurposeComponent.propTypes = {
  handleSelectOption: PropTypes.func,
  options: PropTypes.array,
  selectedCategoryOption: PropTypes.object,
};

export default memo(PurposeComponent);
