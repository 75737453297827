import {
  ASSIGN_TASKS_ERROR,
  ASSIGN_TASKS_REQUEST,
  ASSIGN_TASKS_SUCCESS,
  CREATE_CUSTOM_CARD_ERROR,
  CREATE_CUSTOM_CARD_REQUEST,
  CREATE_CUSTOM_CARD_SUCCESS,
  GET_ALL_INCLUSIVITY_ERROR,
  GET_ALL_INCLUSIVITY_REQUEST,
  GET_ALL_INCLUSIVITY_SUCCESS,
  GET_ALL_JOURNAL_ERROR,
  GET_ALL_JOURNAL_REQUEST,
  GET_ALL_JOURNAL_SUCCESS,
  GET_ALL_PURPOSE_ERROR,
  GET_ALL_PURPOSE_REQUEST,
  GET_ALL_PURPOSE_SUCCESS,
  GET_ALL_TIME_ERROR,
  GET_ALL_TIME_REQUEST,
  GET_ALL_TIME_SUCCESS,
  GET_TASKS_ERROR,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
} from "./actionTypes";

export const getTasksAction = (user) => {
  return {
    type: GET_TASKS_REQUEST,
    payload: user,
  };
};

export const getTasksSuccess = (user, history) => {
  return {
    type: GET_TASKS_SUCCESS,
    payload: { user, history },
  };
};

export const getTasksError = (history) => {
  return {
    type: GET_TASKS_ERROR,
    payload: { history },
  };
};

// ASSIGN TASK
export const assignTaskAction = (user) => {
  return {
    type: ASSIGN_TASKS_REQUEST,
    payload: user,
  };
};

export const assignTaskSuccess = (user, history) => {
  return {
    type: ASSIGN_TASKS_SUCCESS,
    payload: { user, history },
  };
};

export const assignTaskError = (history) => {
  return {
    type: ASSIGN_TASKS_ERROR,
    payload: { history },
  };
};

// GET ALL INCLUSIVITY OPTIONS
export const getAllInclusivityAction = (user) => {
  return {
    type: GET_ALL_INCLUSIVITY_REQUEST,
    payload: user,
  };
};

export const getAllInclusivitySuccess = (user, history) => {
  return {
    type: GET_ALL_INCLUSIVITY_SUCCESS,
    payload: { user, history },
  };
};

export const getAllInclusivityError = (history) => {
  return {
    type: GET_ALL_INCLUSIVITY_ERROR,
    payload: { history },
  };
};

// GET ALL TIME OPTIONS
export const getAllTimeOptionsAction = (user) => {
  return {
    type: GET_ALL_TIME_REQUEST,
    payload: user,
  };
};

export const getAllTimeOptionsSuccess = (user, history) => {
  return {
    type: GET_ALL_TIME_SUCCESS,
    payload: { user, history },
  };
};

export const getAllTimeOptionsError = (history) => {
  return {
    type: GET_ALL_TIME_ERROR,
    payload: { history },
  };
};

// GET ALL JOURNAL OPTIONS
export const getAllJournalOptionsAction = (user) => {
  return {
    type: GET_ALL_JOURNAL_REQUEST,
    payload: user,
  };
};

export const getAllJournalOptionsSuccess = (user, history) => {
  return {
    type: GET_ALL_JOURNAL_SUCCESS,
    payload: { user, history },
  };
};

export const getAllJournalOptionsError = (history) => {
  return {
    type: GET_ALL_JOURNAL_ERROR,
    payload: { history },
  };
};

// GET ALL PURPOSE OPTIONS
export const getAllPurposeOptionsAction = (user) => {
  return {
    type: GET_ALL_PURPOSE_REQUEST,
    payload: user,
  };
};

export const getAllPurposeOptionsSuccess = (user, history) => {
  return {
    type: GET_ALL_PURPOSE_SUCCESS,
    payload: { user, history },
  };
};

export const getAllPurposeOptionsError = (history) => {
  return {
    type: GET_ALL_PURPOSE_ERROR,
    payload: { history },
  };
};

// CREATE CUSTOM CARD
export const createCustomCardAction = (user) => {
  return {
    type: CREATE_CUSTOM_CARD_REQUEST,
    payload: user,
  };
};

export const createCustomCardSuccess = (user, history) => {
  return {
    type: CREATE_CUSTOM_CARD_SUCCESS,
    payload: { user, history },
  };
};

export const createCustomCardError = (history) => {
  return {
    type: CREATE_CUSTOM_CARD_ERROR,
    payload: { history },
  };
};
