export const StackedChartOptions = {
  chart: {
    height: 350,
    stacked: true,
    zoom: {
      enabled: false,
    },
  },
  colors: ["#ffdb43", "#EAE9E5"],
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "60%",
      borderRadiusApplication: "around",
      borderRadiusWhenStacked: "last",
      dataLabels: {
        total: {
          enabled: true,
          position: "top",
          offsetX: 5,
          formatter: function (val, opt) {
            const getActivityScore = (key) => {
              return opt.config.activitiesPerTeamData?.length
                ? opt.config.activitiesPerTeamData[opt.dataPointIndex]?.[key]
                : 0;
            };
            const completedActivityScore = getActivityScore(
              "completed_activities"
            );
            const inCompletedActivityScore = getActivityScore(
              "incompleted_activities"
            );

            const value =
              ` ${completedActivityScore}/${
                inCompletedActivityScore + completedActivityScore
              }` || " 0/0";
            return value;
          },
          style: {
            fontSize: "12px",
            fontFamily: "AvertaBold",
          },
        },
      },
    },
  },
  grid: {
    show: false,
  },

  xaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  dataLabels: {
    formatter: function (val, opt) {
      if (opt.seriesIndex === 1) {
        return val === 100 ? "0%" : "";
      } else {
        return (val ? Math.round(val) : 0) + "%";
      }
    },
    enabled: true,
    style: {
      colors: ["#000"],
    },
  },
  responsive: [
    {
      breakpoint: 576,
      options: {
        dataLabels: {
          style: {
            fontSize: "10px",
          },
        },
      },
    },
    {
      breakpoint: 475,
      options: {
        dataLabels: {
          style: {
            fontSize: "7px",
          },
        },
      },
    },
  ],
  yaxis: {
    max: 100,
    labels: {
      align: "left",
      offsetX: -5,
    },
  },

  tooltip: {
    intersect: false,
    shared: true,
    y: {
      formatter: function (val, opt) {
        if (opt.seriesIndex === 1) {
          return "0%";
        } else {
          return (val > 0 && val <= 100 ? val : 0) + "%";
        }
      },
    },
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    offsetY: 30,
    customLegendItems: ["Completed", "Not Committed"],
  },
};
