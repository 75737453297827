import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import "./ErrorBoundaruComponent.css";
import AnimatedButton from "./AnimatedButton";
// import { ReactComponent as NotificationAlertHexa } from "../../assets/notificationAlertHexa.svg";
import ErrorBoundaryImage from "../../assets/ErrorBoundaryImage.png";
import { ROUTE_PATH } from "../constants";
import TRANSLATIONS from "../../translations/en.json";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  componentDidUpdate(prevProps) {
    // Reset the error state if the component receives new props
    if (prevProps.location !== this.props.location) {
      this.setState({ hasError: false });
    }
  }

  handleGoBack = () => {
    this.props.history.push(ROUTE_PATH.HOME);
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="eroor-boundary-container full-height">
            <div className="text-center flex-center h-100">
              <div className="error-div">
                {/* <NotificationAlertHexa className="error" /> */}
                <img
                  src={ErrorBoundaryImage}
                  alt="companylogo"
                  className="enterprise-logo"
                />
              </div>
              <div className="error-message">
                <p>{TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG}</p>
              </div>
              <AnimatedButton
                type="button"
                className="btn btn-secondary flow-button  back-button  mt-2 justify-align-center"
                onClick={this.handleGoBack}
              >
                Back to Home
              </AnimatedButton>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.func,
  location: PropTypes.node,
};
export default withRouter(ErrorBoundary);
