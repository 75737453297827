import { AXIOS_INSTANCE, CONTACT_US_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { userDetailsError, userDetailsSuccess } from "./actions";

import { USER_DETAILS_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* userDetails(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CONTACT_US_API}/`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(userDetailsSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(userDetailsError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(userDetailsError(errorData));
  }
}

export function* watchUserDetails() {
  yield takeEvery(USER_DETAILS_REQUEST, userDetails);
}

function* UserDetailsSaga() {
  yield all([fork(watchUserDetails)]);
}
export default UserDetailsSaga;
