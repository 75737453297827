import TRANSLATIONS from "../../translations/en.json";

export const DELETE_TEAM_REASONS = [
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.NEW_ROLE,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.CHANING_TEAMS,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.LEAVE_COMPANY,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.NO_NEEDED,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.COMPANY_SHUT_DOWN,
  },
];

export const DELETE_PRO_REASONS = [
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.ENDING_CONTRACT,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.NEW_JOB,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.CHANGING_CARRERS,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.LEAVING_THE_COMPANY,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.NO_LONGER_NEEDED,
  },
];

export const DELETE_TEAM_YES_OR_NO = [
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.YES,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.NO,
  },
];

export const DELETE_TEAM_ISSUES = [
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.BUGS,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.LOAD_TIME,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.LACKING_FEATURES,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.METRICS,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.LOGIN,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.DEVICE_COMPAT,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.POOR_USER,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.SECUIRTY,
  },
  {
    Option: TRANSLATIONS.ACCOUNT_SETTINGS.OTHER,
  },
];
