import {
  REQUEST_ACCESS_ERROR,
  REQUEST_ACCESS_REQUEST,
  REQUEST_ACCESS_SUCCESS,
} from "./actionTypes";

const initialState = {};

const RequestAccess = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ACCESS_REQUEST:
      return {
        ...state,
        isRequestAccessLoading: true,
        requestAccessData: false,
        requestAccessError: false,
      };
    case REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
        isRequestAccessLoading: false,
        requestAccessData: action.payload.user.data,
        requestAccessError: false,
      };
    case REQUEST_ACCESS_ERROR:
      return {
        ...state,
        isRequestAccessLoading: false,
        requestAccessData: false,
        requestAccessError: action.payload.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default RequestAccess;
