import {
  DELETE_MEMBER_ERROR,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  TEAM_SETTINGS_REQUEST,
  TEAM_SETTINGS_SUCCESS,
  TEAM_SETTINGS_ERROR,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_QUESTIONS_ERROR,
  DELETE_TEAM_QUESTIONS_REQUEST,
  DELETE_TEAM_QUESTIONS_SUCCESS,
  NOTIFICATIONS_SETTINGS_REQUEST,
  NOTIFICATIONS_SETTINGS_SUCCESS,
  NOTIFICATIONS_SETTINGS_ERROR,
} from "./actionTypes";

export const deleteMemberAction = (user) => {
  return {
    type: DELETE_MEMBER_REQUEST,
    payload: user,
  };
};

export const deleteMemberSuccess = (user, history) => {
  return {
    type: DELETE_MEMBER_SUCCESS,
    payload: { user, history },
  };
};

export const deleteMemberError = (history) => {
  return {
    type: DELETE_MEMBER_ERROR,
    payload: { history },
  };
};

export const teamSettingsRequest = (user) => {
  return {
    type: TEAM_SETTINGS_REQUEST,
    payload: user,
  };
};

export const teamSettingsSuccess = (user, history) => {
  return {
    type: TEAM_SETTINGS_SUCCESS,
    payload: { user, history },
  };
};

export const teamSettingsError = (history) => {
  return {
    type: TEAM_SETTINGS_ERROR,
    payload: { history },
  };
};

export const deleteTeamAction = (user) => {
  return {
    type: DELETE_TEAM_REQUEST,
    payload: user,
  };
};

export const deleteTeamSuccess = (user, history) => {
  return {
    type: DELETE_TEAM_SUCCESS,
    payload: { user, history },
  };
};

export const deleteTeamError = (history) => {
  return {
    type: DELETE_TEAM_ERROR,
    payload: { history },
  };
};

export const deleteTeamQuestionsRequest = (user) => {
  return {
    type: DELETE_TEAM_QUESTIONS_REQUEST,
    payload: user,
  };
};

export const deleteTeamQuestionsSuccess = (user, history) => {
  return {
    type: DELETE_TEAM_QUESTIONS_SUCCESS,
    payload: { user, history },
  };
};

export const deleteTeamQuestionsError = (history) => {
  return {
    type: DELETE_TEAM_QUESTIONS_ERROR,
    payload: { history },
  };
};

export const notificationSettingsRequest = (user) => {
  return {
    type: NOTIFICATIONS_SETTINGS_REQUEST,
    payload: user,
  };
};

export const notificationSettingsSuccess = (user, history) => {
  return {
    type: NOTIFICATIONS_SETTINGS_SUCCESS,
    payload: { user, history },
  };
};

export const notificationSettingsError = (history) => {
  return {
    type: NOTIFICATIONS_SETTINGS_ERROR,
    payload: { history },
  };
};
