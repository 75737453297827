import {
  ACTIVITIES_PER_TEAM_MEMBER_REQUEST,
  ACTIVITIES_PER_TEAM_MEMBER_SUCCESS,
  ACTIVITIES_PER_TEAM_MEMBER_ERROR,
} from "./actionTypes";

export const activitiesPerTeamMemberRequest = (user) => {
  return {
    type: ACTIVITIES_PER_TEAM_MEMBER_REQUEST,
    payload: user,
  };
};

export const activitiesPerTeamMemberSuccess = (user, history) => {
  return {
    type: ACTIVITIES_PER_TEAM_MEMBER_SUCCESS,
    payload: { user, history },
  };
};

export const activitiesPerTeamMemberError = (history) => {
  return {
    type: ACTIVITIES_PER_TEAM_MEMBER_ERROR,
    payload: { history },
  };
};
