import {
  AXIOS_INSTANCE,
  ENTERPRISE_API,
  JOURNAL_API,
} from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  getTimelineError,
  getTimelineSuccess,
  getAssignedTaskTimelineError,
  getAssignedTaskTimelineSuccess,
  getRemindTaskError,
  getRemindTaskSuccess,
  getEmojisSuccess,
  getEmojisError,
} from "./actions";

import {
  GET_TIMELINE_REQUEST,
  GET_ASSIGNED_TASK_TIMELINE_REQUEST,
  REMIND_TASK_REQUEST,
  GET_EMOJIS_REQUEST,
} from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* timelineRequest(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/timeline`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getTimelineSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getTimelineError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getTimelineError(errorData));
  }
}

function* assignedTaskTimelineRequest(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/get_assigned_tasks`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getAssignedTaskTimelineSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getAssignedTaskTimelineError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getAssignedTaskTimelineError(errorData));
  }
}

function* remindtaskRequest(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/reminder`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getRemindTaskSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getRemindTaskError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getRemindTaskError(errorData));
  }
}

function* emojisRequest(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${JOURNAL_API}/emotions`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(getEmojisSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(getEmojisError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(getEmojisError(errorData));
  }
}

export function* watchTimeline() {
  yield takeEvery(GET_TIMELINE_REQUEST, timelineRequest);
  yield takeEvery(
    GET_ASSIGNED_TASK_TIMELINE_REQUEST,
    assignedTaskTimelineRequest
  );
  yield takeEvery(REMIND_TASK_REQUEST, remindtaskRequest);
  yield takeEvery(GET_EMOJIS_REQUEST, emojisRequest);
}

function* TimelineSaga() {
  yield all([fork(watchTimeline)]);
}
export default TimelineSaga;
