import PropTypes from "prop-types";
import React, { memo, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import { ReactComponent as CalenderIcon } from "../../assets/CalendarIcon.svg";
import TRANSLATIONS from "../../translations/en.json";

import "react-datepicker/dist/react-datepicker.css";
import {
  DATE_FORMAT,
  TIME_FORMAT,
  TIME_INTERVAL,
} from "../../common/constants";
import { formatDateAndExtractYear, handleMaxAndMinTime } from "../../utils";
import "./AssignedDetails.css";
import "./DateTimeComponent.css";

function DateTimeComponent({ startDate, setStartDate }) {
  const datePickerRef = useRef(null);
  const [isDateRange, setIsDateRange] = useState(false);
  const { minTime, maxTime } = handleMaxAndMinTime(startDate);

  const handleShowDateRange = () => {
    setIsDateRange(!isDateRange);
    if (datePickerRef.current) {
      datePickerRef.current.onInputClick();
    }
  };

  return (
    <div className="assigned-details-container">
      <p className="assigned-text m-0">
        {TRANSLATIONS.CUSTOM_CARD_CREATION.SELECT_DATE_AND_TIME}
      </p>
      <div
        className="assigned-date-container cursor-pointer"
        onClick={handleShowDateRange}
      >
        <CalenderIcon
          width={28}
          height={28}
          onClick={handleShowDateRange}
          className="cursor-pointer"
        />
        <div className="assigned-date-picker">
          <DatePicker
            showPopperArrow={false}
            ref={datePickerRef}
            className="date-picker-hidden"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            minDate={new Date()}
            minTime={startDate <= new Date() ? new Date() : minTime} // Update minTime based on selected date
            maxTime={
              startDate <= new Date()
                ? maxTime
                : new Date("9999-12-31T23:59:59")
            } // Update maxTime based on selected date
            timeFormat={TIME_FORMAT}
            timeIntervals={TIME_INTERVAL}
            timeCaption={TRANSLATIONS.CUSTOM_CARD_CREATION.TIME}
            dateFormat={DATE_FORMAT}
            onClickOutside={() => setIsDateRange(!isDateRange)}
          />
        </div>
        <span className="assigned-selected-time-text">{`${
          formatDateAndExtractYear(startDate)?.fulltimeFormat
        }`}</span>
      </div>
    </div>
  );
}

DateTimeComponent.propTypes = {
  userDetails: PropTypes.object,
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
};

export default memo(DateTimeComponent);
