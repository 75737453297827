import React, { memo } from "react";
import PropTypes from "prop-types";

import { ANALYTICS_EVENTS_JSON, PRO } from "../common/constants";
import { AnimatedButton } from "../common/components";
import { logAnalyticsEvent } from "../utils";
import TRANSLATIONS from "../translations/en.json";
import "./DeleteTeam.css";

function DeleteTeam({ handleDelete }) {
  return (
    <div className="settings-footer">
      <span>
        {" "}
        {localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.ACCOUNT_SETTINGS.DELETE_LIFE_COACH
          : TRANSLATIONS.ACCOUNT_SETTINGS.TEAM_ACCOUNT}
      </span>
      <AnimatedButton
        type="button"
        className="delete-team-btn"
        onClick={() => {
          handleDelete();
          logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DELETE_TEAM);
        }}
      >
        {localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.ACCOUNT_SETTINGS.DELETE_ACCOUNT
          : TRANSLATIONS.ACCOUNT_SETTINGS.DELETE_TEAM}
      </AnimatedButton>
    </div>
  );
}
DeleteTeam.propTypes = {
  handleDelete: PropTypes.func.isRequired,
};
export default memo(DeleteTeam);
