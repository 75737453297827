import React from "react";
import { ANALYTICS_EVENTS_JSON, EXTERNAL_URLS } from "../../common/constants";
import TRANSLATIONS from "../../translations/en.json";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./MobileHeader.css";
import { ReactComponent as Hamburger } from "../../assets/hamburgerIcon.svg";
import AppStoreIcons from "../../common/components/SideBarAppStoreIcons";
import { logAnalyticsEvent } from "../../utils";

function MobileHeader({ handleToggle, headerLinks, handleSetUserPreference }) {
  const history = useHistory();
  return (
    <div>
      <nav id="home-menu" className="menu-dropdown p-0 hamburger-menu">
        <div id="home-menu">
          <Hamburger
            onClick={() => {
              logAnalyticsEvent(ANALYTICS_EVENTS_JSON.CONSUMER_HAMBURGER_MENU);
              handleToggle();
            }}
            className="hamburger"
          />
          {headerLinks.map(
            ({ pathname, icon: Icon, name, isExternal, preference }) => {
              return isExternal ? (
                <a
                  className="menu-link"
                  href={EXTERNAL_URLS.BLOG_URL}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    handleToggle();
                  }}
                >
                  <Icon alt="blog" className="sidebar-main-icon mr-3" />
                  {TRANSLATIONS.SIDE_BAR.BLOG}
                </a>
              ) : (
                <Link
                  className={`menu-link ${
                    history.location.pathname === pathname
                      ? [
                          TRANSLATIONS.SIDE_BAR.CONTACT_US,
                          TRANSLATIONS.SIDE_BAR.HPY,
                        ].includes(name)
                        ? "menu-link-active-fill "
                        : "menu-link-active"
                      : ""
                  }`}
                  to={pathname}
                  onClick={() => {
                    handleToggle();
                    handleSetUserPreference(preference);
                  }}
                >
                  <Icon
                    className={`sidebar-main-icon mr-3`}
                    color="#fff"
                    stroke="#fff"
                  />

                  {name}
                </Link>
              );
            }
          )}

          <hr className="separation-line" />
          <div className="py-2">
            <AppStoreIcons />
          </div>
        </div>
      </nav>
      <div className="overlay-sidebar" onClick={handleToggle}></div>
    </div>
  );
}
MobileHeader.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  headerLinks: PropTypes.array.isRequired,
  handleSetUserPreference: PropTypes.func.isRequired,
};
export default MobileHeader;
