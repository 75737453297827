import React, { useState } from "react";
import BenefitsByStudies from "./BenefitsByStudies";
import Chemicals from "./Chemicals";
import EntPgeIntro from "../enterpriseHome/EntPgeIntro";
import EntSignUp from "../enterpriseHome/EntPgeSignUp";
import GetStarted from "../home/GetStarted";
import RequestDemoModal from "./RequestDemoModal";
import Research from "./Research";
import ScienceEndNote from "./ScEndNote";
import TheScience from "./TheScience";
import "./index.css";

function Science() {
  const [isOpenDemo, setIsOpenDemo] = useState(false);
  const demoToggle = () => {
    setIsOpenDemo((prev) => !prev);
  };
  return (
    <div className="science-page">
      {isOpenDemo && (
        <RequestDemoModal isOpen={isOpenDemo} toggle={demoToggle} />
      )}
      <Research />
      <BenefitsByStudies demoToggle={demoToggle} />
      <TheScience />
      <Chemicals />
      <ScienceEndNote />
      <EntPgeIntro />
      <EntSignUp />
      <GetStarted />
    </div>
  );
}
export default Science;
