import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import JoinTeam from "../assets/TeamMembers/JoinTeam.webp";
import { manageBodyClass } from "../utils";
import TRANSLATIONS from "../translations/en.json";
import "./index.css";

function ComingSoon({ description }) {
  useEffect(() => {
    manageBodyClass("coming-soon-screens", "add");
    return () => {
      manageBodyClass("coming-soon-screens", "remove");
    };
  }, []);
  return (
    <Row className="coming-soon-row">
      <Col lg={3} md={3} sm={6} xs={5} className="px-0">
        <img
          src={JoinTeam}
          alt="join-team"
          className="join-team cmng-soon-img h-100"
        />
      </Col>
      <Col
        lg={9}
        md={9}
        sm={6}
        xs={7}
        className="coming-soon-text-section px-0"
      >
        <h2 className="coming-soon-header">{TRANSLATIONS.COMING_SOON.TITLE}</h2>
        <p className="coming-soon-desc">{description}</p>
      </Col>
    </Row>
  );
}
ComingSoon.propTypes = {
  description: PropTypes.string.isRequired,
};
export default ComingSoon;
