import React, { memo } from "react";
import { Col, Row } from "reactstrap";

import "./Happyplace.css";
import { ReactComponent as WhiteSwoosh } from "../assets/white_swoosh.svg";
import ScreenMock1 from "../assets/WebpImages/activitiesscroll_screen.webp";
import ScreenMock2 from "../assets/WebpImages/dailyreflection_screens.webp";
import ScreenMock3 from "../assets/WebpImages/myjournal_view.webp";
import ScreenMock4 from "../assets/WebpImages/dailyreflection_mobile_screens.webp";
import TRANSLATIONS from "../translations/en.json";

const Happyplace = () => {
  const HAPPY_PLACE_CARDS = [
    {
      header: TRANSLATIONS.HOME_PAGE.TRACK_EMOTIONS,
      mobileHeader: TRANSLATIONS.HOME_PAGE.TRACK_EMOTIONS_MOBILE,
      subHeader: TRANSLATIONS.HOME_PAGE.SELECT_EMOTIONS,
      image: ScreenMock1,
    },
    {
      header: TRANSLATIONS.HOME_PAGE.DISCOVER_WHAT_MAKES_HAPPY,
      subHeader: TRANSLATIONS.HOME_PAGE.HAPPINESS_PORTFOLIO,
      image: ScreenMock2,
      alignImageLeft: true,
      alignLeftImage: ScreenMock4,
    },
    {
      header: TRANSLATIONS.HOME_PAGE.MICRO_JOURNALING,
      subHeader: TRANSLATIONS.HOME_PAGE.PROMTS,
      image: ScreenMock3,
    },
  ];
  return (
    <>
      <div>
        <div className="container-fluid p-0 position-relative">
          <WhiteSwoosh className="hpy-white-swoosh-top" />
          <div className="row place">
            <h1 className="happy-place-header mx-auto welcome-hpy">
              {TRANSLATIONS.HOME_PAGE.HAPPY_PLACE}
            </h1>
          </div>
          <div className="row web-section">
            {HAPPY_PLACE_CARDS.map((item) => {
              return (
                <div
                  key={item.src}
                  className="col-lg-3 col-md-12 col-sm-12 activity-col place-descriptions"
                >
                  <div className="place-screens">
                    <img
                      src={item.image}
                      alt="screen1"
                      className="activity-img"
                    />
                  </div>
                  <div>
                    <h4 className="place-text hpy-text-title">
                      {item.header}{" "}
                    </h4>
                    <h2 className="place-text hpy-text-discription">
                      {item.subHeader}
                    </h2>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="mobile-section">
            {HAPPY_PLACE_CARDS.map((item) => {
              return item.alignImageLeft ? (
                <Row className="my-5" key={item.header}>
                  <Col md={6} sm={6} xs={6} className=" my-auto">
                    <p className="activity-image-header mb-0">{item.header}</p>
                    <p className="activity-image-description mb-0">
                      {item.subHeader}
                    </p>
                  </Col>{" "}
                  <Col md={6} sm={6} xs={6} className="text-col pr-0">
                    <img
                      src={item.alignLeftImage}
                      alt="rectangle"
                      className="acivity-img right-align-img"
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="" key={item.header}>
                  <Col md={5} sm={5} xs={5} className="pl-0">
                    <img
                      src={item.image}
                      alt="rectangle"
                      className="acivity-img left-align-img"
                    />
                  </Col>

                  <Col
                    md={7}
                    sm={7}
                    xs={7}
                    className="right-align-text-column pr-4 my-auto"
                  >
                    <p className="activity-image-header mb-0">
                      {item.mobileHeader || item.header}
                    </p>
                    <p className="activity-image-description mb-0">
                      {item.subHeader}
                    </p>
                  </Col>
                </Row>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Happyplace);
