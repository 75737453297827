import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { inviteTeamError, inviteTeamSuccess } from "./actions";

import { INVITE_TEAM_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* inviteTeamMembers(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/invite`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response?.data && response.data?.length) {
      const responseData = {
        data: response.data,
      };
      yield put(inviteTeamSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        error: response.data.error ? response.data.error : response.data,
      };
      yield put(inviteTeamError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(inviteTeamError(errorData));
  }
}

export function* watchInviteTeamMembers() {
  yield takeLatest(INVITE_TEAM_REQUEST, inviteTeamMembers);
}

function* InviteTeamMembersSage() {
  yield all([fork(watchInviteTeamMembers)]);
}
export default InviteTeamMembersSage;
