import React, { memo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";

import { ANALYTICS_EVENTS_JSON } from "../common/constants";
import ButtonSwitch from "./ButtonSwitch";
import TRANSLATIONS from "../translations/en.json";
import { notificationSettingsRequest } from "../redux/actions";
import "./RequestSupportCard.css";

function Notifications() {
  const dispatch = useDispatch();
  const [notificationCategories, setNotificationCategories] = useState([
    {
      eventName: ANALYTICS_EVENTS_JSON.EMAIL_NOTIFICATIONS_TOGGLE,
      isActive: true,
      name: TRANSLATIONS.ACCOUNT_SETTINGS.EMAIL,
      payloadName: "email",
    },
    {
      eventName: ANALYTICS_EVENTS_JSON.TEXT_NOTIFICATIONS_TOGGLE,
      isActive: true,
      name: TRANSLATIONS.ACCOUNT_SETTINGS.TEXT,
      payloadName: "text",
    },
    {
      eventName: ANALYTICS_EVENTS_JSON.PUSH_NOTIFICATIONS_TOGGLE,
      isActive: true,
      name: TRANSLATIONS.ACCOUNT_SETTINGS.PUSH_NOTIFICATIONS,
      payloadName: "push_notification",
    },
  ]);

  // retriving the previous selection of user
  useEffect(() => {
    const notificationSettigsData = JSON.parse(
      localStorage.getItem("notificationSettings")
    );

    if (notificationSettigsData?.length) {
      setNotificationCategories(notificationSettigsData);
    }
  }, []);

  // Updating the state based on user selection
  const handleToggle = (index) => {
    const updatedCategories = [...notificationCategories];
    updatedCategories[index].isActive = !updatedCategories[index].isActive;

    setNotificationCategories(updatedCategories);
    // Storing User settings
    localStorage.setItem(
      "notificationSettings",
      JSON.stringify(updatedCategories)
    );

    // API Call
    const payload = updatedCategories.reduce(
      (result, category) => {
        result.settings[category.name.toLowerCase().split(" ").join("_")] =
          category.isActive ? "On" : "Off";
        return result;
      },
      { settings: {} }
    );

    dispatch(notificationSettingsRequest(payload));
  };

  return (
    <Card className="request-support-card">
      <CardBody>
        <h2 className="activity-header">
          {TRANSLATIONS.ACCOUNT_SETTINGS.NOTIFICATIONS}
        </h2>
        {notificationCategories.map((item, index) => {
          return (
            <Row className="category-row" key={item.name}>
              <Col lg={6} md={6} sm={6} xs={6} className="px-0">
                {item.name}
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} className="px-0 text-right">
                <ButtonSwitch
                  eventName={item.eventName}
                  index={index}
                  isActive={item.isActive}
                  handleToggle={handleToggle}
                />
              </Col>
            </Row>
          );
        })}
      </CardBody>
    </Card>
  );
}
export default memo(Notifications);
