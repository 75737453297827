import React, { memo } from "react";
import "./Research.css";
import TRANSLATIONS from "../translations/en.json";
import ResearchLogo from "../../src/assets/Science/ResearchLogo.webp";

function Research() {
  return (
    <div className="research-container">
      <div className="">
        <p className="research-header">
          {TRANSLATIONS.SCIENCE_PAGE.RESEARCH_HEADR}
        </p>
        <p className="research-description">
          {TRANSLATIONS.SCIENCE_PAGE.RESEARCH_DESC}
          <br />
        </p>
        <p className="research-description">
          {TRANSLATIONS.SCIENCE_PAGE.RESEARCH_DESC_2}{" "}
        </p>
      </div>
      <div className="research-image">
        <img src={ResearchLogo} alt="logo" className="research-logo" />
      </div>
    </div>
  );
}

export default memo(Research);
