import { useState, useEffect } from "react";
import { MAX_RECORDING_TIME } from ".";
import { startRecording, saveRecording } from "./recorderControls";

const initialState = {
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
  duration: 0,
};

export default function useRecorder() {
  const [recorderState, setRecorderState] = useState(initialState);

  useEffect(() => {
    let recordingInterval = null;

    if (recorderState.initRecording) {
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => {
          if (prevState.recordingSeconds === MAX_RECORDING_TIME) {
            clearInterval(recordingInterval);
            if (prevState.mediaRecorder.state !== "inactive")
              prevState.mediaRecorder.stop();
            return prevState;
          }

          if (
            prevState.recordingSeconds >= 0 &&
            prevState.recordingSeconds < MAX_RECORDING_TIME
          )
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            };
        });
      }, 1000);
    } else {
      clearInterval(recordingInterval);
    }

    return () => clearInterval(recordingInterval);
  }, [recorderState.initRecording]);

  useEffect(() => {
    if (recorderState.mediaStream)
      setRecorderState((prevState) => {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      });
  }, [recorderState.mediaStream]);

  useEffect(() => {
    const recorder = recorderState.mediaRecorder;
    let chunks = [];

    const handleDataAvailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };

    const handleStop = () => {
      const blob = new Blob(chunks, {
        type: recorder.mimeType,
      });
      chunks = [];

      setRecorderState((prevState) => {
        if (prevState.mediaRecorder) {
          return {
            ...initialState,
            duration: prevState.recordingSeconds,
            audio: blob,
          };
        } else {
          return initialState;
        }
      });
    };

    if (recorder && recorder.state === "inactive") {
      recorder.ondataavailable = handleDataAvailable;
      recorder.onstop = handleStop;

      try {
        recorder.start();
      } catch (err) {
        console.error("Error starting recorder:", err);
      }
    }

    return () => {
      if (recorder) {
        recorder.ondataavailable = null;
        recorder.onstop = null;

        recorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [recorderState.mediaRecorder]);

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
  };
}
