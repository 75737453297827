import React, { memo } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import "./MembersList.css";
import {
  ANALYTICS_EVENTS_JSON,
  TEAM_MEMBER_STATUS,
  ROUTE_PATH,
  PRO,
  STRIPE_SUBSCRIPTION_STATUS,
  SUBSCRIPTION_LABELS,
} from "../../common/constants";
import { AnimatedButton } from "../../common/components";
import { logAnalyticsEvent } from "../../utils";
import Scrollbar from "react-custom-scrollbars-2";
import TRANSLATIONS from "../../translations/en.json";
import trashCan from "../../assets/trashCan.svg";
import { ReactComponent as TrashCanMobile } from "../../assets/TeamMembers/TrashCanMobile.svg";
import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

/**exclude below users for checking subscription status as therapist should be able
 * to select below users as they are old users in the application as per client request */
const exceptionalUserUUIDs = [
  "ed08bf07-0a26-4e9a-9c82-fdc8e3a6c5e9",
  "d8b637c7-17b4-4ecc-b13e-0a9f0eb096d5",
];

function MembersList({
  allTeamMembers,
  buttonText,
  handleResendCode,
  handleDelete,
  isSeeAll,
}) {
  const history = useHistory();

  const admin = allTeamMembers?.length
    ? allTeamMembers.find((e) => e.isAdmin)
    : "";
  const isMember = (item) => {
    return (
      !item.isAdmin && (isSeeAll || item.status === TEAM_MEMBER_STATUS.PENDING)
    );
  };

  const subscribedStatus = [
    STRIPE_SUBSCRIPTION_STATUS.ACTIVE,
    STRIPE_SUBSCRIPTION_STATUS.TRIALING,
    STRIPE_SUBSCRIPTION_STATUS.TRIAL_END,
  ];
  const unSubscribedStatus = [
    STRIPE_SUBSCRIPTION_STATUS.INACTIVE,
    STRIPE_SUBSCRIPTION_STATUS.CANCELLED,
    STRIPE_SUBSCRIPTION_STATUS.PAST_DUE,
  ];

  const handleRedirectToUserProfile = (details) => {
    if (checkSubscriptionStatus(details)) {
      if (localStorage.getItem("subscription") === PRO) {
        history.push({
          pathname: ROUTE_PATH.MEMBER_PROFILE,
          state: details,
        });
        localStorage.setItem("teamUserDetails", JSON.stringify(details));
      }
    }
  };

  const checkSubscriptionStatus = (item) => {
    const { stripeSubscriptionStatus } = item;
    if (
      subscribedStatus.includes(stripeSubscriptionStatus) ||
      exceptionalUserUUIDs.includes(item.userUUID)
    ) {
      return true;
    } else if (unSubscribedStatus.includes(stripeSubscriptionStatus)) {
      return false;
    }
  };

  const renderMember = (item) => {
    return (
      <>
        <Row
          key={item.userUUID}
          className={`each-member mobile-view ${
            allTeamMembers?.length > 10 ? "member-scroll" : ""
          }`}
        >
          <Col xs={6} className="px-0">
            <div className="timeline px-0">
              {item.status === TEAM_MEMBER_STATUS.PENDING ? (
                <span className="member-name">{item.email || item.phone}</span>
              ) : (
                <>
                  <span
                    className={`member-name ${
                      checkSubscriptionStatus(item) ? "cursor-pointer" : ""
                    }`}
                    onClick={() => handleRedirectToUserProfile(item)}
                  >
                    {item.name}
                  </span>
                </>
              )}
              {item.status === TEAM_MEMBER_STATUS.ACCEPTED ? (
                <span
                  className={`last-active ${
                    checkSubscriptionStatus(item) ? "cursor-pointer" : ""
                  }`}
                  onClick={() => handleRedirectToUserProfile(item)}
                >
                  {item.email || item.phone}
                </span>
              ) : (
                <AnimatedButton className="transparent-btn">
                  {" "}
                  <a
                    className="resend-code  hide-link-web"
                    onClick={() => {
                      logAnalyticsEvent(ANALYTICS_EVENTS_JSON.RESEND_CODE_BTN);
                      handleResendCode(item);
                    }}
                  >
                    {TRANSLATIONS.TEAM_SETINGS.RESEND_CODE}
                  </a>
                </AnimatedButton>
              )}
            </div>
          </Col>
          <Col xs={6} className="px-0 text-right">
            {item.status === TEAM_MEMBER_STATUS.ACCEPTED ? (
              <span className="member">
                {checkSubscriptionStatus(item)
                  ? SUBSCRIPTION_LABELS.SUBSCRIBED
                  : item.stripeSubscriptionStatus ===
                    STRIPE_SUBSCRIPTION_STATUS.PAST_DUE
                  ? SUBSCRIPTION_LABELS.AMOUNTDUE
                  : SUBSCRIPTION_LABELS.UNSUBSCRIBED}
              </span>
            ) : (
              ""
            )}
            {item.isAdmin ? (
              <span className="admin-btn">
                {TRANSLATIONS.TEAM_MEMBERS.ADMIN}
              </span>
            ) : (
              <>
                {item.status !== TEAM_MEMBER_STATUS.ACCEPTED && (
                  <span className="pending">
                    {TRANSLATIONS.TEAM_SETINGS.PENDING}
                  </span>
                )}
              </>
            )}
            {!item.isAdmin && (
              <AnimatedButton className="transparent-btn">
                {" "}
                <img
                  src={trashCan}
                  alt="trashCan"
                  className="trashCan"
                  onClick={() => {
                    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DELETE_MEMBER_BTN);
                    handleDelete(item);
                  }}
                />
              </AnimatedButton>
            )}
          </Col>
        </Row>
        <div
          key={item.userUUID}
          className={`each-member web-view ${
            allTeamMembers?.length > 10 ? "member-scroll" : ""
          }`}
        >
          <div className="timeline px-0">
            {item.status === TEAM_MEMBER_STATUS.PENDING ? (
              <span className="member-name">{item.email || item.phone}</span>
            ) : (
              <>
                {}
                <span
                  className={`member-name ${
                    checkSubscriptionStatus(item) ? "cursor-pointer" : ""
                  }`}
                  onClick={() => handleRedirectToUserProfile(item)}
                >
                  {item.name}
                </span>
              </>
            )}
            {item.status === TEAM_MEMBER_STATUS.ACCEPTED ? (
              <span
                className={`last-active ${
                  checkSubscriptionStatus(item) ? "cursor-pointer" : ""
                }`}
                onClick={() => handleRedirectToUserProfile(item)}
              >
                {item.email || item.phone}
              </span>
            ) : (
              <AnimatedButton className="transparent-btn">
                <a
                  className="resend-code  hide-link-web"
                  onClick={() => handleResendCode(item)}
                >
                  {TRANSLATIONS.TEAM_SETINGS.RESEND_CODE}
                </a>
              </AnimatedButton>
            )}
          </div>
          <div className="d-flex align-items-center">
            {item.status === TEAM_MEMBER_STATUS.PENDING ? (
              <AnimatedButton className="transparent-btn">
                {" "}
                <a
                  className="resend-code  hide-link"
                  onClick={() => handleResendCode(item)}
                >
                  {TRANSLATIONS.TEAM_SETINGS.RESEND_CODE}
                </a>
              </AnimatedButton>
            ) : (
              ""
            )}

            {item.status === TEAM_MEMBER_STATUS.ACCEPTED ? (
              <span className="member">
                {checkSubscriptionStatus(item)
                  ? SUBSCRIPTION_LABELS.SUBSCRIBED
                  : item.stripeSubscriptionStatus ===
                    STRIPE_SUBSCRIPTION_STATUS.PAST_DUE
                  ? SUBSCRIPTION_LABELS.AMOUNTDUE
                  : SUBSCRIPTION_LABELS.UNSUBSCRIBED}
              </span>
            ) : (
              ""
            )}
            {item.isAdmin ? (
              <span className="admin-btn">
                {TRANSLATIONS.TEAM_MEMBERS.ADMIN}
              </span>
            ) : (
              <>
                {item.status !== TEAM_MEMBER_STATUS.ACCEPTED && (
                  <span className="pending">
                    {TRANSLATIONS.TEAM_SETINGS.PENDING}
                  </span>
                )}
              </>
            )}
            {!item.isAdmin && (
              <AnimatedButton className="transparent-btn">
                {" "}
                <img
                  src={trashCan}
                  alt="trashCan"
                  className="trashCan"
                  onClick={() => handleDelete(item)}
                />
              </AnimatedButton>
            )}
          </div>
        </div>
      </>
    );
  };
  const renderList = () => {
    if (allTeamMembers?.length) {
      return allTeamMembers.map((item) => {
        if (isMember(item)) {
          return renderMember(item);
        }
      });
    }
  };

  //rendering swipeable actions for mobile
  const trailingActions = (item) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => {
          logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DELETE_MEMBER_BTN);

          handleDelete(item);
        }}
      >
        <div className="trailing-action">
          <TrashCanMobile className="delete-icon" />
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  return (
    <Card className="members-card">
      <CardHeader className="members-header">
        {buttonText}{" "}
        {localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.VERTICAL_MENU.MEMBERS
          : TRANSLATIONS.VERTICAL_MENU.TEAM_MEMBERS}
      </CardHeader>
      {admin &&
        isSeeAll &&
        localStorage.getItem("subscription") !== PRO &&
        renderMember(admin)}
      <div className="web-view">
        {allTeamMembers?.length > 10 ? (
          <div className={"list-scroll"}>
            <Scrollbar style={{ backgroundColor: "#fff" }}>
              {renderList()}
            </Scrollbar>
          </div>
        ) : (
          <>{renderList()}</>
        )}
      </div>
      <div
        className={
          allTeamMembers?.length > 5
            ? "mobile-section list-scroll"
            : "mobile-section "
        }
      >
        <SwipeableList>
          {allTeamMembers?.length
            ? allTeamMembers.map((item) => {
                if (isMember(item)) {
                  return (
                    <SwipeableListItem
                      key={item.userUUID}
                      trailingActions={trailingActions(item)}
                    >
                      {renderMember(item)}
                    </SwipeableListItem>
                  );
                }
                return <></>;
              })
            : []}
        </SwipeableList>
      </div>
    </Card>
  );
}
MembersList.propTypes = {
  allTeamMembers: PropTypes.array,
  buttonText: PropTypes.string,
  handleDelete: PropTypes.func,
  handleResendCode: PropTypes.func,
  isSeeAll: PropTypes.bool,
};
export default memo(MembersList);
