//External packages
import React from "react";
import { Col, Row } from "reactstrap";

//local files
import "./Privacy.css";

const Privacy = () => {
  return (
    <>
      <Row className="bg-branding">
        <Col md={12} sm={12} className="mx-auto">
          <div className="privacy-content ">
            <div className="header">
              <h1 className="privacy-header">hpy Privacy Policy</h1>
              <p>Updated Oct 9, 2020</p>
            </div>
            <p>
              The Privacy Policy for hpy explains how we collect, use and
              disclose your information when you access or use our mobile app
              (“App”), our website, and any other online services and when you
              contact our customer service team, engage with us in any social
              media platform, or otherwise interact with us.
            </p>
            <p>
              We may change this Privacy Policy from time to time, however, if
              and when we make changes, we will notify you by revising the date
              at the top of the policy and in some cases, provide you with
              additional notice (such as a push notification).
            </p>

            <ol type="I">
              <h2>
                <li>Collection of Data</li>
              </h2>
              <h3>Personal Information</h3>
              <p>
                We may collect information from you in a variety of ways. The
                information we may collect about you via the App may depend on
                the content and materials you use, and includes:
              </p>
              <strong>Personal Data:</strong>
              <p>
                We collect and store all personal information related to your
                App profile, which you voluntarily give us either upon sign-in
                or through continued use of the App:
              </p>
              <p>
                First Name
                <br />
                Nickname
                <br />
                Password
                <br />
                Mobile Number
                <br />
                Email Address
                <br />
                Demographic Information
                <br />
                Mood
                <br />
                Emotions
                <br />
                Goals
                <br />
                Feedback
                <br />
                Survey Responses
                <br />
              </p>

              <strong>Derivative Data:</strong>
              <p>
                Our servers also may collect information through our servers
                when you access the App, such as your native actions that are
                integral to the App, actions taken when creating entries and
                actions taken while editing entries.
              </p>

              <h2>
                <li>How We Collect Data</li>
              </h2>
              <p>
                We can collect data in one of two ways. Either you voluntarily
                provide us with data- for example, you decide to sign up to our
                App, become a user, and begin making/completing entries within
                the App. This could also include contacting us via the App. We
                can also collect data automatically- for example, when you are
                using our App, we may use our SDK, Cookies, and similar tracking
                technologies to gather your information automatically such as
                your Advertising ID, IP address, etc.
              </p>

              <h2>
                <li>How We Use Your Data</li>
              </h2>
              <ol>
                <li>To operate and improve the App</li>
                <ol type="a">
                  <li>To enable you to use the App’s features</li>
                  <li>
                    Send you promotional content, calendar invites,
                    announcements, updates, and security alerts which we would
                    do via a push notification and responding to your requests
                    regarding feedback, support, and questions.
                  </li>
                  <li>Provide technical support and maintenance of the App</li>
                  <li>
                    Personalize your online experience & participation with our
                    interactive features
                  </li>
                  <li>
                    Perform statistical analysis of the use of the App (which
                    includes the use of Google Analytics).
                  </li>
                </ol>
              </ol>

              <h2 className="mt-3">
                <li>Information for California Residents</li>
              </h2>
              <p>
                This section provides additional disclosures required by the
                California Consumer Privacy Act (“CCPA”).
                <br />
                <br />
                In the last 12 months, we collected the following categories of
                personal information: identifiers (name and contact information)
                and other personal information (such as user credentials or
                health related information). For more details about the personal
                information we collect, please see the section above, I.
                Collection of Data. We collect this information for the
                operation and business use of the App and do not share this
                personal data with any third parties.
                <br />
                <br />
                Subject to certain limitations and exceptions, the CCPA provides
                California consumers the right to request to know more details
                about the categories and specific piece of personal information,
                to delete their personal information, to opt out of any “sales”,
                and to not be discriminated against when exercising these
                rights.
                <br />
                <br />
                We do not advertise, market, share or sell your personal
                information we collect. The use of the data is purely for
                operating and refining the App for customer benefit and success.
              </p>

              <h2>
                <li>Retention of Data</li>
              </h2>
              <p>
                As a registered user of our App, we will retain user data as
                long as you are an active member of the App. After three years
                of inactivity, user data will be deleted. Please note that user
                data is not stored on our servers, it is stored on the user’s
                mobile device.
              </p>

              <h2>
                <li>Contact Us</li>
              </h2>
              <p>
                If you have any additional questions about this Privacy Policy,
                please reach out to us at: support@hpy.app
              </p>
            </ol>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Privacy;
