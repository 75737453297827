import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Modal, ModalFooter } from "reactstrap";

import { ReactComponent as Close } from "../assets/closeIcon.svg";
import { AnimatedButton } from "../common/components";
import {
  DELETE_PRO_REASONS,
  DELETE_TEAM_ISSUES,
  DELETE_TEAM_REASONS,
  DELETE_TEAM_YES_OR_NO,
} from "../common/constants/deleteTeamModal.js";
import { PRO } from "../common/constants/index.js";
import { deleteTeamAction } from "../redux/actions";
import TRANSLATIONS from "../translations/en.json";
import {
  deleteModalSelectedClassName,
  deleteModalSelectionStyling,
} from "../utils";
import "./DeleteTeamModal.css";

function DeleteTeamModal({ isOpen, toggle, questionsData }) {
  const dispatch = useDispatch();
  const [selectedReasonOption, setSelectedReasonOption] = useState([]);
  const [selectedSpecificDesicion, setSelectedSpecificDesicion] =
    useState(null);
  const [selectedSpecificDesicionText, setSelectedSpecificDesicionText] =
    useState("");
  const [selectedIssueOption, setSelectedIssueOption] = useState([]);
  const [selectedIssueOptionText, setSelectedIssueOptionText] = useState("");
  const [selectedUseAgain, setSelectedUseAgain] = useState(null);
  const [selectedUseAgainText, setSelectedUseAgainText] = useState("");

  // Submit button
  const onSubmit = () => {
    const payload = {
      deleteFeedbackValues: [
        {
          questionUUID: questionsData?.length && questionsData?.[0]?.uuid,
          answers: selectedReasonOption,
        },
        {
          questionUUID: questionsData?.length && questionsData?.[1]?.uuid,
          answers: [selectedSpecificDesicion, selectedSpecificDesicionText],
        },
        {
          questionUUID: questionsData?.length && questionsData?.[2]?.uuid,
          answers: [...selectedIssueOption, selectedIssueOptionText],
        },
        {
          questionUUID: questionsData?.length && questionsData?.[3]?.uuid,
          answers: [selectedUseAgain, selectedUseAgainText],
        },
      ],
    };
    toggle();
    dispatch(deleteTeamAction(payload));
  };

  // For 1st Question Reasons mentioned, Can Select only 2
  const handleReasonOptionClick = (name) => {
    const isSelected = selectedReasonOption.includes(name);

    if (isSelected) {
      setSelectedReasonOption(selectedReasonOption.filter((id) => id !== name));
    } else {
      if (setSelectedReasonOption.length < 2) {
        setSelectedReasonOption([...selectedReasonOption, name]);
      }
    }
  };

  // For 2rd Question Specific
  const handleSpecificDesicion = (ans) => {
    setSelectedSpecificDesicion(ans);
  };

  // For 3rd Question Issues mentioned, Can Select only 3
  const handleIssueOptionClick = (name) => {
    const isSelected = selectedIssueOption.includes(name);

    if (isSelected) {
      setSelectedIssueOption(selectedIssueOption.filter((id) => id !== name));
    } else {
      if (setSelectedIssueOption.length < 3) {
        setSelectedIssueOption([...selectedIssueOption, name]);
      }
    }
  };

  // For 4th Question useAgain
  const handleUseAgain = (ans) => {
    setSelectedUseAgain(ans);
  };

  const reasons =
    localStorage.getItem("subscription") === PRO
      ? DELETE_PRO_REASONS
      : DELETE_TEAM_REASONS;

  return (
    <Modal className="delete-team-modal" isOpen={isOpen} toggle={toggle}>
      <Close
        className="delete-team-modal-close"
        fill={"#fff"}
        stroke="#fff"
        onClick={() => toggle()}
      />
      <div className="delete-team-modal-content">
        <h1 className="header mb-5">
          {TRANSLATIONS.ACCOUNT_SETTINGS.DELETE_MODAL_HEADER}
        </h1>
        <div className="delete-team-options-container my-4">
          <h3 className="delete-team-account-header">
            {localStorage.getItem("subscription") === PRO
              ? TRANSLATIONS.ACCOUNT_SETTINGS.PRO_DELETE_QUESTION
              : questionsData?.length && questionsData?.[0]?.question}{" "}
            *
          </h3>
          <span className="select-options-text">
            {TRANSLATIONS.ACCOUNT_SETTINGS.SELECT_OPTIONS_TEXT}
          </span>
          <div className="delete-options-container">
            {reasons?.map((options, key) => (
              <span
                className={deleteModalSelectedClassName(
                  selectedReasonOption,
                  options.Option,
                  "reasons"
                )}
                style={deleteModalSelectionStyling(
                  selectedReasonOption,
                  options.Option,
                  "reasons"
                )}
                onClick={() => handleReasonOptionClick(options.Option)}
                key={key}
              >
                {options.Option}
              </span>
            ))}
          </div>
        </div>
        <div className="delete-team-options-container my-4">
          <h3 className="delete-team-account-header">
            {questionsData?.length && questionsData?.[1]?.question} *
          </h3>
          <div className="delete-decision-container">
            {DELETE_TEAM_YES_OR_NO?.map((options, key) => (
              <span
                className={deleteModalSelectedClassName(
                  selectedSpecificDesicion,
                  options.Option,
                  "desicions"
                )}
                onClick={() => handleSpecificDesicion(options.Option)}
                key={key}
              >
                {options.Option}
              </span>
            ))}
            <Input
              className="delete-reason-input"
              type="text"
              placeholder={TRANSLATIONS.ACCOUNT_SETTINGS.PLEASE_EXPLAIN}
              onChange={(e) => setSelectedSpecificDesicionText(e.target.value)}
            />
          </div>
        </div>
        <div className="delete-team-options-container my-4">
          <h3 className="delete-team-account-header">
            {questionsData?.length && questionsData?.[2]?.question} *
          </h3>
          <span className="select-options-text">
            {TRANSLATIONS.ACCOUNT_SETTINGS.SELECT_3_OPTIONS}
          </span>
          <div className="delete-options-container delete-options-container-extra-margin ">
            {DELETE_TEAM_ISSUES?.map((options, key) => (
              <span
                className={deleteModalSelectedClassName(
                  selectedIssueOption,
                  options.Option,
                  "issues"
                )}
                style={deleteModalSelectionStyling(
                  selectedIssueOption,
                  options.Option,
                  "issues"
                )}
                onClick={() => handleIssueOptionClick(options.Option)}
                key={key}
              >
                {options.Option}
              </span>
            ))}
            <Input
              className="delete-issues-input"
              type="text"
              onChange={(e) => setSelectedIssueOptionText(e.target.value)}
            />
          </div>
        </div>
        <div className="delete-team-options-container my-4">
          <h3 className="delete-team-account-header">
            {questionsData?.length && questionsData?.[3]?.question} *
          </h3>
          <div className="delete-decision-container">
            {DELETE_TEAM_YES_OR_NO?.map((options, key) => (
              <span
                className={deleteModalSelectedClassName(
                  selectedUseAgain,
                  options.Option,
                  "useAgain"
                )}
                onClick={() => handleUseAgain(options.Option)}
                key={key}
              >
                {options.Option}
              </span>
            ))}
            <Input
              className="delete-reason-input"
              type="text"
              placeholder={TRANSLATIONS.ACCOUNT_SETTINGS.PLEASE_EXPLAIN}
              onChange={(e) => setSelectedUseAgainText(e.target.value)}
            />
          </div>
        </div>
        <footer className="delete-team-modal-footer">
          {TRANSLATIONS.ACCOUNT_SETTINGS.FOOTER}
        </footer>
      </div>
      <ModalFooter>
        <AnimatedButton className="cancel-btn" onClick={toggle}>
          {TRANSLATIONS.CONFIRMATION.CANCEL}
        </AnimatedButton>{" "}
        <AnimatedButton
          className="submit-btn"
          onClick={onSubmit}
          disabled={
            !(
              selectedReasonOption?.length &&
              selectedSpecificDesicion?.length &&
              selectedIssueOption &&
              selectedUseAgain
            )
          }
        >
          {TRANSLATIONS.CONTACT_US.SUBMIT}
        </AnimatedButton>
      </ModalFooter>
    </Modal>
  );
}

DeleteTeamModal.propTypes = {
  isOpen: PropTypes.bool,
  handleSubmit: PropTypes.func,
  toggle: PropTypes.func,
  questionsData: PropTypes.array,
};
export default DeleteTeamModal;
