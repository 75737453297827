import {
  CREATE_CHATBOT_REQUEST,
  CREATE_CHATBOT_SUCCESS,
  CREATE_CHATBOT_ERROR,
} from "./actionTypes";

const initialState = {};

const ChatBot = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CHATBOT_REQUEST:
      return {
        ...state,
        ChatbotUserRequestData: true,
        ChatbotUserData: false,
        ChatbotUserError: false,
      };
    case CREATE_CHATBOT_SUCCESS:
      return {
        ...state,
        ChatbotUserRequestData: false,
        ChatbotUserData: action.payload.user.data,
        ChatbotUserError: false,
      };
    case CREATE_CHATBOT_ERROR:
      return {
        ...state,
        ChatbotUserRequestData: false,
        ChatbotUserData: false,
        ChatbotUserError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ChatBot;
