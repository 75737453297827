import React, { memo } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "reactstrap";

import TRANSLATIONS from "../../translations/en.json";
import { logAnalyticsEvent } from "../../utils";
import { ANALYTICS_EVENTS_JSON } from "../constants";

function ShareTeamCodePopUp({ profileInfo, toggle }) {
  return (
    <Row className="cards-row">
      <div className="card-slider white-div w-100">
        <Col lg={5} md={8} sm={10} xs={10} className="mx-auto">
          <Card className="share-team-card shadow-lg">
            <h4 className="team-code-text">{TRANSLATIONS.HEADER.TEAM_CODE}</h4>
            <div className=" space-btn">
              <h3 className="code-number">{profileInfo.teamCode}</h3>
              <div className=" mb-2 button-align-popup">
                <button
                  className="share-btn mt-1"
                  onClick={() => {
                    logAnalyticsEvent(
                      ANALYTICS_EVENTS_JSON.SHARE_WITH_TEAM_POPUP_OPEN_BTN
                    );
                    toggle();
                  }}
                >
                  {TRANSLATIONS.HEADER.SHARE_CODE_BTN}
                </button>
              </div>
            </div>
          </Card>
        </Col>
      </div>
    </Row>
  );
}
ShareTeamCodePopUp.propTypes = {
  profileInfo: PropTypes.object,
  toggle: PropTypes.func,
};
export default memo(ShareTeamCodePopUp);
