import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as Hamburger } from "../../../assets/hamburgerIcon.svg";
import { ReactComponent as Hpy } from "../../../assets/Hpy.svg";
import TRANSLATIONS from "../../../translations/en.json";
import "./MobileSidebarHeader.css";
import { logAnalyticsEvent, manageBodyClass } from "../../../utils";
import { ANALYTICS_EVENTS_JSON, PRO } from "../../../common/constants";

function MobileSidebarHeader({ collapsed, collapseSidebar, toggleSidebar }) {
  return (
    <div className="mobile-sb-header">
      <Hamburger
        onClick={() => {
          logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DASHBOARD_HAMBURGER_MENU);

          if (collapsed) {
            collapseSidebar();
            manageBodyClass("vertical-menu-collapsed", "remove");
            toggleSidebar();
          } else {
            toggleSidebar();
          }
        }}
        className={"hamburger"}
      />
      <div className="menu-branding d-flex align-items-center">
        <div className="d-flex align-items-center mt-0">
          <Hpy className="menu-hpy-logo" />

          <p className="teams my-0">
            {localStorage.getItem("subscription") === PRO
              ? TRANSLATIONS.COMMON.PRO
              : TRANSLATIONS.COMMON.TEAMS}
          </p>
        </div>
      </div>
    </div>
  );
}
MobileSidebarHeader.propTypes = {
  collapseSidebar: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};
export default MobileSidebarHeader;
