import PropTypes from "prop-types";
import React, { memo } from "react";

import "./StepperComponent.css";

function StepperComponent({ activeStep, title }) {
  const gradientColor = `linear-gradient(to right, #FFDB43 ${
    activeStep * 20
  }%, rgba(255, 219, 67, 0.40) ${activeStep * 20}%)`;

  return (
    <div className="stepper">
      <h2 className="stepper-header">{title}</h2>
      {activeStep >= 6 ? (
        ""
      ) : (
        <>
          <p className="active-step mb-">
            {activeStep} <span className="const-step"> of 5`</span>
          </p>
          <input
            type="range"
            min="0"
            max="5"
            step="1"
            value={activeStep}
            style={{ background: gradientColor }}
          />
        </>
      )}
    </div>
  );
}

StepperComponent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  title: PropTypes.string,
};

export default memo(StepperComponent);
