import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { menuClasses, Sidebar, useProSidebar } from "react-pro-sidebar";
import { Scrollbars } from "react-custom-scrollbars-2";

import SidebarContent from "./SidebarContent";
import SidebarHeader from "./SidebarHeader";
import SidebarFooter from "./SidebarFooter";
import "./Sidebar.css";
import { bodyContainsCollapseClass, manageBodyClass } from "../../../utils";

function SideMenu({ inviteModaltoggle, isMobile, isProUser }) {
  const { collapsed, collapseSidebar, toggled, toggleSidebar } =
    useProSidebar();
  const [transitionDuration, setTransitionDuration] = useState(100);
  const [defaultCollapsed, setDefaultCollapsed] = useState(true);

  const updateSidebarDuration = () => {
    setTimeout(() => {
      const sideMenu = document.getElementById("side-menu");
      if (sideMenu) {
        sideMenu.style = "display:block !important";
      }
    }, 2000);

    if (document.documentElement.clientWidth <= 1000) {
      setDefaultCollapsed(false);
      setTransitionDuration(1000);
    } else {
      setDefaultCollapsed(true);
      setTransitionDuration(100);
    }
  };

  const updateSidebar = () => {
    if (document.documentElement.clientWidth > 1000) {
      if (!bodyContainsCollapseClass()) {
        collapseSidebar();
        manageBodyClass("vertical-menu-collapsed", "add");
      }
    } else {
      if (!isMobile) {
        const sideMenu = document.getElementById("side-menu");
        if (sideMenu) {
          sideMenu.style = "display:none !important";
        }
        window.location.reload();
      }
      manageBodyClass("vertical-menu-collapsed", "remove");
    }
  };
  const resizingEvents = () => {
    updateSidebarDuration();
    updateSidebar();
  };
  //handling closing and opening sidebar based on resize
  useEffect(() => {
    updateSidebarDuration();
    // adding & removing eventlistner
    window.addEventListener("resize", resizingEvents);

    return function cleanup() {
      window.removeEventListener("resize", resizingEvents);
    };
  }, []);

  return (
    <Sidebar
      rootStyles={{
        [`.${menuClasses.icon}`]: {
          backgroundColor: "transparent",
          width: "unset",
        },
      }}
      customBreakPoint="1000px"
      backgroundColor="#1A2630"
      color="#fff"
      collapsedWidth="95px"
      id="side-menu"
      className="side-menu"
      defaultCollapsed={defaultCollapsed}
      transitionDuration={transitionDuration}
    >
      <Scrollbars autoHide={true}>
        <SidebarHeader
          collapsed={collapsed}
          collapseSidebar={collapseSidebar}
          isProUser={isProUser}
          toggled={toggled}
          toggleSidebar={toggleSidebar}
        />
        <SidebarContent isProUser={isProUser} />
        <SidebarFooter
          toggleSidebar={toggleSidebar}
          inviteModaltoggle={inviteModaltoggle}
        />
      </Scrollbars>
    </Sidebar>
  );
}
SideMenu.propTypes = {
  inviteModaltoggle: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isProUser: PropTypes.bool.isRequired,
};
export default memo(SideMenu);
