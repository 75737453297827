import {
  ASSIGN_TASKS_ERROR,
  ASSIGN_TASKS_REQUEST,
  ASSIGN_TASKS_SUCCESS,
  CREATE_CUSTOM_CARD_ERROR,
  CREATE_CUSTOM_CARD_REQUEST,
  CREATE_CUSTOM_CARD_SUCCESS,
  GET_ALL_INCLUSIVITY_ERROR,
  GET_ALL_INCLUSIVITY_REQUEST,
  GET_ALL_INCLUSIVITY_SUCCESS,
  GET_ALL_JOURNAL_ERROR,
  GET_ALL_JOURNAL_REQUEST,
  GET_ALL_JOURNAL_SUCCESS,
  GET_ALL_PURPOSE_ERROR,
  GET_ALL_PURPOSE_REQUEST,
  GET_ALL_PURPOSE_SUCCESS,
  GET_ALL_TIME_ERROR,
  GET_ALL_TIME_REQUEST,
  GET_ALL_TIME_SUCCESS,
  GET_TASKS_ERROR,
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
} from "./actionTypes";

const initialState = {};

const Tasks = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS_REQUEST:
      return {
        ...state,
        getTasksRequestData: true,
        getTasksData: false,
        getTasksError: false,
      };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        getTasksRequestData: false,
        getTasksData: action.payload.user.data,
        getTasksError: false,
      };
    case GET_TASKS_ERROR:
      return {
        ...state,
        getTasksRequestData: false,
        getTasksData: false,
        getTasksError: action.payload.history.error,
      };
    case ASSIGN_TASKS_REQUEST:
      return {
        ...state,
        assignTaskRequestData: true,
        assignTaskData: false,
        assignTaskError: false,
      };
    case ASSIGN_TASKS_SUCCESS:
      return {
        ...state,
        assignTaskRequestData: false,
        assignTaskData: action.payload.user.data,
        assignTaskError: false,
      };
    case ASSIGN_TASKS_ERROR:
      return {
        ...state,
        assignTaskRequestData: false,
        assignTaskData: false,
        assignTaskError: action.payload.history.error,
      };
    case GET_ALL_INCLUSIVITY_REQUEST:
      return {
        ...state,
        getAllInclusivityRequestData: true,
        getAllInclusivityData: false,
        getAllInclusivityError: false,
      };
    case GET_ALL_INCLUSIVITY_SUCCESS:
      return {
        ...state,
        getAllInclusivityRequestData: false,
        getAllInclusivityData: action.payload.user.data,
        getAllInclusivityError: false,
      };
    case GET_ALL_INCLUSIVITY_ERROR:
      return {
        ...state,
        getAllInclusivityRequestData: false,
        getAllInclusivityData: false,
        getAllInclusivityError: action.payload.history.error,
      };

    case GET_ALL_TIME_REQUEST:
      return {
        ...state,
        getAllTimeOptionsRequestData: true,
        getAllTimeOptionsData: false,
        getAllTimeOptionsError: false,
      };
    case GET_ALL_TIME_SUCCESS:
      return {
        ...state,
        getAllTimeOptionsRequestData: false,
        getAllTimeOptionsData: action.payload.user.data,
        getAllTimeOptionsError: false,
      };
    case GET_ALL_TIME_ERROR:
      return {
        ...state,
        getAllTimeOptionsRequestData: false,
        getAllTimeOptionsData: false,
        getAllTimeOptionsError: action.payload.history.error,
      };

    case GET_ALL_JOURNAL_REQUEST:
      return {
        ...state,
        getAllJournalOptionsRequestData: true,
        getAllJournalOptionsData: false,
        getAllJournalOptionsError: false,
      };
    case GET_ALL_JOURNAL_SUCCESS:
      return {
        ...state,
        getAllJournalOptionsRequestData: false,
        getAllJournalOptionsData: action.payload.user.data,
        getAllJournalOptionsError: false,
      };
    case GET_ALL_JOURNAL_ERROR:
      return {
        ...state,
        getAllJournalOptionsRequestData: false,
        getAllJournalOptionsData: false,
        getAllJournalOptionsError: action.payload.history.error,
      };

    case GET_ALL_PURPOSE_REQUEST:
      return {
        ...state,
        getAllPurposeOptionsRequestData: true,
        getAllPurposeOptionsData: false,
        getAllPurposeOptionsError: false,
      };
    case GET_ALL_PURPOSE_SUCCESS:
      return {
        ...state,
        getAllPurposeOptionsRequestData: false,
        getAllPurposeOptionsData: action.payload.user.data,
        getAllPurposeOptionsError: false,
      };
    case GET_ALL_PURPOSE_ERROR:
      return {
        ...state,
        getAllPurposeOptionsRequestData: false,
        getAllPurposeOptionsData: false,
        getAllPurposeOptionsError: action.payload.history.error,
      };

    case CREATE_CUSTOM_CARD_REQUEST:
      return {
        ...state,
        createCustomCardRequestData: true,
        createCustomCardData: false,
        createCustomCardError: false,
      };
    case CREATE_CUSTOM_CARD_SUCCESS:
      return {
        ...state,
        createCustomCardRequestData: false,
        createCustomCardData: action.payload.user.data,
        createCustomCardError: false,
      };
    case CREATE_CUSTOM_CARD_ERROR:
      return {
        ...state,
        createCustomCardRequestData: false,
        createCustomCardData: false,
        createCustomCardError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Tasks;
