import {
  VERIFY_OTP_ERROR,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
} from "./actionTypes";

export const verifyOtpAction = (user) => {
  return {
    type: VERIFY_OTP_REQUEST,
    payload: user,
  };
};

export const verifyOtpSuccess = (user, history) => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: { user, history },
  };
};

export const verifyOtpError = (history) => {
  return {
    type: VERIFY_OTP_ERROR,
    payload: { history },
  };
};
