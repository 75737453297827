import React from "react";
import { Col, Row } from "reactstrap";
import { Nav } from "react-bootstrap";

import BlackSun from "../../assets/BlackSun.svg";
import { EXTERNAL_URLS, ROUTE_PATH } from "../../common/constants";
import TRANSLATIONS from "../../translations/en.json";
import { useHistory } from "react-router-dom";
import "./Footer.css";

export default function EntFooter() {
  const history = useHistory();
  const FOOTER_LINKS = [
    {
      pathname: ROUTE_PATH.SCIENCE,
      name: TRANSLATIONS.COMMON.THE_SCIENCE,
    },
    {
      pathname: ROUTE_PATH.HOW_IT_WORKS,
      name: TRANSLATIONS.COMMON.HOW_IT_WORKS,
    },
    {
      isExternal: true,
      url: EXTERNAL_URLS.BLOG_URL,
      name: TRANSLATIONS.SIDE_BAR.BLOG,
    },
    {
      pathname: ROUTE_PATH.CONTACT,
      name: TRANSLATIONS.SIDE_BAR.CONTACT_US,
    },
  ];
  return (
    <div className=" ent-footer-card">
      <Nav>
        <Row className="footer-container">
          <Col md={9} sm={12} xs={12} className="mx-auto">
            <Row>
              {FOOTER_LINKS.map((item) => {
                return (
                  <Col md={3} sm={6} xs={12} key={item.name}>
                    {item.isExternal ? (
                      <Nav.Link
                        href={item.url}
                        className="tag-bold-footer blog-link"
                        target="_blank"
                      >
                        {item.name}
                      </Nav.Link>
                    ) : (
                      <Nav.Link
                        to={item.pathname}
                        onClick={() => history.push(item.pathname)}
                        className="tag-bold-footer"
                      >
                        {item.name}
                      </Nav.Link>
                    )}
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Nav>

      <div className="justify-align-center">
        <img src={BlackSun} alt="logo" className="center" />
      </div>
    </div>
  );
}
