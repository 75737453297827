import {
  GET_ASSIGNED_TASK_TIMELINE_ERROR,
  GET_ASSIGNED_TASK_TIMELINE_REQUEST,
  GET_ASSIGNED_TASK_TIMELINE_SUCCESS,
  GET_TIMELINE_ERROR,
  GET_TIMELINE_REQUEST,
  GET_TIMELINE_SUCCESS,
  REMIND_TASK_ERROR,
  REMIND_TASK_REQUEST,
  REMIND_TASK_SUCCESS,
  GET_EMOJIS_ERROR,
  GET_EMOJIS_REQUEST,
  GET_EMOJIS_SUCCESS,
} from "./actionTypes";

const initialState = {};

const Timeline = (state = initialState, action) => {
  switch (action.type) {
    case GET_TIMELINE_REQUEST:
      return {
        ...state,
        getTimelineRequestData: true,
        getTimelineData: false,
        getTimelineError: false,
      };
    case GET_TIMELINE_SUCCESS:
      return {
        ...state,
        getTimelineRequestData: false,
        getTimelineData: action.payload.user.data,
        getTimelineError: false,
      };
    case GET_TIMELINE_ERROR:
      return {
        ...state,
        getTimelineRequestData: false,
        getTimelineData: false,
        getTimelineError: action.payload.history.error,
      };
    case GET_ASSIGNED_TASK_TIMELINE_REQUEST:
      return {
        ...state,
        getAssignedTaskTimelineRequestData: true,
        getAssignedTaskTimelineData: false,
        getAssignedTaskTimelineError: false,
      };
    case GET_ASSIGNED_TASK_TIMELINE_SUCCESS:
      return {
        ...state,
        getAssignedTaskTimelineRequestData: false,
        getAssignedTaskTimelineData: action.payload.user.data,
        getAssignedTaskTimelineError: false,
      };
    case GET_ASSIGNED_TASK_TIMELINE_ERROR:
      return {
        ...state,
        getAssignedTaskTimelineRequestData: false,
        getAssignedTaskTimelineData: false,
        getAssignedTaskTimelineError: action.payload.history.error,
      };
    case REMIND_TASK_REQUEST:
      return {
        ...state,
        remindTaskRequestData: true,
        remindTaskData: false,
        remindTaskError: false,
      };
    case REMIND_TASK_SUCCESS:
      return {
        ...state,
        remindTaskRequestData: false,
        remindTaskData: action.payload.user.data,
        remindTaskError: false,
      };
    case REMIND_TASK_ERROR:
      return {
        ...state,
        remindTaskRequestData: false,
        remindTaskData: false,
        remindTaskError: action.payload.history.error,
      };
    case GET_EMOJIS_REQUEST:
      return {
        ...state,
        getEmojisRequestData: true,
        getEmojisData: false,
        getEmojisError: false,
      };
    case GET_EMOJIS_SUCCESS:
      return {
        ...state,
        getEmojisRequestData: false,
        getEmojisData: action.payload.user.data,
        getEmojisError: false,
      };
    case GET_EMOJIS_ERROR:
      return {
        ...state,
        getEmojisRequestData: false,
        getEmojisData: false,
        getEmojisError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Timeline;
