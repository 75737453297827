import {
  VERIFY_ACCESS_ERROR,
  VERIFY_ACCESS_REQUEST,
  VERIFY_ACCESS_SUCCESS,
} from "./actionTypes";

const initialState = {};

const VerifyAccess = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_ACCESS_REQUEST:
      return {
        ...state,
        verifyAccessRequestData: true,
        verifyAccessData: false,
        verifyAccessError: false,
      };
    case VERIFY_ACCESS_SUCCESS:
      return {
        ...state,
        verifyAccessRequestData: false,
        verifyAccessData: action.payload.user.data,
        verifyAccessError: false,
      };
    case VERIFY_ACCESS_ERROR:
      return {
        ...state,
        verifyAccessRequestData: false,
        verifyAccessData: false,
        verifyAccessError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default VerifyAccess;
