import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";

import "./Results.css";
import Dopamine from "../../assets/DoseIcons/dopamineIcon.webp";
import DopamineHead from "../../assets/ChemicalBackgrounds/DopamineBg.webp";
import Endorphin from "../../assets/DoseIcons/endorphin.webp";
import EndorphinHead from "../../assets/ChemicalBackgrounds/EndorphinBg.webp";
import Oxytocin from "../../assets/DoseIcons/oxytocin.webp";
import OxytocinHead from "../../assets/ChemicalBackgrounds/OxytocinBg.webp";
import Serotonin from "../../assets/DoseIcons/serotonin.webp";
import SerotoninHead from "../../assets/ChemicalBackgrounds/SerotoninBg.webp";
import TRANSLATIONS from "../../translations/en.json";
import { ReactComponent as WhiteSwoosh } from "../../assets/white_swoosh.svg";

const resultData = {
  dopamine: {
    cardText: [
      "Cook something new in the kitchen",
      "Set an exercise goal and work to achieve it",
      "Read a chapter or more from your favorite book",
    ],
    chem: "Dopamine",
    description1: `Often called the “reward center” of your brain, dopamine is that high we get after beating a tough level of candy crush...and the need to beat another. You know that mood bump you get after someone likes your instagram post? That’s dopamine in action. Enjoying that tasty second piece of cake? Dopamine.`,
    description2: `Often linked to short-term pleasure and gratification, dopamine is responsible for creating feedback loops in the brain that make us want to do more dopamine-inducing activities over and over again.`,
    head: DopamineHead,
    headerText: "Reward Yourself",
    icon: Dopamine,
    color: "#2ab98c",
    definitionText: "The Reward Chemical",
    cardResult1: "Cook something new in the kitchen",
    cardResult2: "Set an exercise goal and work to achieve it",
    cardResult3: "Read a chapter or more from your favorite book",
    time1: "20-30 mins",
    time2: "20-30 mins",
    time3: "20-30 mins",
  },
  oxytocin: {
    cardText: [
      "Schedule time to enjoy afternoon sunshine with someone you love",
      "Watch a live-streaming sports event with a close friend",
      "Give your dog or pet a big hug",
    ],
    chem: "Oxytocin",
    description1: `Known fondly as the “love hormone,” oxytocin is often released when we bond with another person. The warm fuzzies you get from hugging a friend or sharing an intimate moment with someone? That’s your oxytocin firing. The effects of oxytocin are most commonly experienced during childbirth, when showing empathy, and during sex.`,
    description2: `Oxytocin is produced by the hypothalamus and is largely responsible for our feelings of social connectedness. Strong feelings of trust, intimacy, and yes, love, are often associated with this hormone.`,
    head: OxytocinHead,
    headerText: "Love Yourself",
    icon: Oxytocin,
    color: "#F47D54",
    definitionText: "The Love Hormone",
    cardResult1:
      "Schedule time to enjoy afternoon sunshine with someone you love",
    cardResult2: "Watch a live-streaming sports event with a close friend",
    cardResult3: "Give your dog or pet a big hug",
    time1: "20-30 mins",
    time2: "20-30 mins",
    time3: "20-30 mins",
  },
  serotonin: {
    cardText: [],
    chem: "Serotonin",
    description1: `Serotonin is seen as a full-body stabilizer. It plays a key role in regulating your mood and ensuring healthy digestion. It also helps with sleep and overall brain function. So the feeling of accomplishment you get after completing a big project, the calm that comes over you from a deep meditation, and the feeling you get from a great night’s sleep can all be traced back to this nifty chemical.`,
    description2: `Because serotonin is key to your sense of well being, treatments for mood disorders such as depression or anxiety involve increasing serotonin levels in the brain.`,
    head: SerotoninHead,
    headerText: "Calm Yourself",
    icon: Serotonin,
    color: "#00cec3",
    definitionText: "The Mood Stabilizer",
    cardResult1:
      "Go for a walk and smile at those your see for no apparant reason",
    cardResult2: "Sit outside to feel the warmth of the afternoon sunshine",
    cardResult3: "Savor a piece of chocolate",
    time1: "20-30 mins",
    time2: "20-30 mins",
    time3: "20-30 mins",
  },
  endorphin: {
    cardText: [],
    chem: "Endorphin",
    description1: `Classified as the relief hormone, endorphins are often produced in response to intense physical activity or to reduce pain. Like oxytocin, endorphins are released by the hypothalamus and are the main culprits behind the infamous “runner’s high.”`,
    description2: `
    Dopamine production increases from exercise (especially cardio), laughter, and eating spicy foods. Exercise is also used as a common countermeasure for depression and anxiety because it stimulates the production of this feel-good chemical. Laughter really is the best medicine.`,
    head: EndorphinHead,
    headerText: "Elevate Yourself",
    icon: Endorphin,
    color: "#2ea0d8",
    definitionText: "The Relief Chemical",
    cardResult1: "Go for a run with a friend",
    cardResult2: "Teach a friend how to cook a dish within 30 minutes",
    cardResult3: "Roll on the ground with your dog",
    time1: "20-30 mins",
    time2: "20-30 mins",
    time3: "20-30 mins",
  },
};

function Results() {
  const { chemical: chemParam } = useParams();
  const chemical = resultData[chemParam];
  const iconList = [
    { chem: "Dopamine", icon: Dopamine },
    { chem: "Oxytocin", icon: Oxytocin },
    { chem: "Serotonin", icon: Serotonin },
    { chem: "Endorphin", icon: Endorphin },
  ].filter((i) => i.chem !== chemical.chem);

  /*update app title based on route */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  return (
    <>
      <div className="Results">
        <div
          className={`container-fluid p-0 chemical-result-img ${chemical.chem}Img`}
        >
          <div className="row dose-overlay ">
            <div className="col-12 col-lg-10 col-md-12 results-section">
              <div className="branding-section">
                {" "}
                <img
                  src={chemical.icon}
                  alt="serotoninIcon"
                  className="chemicalIconResult"
                />
              </div>
              <div className="chemical-header-text">{chemical.headerText}</div>
              <div className="chemical-result-text">
                {TRANSLATIONS.RESULTS.BASED}{" "}
              </div>
              <div
                className="chemColorResult"
                style={{ color: chemical.color }}
              >
                {chemical.chem}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid bg-branding">
          <div className="col textResult">
            <div className="text-color-result main-description">
              {chemical.description1}
            </div>
            <div className="text-color-result sub-text">
              {chemical.description2}
            </div>
            <div className="text-color-result">
              {TRANSLATIONS.RESULTS.ACTIVITIES}
            </div>
          </div>
        </div>
        <div className="container-fluid result-card-main pb-5 ">
          <div className="row result-card-section pt-5 px-4 ">
            {[1, 2, 3].map((item) => {
              return (
                <div
                  key={item}
                  className="col-lg-3 result-card"
                  style={{ backgroundColor: chemical.color }}
                >
                  <p className="result-time  web-section">
                    {chemical[`time${item}`]}
                  </p>

                  <h3 className="result-text">
                    {chemical[`cardResult${item}`]}
                  </h3>
                  <p className="result-time  mobile-section">
                    {chemical[`time${item}`]}
                  </p>
                </div>
              );
            })}
          </div>
          <WhiteSwoosh className="results-white-swoosh-bottom" />
        </div>
        <div className="container-fluid learnSectionResult pt-5">
          <div className="col-12">
            <div className="learnMoreResult">{TRANSLATIONS.RESULTS.LEARN}</div>
          </div>
          <div className="row">
            {iconList.map((icon, key) => (
              <div className="col-lg-4 col-md-4 col-4 chemResult" key={key}>
                <Link to={`/results/${icon.chem.toLowerCase()}`}>
                  <img
                    src={icon.icon}
                    alt={icon.chem}
                    className="chemicalResult"
                  />
                  <div className="chemicalNameResult">{icon.chem}</div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
// change linebreaks to margins and all custom tags to divs with classNames
export default Results;
