import {
  GET_ASSIGNED_TASK_TIMELINE_ERROR,
  GET_ASSIGNED_TASK_TIMELINE_REQUEST,
  GET_ASSIGNED_TASK_TIMELINE_SUCCESS,
  GET_TIMELINE_ERROR,
  GET_TIMELINE_REQUEST,
  GET_TIMELINE_SUCCESS,
  REMIND_TASK_ERROR,
  REMIND_TASK_REQUEST,
  REMIND_TASK_SUCCESS,
  GET_EMOJIS_ERROR,
  GET_EMOJIS_REQUEST,
  GET_EMOJIS_SUCCESS,
} from "./actionTypes";

export const getTimelineAction = (user) => {
  return {
    type: GET_TIMELINE_REQUEST,
    payload: user,
  };
};

export const getTimelineSuccess = (user, history) => {
  return {
    type: GET_TIMELINE_SUCCESS,
    payload: { user, history },
  };
};

export const getTimelineError = (history) => {
  return {
    type: GET_TIMELINE_ERROR,
    payload: { history },
  };
};

export const getAssignedTaskTimelineAction = (user) => {
  return {
    type: GET_ASSIGNED_TASK_TIMELINE_REQUEST,
    payload: user,
  };
};

export const getAssignedTaskTimelineSuccess = (user, history) => {
  return {
    type: GET_ASSIGNED_TASK_TIMELINE_SUCCESS,
    payload: { user, history },
  };
};

export const getAssignedTaskTimelineError = (history) => {
  return {
    type: GET_ASSIGNED_TASK_TIMELINE_ERROR,
    payload: { history },
  };
};

export const getRemindTaskAction = (user) => {
  return {
    type: REMIND_TASK_REQUEST,
    payload: user,
  };
};

export const getRemindTaskSuccess = (user, history) => {
  return {
    type: REMIND_TASK_SUCCESS,
    payload: { user, history },
  };
};

export const getRemindTaskError = (history) => {
  return {
    type: REMIND_TASK_ERROR,
    payload: { history },
  };
};

export const getEmojisAction = (user) => {
  return {
    type: GET_EMOJIS_REQUEST,
    payload: user,
  };
};

export const getEmojisSuccess = (user, history) => {
  return {
    type: GET_EMOJIS_SUCCESS,
    payload: { user, history },
  };
};

export const getEmojisError = (history) => {
  return {
    type: GET_EMOJIS_ERROR,
    payload: { history },
  };
};
