import React from "react";
import { Nav } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import "../EnterpriseLayout/Footer.css";

import BlackSun from "../../assets/WebpImages/BlackSun.webp";
import { EXTERNAL_URLS, ROUTE_PATH } from "../../common/constants";
import TRANSLATIONS from "../../translations/en.json";

const Footer = () => {
  const history = useHistory();
  const FOOTER_LINKS = [
    {
      url: EXTERNAL_URLS.LINKEDIN_URL,
      name: TRANSLATIONS.HOME_PAGE.ABOUT_US,
      isExternal: true,
    },

    {
      isExternal: true,
      url: EXTERNAL_URLS.BLOG_URL,
      name: TRANSLATIONS.SIDE_BAR.BLOG,
    },
    {
      pathname: ROUTE_PATH.FAQS,
      name: TRANSLATIONS.HOME_PAGE.FAQS,
    },
    {
      pathname: ROUTE_PATH.CONTACT,
      name: TRANSLATIONS.SIDE_BAR.CONTACT_US,
    },
  ];
  return (
    <div className=" ent-footer-card">
      <Nav>
        <Row className="footer-container">
          <Col md={10} lg={9} sm={12} xs={12} className="mx-auto">
            <Row>
              {FOOTER_LINKS.map((item) => {
                return (
                  <Col
                    md={3}
                    sm={6}
                    xs={12}
                    key={item.name}
                    className="tag-bold-footer-col"
                  >
                    {item.isExternal ? (
                      <Nav.Link
                        href={item.url}
                        className="tag-bold-footer blog-link "
                        target="_blank"
                      >
                        {item.name}
                      </Nav.Link>
                    ) : (
                      <Nav.Link
                        to={item.pathname}
                        onClick={() => history.push(item.pathname)}
                        className="tag-bold-footer"
                      >
                        {item.name}
                      </Nav.Link>
                    )}
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Nav>

      <div className="image-footer-container justify-align-center">
        <img src={BlackSun} alt="logo" className="center" />
      </div>
    </div>
  );
};

export default Footer;
