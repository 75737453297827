import React from "react";
import { Col, Row } from "reactstrap";
import Reminders from "../../src/assets/EnterpriseHomePageWebps/Reminders.webp";
import ImgLazyLoadComponent from "../common/components/ImageLazyLoad";
import TRANSLATIONS from "../translations/en.json";

export default function ProPgeReminders() {
  return (
    <Row className="image-container pt-container">
      <Col md={6} sm={6} xs={6}>
        <ImgLazyLoadComponent
          src={Reminders}
          alt="reminderLogo"
          className="logo"
        />
      </Col>
      <Col md={6} sm={6} xs={6}>
        <p className="image-header mb-0">
          {TRANSLATIONS.ENT_PGE_REMINDER.REM_TITLE}
        </p>
        <p className="image-description w-90 mb-0">
          {TRANSLATIONS.HAPPY_PRO.REM_TEXT}
        </p>
      </Col>
    </Row>
  );
}
