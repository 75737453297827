import { Col, Row } from "reactstrap";

import React from "react";
import RectangleBonds from "../../src/assets/RectangleBonds.svg";
import TRANSLATIONS from "../translations/en.json";

export default function EntBonds() {
  return (
    <Row className="image-container pt-container">
      <Col md={6} sm={6} xs={6} className="pl-0">
        <div className="bonds-img">
          <img
            src={RectangleBonds}
            alt="bondsLogo2"
            className="logo-overlay logo-overlay-bonds"
          />
        </div>
      </Col>
      <Col md={6} sm={6} xs={6}>
        <p className="image-header mb-0">
          {TRANSLATIONS.ENT_PGE_BONDS.BONDS_TITLE}
        </p>
        <p className="image-description w-90 mb-0">
          {TRANSLATIONS.ENT_PGE_BONDS.BONDS_TEXT}
        </p>
      </Col>
    </Row>
  );
}
