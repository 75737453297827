import {
  DOWNLOAD_ERROR,
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
} from "./actionTypes";

const initialState = {};

const download = (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOAD_REQUEST:
      return {
        ...state,
        downloadRequestData: true,
        downloadData: false,
        downloadError: false,
      };
    case DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadRequestData: false,
        downloadData: action.payload.user.data,
        downloadError: false,
      };
    case DOWNLOAD_ERROR:
      return {
        ...state,
        downloadRequestData: false,
        downloadData: false,
        downloadError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default download;
