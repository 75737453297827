import { AXIOS_INSTANCE, CONTACT_US_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { profileError, profileSuccess } from "./actions";

import { PROFILE_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* profilePicture(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CONTACT_US_API}/update_profile_picture`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(profileSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(profileError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(profileError(errorData));
  }
}

export function* watchProfilePicture() {
  yield takeEvery(PROFILE_REQUEST, profilePicture);
}

function* ProfilePictureSaga() {
  yield all([fork(watchProfilePicture)]);
}
export default ProfilePictureSaga;
