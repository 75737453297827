import {
  DOWNLOAD_REQUEST,
  DOWNLOAD_SUCCESS,
  DOWNLOAD_ERROR,
} from "./actionTypes";

export const downloadRequest = (user) => {
  return {
    type: DOWNLOAD_REQUEST,
    payload: user,
  };
};

export const downloadSuccess = (user, history) => {
  return {
    type: DOWNLOAD_SUCCESS,
    payload: { user, history },
  };
};

export const downloadError = (history) => {
  return {
    type: DOWNLOAD_ERROR,
    payload: { history },
  };
};
