import {
  MOODS_BREAKDOWN_REQUEST,
  MOODS_BREAKDOWN_SUCCESS,
  MOODS_BREAKDOWN_ERROR,
  LONGEST_STREAK_CARD_REQUEST,
  LONGEST_STREAK_CARD_SUCCESS,
  LONGEST_STREAK_CARD_ERROR,
  PAST_LONGEST_STREAK_CARD_REQUEST,
  PAST_LONGEST_STREAK_CARD_SUCCESS,
  PAST_LONGEST_STREAK_CARD_ERROR,
  DAILY_REFLECTIONS_CARD_REQUEST,
  DAILY_REFLECTIONS_CARD_SUCCESS,
  DAILY_REFLECTIONS_CARD_ERROR,
  PAST_DAILY_REFLECTIONS_CARD_REQUEST,
  PAST_DAILY_REFLECTIONS_CARD_SUCCESS,
  PAST_DAILY_REFLECTIONS_CARD_ERROR,
} from "./actionsTypes";

const initialState = {};

const DashboardActivities = (state = initialState, action) => {
  switch (action.type) {
    case MOODS_BREAKDOWN_REQUEST:
      return {
        ...state,
        isMoodsBreakdownDataLoading: true,
        moodsBreakdownData: false,
        moodsBreakdownError: false,
      };
    case MOODS_BREAKDOWN_SUCCESS:
      return {
        ...state,
        isMoodsBreakdownDataLoading: false,
        moodsBreakdownData: action.payload.user.data,
        moodsBreakdownError: false,
      };
    case MOODS_BREAKDOWN_ERROR:
      return {
        ...state,
        isMoodsBreakdownDataLoading: false,
        moodsBreakdownData: false,
        moodsBreakdownError: action.payload.history.error,
      };
    case LONGEST_STREAK_CARD_REQUEST:
      return {
        ...state,
        longestStreakRequestData: true,
        longestStreakData: false,
        longestStreakError: false,
      };
    case LONGEST_STREAK_CARD_SUCCESS:
      return {
        ...state,
        longestStreakRequestData: false,
        longestStreakData: action.payload.user.data,
        longestStreakError: false,
      };
    case LONGEST_STREAK_CARD_ERROR:
      return {
        ...state,
        longestStreakRequestData: false,
        longestStreakData: false,
        longestStreakError: action.payload.history.error,
      };
    case PAST_LONGEST_STREAK_CARD_REQUEST:
      return {
        ...state,
        pastLongestStreakRequestData: true,
        pastLongestStreakData: false,
        pastLongestStreakError: false,
      };
    case PAST_LONGEST_STREAK_CARD_SUCCESS:
      return {
        ...state,
        pastLongestStreakRequestData: false,
        pastLongestStreakData: action.payload.user.data,
        pastLongestStreakError: false,
      };
    case PAST_LONGEST_STREAK_CARD_ERROR:
      return {
        ...state,
        pastLongestStreakRequestData: false,
        pastLongestStreakData: false,
        pastLongestStreakError: action.payload.history.error,
      };
    case DAILY_REFLECTIONS_CARD_REQUEST:
      return {
        ...state,
        dailyReflectionsRequestData: true,
        dailyReflectionsData: false,
        dailyReflectionsError: false,
      };
    case DAILY_REFLECTIONS_CARD_SUCCESS:
      return {
        ...state,
        dailyReflectionsRequestData: false,
        dailyReflectionsData: action.payload.user.data,
        dailyReflectionsError: false,
      };
    case DAILY_REFLECTIONS_CARD_ERROR:
      return {
        ...state,
        dailyReflectionsRequestData: false,
        dailyReflectionsData: false,
        dailyReflectionsError: action.payload.history.error,
      };
    case PAST_DAILY_REFLECTIONS_CARD_REQUEST:
      return {
        ...state,
        pastDailyReflectionsRequestData: true,
        dailyReflectionsData: false,
        dailyReflectionsError: false,
      };
    case PAST_DAILY_REFLECTIONS_CARD_SUCCESS:
      return {
        ...state,
        pastDailyReflectionsRequestData: false,
        pastDailyReflectionsData: action.payload.user.data,
        pastDailyReflectionsError: false,
      };
    case PAST_DAILY_REFLECTIONS_CARD_ERROR:
      return {
        ...state,
        pastDailyReflectionsRequestData: false,
        pastDailyReflectionsData: false,
        pastDailyReflectionsError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DashboardActivities;
