import React, { memo } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Hamburger } from "../../../assets/hamburgerIcon.svg";
import { ReactComponent as Hpy } from "../../../assets/Hpy.svg";
import TRANSLATIONS from "../../../translations/en.json";
import {
  bodyContainsCollapseClass,
  logAnalyticsEvent,
  manageBodyClass,
} from "../../../utils";
import { ANALYTICS_EVENTS_JSON, PRO } from "../../../common/constants";

function SidebarHeader({ collapsed, collapseSidebar, toggled, toggleSidebar }) {
  return (
    <>
      <Hamburger
        onClick={() => {
          logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DASHBOARD_HAMBURGER_MENU);

          if (toggled) {
            toggleSidebar();
          } else {
            manageBodyClass(
              "vertical-menu-collapsed",
              bodyContainsCollapseClass() ? "remove" : "add"
            );
            collapseSidebar();
          }
        }}
        className={collapsed ? "hamburger hamburger-mini" : "hamburger"}
      />
      {collapsed ? (
        <div className="menu-branding-mini content-branding">
          <div className=" mt-0">
            <Hpy className="menu-hpy-logo-mini" />
            <p className="teams-mini mb-0 mt-1 text-center">
              {localStorage.getItem("subscription") === PRO
                ? TRANSLATIONS.COMMON.PRO
                : TRANSLATIONS.COMMON.TEAMS}
            </p>
          </div>
        </div>
      ) : (
        <div className="menu-branding d-flex align-items-center content-branding">
          <div className=" mt-0">
            <Hpy className="menu-hpy-logo" />
          </div>
          <div>
            <p className="teams my-0">
              {localStorage.getItem("subscription") === PRO
                ? TRANSLATIONS.COMMON.PRO
                : TRANSLATIONS.COMMON.TEAMS}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
SidebarHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  collapseSidebar: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};
export default memo(SidebarHeader);
