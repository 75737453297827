import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  updateEnterpriseLogoError,
  updateEnterpriseLogoSuccess,
} from "./actions";

import { UPDATE_ENTERPRISE_LOGO_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* updateEnterpriseLogo(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/update_enterprise_logo`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data || true,
      };
      yield put(updateEnterpriseLogoSuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(updateEnterpriseLogoError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(updateEnterpriseLogoError(errorData));
  }
}

export function* watchUpdateEnterpriseLogo() {
  yield takeEvery(UPDATE_ENTERPRISE_LOGO_REQUEST, updateEnterpriseLogo);
}

function* updateEnterpriseLogoSaga() {
  yield all([fork(watchUpdateEnterpriseLogo)]);
}
export default updateEnterpriseLogoSaga;
