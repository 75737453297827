export const ACTIVITIES_PER_CHEMICAL_REQUEST =
  "ACTIVITIES_PER_CHEMICAL_REQUEST";
export const ACTIVITIES_PER_CHEMICAL_SUCCESS =
  "ACTIVITIES_PER_CHEMICAL_SUCCESS";
export const ACTIVITIES_PER_CHEMICAL_ERROR = "ACTIVITIES_PER_CHEMICAL_ERROR";

export const PAST_ACTIVITIES_PER_CHEMICAL_REQUEST =
  "PAST_ACTIVITIES_PER_CHEMICAL_REQUEST";
export const PAST_ACTIVITIES_PER_CHEMICAL_SUCCESS =
  "PAST_ACTIVITIES_PER_CHEMICAL_SUCCESS";
export const PAST_ACTIVITIES_PER_CHEMICAL_ERROR =
  "PAST_ACTIVITIES_PER_CHEMICAL_ERROR";
