//External packages
import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

//local files
import "./Header.css";
import appstoreIcon from "../../assets/applestoreIcon.svg";
import {
  ANALYTICS_EVENTS_JSON,
  EXTERNAL_URLS,
  ROUTE_PATH,
} from "../../common/constants";
import { AnimatedButton } from "../../common/components";
import hamburger from "../../assets/hamburgerIcon.svg";
import Hpy from "../../assets/Hpy.svg";
import playIcon from "../../assets/googleplaystore.svg";
import TRANSLATIONS from "../../translations/en.json";
import MobileHeader from "./MobileHeader";
import { ReactComponent as Blog } from "../../assets/AdminSideIcons/Blog.svg";
import { ReactComponent as ContactIcon } from "../../assets/AdminSideIcons/ContactIcon.svg";
import { ReactComponent as EnterpriseIcon } from "../../assets/AdminSideIcons/EnterpriseIcon.svg";
import { ReactComponent as HomeIcon } from "../../assets/AdminSideIcons/HomeIcon.svg";
import { ReactComponent as PrivacyIcon } from "../../assets/AdminSideIcons/PrivacyIcon.svg";
import { ReactComponent as WhiteSunIcon } from "../../assets/AdminSideIcons/DoseSunIcon.svg";
import { logAnalyticsEvent } from "../../utils";

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const headerLinks = [
    {
      icon: HomeIcon,
      name: TRANSLATIONS.SIDE_BAR.HPY,
      pathname: ROUTE_PATH.HOME,
    },
    {
      icon: WhiteSunIcon,
      name: TRANSLATIONS.SIDE_BAR.WHAT_IS_DOSE,
      pathname: ROUTE_PATH.DOSE,
    },
    {
      icon: Blog,
      name: TRANSLATIONS.SIDE_BAR.BLOG,
      url: EXTERNAL_URLS.BLOG_URL,
      isExternal: true,
    },
    {
      icon: ContactIcon,
      name: TRANSLATIONS.SIDE_BAR.CONTACT_US,
      pathname: ROUTE_PATH.CONTACT,
    },
    {
      icon: PrivacyIcon,
      name: TRANSLATIONS.SIDE_BAR.PRIVACY,
      pathname: ROUTE_PATH.PRIVACY,
    },
    {
      icon: EnterpriseIcon,
      name: TRANSLATIONS.SIDE_BAR.ENTERPRISE_BTN,
      pathname: ROUTE_PATH.ENTERPRISE,
      preference: "FREE",
    },
    {
      icon: EnterpriseIcon,
      name: TRANSLATIONS.SIDE_BAR.WEB_HY_PRO,
      pathname: ROUTE_PATH.PRO_HOME,
      isLast: true,
      preference: "PRO",
    },
  ];

  const handleSetUserPreference = (preference) => {
    ["FREE", "PRO"].includes(preference)
      ? localStorage.setItem("subscription", preference)
      : "";
  };

  return (
    <nav className="navbar navbar-expand-md mynav navigation p-0 bg-branding">
      <div className="container-fluid nav-container bg-branding p-0">
        <div className="row">
          <Link
            className="navbar-brand"
            to="/"
            onClick={() => {
              logAnalyticsEvent(ANALYTICS_EVENTS_JSON.HPY_LOGO);
            }}
          >
            <img src={Hpy} alt="logoText" className="logoText" />
          </Link>
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="light"
            className="mobile-navbar d-none d-lg-block pr-0"
          >
            <Container fluid>
              <Navbar.Collapse
                className="justify-content-end "
                id="responsive-navbar-nav"
              >
                <Nav className="align-items-baseline">
                  {headerLinks.map((item) => {
                    return item.isExternal ? (
                      <Nav.Link
                        href={EXTERNAL_URLS.BLOG_URL}
                        target="_blank"
                        onClick={() => {
                          logAnalyticsEvent(ANALYTICS_EVENTS_JSON.BLOG_LINK);
                        }}
                        className={" link-text spacing-nav-tags blog"}
                      >
                        {TRANSLATIONS.SIDE_BAR.BLOG}
                      </Nav.Link>
                    ) : (
                      <Link
                        to={item.pathname}
                        className={
                          item.isLast
                            ? "link-text spacing-nav-tags last-spacing-nav-tag"
                            : window.location.pathname === item.pathname
                            ? "link-text  active remove-underline spacing-nav-tags"
                            : "link-text spacing-nav-tags"
                        }
                        onClick={() => handleSetUserPreference(item.preference)}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <div className="row appstore-section">
          <AnimatedButton className="transparent-btn">
            <a href={EXTERNAL_URLS.IOS_URL} target="_blank" rel="noreferrer">
              <img
                src={appstoreIcon}
                alt="appstore"
                onClick={() => {
                  logAnalyticsEvent(ANALYTICS_EVENTS_JSON.IOS_STORE_BTN);
                }}
                className="img-fluid appstore-icon d-none d-lg-block mr-4 "
              />
            </a>
          </AnimatedButton>
          <AnimatedButton className="transparent-btn">
            <a href={EXTERNAL_URLS.GOOGLE_URL} target="_blank" rel="noreferrer">
              <img
                className=" img-fluid appstore-icon  d-none d-lg-block "
                alt="Get it on Google Play"
                src={playIcon}
                onClick={() => {
                  logAnalyticsEvent(ANALYTICS_EVENTS_JSON.GOOGLE_PLAYSTORE_BTN);
                }}
              />
            </a>
          </AnimatedButton>
          <div className="p-menu d-lg-none">
            <img
              src={hamburger}
              onClick={() => {
                logAnalyticsEvent(
                  ANALYTICS_EVENTS_JSON.CONSUMER_HAMBURGER_MENU
                );
                handleToggle();
              }}
              className="hamburger-1"
            />
            {sidebarOpen && (
              <MobileHeader
                headerLinks={headerLinks}
                handleToggle={handleToggle}
                handleSetUserPreference={handleSetUserPreference}
              />
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
