import {
  ACTIVITIES_PER_CHEMICAL_REQUEST,
  ACTIVITIES_PER_CHEMICAL_SUCCESS,
  ACTIVITIES_PER_CHEMICAL_ERROR,
  PAST_ACTIVITIES_PER_CHEMICAL_REQUEST,
  PAST_ACTIVITIES_PER_CHEMICAL_SUCCESS,
  PAST_ACTIVITIES_PER_CHEMICAL_ERROR,
} from "./actionTypes";

const initialState = {};

const ActivitiesPerChemical = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITIES_PER_CHEMICAL_REQUEST:
      return {
        ...state,
        isActivitiesPerChemicalLoading: true,
        activitiesPerChemicalData: false,
        activitiesPerChemicalError: false,
      };
    case ACTIVITIES_PER_CHEMICAL_SUCCESS:
      return {
        ...state,
        isActivitiesPerChemicalLoading: false,
        activitiesPerChemicalData: action.payload.user.data,
        activitiesPerChemicalError: false,
      };
    case ACTIVITIES_PER_CHEMICAL_ERROR:
      return {
        ...state,
        isActivitiesPerChemicalLoading: false,
        activitiesPerChemicalData: false,
        activitiesPerChemicalError: action.payload.history.error,
      };
    case PAST_ACTIVITIES_PER_CHEMICAL_REQUEST:
      return {
        ...state,
        pastActivitiesPerChemicalRequestData: true,
        pastActivitiesPerChemicalData: false,
        pastActivitiesPerChemicalError: false,
      };
    case PAST_ACTIVITIES_PER_CHEMICAL_SUCCESS:
      return {
        ...state,
        pastActivitiesPerChemicalRequestData: false,
        pastActivitiesPerChemicalData: action.payload.user.data,
        pastActivitiesPerChemicalError: false,
      };
    case PAST_ACTIVITIES_PER_CHEMICAL_ERROR:
      return {
        ...state,
        pastActivitiesPerChemicalRequestData: false,
        pastActivitiesPerChemicalData: false,
        pastActivitiesPerChemicalError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ActivitiesPerChemical;
