import React from "react";
import "./index.css";

import GetStarted from "../home/GetStarted";
import ProPgeBonds from "./EntPgeBonds";
import ProPgeHeader from "./EntPgeHeader";
import ProPgeHero from "./EntPgeHero";
import ProPgeInsights from "./EntPgeInsights";
import ProPgeIntro from "./EntPgeIntro";
import ProPgeMatters from "./EntPgeMatters";
import ProPgeReflections from "./EntPgeReflections";
import ProPgeReminders from "./EntPgeReminders";
import ProPgeScience from "./EntPgeScience";
import ProPgeSignUp from "./EntPgeSignUp";

function ProHome() {
  return (
    <div className="entpage ">
      <ProPgeHeader />
      <ProPgeHero />
      <ProPgeIntro />
      <ProPgeMatters />
      <ProPgeReminders />
      <ProPgeReflections />
      <ProPgeScience />
      <ProPgeInsights />
      <ProPgeBonds />
      <ProPgeSignUp />
      <GetStarted />
    </div>
  );
}
export default ProHome;
