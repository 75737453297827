import React, { memo } from "react";
import PropTypes from "prop-types";
import MembersList from "./MembersList";
import RequestAccess from "../RequestAccess";
import SendCodeCard from "../WelcomeScreen/SendCodeCard";
function MembersScreen({
  allTeamMembers,
  buttonText,
  inviteModaltoggle,
  handleDelete,
  handleResendCode,
  isSeeAll,
  teamAccessRequests,
  userDetails,
}) {
  return (
    <div className="members-screen">
      <RequestAccess teamAccessRequests={teamAccessRequests} />
      <MembersList
        allTeamMembers={allTeamMembers}
        buttonText={buttonText}
        handleResendCode={handleResendCode}
        handleDelete={handleDelete}
        isSeeAll={isSeeAll}
      />
      <SendCodeCard
        teamCode={userDetails?.teamCode}
        inviteModaltoggle={inviteModaltoggle}
        createdAt={userDetails?.createdAt}
      />
    </div>
  );
}
MembersScreen.propTypes = {
  allTeamMembers: PropTypes.array,
  buttonText: PropTypes.string,
  inviteModaltoggle: PropTypes.func,
  handleResendCode: PropTypes.func,
  handleDelete: PropTypes.func,
  isSeeAll: PropTypes.bool,
  teamAccessRequests: PropTypes.array,
  userDetails: PropTypes.object,
};
export default memo(MembersScreen);
