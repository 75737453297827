export const LineChartOptions = {
  theme: {
    mode: "light",
  },
  grid: {
    show: true,
  },
  annotations: {
    yaxis: [
      {
        y: 10,
        text: "avg",
      },
    ],
  },
  chart: {
    type: "line",
    background: "transparent",
    shadow: {
      enabled: false,
      color: "#bbb",
      top: 3,
      left: 2,
      blur: 3,
      opacity: 1,
    },
    zoom: {
      enabled: false,
    },
  },
  stroke: {
    width: 4,
    curve: "smooth",
  },
  xaxis: {
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      style: {
        colors: "#9D9D9D",
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: true,
    },
  },
  responsive: [
    {
      breakpoint: 460,
      options: {
        xaxis: {
          labels: { rotate: 0, rotateAlways: false },
          tickPlacement: "on",
        },
      },
    },
  ],
  tooltip: {
    intersect: false,
    shared: true,
    y: {
      formatter: function (val) {
        return val;
      },
    },
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      colorStops: [
        {
          offset: 0,
          color: "#ffdb43",
          opacity: 1,
        },
        {
          offset: 20,
          color: "#09C28E",
          opacity: 1,
        },
        {
          offset: 40,
          color: "#00CEC3",
          opacity: 1,
        },
        {
          offset: 70,
          color: "#33A0D7",
          opacity: 1,
        },
        {
          offset: 100,
          color: "#FF7F52",
          opacity: 1,
        },
      ],
      gradientToColors: ["#ffdb43", "#09C28E", "#00CEC3", "#33A0D7", "#FF7F52"],
      shadeIntensity: 1,
      type: "vertical",
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100, 100, 100],
    },
  },
  markers: {
    size: 0,
    hover: {
      size: 5,
    },
  },
  colors: ["#ffdb43"],
  yaxis: {
    min: 0,
    max: 6,
    forceNiceScale: false,
    floating: false,
    logBase: 10,
    tickAmount: 6,
    axisBorder: {
      show: false,
    },
    crosshairs: {
      show: false,
    },
  },
  plotOptions: {
    line: {
      horizontal: true,
    },
  },
};
