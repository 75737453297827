import React from "react";
import { Col, Row } from "reactstrap";

import TRANSLATIONS from "../translations/en.json";
import RectangleScience from "../../src/assets/RectangleScience.svg";

export default function ProPgeScience() {
  return (
    <Row className="image-container pt-container">
      <Col md={6} sm={6} xs={6} className="pl-0">
        <div className="science-img">
          <img
            src={RectangleScience}
            alt="rectangle"
            className="logo-overlay"
          />
        </div>
      </Col>
      <Col md={6} sm={6} xs={6}>
        <p className="image-header mb-0">
          {TRANSLATIONS.ENT_PGE_SCIENCE.SCIENCE_TITLE}
        </p>
        <p className="image-description w-90 mb-0">
          {TRANSLATIONS.HAPPY_PRO.SCIENCE_TEXT}
        </p>
      </Col>
    </Row>
  );
}
