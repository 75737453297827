import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "reactstrap";

import "./HappyCard.css";
import AnimatedButton from "../common/components/AnimatedButton";

function HappyCard({ details }) {
  const {
    button,
    bunttonContainer,
    classname,
    description,
    extraSpace,
    header,
    image,
    imageClassName,
    imageDivClassName,
    isImageChanged,
    mobileDeviceImage,
    mobileDeviceImageClassName,
  } = details;

  // Scroll into Request Demo Component
  const handleScrollToRequestDemo = () => {
    setTimeout(() => {
      document
        .getElementById("request-demo")
        .scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <Card className={`happy-card ${classname} h-100`}>
      <div className={imageDivClassName}>
        <img src={image} alt="rectangle" className={imageClassName} />
        {isImageChanged && (
          <img
            src={mobileDeviceImage}
            alt="rectangle"
            className={mobileDeviceImageClassName}
          />
        )}
      </div>
      <Row className={`happy-card-text-container ${extraSpace} h-100`}>
        <Col className="px-0">
          <h2 className="happy-card-header">{header}</h2>
          <p className="happy-card-desc ">{description}</p>
          <div className={bunttonContainer}>
            <AnimatedButton
              className="happy-button"
              onClick={() => {
                handleScrollToRequestDemo();
              }}
            >
              {button}
            </AnimatedButton>
          </div>
        </Col>
      </Row>
    </Card>
  );
}
HappyCard.propTypes = {
  details: PropTypes.object,
};
export default HappyCard;
