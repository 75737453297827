import _ from "lodash";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";

import {
  formatDateAndExtractYear,
  getDateFormatWithoutYear,
} from "../../../utils";
import "./index.css";

// lazy loading components
const HappyScoreCard = lazy(() => import("./HappyScoreCard"));
const MemberUserDetails = lazy(() => import("./MemberUserDetails"));

function UserProfile() {
  const locationData = useLocation();

  const [userProfile, setUserProfile] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (!_.isEmpty(locationData?.state)) {
      const date = getDateFormatWithoutYear(locationData?.state?.lastVerified);
      const time = formatDateAndExtractYear(
        locationData?.state?.lastVerified
      )?.formattedTime;
      setUserDetails({
        userName: locationData?.state?.name,
        email: locationData?.state?.email,
        phoneNumber: locationData?.state?.phone,
        lastLogin: `Last logged in ${date}, ${time} `,
        happinessScore: locationData?.state?.happinessScore,
      });
      if (locationData?.state?.profileImageURL?.length) {
        setUserProfile(locationData?.state?.profileImageURL);
      }
    }
  }, [locationData]);

  return (
    <Suspense fallback="">
      <div className="user-profile-container mb-md-3 mt-3">
        <Row>
          <Col xs={12} md={8} className="user-profile-details px-md-0">
            <MemberUserDetails
              userProfile={userProfile}
              userDetails={userDetails}
            />
          </Col>
          <Col xs={12} md={4} className="user-profile-details px-md-0">
            <HappyScoreCard userDetails={userDetails} />
          </Col>
        </Row>
      </div>
    </Suspense>
  );
}

export default UserProfile;
