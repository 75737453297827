import React, { memo, useEffect, useRef, useState } from "react";
import TRANSLATIONS from "../translations/en.json";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { isValidPhoneNumber } from "react-phone-number-input";

import {
  phoneNumberField,
  inputField,
  selectField,
  required,
  validateEmail,
  validateName,
} from "../common/reduxFields";
import { connect, useDispatch, useSelector } from "react-redux";
import "./RequestDemo.css";
import { AnimatedButton, ButtonLoader } from "../common/components";
import { renderOptions, showError, showSuccess } from "../utils";
import { COUNTRIES_JSON_LIST, NO_OF_TEAM_MEMBERS } from "../common/constants";
import { requestDemo } from "../redux/actions";

function RequestDemo({ handleSubmit, reset }) {
  const dispatch = useDispatch();
  const [focused, setFocused] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [isValidMobileNumber, setIsValidMobileNumber] = useState(true);

  const { isRequestDemoLoading, requestDemoData, requestDemoError } =
    useSelector((state) => state.RequestDemo);

  const isRequestingDemo = useRef(true);
  useEffect(() => {
    if (isRequestingDemo.current) {
      isRequestingDemo.current = false;
      return;
    }
    if (!isRequestDemoLoading && requestDemoData) {
      reset();
      setTimeout(() => {
        if (
          requestDemoData === TRANSLATIONS.REQUEST_DEMO.REQUEST_DEMO_SUCCESS
        ) {
          showSuccess(requestDemoData);
        } else {
          showError(requestDemoData);
        }
      }, 500);
    } else if (!isRequestDemoLoading && requestDemoError) {
      showError(TRANSLATIONS.REQUEST_DEMO.REQUEST_DEMO_ERROR);
    }
  }, [isRequestDemoLoading, requestDemoData, requestDemoError]);

  const isValidatingPhoneNumber = useRef(true);
  useEffect(() => {
    if (isValidatingPhoneNumber.current) {
      isValidatingPhoneNumber.current = false;
      return;
    }
    if (mobileNumber) {
      setIsValidMobileNumber(isValidPhoneNumber(mobileNumber));
    } else {
      setIsValidMobileNumber(false);
    }
  }, [mobileNumber]);

  const onSubmit = (formProps) => {
    if (!formProps.phoneNumber) {
      setFocused(true);
      return;
    }
    const formData = {
      fullName: formProps.fullName,
      workEmail: formProps.email,
      phoneNumber: formProps.phoneNumber,
      country: formProps.country,
      jobTitle: formProps.jobTitle,
      teamName: formProps.teamName,
      numberOfTeamMembers: formProps.numberOfTeamMembers,
      message: formProps.message,
    };
    dispatch(requestDemo(formData));
  };
  return (
    <div className="request-demo" id="request-demo">
      <h2 className="header">{TRANSLATIONS.REQUEST_DEMO.CONNECT}</h2>
      <p className="subheader">{TRANSLATIONS.REQUEST_DEMO.BOOK_A_DEMO}</p>
      <form
        className="profile-edit-form"
        id="fields-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row className="form-row">
          <Col lg={6} md={8} sm={12} xs={12} className="demo-input-col">
            <Field
              component={inputField}
              name="fullName"
              placeholder={TRANSLATIONS.ADMIN_PROFILE_FORM.FULL_NAME}
              type="text"
              validate={[required, validateName]}
            />
          </Col>
          <Col lg={6} md={8} sm={12} xs={12} className="demo-input-col">
            <Field
              component={inputField}
              name="email"
              placeholder={TRANSLATIONS.ADMIN_PROFILE_FORM.WORK_EMAIL}
              type="email"
              validate={[required, validateEmail]}
            />
          </Col>
          <Col lg={6} md={8} sm={12} xs={12} className="demo-input-col">
            <div
              className={`PhoneInput form-control  ${
                (mobileNumber && !isValidMobileNumber) ||
                (focused && !mobileNumber)
                  ? "error-phone-input"
                  : ""
              }`}
            >
              <Field
                component={phoneNumberField}
                countryCallingCodeEditable={false}
                className="w-100"
                defaultCountry="US"
                international={true}
                name="phoneNumber"
                onChange={(e) => {
                  setMobileNumber(e);
                  setFocused(true);
                }}
                placeholder={TRANSLATIONS.DASHBOARD_SETUP.PHONE_NUMBER}
              />
            </div>
            {mobileNumber && !isValidMobileNumber ? (
              <span className="form-error">
                {TRANSLATIONS.COMMON.INVALID_NUMBER}
              </span>
            ) : (
              focused &&
              !mobileNumber && (
                <span className="form-error">
                  {TRANSLATIONS.ADMIN_PROFILE_FORM.REQUIRED}
                </span>
              )
            )}
          </Col>
          <Col lg={6} md={8} sm={12} xs={12} className="demo-input-col">
            <Field
              component={selectField}
              name="country"
              placeholder={TRANSLATIONS.REQUEST_DEMO.COUNTRY_OF_WORK}
              type="text"
              validate={[required, validateName]}
            >
              {renderOptions(COUNTRIES_JSON_LIST, "name", "name")}
            </Field>
          </Col>
          <Col lg={6} md={8} sm={12} xs={12} className="demo-input-col">
            <Field
              component={inputField}
              name="jobTitle"
              placeholder={TRANSLATIONS.REQUEST_DEMO.JOB_TITLE}
              type="text"
              validate={[required, validateName]}
            />
          </Col>
          <Col lg={6} md={8} sm={12} xs={12} className="demo-input-col">
            <Field
              component={inputField}
              name="teamName"
              placeholder={TRANSLATIONS.ADMIN_PROFILE_FORM.TEAM_NAME}
              type="text"
              validate={[required, validateName]}
            />
          </Col>
          <Col
            lg={6}
            md={8}
            sm={12}
            xs={12}
            className="demo-input-col position-relative"
          >
            <Field
              component={selectField}
              name="numberOfTeamMembers"
              placeholder={TRANSLATIONS.REQUEST_DEMO.NO_OF_TEAM_MEMBERS}
              validate={[required]}
            >
              {renderOptions(NO_OF_TEAM_MEMBERS)}
            </Field>
          </Col>

          <Col
            lg={6}
            md={8}
            sm={12}
            xs={12}
            className="textarea-input demo-input-col"
          >
            <Field
              rows={"6"}
              name="message"
              component={inputField}
              type="textarea"
              placeholder={TRANSLATIONS.REQUEST_DEMO.MESSAGE}
              validate={[required]}
            />
          </Col>
          <Col
            lg={6}
            md={8}
            sm={12}
            xs={12}
            className="demo-input-col request-demo-btn"
          >
            <AnimatedButton className="upgrade-btn">
              {TRANSLATIONS.REQUEST_DEMO.BUTTON}
              {isRequestDemoLoading && <ButtonLoader />}
            </AnimatedButton>
          </Col>
        </Row>
      </form>
    </div>
  );
}
RequestDemo.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
};
export default connect()(
  reduxForm({
    form: "RequestDemo",
    touchOnChange: true,
  })(memo(RequestDemo))
);
