import React, { memo } from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

import TRANSLATIONS from "../translations/en.json";
import "./AverageHpyScoreCard.css";
import {
  DASHBOARD_EMPTY_STATE_COLOR,
  PRIMARY_COLOR,
  PRO,
} from "../common/constants";
function AverageHpyScoreCard({ isEmptyDashboard, score }) {
  return (
    <Card
      className="hpy-score-card"
      style={{
        backgroundColor: isEmptyDashboard
          ? DASHBOARD_EMPTY_STATE_COLOR
          : PRIMARY_COLOR,
      }}
    >
      <div>
        <h2 className="avg-hpy">
          {TRANSLATIONS.USER_DASHBOARD.AVG_HPY_SCRORE}
        </h2>
        <p
          className="tech-team-score"
          style={{
            color: isEmptyDashboard ? "#BCBCBC" : "#1A2630",
          }}
        >
          {localStorage.getItem("subscription") === PRO
            ? TRANSLATIONS.USER_DASHBOARD.TECH_GROUP_SCORE
            : TRANSLATIONS.USER_DASHBOARD.TECH_TEAM_SCORE}
        </p>
      </div>
      <div
        className="score-value"
        style={{
          color: isEmptyDashboard ? "#BCBCBC" : "#1A2630",
        }}
      >{`${(!isEmptyDashboard && score) || "-"}/100`}</div>
    </Card>
  );
}
AverageHpyScoreCard.propTypes = {
  isEmptyDashboard: PropTypes.bool.isRequired,
  score: PropTypes.string.isRequired,
};
export default memo(AverageHpyScoreCard);
