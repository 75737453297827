import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { ProSidebarProvider } from "react-pro-sidebar";
Sentry.init({
  dsn: "https://24398ee1b7354df8900b6296d7736995@o4504116316143616.ingest.sentry.io/4504116323418112",
  integrations: [new BrowserTracing()],
  environment: "production",
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
let initialDistance = null;

function calculateDistance(touches) {
  const [touch1, touch2] = touches;
  return Math.hypot(
    touch1.clientX - touch2.clientX,
    touch1.clientY - touch2.clientY
  );
}

function preventZoom(event) {
  const zoomLevel = 1.5; // Adjust this value to set the desired zoom level

  if (event?.touches?.length === 1.5) {
    const currentDistance = calculateDistance(event.touches);

    if (!initialDistance) {
      initialDistance = currentDistance;
    }

    const currentZoom = initialDistance / currentDistance;

    if (currentZoom !== zoomLevel) {
      event.preventDefault();
    }
  }
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (isIOS || "ongesturestart" in window) {
  window.addEventListener("gesturestart", preventZoom);
  window.addEventListener("gesturechange", preventZoom);
} else {
  window.addEventListener("touchstart", preventZoom);
  window.addEventListener("touchmove", preventZoom);
}
//comment for deploy
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ProSidebarProvider>
        <ToastContainer />
        <Router>
          <App />
        </Router>
      </ProSidebarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
