import React, { memo, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ActivityCard from "./ActivityCard";
import TRANSLATIONS from "../translations/en.json";
import { ReactComponent as Bulb } from "../assets/Dashboard/Bulb.svg";
import {
  getPercentageText,
  getTotalDailyReflections,
  getPercentageByDataComparision,
} from "../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  DailyReflectionsCardRequest,
  PastDailyReflectionsCardRequest,
} from "../redux/actions";

function DailyReflectionsCard({ isEmptyDashboard, periodDates }) {
  const dispatch = useDispatch();
  const [currentDailyReflections, setCurrentDailyReflections] = useState([]);
  const [pastDailyReflections, setPastDailyReflections] = useState([]);
  const [dailyReflectionsPercentage, setDailyReflectionsPercentage] =
    useState(0);

  const fecthActivitiesData = () => {
    dispatch(
      DailyReflectionsCardRequest({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );
    dispatch(
      PastDailyReflectionsCardRequest({
        start_date: periodDates.previousStartDate,
        end_date: periodDates.previousEndDate,
      })
    );
  };
  useEffect(() => {
    fecthActivitiesData(periodDates);
  }, [periodDates]);

  const { dailyReflectionsData, pastDailyReflectionsData } = useSelector(
    (state) => state.DashboardActivities
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (dailyReflectionsData) {
      setCurrentDailyReflections(dailyReflectionsData);
    }
    if (pastDailyReflectionsData) {
      setPastDailyReflections(pastDailyReflectionsData);
    }
  }, [dailyReflectionsData, pastDailyReflectionsData]);

  const currentTotalDailyReflections = getTotalDailyReflections(
    currentDailyReflections
  );
  const pastTotalDailyReflections =
    getTotalDailyReflections(pastDailyReflections);

  useEffect(() => {
    if (currentTotalDailyReflections >= 0 && pastTotalDailyReflections >= 0) {
      setDailyReflectionsPercentage(
        getPercentageByDataComparision(
          currentTotalDailyReflections,
          pastTotalDailyReflections
        )
      );
    }
  }, [currentTotalDailyReflections, pastTotalDailyReflections]);

  const { percentageText, icon } = getPercentageText(
    { currentTotalDailyReflections },
    {
      currentTotalDailyReflections: pastTotalDailyReflections,
    },
    "currentTotalDailyReflections"
  );
  return (
    <ActivityCard
      header={TRANSLATIONS.USER_DASHBOARD.DAILY_REFLECTIONS}
      Icon={Bulb}
      isEmptyDashboard={isEmptyDashboard}
      value={currentTotalDailyReflections}
      iconClassName={isEmptyDashboard && "activity-icon-empty"}
      description={`${icon}${
        dailyReflectionsPercentage > 0 ? dailyReflectionsPercentage : ""
      }${percentageText}`}
    />
  );
}
DailyReflectionsCard.propTypes = {
  periodDates: PropTypes.object,
  isEmptyDashboard: PropTypes.bool,
};
export default memo(DailyReflectionsCard);
