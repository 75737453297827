import PropTypes from "prop-types";
import React, { memo } from "react";

import DefaultImage from "../../../assets/AdminProfile/DefaultProfile.svg";
import { ReactComponent as FavStarIcon } from "../../../assets/StarIcon.svg";
import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import TRANSLATIONS from "../../../translations/en.json";
import { getColorBasedOnType } from "../../../utils";
import "../index.css";
import "./ReviewCard.css";

function ReviewCard({
  cardDescription,
  setActiveStep,
  handleCreateCard,
  selectedCategoryOption,
  selectedTimeOption,
  userDetails,
}) {
  return (
    <div>
      <div
        className="h-100 review-card-container"
        style={{
          backgroundColor: getColorBasedOnType(selectedCategoryOption?.title),
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 mb-3">
          <p className="card-time">
            {selectedTimeOption?.max_minutes === selectedTimeOption?.min_minutes
              ? `0-${selectedTimeOption?.max_minutes} mins`
              : `${selectedTimeOption?.min_minutes}-${selectedTimeOption?.max_minutes} mins`}
          </p>
          <p>
            <FavStarIcon width={24} height={24} />
          </p>
        </div>
        <p className="card-desc">{cardDescription}</p>
        <div style={{ paddingBottom: "100px" }} className="mb-4" />
        <div className="card-assigned-details">
          <div className="card-assigned-user">
            <p className="m-0 task-created">
              {TRANSLATIONS.CUSTOM_CARD_CREATION.TASK_ASSIGNED_TO}
            </p>
            <img
              src={
                userDetails?.profileImageURL
                  ? userDetails?.profileImageURL
                  : DefaultImage
              }
              alt="user_image"
              className="card-assigned-user-profile-img"
              title={userDetails?.name}
              width={48}
              height={48}
            />
          </div>
        </div>
      </div>
      <p className="mb-text-note">
        {TRANSLATIONS.CUSTOM_CARD_CREATION.CUSTOM_CARD_TEXT}
      </p>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 0px" }}
      >
        <div
          className="stepper-back-btn cursor-pointer"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          <BackArrow />
        </div>{" "}
        <button className="stepper-continue-btn" onClick={handleCreateCard}>
          {TRANSLATIONS.CUSTOM_CARD_CREATION.CUSTOM_CARD_BTN} <BackArrow />
        </button>
      </div>
    </div>
  );
}

ReviewCard.propTypes = {
  cardDescription: PropTypes.string,
  setActiveStep: PropTypes.func.isRequired,
  handleCreateCard: PropTypes.func.isRequired,
  selectedCategoryOption: PropTypes.object,
  selectedTimeOption: PropTypes.object,
  userDetails: PropTypes.object,
};

export default memo(ReviewCard);
