import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  LongestStreakCardRequest,
  PastLongestStreakCardRequest,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getPercentageText, getPercentageByDataComparision } from "../utils";
import ActivityCard from "./ActivityCard";
import TRANSLATIONS from "../translations/en.json";
import { ReactComponent as Streak } from "../assets/Dashboard/Streak.svg";
import { PRO } from "../common/constants";

function LongestStreakCard({ isEmptyDashboard, periodDates }) {
  const dispatch = useDispatch();
  const [longestStreakPercentage, setLongestStreakPercentage] = useState(0);

  const fecthActivitiesData = (periodDates) => {
    dispatch(
      LongestStreakCardRequest({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );
    dispatch(
      PastLongestStreakCardRequest({
        start_date: periodDates.previousStartDate,
        end_date: periodDates.previousEndDate,
      })
    );
  };

  useEffect(() => {
    fecthActivitiesData(periodDates);
  }, [periodDates]);

  const { longestStreakData, pastLongestStreakData } = useSelector(
    (state) => state.DashboardActivities
  );

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (longestStreakData && pastLongestStreakData) {
      if (
        longestStreakData?.streak !== undefined &&
        pastLongestStreakData?.streak !== undefined
      ) {
        let getPercentage = getPercentageByDataComparision(
          longestStreakData.streak,
          pastLongestStreakData.streak
        );
        setLongestStreakPercentage(getPercentage);
      }
    }
  }, [longestStreakData, pastLongestStreakData]);
  const { percentageText, icon } = getPercentageText(
    longestStreakData,
    pastLongestStreakData,
    "streak"
  );
  return (
    <ActivityCard
      header={
        localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.USER_DASHBOARD.GROUP_LONGEST_STREAK
          : TRANSLATIONS.USER_DASHBOARD.LONGEST_STREAK
      }
      Icon={Streak}
      value={!isEmptyDashboard && longestStreakData?.streak}
      isEmptyDashboard={isEmptyDashboard}
      iconClassName={isEmptyDashboard && "activity-icon-empty"}
      description={`${icon}${
        longestStreakPercentage > 0 ? longestStreakPercentage : ""
      }${percentageText}`}
    />
  );
}
LongestStreakCard.propTypes = {
  isEmptyDashboard: PropTypes.bool,
  periodDates: PropTypes.object,
};
export default LongestStreakCard;
