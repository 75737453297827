import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  capitalize,
  getOrderedItems,
  logAnalyticsEvent,
  manageBodyClass,
  orderingByAdmin,
  showError,
  showSuccess,
} from "../utils";
import {
  ANALYTICS_EVENTS_JSON,
  PRO,
  TEAM_MEMBER_STATUS,
} from "../common/constants";
import { Confirmation } from "../common/components";
import InviteCodeModel from "./InviteCodeModel";
import MembersScreen from "./MembersScreen";
import {
  deleteMemberAction,
  inviteTeamRequest,
  teamSettingsRequest,
} from "../redux/actions";
import TRANSLATIONS from "../translations/en.json";
import WelcomeScreen from "./WelcomeScreen";
import WelcomeTitle from "./WelcomeTitle";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function TeamMembers({ userDetails }) {
  const query = useQuery();
  const dispatch = useDispatch();
  const [isSeeAll, setIsSeeAll] = useState(true);
  const [filterText, setFilterText] = useState(TRANSLATIONS.WELCOME_SCREEN.ALL);
  const [allTeamMembers, setAllTeamMembers] = useState([]);
  const [teamAccessRequests, setTeamAccessRequests] = useState({});
  const [selectedMember, setSelectedMember] = useState("");
  const [resend, setResend] = useState(false);
  const [isOpenConfirmation, setIsOpenConfirmation] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  /*invite team modal toggle */
  const [modal, setModal] = useState(false);
  const inviteModaltoggle = () => setModal(!modal);

  const refreshTeamSettings = () => {
    dispatch(teamSettingsRequest({ isMember: false }));
  };

  useEffect(() => {
    // Store subscription status in local storage if present in query parameters
    const subscription = query.get('subscription');
    if (subscription) {
      localStorage.setItem("subscription", subscription);
      localStorage.setItem("login", subscription);
    }
    refreshTeamSettings();
    return () => {
      manageBodyClass("team-members-screen", "remove");
      manageBodyClass("coming-soon-screens", "remove");
    };
  }, []);

  const { teamSettigsRequestData, teamSettigsData } = useSelector(
    (state) => state.teamSettings
  );

  const getOrderedTeamMembers = (teamMembers) => {
    //members invited by phone
    const pendingNumbersData = teamMembers.filter(
      (item) =>
        item.status === TEAM_MEMBER_STATUS.PENDING && item.phone && !item.email
    );
    //members invited by email
    const pendingEmailsData = teamMembers.filter(
      (item) =>
        (item.status === TEAM_MEMBER_STATUS.PENDING &&
          item.email &&
          !item.phone) ||
        (item.status === TEAM_MEMBER_STATUS.PENDING && item.email && item.phone)
    );
    //acceptedmembers
    const acceptedMembers = teamMembers.filter(
      (item) => item.status === TEAM_MEMBER_STATUS.ACCEPTED
    );
    const orderedTeamMembers = getOrderedItems(
      pendingEmailsData,
      "email",
      "asc"
    )
      .concat(pendingNumbersData)
      .concat(acceptedMembers);

    //unshifting admin on top of array
    orderingByAdmin(orderedTeamMembers);
    return orderedTeamMembers;
  };
  //to get the data of the team members
  useEffect(() => {
    if (!teamSettigsRequestData && teamSettigsData?.length) {
      setTeamAccessRequests(
        teamSettigsData.filter((e) => e.status === TEAM_MEMBER_STATUS.REQUESTED)
      );
      let validTeamMembersData = teamSettigsData.filter((item) =>
        [TEAM_MEMBER_STATUS.PENDING, TEAM_MEMBER_STATUS.ACCEPTED].includes(
          item.status
        )
      );
      setDataLoaded(true);
      if (validTeamMembersData?.length > 1) {
        setAllTeamMembers(getOrderedTeamMembers(validTeamMembersData));
        if (validTeamMembersData?.length < 3) {
          manageBodyClass("coming-soon-screens", "add");
        } else {
          manageBodyClass("team-members-screen", "add");
        }
      } else {
        manageBodyClass("team-members-screen", "add");
        setAllTeamMembers([]);
      }
    }
  }, [teamSettigsRequestData, teamSettigsData]);

  const handleActions = (isPendingMembers) => {
    let filteredData = [];
    if (teamSettigsData?.length) {
      if (isPendingMembers) {
        setFilterText(TRANSLATIONS.TEAM_SETINGS.PENDING);
        filteredData = teamSettigsData.filter(
          (e) => e.isAdmin || e.status === TEAM_MEMBER_STATUS.PENDING
        );
      } else {
        setFilterText(TRANSLATIONS.WELCOME_SCREEN.ALL);
        filteredData = teamSettigsData.filter((item) =>
          [TEAM_MEMBER_STATUS.PENDING, TEAM_MEMBER_STATUS.ACCEPTED].includes(
            item.status
          )
        );
      }
      if (filteredData?.length < 3) {
        manageBodyClass("team-members-screen", "remove");
        manageBodyClass("coming-soon-screens", "add");
      } else {
        manageBodyClass("coming-soon-screens", "remove");
        manageBodyClass("team-members-screen", "add");
      }
      if (filteredData?.length) {
        setAllTeamMembers(getOrderedTeamMembers(filteredData));
      } else {
        setAllTeamMembers([]);
      }
    }
  };

  const handleResendCode = (member) => {
    setResend(true);
    let invitesArray = [];
    if (member.email) {
      invitesArray.push(...invitesArray, member.email);
      dispatch(inviteTeamRequest({ invitees: invitesArray }));
    } else if (member.phone) {
      invitesArray.push(...invitesArray, member.phone);
      dispatch(inviteTeamRequest({ invitees: invitesArray }));
    }
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.RESEND_CODE_BTN);
  };
  const handleDelete = (member) => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DELETE_MEMBER_BTN);
    setSelectedMember(member);
    setIsOpenConfirmation(true);
  };

  const handleConfirmToggle = () => {
    setIsOpenConfirmation(!isOpenConfirmation);
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DELETE_CANCEL_BTN);
  };
  const onConfirm = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.DELETE_CONFIRM_BTN);

    if (selectedMember.email && selectedMember.phone) {
      dispatch(deleteMemberAction({ email: selectedMember.email }));
    } else if (selectedMember.email) {
      dispatch(deleteMemberAction({ email: selectedMember.email }));
    } else if (selectedMember.phone) {
      dispatch(deleteMemberAction({ phoneNumber: selectedMember.phone }));
    }
    handleConfirmToggle();
  };

  const {
    inviteTeamMembersData,
    inviteTeamMembersError,
    inviteTeamMembersRequestData,
  } = useSelector((state) => state.InviteTeamMembers);

  let { deleteMemberRequestData, deleteMemberData, deleteMemberError } =
    useSelector((state) => state.teamSettings);

  useEffect(() => {
    if (inviteTeamMembersData && resend) {
      setResend(false);
      const successMsg =
        localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.POPUP.SENT_COACH_SUCCESSFULLY
          : TRANSLATIONS.POPUP.SENT_SUCCESSFULLY;
      showSuccess(successMsg);
    } else if (inviteTeamMembersError && resend) {
      setResend(false);
      if (inviteTeamMembersError?.length) {
        showError(inviteTeamMembersError[0]);
      } else {
        showError(TRANSLATIONS.POPUP.FAILED_TO_SENT);
      }
    }
  }, [inviteTeamMembersRequestData, inviteTeamMembersData]);

  const FirstRun = useRef(true);
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (deleteMemberData) {
      showSuccess(TRANSLATIONS.COMMON.DELETE_MEMBER_SUCESS);
      dispatch(teamSettingsRequest({ isMember: false }));
    } else if (deleteMemberError) {
      showError(TRANSLATIONS.COMMON.DELETE_MEMBER_FAILURE);
    }
  }, [deleteMemberData, deleteMemberError]);

  return (
    <div>
      {(teamSettigsRequestData ||
        deleteMemberRequestData ||
        inviteTeamMembersRequestData) && (
        <Spinner className="loader-container dashboard-loader overlay-admin-screen" />
      )}
      {isOpenConfirmation && (
        <Confirmation
          isOpen={isOpenConfirmation}
          toggle={handleConfirmToggle}
          handleSubmit={() => onConfirm()}
        />
      )}

      {modal && (
        <InviteCodeModel
          modal={modal}
          setModal={setModal}
          toggle={inviteModaltoggle}
        />
      )}
      <WelcomeTitle
        allTeamMembers={allTeamMembers}
        handleActions={handleActions}
        isHideActions={allTeamMembers?.length <= 1}
        refreshTeamSettings={refreshTeamSettings}
        setIsSeeAll={setIsSeeAll}
        isSeeAll={isSeeAll}
        teamName={userDetails?.teamName ? capitalize(userDetails.teamName) : ""}
        userDetails={userDetails}
      />
      {dataLoaded
        ? !teamSettigsRequestData &&
          (allTeamMembers?.length <= 1 && !teamAccessRequests?.length ? (
            <WelcomeScreen
              refreshTeamSettings={refreshTeamSettings}
              userDetails={userDetails}
              inviteModaltoggle={inviteModaltoggle}
            />
          ) : (
            <MembersScreen
              inviteModaltoggle={inviteModaltoggle}
              refreshTeamSettings={refreshTeamSettings}
              userDetails={userDetails}
              allTeamMembers={allTeamMembers}
              teamAccessRequests={teamAccessRequests}
              buttonText={filterText}
              handleResendCode={handleResendCode}
              handleDelete={handleDelete}
              isSeeAll={isSeeAll}
            />
          ))
        : ""}
    </div>
  );
}
TeamMembers.propTypes = {
  userDetails: PropTypes.object,
};
export default TeamMembers;
