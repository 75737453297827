import {
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from "./actionTypes";

export const updateProfileRequest = (user) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: user,
  };
};

export const updateProfileSuccess = (user, history) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: { user, history },
  };
};

export const updateProfileError = (history) => {
  return {
    type: UPDATE_PROFILE_ERROR,
    payload: { history },
  };
};
