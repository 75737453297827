import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { signUpError, signUpSuccess } from "./actions";

import { SIGN_UP_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* signUp(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/domain_verification`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data?.valid) {
      const responseData = {
        data: { ...response.data, email: action.payload.email },
      };
      yield put(signUpSuccess(responseData));
    } else {
      const responseData = {
        error: response.data,
      };
      yield put(signUpError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(signUpError(errorData));
  }
}

export function* watchSignUp() {
  yield takeEvery(SIGN_UP_REQUEST, signUp);
}

function* SignUpSaga() {
  yield all([fork(watchSignUp)]);
}
export default SignUpSaga;
