import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import {
  createNotesError,
  createNotesSuccess,
  getNotesError,
  getNotesSuccess,
  updateNotesError,
  updateNotesSuccess,
} from "./actions";

import { checkHttpStatus } from "../../api/apiUtils";
import {
  CREATE_NOTES_REQUEST,
  GET_NOTE_REQUEST,
  UPDATE_NOTES_REQUEST,
} from "./actionTypes";

function* getUserNotes(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/get_note`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data,
      };
      yield put(getNotesSuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(getNotesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(getNotesError(errorData));
  }
}

function* createUserNotes(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/create_note`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data,
      };
      yield put(createNotesSuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(createNotesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(createNotesError(errorData));
  }
}

function* updateUserNotes(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/update_note`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data,
      };
      yield put(updateNotesSuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(updateNotesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(updateNotesError(errorData));
  }
}

export function* watchUserNotes() {
  yield takeEvery(GET_NOTE_REQUEST, getUserNotes);
  yield takeEvery(CREATE_NOTES_REQUEST, createUserNotes);
  yield takeEvery(UPDATE_NOTES_REQUEST, updateUserNotes);
}

function* UserNotesSaga() {
  yield all([fork(watchUserNotes)]);
}
export default UserNotesSaga;
