import {
  ADMIN_PROFILE_ACTIVITY_ERROR,
  ADMIN_PROFILE_ACTIVITY_REQUEST,
  ADMIN_PROFILE_ACTIVITY_SUCCESS,
} from "./actionTypes";

export const adminProfileActivity = (payload) => {
  return {
    type: ADMIN_PROFILE_ACTIVITY_REQUEST,
    payload,
  };
};

export const adminProfileActivitySuccess = (data) => {
  return {
    type: ADMIN_PROFILE_ACTIVITY_SUCCESS,
    data,
  };
};

export const adminProfileActivityError = (error) => {
  return {
    type: ADMIN_PROFILE_ACTIVITY_ERROR,
    error,
  };
};
