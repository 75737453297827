import React, { memo } from "react";
import { Col, Row } from "reactstrap";

import { SCIENCE_CHEMICALS } from "../common/constants/science";
import "./Chemicals.css";

function Chemicals() {
  return (
    <div className="sc-chemicals-container">
      {SCIENCE_CHEMICALS.map((item) => {
        return (
          <Row key={item.IMAGE} className="mb-5">
            <Col
              lg={3}
              md={3}
              sm={12}
              xs={12}
              className=" chemical-logo-col mb-4"
            >
              <img
                src={
                  window.document.documentElement.clientWidth <= 576
                    ? item.MOBILE_IMAGE
                    : item.IMAGE
                }
                alt="chemical-logo"
                className="sc-chemical-logo"
              />
            </Col>
            <Col
              className={`chemical-description ${item.CLASSNAME}`}
              lg={9}
              md={9}
              sm={12}
              xs={11}
            >
              <p className="sub-text mb-3">{item.DESC_1}</p>
              <p className="sub-text mb-0">{item.DESC_2}</p>
            </Col>
          </Row>
        );
      })}
    </div>
  );
}
export default memo(Chemicals);
