import _ from "lodash";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Input, Row, Spinner } from "reactstrap";
import { polyfill } from "seamless-scroll-polyfill";

import StepperComponent from "../../common/components/StepperComponent";
import {
  INCLUSIVITY,
  JOURNAL,
  PURPOSE,
  ROUTE_PATH,
  TIME_FILTER,
} from "../../common/constants";
import {
  assignTaskAction,
  createCustomCardAction,
  getAllInclusivityAction,
  getAllJournalOptionsAction,
  getAllPurposeOptionsAction,
  getAllTimeOptionsAction,
} from "../../redux/actions";
import TRANSLATIONS from "../../translations/en.json";
import {
  handleDateTimeStamp,
  requestDateFormat,
  showError,
  showSuccess,
} from "../../utils";
import Activty from "./Activity";
import AssignedDetails from "./AssignedDetails";
import CustomCardCategories from "./CustomCardCategories";
import DateTimeComponent from "./DateTimeComponent";
import CardActivity from "./mobileScreens/CardActivity";
import Tags from "./mobileScreens/Tags";
import PurposeComponent from "./PurposeComponent";

import "./index.css";
import DateTime from "./mobileScreens/DateTime";
import Inclusivity from "./mobileScreens/Inclusivity";
import ReviewCard from "./mobileScreens/ReviewCard";
import Time from "./mobileScreens/Time";
const WelcomeTitle = lazy(() => import("../WelcomeTitle"));

function CustomCardCreation() {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationData = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [timeOptions, setTimeOptions] = useState([]);
  const [inclusivityOptions, setInclusivityOptions] = useState([]);
  const [journalOptions, setJournalOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [selectedTimeOption, setSelectedTimeOpton] = useState({});
  const [selectedInclusivityOption, setSelectedInclusivityOption] = useState(
    []
  );
  const [selectedJournalOption, setSelectedJournalOption] = useState([]);
  const [selectedCategoryOption, setSelectedCategoryOption] = useState({});
  const [cardDescription, setCardDescription] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [activeStep, setActiveStep] = useState(1);

  polyfill(); // Using this so that scrollTo will work in IOS safari, Since window.ScrollTo is not working
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    if (!_.isEmpty(locationData?.state)) {
      setUserDetails(locationData?.state);

      // Getting all inclusvity options
      dispatch(getAllInclusivityAction({}));
      // Getting all Time Options
      dispatch(getAllTimeOptionsAction({}));
      // Getting all Journal options
      dispatch(getAllJournalOptionsAction({}));
      // Getting all purpose options
      dispatch(getAllPurposeOptionsAction({}));
    }
  }, [locationData]);

  const {
    getAllInclusivityData,
    getAllTimeOptionsData,
    getAllJournalOptionsData,
    getAllPurposeOptionsData,
    createCustomCardRequestData,
    createCustomCardData,
    createCustomCardError,
    assignTaskData,
    assignTaskRequestData,
    assignTaskError,
  } = useSelector((state) => state.Tasks);

  useEffect(() => {
    if (getAllInclusivityData?.length) {
      const updatedData = getAllInclusivityData.map((item) => ({
        ...item,
        type: "inclusivity",
      }));
      setInclusivityOptions(updatedData || []);
    }
  }, [getAllInclusivityData]);

  useEffect(() => {
    if (getAllTimeOptionsData?.length) {
      const updatedData = getAllTimeOptionsData.map((item) => ({
        ...item,
        type: "time",
      }));
      setTimeOptions(updatedData || []);
    }
  }, [getAllTimeOptionsData]);

  useEffect(() => {
    if (getAllJournalOptionsData?.length) {
      const updatedData = getAllJournalOptionsData.map((item) => ({
        ...item,
        type: "journal",
      }));
      setJournalOptions(updatedData || []);
    }
  }, [getAllJournalOptionsData]);

  useEffect(() => {
    if (getAllPurposeOptionsData?.length) {
      const updatedData = getAllPurposeOptionsData.map((item) => ({
        ...item,
        type: "purpose",
      }));
      setPurposeOptions(updatedData || []);
    }
  }, [getAllPurposeOptionsData]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (!createCustomCardRequestData && createCustomCardData) {
      if (createCustomCardData?.error?.length) {
        showError(TRANSLATIONS.COMMON.TASK_IS_CREATED);
      } else {
        // If user Doesn't change date then set date to 2 weeks from current date
        const updatedStartDate =
          startDate.toISOString().split("T")[0] ===
          new Date().toISOString().split("T")[0]
            ? new Date(startDate.getTime() + 14 * 24 * 60 * 60 * 1000) // Add 2 weeks
            : startDate;
        const payload = {
          activityUUID: createCustomCardData?.uuid,
          memberUUID: userDetails?.userUUID,
          commitDate: requestDateFormat(updatedStartDate),
          commitTimestamp: handleDateTimeStamp(updatedStartDate),
        };
        dispatch(assignTaskAction(payload));
      }
    } else if (!createCustomCardRequestData && createCustomCardError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [createCustomCardRequestData, createCustomCardData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (!assignTaskRequestData && assignTaskData) {
      showSuccess(TRANSLATIONS.ASSIGN_TASK.TASK_ASSIGNED_SUCCESSFULLY);
      history.push({
        pathname: ROUTE_PATH.TASK_ASSIGMENT,
        state: JSON.parse(localStorage.getItem("teamUserDetails")),
      });
    } else if (!assignTaskRequestData && assignTaskError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [assignTaskRequestData, assignTaskData]);

  const handleSelectOption = (value, type) => {
    switch (type) {
      case INCLUSIVITY:
        setSelectedInclusivityOption((prev) =>
          prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value]
        );
        break;
      case TIME_FILTER:
        setSelectedTimeOpton((prev) => (prev === value ? {} : value));
        break;
      case JOURNAL:
        setSelectedJournalOption((prev) =>
          prev.includes(value)
            ? prev.filter((item) => item !== value)
            : [...prev, value]
        );
        break;
      case PURPOSE:
        setSelectedCategoryOption((prev) => (prev === value ? {} : value));
        break;
      default:
        return null;
    }
  };

  const handleCreateCard = () => {
    const payload = {
      activityTimeUUID: !_.isEmpty(selectedTimeOption)
        ? selectedTimeOption?.uuid
        : "",
      label: cardDescription?.length ? cardDescription : "",
      chemicalType: !_.isEmpty(selectedCategoryOption)
        ? selectedCategoryOption?.title
        : "",
      tagUUIDs: selectedJournalOption?.length
        ? selectedJournalOption?.map((journal) => journal.uuid)
        : [],
      inclusivityUUID: selectedInclusivityOption?.length
        ? selectedInclusivityOption?.map((journal) => journal.uuid)
        : [],
      privacy : "PRIVATE"
    };

    if (
      payload?.label !== "" &&
      payload?.activityTimeUUID !== "" &&
      payload?.chemicalType !== ""
    ) {
      dispatch(createCustomCardAction(payload));
    }
  };

  const renderStepperComponent = () => {
    switch (activeStep) {
      case 1:
        return (
          <CardActivity
            activeStep={activeStep}
            cardDescription={cardDescription}
            setActiveStep={setActiveStep}
            setCardDescription={setCardDescription}
          />
        );
      case 2:
        return (
          <Tags
            handleSelectOption={handleSelectOption}
            journalOptions={journalOptions}
            options={purposeOptions}
            setActiveStep={setActiveStep}
            selectedCategoryOption={selectedCategoryOption}
            selectedJournalOption={selectedJournalOption}
          />
        );
      case 3:
        return (
          <Inclusivity
            setActiveStep={setActiveStep}
            handleSelectOption={handleSelectOption}
            options={inclusivityOptions}
            selectedInclusivityOption={selectedInclusivityOption}
          />
        );
      case 4:
        return (
          <Time
            setActiveStep={setActiveStep}
            handleSelectOption={handleSelectOption}
            options={timeOptions}
            selectedTimeOption={selectedTimeOption}
          />
        );
      case 5:
        return (
          <DateTime
            startDate={startDate}
            setStartDate={setStartDate}
            setActiveStep={setActiveStep}
            userDetails={userDetails}
            selectedCategoryOption={selectedCategoryOption}
          />
        );
      case 6:
        return (
          <ReviewCard
            cardDescription={cardDescription}
            handleCreateCard={handleCreateCard}
            selectedCategoryOption={selectedCategoryOption}
            selectedTimeOption={selectedTimeOption}
            setActiveStep={setActiveStep}
            userDetails={userDetails}
          />
        );
    }
  };

  const renderStepperTitle = () => {
    switch (activeStep) {
      case 1:
        return TRANSLATIONS.CUSTOM_CARD_CREATION.MB_HEADER;
      case 2:
        return TRANSLATIONS.CUSTOM_CARD_CREATION.BEST_FIT_TAGS;
      case 3:
        return TRANSLATIONS.CUSTOM_CARD_CREATION.INCLUSIVITY_HEADER;
      case 4:
        return TRANSLATIONS.CUSTOM_CARD_CREATION.TIME_IS_ESS;
      case 5:
        return TRANSLATIONS.CUSTOM_CARD_CREATION.DATE_AND_TIME;
      case 6:
        return TRANSLATIONS.CUSTOM_CARD_CREATION.REVIEW_CARD_TEXT;
    }
  };

  return (
    <Suspense fallback="">
      {(createCustomCardRequestData || assignTaskRequestData) && (
        <Spinner className="loader-container dashboard-loader overlay-admin-screen" />
      )}
      <div className="d-block d-md-none">
        <WelcomeTitle
          isHideActions={true}
          style={true}
          title={TRANSLATIONS.CUSTOM_CARD_CREATION.MOBILE_CUSTOM_CARD_BACK}
          isBack={true}
        />
        <div>
          <StepperComponent
            activeStep={activeStep}
            title={renderStepperTitle()}
            setActiveStep={setActiveStep}
          />
          <div className="stepper-component-render">
            {renderStepperComponent()}
          </div>
        </div>
      </div>
      <div className="d-none d-md-block">
        <WelcomeTitle
          isHideActions={true}
          title={TRANSLATIONS.CUSTOM_CARD_CREATION.BACK_TO_ASSIGN}
        />
        <div className="custom-card-container mt-4 m-3">
          <h2 className="custom-card-header">
            {TRANSLATIONS.CUSTOM_CARD_CREATION.ENTER_CUSTOM_CARD_TEXT}
          </h2>
          <Row className="custom-card-padding">
            <Col lg={7} className="p-0 pb-3">
              <AssignedDetails userDetails={userDetails} />
            </Col>
            <Col lg={5} className="p-0 pb-3">
              <DateTimeComponent
                userDetails={userDetails}
                startDate={startDate}
                setStartDate={setStartDate}
              />
            </Col>
            <Col lg={8} className="p-0 py-3">
              <Input
                type="textarea"
                rows={"10"}
                className="w-100 custom-card-task-input"
                placeholder={
                  TRANSLATIONS.CUSTOM_CARD_CREATION.CARD_TEXT_PLACEHOLDER
                }
                value={cardDescription}
                onChange={(e) => setCardDescription(e.target.value)}
              />
            </Col>
            <Col lg={4} className="py-3">
              <Activty
                inclusivityOptions={inclusivityOptions}
                handleSelectOption={handleSelectOption}
                selectedInclusivityOption={selectedInclusivityOption}
                selectedTimeOption={selectedTimeOption}
                timeOptions={timeOptions}
              />
            </Col>
            <Col className="p-0 mb-3" lg={12}>
              <CustomCardCategories
                handleSelectOption={handleSelectOption}
                journalOptions={journalOptions}
                selectedJournalOption={selectedJournalOption}
              />
            </Col>
            <Col className="p-0 mb-5" lg={12}>
              <PurposeComponent
                handleSelectOption={handleSelectOption}
                options={purposeOptions}
                selectedCategoryOption={selectedCategoryOption}
              />
            </Col>
            <Col lg={12} className="d-flex justify-content-center">
              <Button
                className="custom-card-create-btn"
                onClick={handleCreateCard}
                disabled={
                  !(
                    cardDescription !== "" &&
                    !_.isEmpty(selectedTimeOption) &&
                    !_.isEmpty(selectedCategoryOption)
                  )
                }
              >
                {TRANSLATIONS.CUSTOM_CARD_CREATION.SAVE_CUSTOM_CARD}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Suspense>
  );
}

export default CustomCardCreation;
