import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { getStartedRequest } from "../redux/actions";
import {
  ANALYTICS_EVENTS_JSON,
  PRO,
  ROUTE_PATH,
  SUBSCRIPTION_PLAN,
} from "../common/constants";
import { inputField, validateEmail } from "../common/reduxFields";
import Righticon from "../assets/RightArrow.svg";
import { logAnalyticsEvent } from "../utils";
import TRANSLATIONS from "../translations/en.json";
import { AnimatedButton, ButtonLoader } from "../common/components";

const GetStarted = (props) => {
  const { handleSubmit, reset } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");

  const selectedPlan = useState(() => {
    // Get the value from localStorage or default to "FREE"
    return localStorage.getItem("login") || SUBSCRIPTION_PLAN.PRO;
  })[0];

  const onSubmit = (formProps) => {
    const formData = {
      email: formProps.Email,
      subscription: selectedPlan,
    };
    localStorage.setItem("subscription", selectedPlan);
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.GET_STARTED);
    dispatch(getStartedRequest(formData));
  };

  const { isGetStartedRequesting, getStartedData, getStartedError } =
    useSelector((state) => state.GetStarted);

  const FirstRun = useRef(true);
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (isGetStartedRequesting) {
      setIsLoading(true);
    } else if (getStartedData?.email) {
      setIsLoading(false);
      reset();
      history.push(ROUTE_PATH.VERIFY_EMAIL, { email: getStartedData.email });
    } else if (getStartedError) {
      setIsLoading(false);
      if (getStartedError.data) {
        setErrorResponse(getStartedError.data);
      }
    }
  }, [isGetStartedRequesting, getStartedData, getStartedError]);

  {
    /**NOT REQUIRED NOW */
  }
  // const handlePlanChange = (event) => {
  //   setSelectedPlan(event.target.value);
  // };

  return (
    <div className="bg-login-dashBoard">
      <div className="auth-grey-section p-2">
        <h1 className="auth-header-text">
          {selectedPlan === PRO
            ? TRANSLATIONS.LOGIN.PRO_LOGIN_HEADER
            : TRANSLATIONS.LOGIN.LOGIN_HEADER}
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {/**commented for now */}
            {/* <Col sm={12}>
              <div className="switch-main-container mx-auto">
                <div className="switches-container">
                  <input
                    type="radio"
                    id="switchPro"
                    name="switchPlan"
                    value="PRO"
                    checked={selectedPlan === "PRO"}
                    onChange={handlePlanChange}
                  />
                  <input
                    type="radio"
                    id="switchFree"
                    name="switchPlan"
                    value="FREE"
                    checked={selectedPlan === "FREE"}
                    onChange={handlePlanChange}
                  />
                  <label htmlFor="switchPro">Pro</label>
                  <label htmlFor="switchFree">Enterprise</label>
                  <div className="switch-wrapper">
                    <div className="switch">
                      <div>Pro</div>
                      <div>Enterprise</div>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
            <Col md={8} lg={5} sm={10} xs={12} className="mx-auto">
              <div className="input-block">
                <h5 className="auth-input-label">
                  {TRANSLATIONS.COMMON.EMAIL}
                </h5>
                <div className="form-group auth-input-email">
                  <Field
                    name="Email"
                    label={TRANSLATIONS.COMMON.EMAIL}
                    component={inputField}
                    placeholder={TRANSLATIONS.COMMON.EMAIL_REQUIRED}
                    className="auth-input-email"
                    required
                    onChange={() => setErrorResponse("")}
                    isShowError={errorResponse}
                    isError={true}
                    validate={[validateEmail]}
                  />
                  {
                    <span className="form-error text-left">
                      {errorResponse}
                    </span>
                  }
                </div>
              </div>
              <div className="justify-align-center finish-setup-btn">
                <AnimatedButton
                  type="submit"
                  color="secondary"
                  className="auth-btn"
                >
                  {TRANSLATIONS.GET_STARTED.BUTTON}

                  {isLoading ? (
                    <ButtonLoader />
                  ) : (
                    <img src={Righticon} alt="icon" />
                  )}
                </AnimatedButton>
              </div>
            </Col>
          </Row>
        </form>
        <p className="auth-footer-text mt-4 mb-4 auth-page-bottom ">
          {TRANSLATIONS.GET_STARTED.HAVING_ISSUES}
          <span
            className=" ml-1 auth-footer-link"
            onClick={() => history.push(ROUTE_PATH.CONTACT)}
          >
            {TRANSLATIONS.GET_STARTED.CONTACT_SUPPORT}
          </span>
        </p>
      </div>
    </div>
  );
};

GetStarted.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
};
export default connect()(
  reduxForm({
    form: "GetStarted",
  })(GetStarted)
);
