//components
import { DashBoardSetUp, GetStarted, VerifyOTP } from "./authentication";
import ContactUs from "./consumerWebsitePages/Contact";
import EnterpriseHome from "./enterpriseHome";
import ProHome from "./proHome";
import Faqs from "./consumerWebsitePages/faqs/Faqs";
import Home from "./home";
import HowItWorks from "./howItWorks";
import Privacy from "./consumerWebsitePages/Privacy";
import Results from "./consumerWebsitePages/results/Results";
import Science from "./science";
import WhatIsDose from "./consumerWebsitePages/whatIsDose/Landing";

//utils
import { PAGE_TITLES, PRO } from "./common/constants";
import TRANSLATIONS from "./translations/en.json";
import { ReactComponent as TeamMembersIcon } from "./assets/AdminSideIcons/TeamMembers.svg";
import { ReactComponent as DashboardIcon } from "./assets/AdminSideIcons/OverallIcon.svg";
import { ReactComponent as EnterpriseIcon } from "./assets/AdminSideIcons/EnterpriseIcon.svg";
import { ReactComponent as MessagesIcon } from "./assets/AdminSideIcons/Messages.svg";
import { ReactComponent as SettingsIcon } from "./assets/AdminSideIcons/SettingIcon.svg";
import { ReactComponent as LifeCoachIcon } from "./assets/AdminSideIcons/LifeCoach.svg";
import { ReactComponent as AdminProfileIcon } from "./assets/AdminSideIcons/ProfileSettings.svg";
//v2 pages
import AccountSettings from "./accountSettings";
import AdminProfile from "./adminProfile";
import Dashboard from "./dashboard";
import LifeCoaches from "./lifeCoaches";
import Messages from "./messages";
import TeamMembers from "./teamMembers";
import MembersProfile from "./teamMembers/MembersProfile";
import AssignTask from "./assignTask";
import CustomCardCreation from "./assignTask/CustomCardCreation";
//layouts
import AuthLayout from "./layouts/AuthLayout";
import ConsumerWebLayout from "./layouts/ConsumerWebLayout";
import EnterpriseLayout from "./layouts/EnterpriseLayout";
import DashboardLayout from "./layouts/DashboardLayout";

export const authRoutes = [
  {
    component: GetStarted,
    layout: AuthLayout,
    pageTitle: PAGE_TITLES.LOGIN,
    path: "/takeme-to-mydashboard",
  },
  {
    component: VerifyOTP,
    layout: AuthLayout,
    pageTitle: PAGE_TITLES.VERIFY_EMAIL,
    path: "/verify-email",
  },
  {
    component: DashBoardSetUp,
    layout: AuthLayout,
    pageTitle: PAGE_TITLES.DASHBOARD_SETUP,
    path: "/dashboard-setup",
  },
];

export const consumerRoutes = [
  {
    component: Home,
    layout: ConsumerWebLayout,
    pageTitle: PAGE_TITLES.HOME,
    path: "/",
  },
  {
    component: WhatIsDose,
    layout: ConsumerWebLayout,
    pageTitle: PAGE_TITLES.WHAT_IS_DOSE,
    path: "/what-is-dose",
  },
  {
    component: Faqs,
    layout: ConsumerWebLayout,
    pageTitle: PAGE_TITLES.FAQS,
    path: "/faqs",
  },
  {
    component: Privacy,
    layout: ConsumerWebLayout,
    pageTitle: PAGE_TITLES.PRIVACY,
    path: "/privacy",
  },
  {
    component: ContactUs,
    layout: ConsumerWebLayout,
    pageTitle: PAGE_TITLES.CONTACT_US,
    path: "/contact",
  },
  {
    component: Results,
    layout: ConsumerWebLayout,
    pageTitle: PAGE_TITLES.RESULTS,
    path: "/results/:chemical",
  },
];

export const dashboardRoutes = [
  {
    component: TeamMembers,
    icon: TeamMembersIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.TEAM_MEMBERS,
    pageTitle: PAGE_TITLES.TEAM_MEMBERS,
    path: "/team-members",
  },
  {
    component: Dashboard,
    icon: DashboardIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.COMMON.DASHBOARD,
    pageTitle: PAGE_TITLES.DASHBOARD,
    path: "/moods-and-emotions",
  },
  {
    component: Messages,
    icon: MessagesIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.MESSAGES,
    pageTitle: PAGE_TITLES.MESSAGES,
    path: "/messages",
  },
  {
    component: LifeCoaches,
    icon: LifeCoachIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.LIFE_COACH,
    pageTitle: PAGE_TITLES.LIFE_COACH,
    path: "/life-coach",
  },
  {
    component: AdminProfile,
    icon: AdminProfileIcon,
    layout: DashboardLayout,
    name:
      localStorage.getItem("subscription") === PRO
        ? TRANSLATIONS.VERTICAL_MENU.COACH_PROFILE
        : TRANSLATIONS.VERTICAL_MENU.ADMIN_PROFILE,
    pageTitle:
      localStorage.getItem("subscription") === PRO
        ? PAGE_TITLES.COACH_PROFILE
        : PAGE_TITLES.ADMIN_PROFILE,
    path: "/admin-profile",
  },
];
export const dashboardFooterRoutes = [
  {
    component: AccountSettings,
    icon: SettingsIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.ACCOUNT_SETTINGS,
    pageTitle: PAGE_TITLES.ACCOUNT_SETTINGS,
    path: "/account-settings",
  },
  {
    component: EnterpriseHome,
    icon: EnterpriseIcon,
    layout: EnterpriseLayout,
    name: TRANSLATIONS.SIDE_BAR.ENT_HOME,
    pageTitle: PAGE_TITLES.ENTERPRISE_HOME,
    path: "/enterprise",
  },
];
export const enterpriseRoutes = [
  {
    component: EnterpriseHome,
    layout: EnterpriseLayout,
    pageTitle: PAGE_TITLES.ENTERPRISE_HOME,
    path: "/enterprise",
  },
  {
    component: ProHome,
    layout: EnterpriseLayout,
    pageTitle: PAGE_TITLES.PRO_HOME,
    path: "/pro-home",
  },
  {
    component: HowItWorks,
    layout: EnterpriseLayout,
    pageTitle: PAGE_TITLES.HOW_IT_WORKS,
    path: "/how-it-works",
  },
  {
    component: Science,
    layout: EnterpriseLayout,
    pageTitle: PAGE_TITLES.SCIENCE,
    path: "/science",
  },
];

export const hpyProRoutes = [
  {
    component: MembersProfile,
    layout: DashboardLayout,
    pageTitle: PAGE_TITLES.MEMBER_PROFILE,
    path: "/member-profile",
  },
  {
    component: AssignTask,
    layout: DashboardLayout,
    pageTitle: PAGE_TITLES.TASK_ASSIGNMENT,
    path: "/task-assignment",
  },
  {
    component: CustomCardCreation,
    layout: DashboardLayout,
    pageTitle: PAGE_TITLES.CUSTOM_CARD_CREATION,
    path: "/custom-card-creation",
  },
];
export const hpyProDashboardRoutes = [
  {
    component: TeamMembers,
    icon: TeamMembersIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.YOUR_MEMBERS,
    pageTitle: PAGE_TITLES.TEAM_MEMBERS,
    path: "/team-members",
  },
  {
    component: Dashboard,
    icon: DashboardIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.COMMON.DASHBOARD,
    pageTitle: PAGE_TITLES.DASHBOARD,
    path: "/moods-and-emotions",
  },
  {
    component: AdminProfile,
    icon: AdminProfileIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.COACH_PROFILE,
    pageTitle: PAGE_TITLES.COACH_PROFILE,
    path: "/admin-profile",
  },
];

export const hpyProDashboardFooterRoutes = [
  {
    component: AccountSettings,
    icon: SettingsIcon,
    layout: DashboardLayout,
    name: TRANSLATIONS.VERTICAL_MENU.ACCOUNT_SETTINGS,
    pageTitle: PAGE_TITLES.ACCOUNT_SETTINGS,
    path: "/account-settings",
  },
  {
    component: ProHome,
    icon: EnterpriseIcon,
    layout: EnterpriseLayout,
    name: TRANSLATIONS.SIDE_BAR.PRO_HOME,
    pageTitle: PAGE_TITLES.PRO_HOME,
    path: "/pro-home",
  },
];

const globalRoutes = [
  ...authRoutes,
  ...consumerRoutes,
  ...dashboardFooterRoutes,
  ...dashboardRoutes,
  ...enterpriseRoutes,
  ...hpyProRoutes,
  ...hpyProDashboardFooterRoutes,
  ...hpyProDashboardRoutes,
];
export default globalRoutes;
