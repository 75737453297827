import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { requestAccessError, requestAccessSuccess } from "./actions";

import { REQUEST_ACCESS_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* requestAccess(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/respond`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response?.data?.[0]?.Success) {
      const responseData = {
        data: { ...response.data, members: action.payload.members },
      };
      yield put(requestAccessSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error ? response.data.error : response.data,
      };
      yield put(requestAccessError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error.response.data,
        netWorkError: true,
      },
    };
    yield put(requestAccessError(errorData));
  }
}

export function* watchRequestAccess() {
  yield takeEvery(REQUEST_ACCESS_REQUEST, requestAccess);
}

function* RequestAccessSaga() {
  yield all([fork(watchRequestAccess)]);
}
export default RequestAccessSaga;
