import { PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_ERROR } from "./actionTypes";

export const profileRequest = (user) => {
  return {
    type: PROFILE_REQUEST,
    payload: user,
  };
};

export const profileSuccess = (user, history) => {
  return {
    type: PROFILE_SUCCESS,
    payload: { user, history },
  };
};

export const profileError = (history) => {
  return {
    type: PROFILE_ERROR,
    payload: { history },
  };
};
