import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useProSidebar } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";

import { PRO, ROUTE_PATH } from "../../common/constants";
import { teamSettingsRequest, userDetailsRequest } from "../../redux/actions";
import InviteCodeModel from "../../teamMembers/InviteCodeModel";
import {
  bodyContainsCollapseClass,
  isUserAuthenticated,
  manageBodyClass,
  updateDocumentTitle,
} from "../../utils";
import Footer from "./Footer";
import Header from "./Header/Header";
import MobileSidebarHeader from "./Sidebar/MobileSidebarHeader";
import Sidebar from "./Sidebar/Sidebar";
import "./index.css";

function DashboardLayout({ component: Component, pageTitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { collapsed, collapseSidebar, toggleSidebar } = useProSidebar();
  const pathname = window.location.pathname.substring(1);
  const scrollbarsRef = useRef(null);

  /*invite team modal toggle */
  const [isOpenInviteModal, setIsOpenInviteModal] = useState(false);
  const inviteModaltoggle = () => setIsOpenInviteModal(!isOpenInviteModal);

  useEffect(() => {
    if (!isUserAuthenticated()) {
      history.push(ROUTE_PATH.GETSTARTED);
    }
  }, []);

  const { teamSettigsData } = useSelector((state) => state.teamSettings);

  useEffect(() => {
    if (
      !teamSettigsData?.length &&
      window.location.pathname !== ROUTE_PATH.TEAM_MEMBERS
    ) {
      dispatch(teamSettingsRequest({ isMember: false }));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    /*update document title based on route */
    updateDocumentTitle(pageTitle);
  }, [pageTitle, window.location.pathname]);

  const { userDetailsData, userDetailsRequestData } = useSelector(
    (state) => state.UserDetails
  );
  const { teamSettigsRequestData } = useSelector((state) => state.teamSettings);

  useEffect(() => {
    if (document.documentElement.clientWidth > 1000) {
      if (!bodyContainsCollapseClass()) {
        collapseSidebar();
        manageBodyClass("vertical-menu-collapsed", "remove");
      } else {
        manageBodyClass("vertical-menu-collapsed", "add");
      }
    }
    if (!userDetailsData) {
      dispatch(userDetailsRequest({}));
    }
  }, []);

  return (
    <div className="dashboard-layout">
      {userDetailsRequestData && (
        <Spinner className="loader-container dashboard-loader overlay-admin-screen" />
      )}
      <div className="db-mobile-header">
        <MobileSidebarHeader
          collapseSidebar={collapseSidebar}
          collapsed={collapsed}
          toggleSidebar={toggleSidebar}
          isProUser={localStorage.getItem("subscription") === PRO}
        />
      </div>
      {isOpenInviteModal && (
        <InviteCodeModel
          modal={isOpenInviteModal}
          setModal={setIsOpenInviteModal}
          toggle={inviteModaltoggle}
        />
      )}
      <Sidebar
        isMobile={isMobile}
        inviteModaltoggle={inviteModaltoggle}
        isProUser={localStorage.getItem("subscription") === PRO}
      />
      <div className="vertical-menu">
        <Scrollbars autoHide={true} id="scrolls" ref={scrollbarsRef}>
          <main className={`main-content`} id="main-content">
            {" "}
            <Header
              headerText={
                pathname === "team-members"
                  ? localStorage.getItem("subscription") === PRO
                    ? "My Members"
                    : pageTitle
                  : pageTitle
              }
              enterpriseLogoURL={userDetailsData?.enterpriseLogoURL || ""}
              isLoading={userDetailsRequestData}
              inviteModaltoggle={inviteModaltoggle}
              teamSettigsRequestData={teamSettigsRequestData}
            />
            <div
              id={pathname}
              className={
                ["member-profile", "task-assignment"].includes(pathname)
                  ? `page-view member-view`
                  : "page-view"
              }
            >
              <Component
                userDetails={userDetailsData}
                scrollbarsRef={scrollbarsRef}
              />
            </div>
          </main>
          {!userDetailsRequestData && !teamSettigsRequestData && <Footer />}
        </Scrollbars>
      </div>
    </div>
  );
}
DashboardLayout.propTypes = {
  component: PropTypes.node,
  pageTitle: PropTypes.string,
};
export default DashboardLayout;
