import React from "react";
import { Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import "../node_modules/bootstrap/dist/css/bootstrap.css";

import ErrorBoundary from "./common/components/ErrorBoundaryComponent";
import globalRoutes from "./routes";
import "./firebase";
import "./App.css";

function App() {
  return (
    <div>
      <ErrorBoundary>
        <Switch>
          {globalRoutes.map(
            ({ component, layout: Layout, pageTitle, path }) => {
              return (
                <Route path={path} exact={true} key={path}>
                  <Layout component={component} pageTitle={pageTitle} />
                </Route>
              );
            }
          )}
        </Switch>
      </ErrorBoundary>
    </div>
  );
}

export default Sentry.withProfiler(App);
