export const MOODS_BREAKDOWN_REQUEST = "MOODS_BREAKDOWN_REQUEST";
export const MOODS_BREAKDOWN_SUCCESS = "MOODS_BREAKDOWN_SUCCESS";
export const MOODS_BREAKDOWN_ERROR = "MOODS_BREAKDOWN_ERROR";

export const LONGEST_STREAK_CARD_REQUEST = "LONGEST_STREAK_CARD_REQUEST";
export const LONGEST_STREAK_CARD_SUCCESS = "LONGEST_STREAK_CARD_SUCCESS";
export const LONGEST_STREAK_CARD_ERROR = "LONGEST_STREAK_CARD_ERROR";

export const PAST_LONGEST_STREAK_CARD_REQUEST =
  "PAST_LONGEST_STREAK_CARD_REQUEST";
export const PAST_LONGEST_STREAK_CARD_SUCCESS =
  "PAST_LONGEST_STREAK_CARD_SUCCESS";
export const PAST_LONGEST_STREAK_CARD_ERROR = "PAST_LONGEST_STREAK_CARD_ERROR";

export const DAILY_REFLECTIONS_CARD_REQUEST = "DAILY_REFLECTIONS_CARD_REQUEST";
export const DAILY_REFLECTIONS_CARD_SUCCESS = "DAILY_REFLECTIONS_CARD_SUCCESS";
export const DAILY_REFLECTIONS_CARD_ERROR = "DAILY_REFLECTIONS_CARD_ERROR";

export const PAST_DAILY_REFLECTIONS_CARD_REQUEST =
  "PAST_DAILY_REFLECTIONS_CARD_REQUEST";
export const PAST_DAILY_REFLECTIONS_CARD_SUCCESS =
  "PAST_DAILY_REFLECTIONS_CARD_SUCCESS";
export const PAST_DAILY_REFLECTIONS_CARD_ERROR =
  "PAST_DAILY_REFLECTIONS_CARD_ERROR";
