import React from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import "./AuthLayout.css";
import "./Navigation.css";

import {
  isDashboardDataAvailable,
  isUserAuthenticated,
  logAnalyticsEvent,
  removeCookiesData,
} from "../../utils";
import { ANALYTICS_EVENTS_JSON, PRO, ROUTE_PATH } from "../../common/constants";
import AppStoreIcons from "../../common/components/SideBarAppStoreIcons";
import LogoutIcon from "../../assets/AdminSideIcons/LogoutIcon.svg";
import { ReactComponent as ContactIcon } from "../../assets/AdminSideIcons/ContactIcon.svg";
import { ReactComponent as DashboardIcon } from "../../assets/AdminSideIcons/OverallIcon.svg";
import { ReactComponent as EnterpriseIcon } from "../../assets/AdminSideIcons/EnterpriseIcon.svg";
import { ReactComponent as Hamburger } from "../../assets/hamburgerIcon.svg";
import { ReactComponent as HowItWorksIcon } from "../../assets/AdminSideIcons/HowItWorks.svg";
import { ReactComponent as ScienceIcon } from "../../assets/AdminSideIcons/Science.svg";
import { ReactComponent as SignUpIcon } from "../../assets/AdminSideIcons/SignupIcon.svg";
import { ReactComponent as WhiteSunIcon } from "../../assets/AdminSideIcons/DoseSunIcon.svg";
import TRANSLATIONS from "../../translations/en.json";

function Sidebar({ handleToggle }) {
  const history = useHistory();
  const LINKS =
    localStorage.getItem("subscription") === PRO
      ? [
          {
            icon: EnterpriseIcon,
            name: TRANSLATIONS.COMMON.HAPPY_FOR_PRO,
            pathName: ROUTE_PATH.PRO_HOME,
          },
          {
            icon: DashboardIcon,
            pathName: isUserAuthenticated()
              ? (isDashboardDataAvailable() && ROUTE_PATH.DASHBOARD) ||
                ROUTE_PATH.TEAM_MEMBERS
              : ROUTE_PATH.GETSTARTED,
            name: TRANSLATIONS.COMMON.DASHBOARD,
            links: [
              ROUTE_PATH.VERIFY_LOGIN,
              ROUTE_PATH.VERIFY_SIGN_UP,
              ROUTE_PATH.LOGIN,
              ROUTE_PATH.SIGN_UP,
              ROUTE_PATH.DASHBOARD_SETUP,
            ],
          },
          {
            icon: ScienceIcon,
            pathName: ROUTE_PATH.SCIENCE,
            name: TRANSLATIONS.SIDE_BAR.SCIENCE,
          },
          {
            icon: HowItWorksIcon,
            pathName: ROUTE_PATH.HOW_IT_WORKS,
            name: TRANSLATIONS.SIDE_BAR.HOW_IT_WORKS,
          },
          {
            icon: ContactIcon,
            pathName: ROUTE_PATH.CONTACT,
            name: TRANSLATIONS.SIDE_BAR.SUPPORT,
          },
        ]
      : [
          {
            icon: EnterpriseIcon,
            pathName: ROUTE_PATH.ENTERPRISE,
            name: TRANSLATIONS.COMMON.HAPPY_FOR_TEAMS,
          },
          {
            icon: DashboardIcon,
            pathName: isUserAuthenticated()
              ? (isDashboardDataAvailable() && ROUTE_PATH.DASHBOARD) ||
                ROUTE_PATH.TEAM_MEMBERS
              : ROUTE_PATH.GETSTARTED,
            name: TRANSLATIONS.COMMON.DASHBOARD,
            links: [
              ROUTE_PATH.VERIFY_LOGIN,
              ROUTE_PATH.VERIFY_SIGN_UP,
              ROUTE_PATH.LOGIN,
              ROUTE_PATH.SIGN_UP,
              ROUTE_PATH.DASHBOARD_SETUP,
            ],
          },
          {
            icon: ScienceIcon,
            pathName: ROUTE_PATH.SCIENCE,
            name: TRANSLATIONS.SIDE_BAR.SCIENCE,
          },
          {
            icon: HowItWorksIcon,
            pathName: ROUTE_PATH.HOW_IT_WORKS,
            name: TRANSLATIONS.SIDE_BAR.HOW_IT_WORKS,
          },
          {
            icon: ContactIcon,
            pathName: ROUTE_PATH.CONTACT,
            name: TRANSLATIONS.SIDE_BAR.SUPPORT,
          },
        ];
  return (
    <nav id="home-menu" className="authlayout menu-dropdown p-0 hamburger-menu">
      <div id="home-menu">
        <Hamburger
          onClick={() => {
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.ENT_HOME_HAMBURGER_MENU);
            handleToggle();
          }}
          className="hamburger"
        />
        {LINKS.map((item) => {
          return (
            <Link
              className={`menu-link ${
                history.location.pathname === item.pathName ||
                (item.links && item.links.includes(window.location.pathname)
                  ? "menu-link-active pointer-events-none"
                  : history.location.pathname === item.pathName &&
                    item.name === TRANSLATIONS.SIDE_BAR.ENT_HOME)
                  ? "menu-link-active-fill"
                  : ""
              }`}
              to={item.pathName}
              key={item.pathName}
              onClick={() => handleToggle()}
            >
              <item.icon
                className="sidebar-main-icon mr-3"
                alt="settingsIcon"
              />
              {item.name}
            </Link>
          );
        })}
        {!isUserAuthenticated() && (
          <>
            <hr className="separation-line" />
            <Link className="menu-link dose-text" to={ROUTE_PATH.GETSTARTED}>
              <SignUpIcon alt="blog" className="sidebar-main-icon mr-3" />
              {TRANSLATIONS.COMMON.SIGN_UP}
            </Link>
          </>
        )}
        <hr className="separation-line" />
        <Link className="menu-link dose-text" to={ROUTE_PATH.HOME}>
          <WhiteSunIcon alt="blog" className="sidebar-main-icon mr-3" />
          {TRANSLATIONS.SIDE_BAR.HPY}
        </Link>
        <hr className="separation-line" />
        <div className="py-2">
          <AppStoreIcons />
        </div>
        {isUserAuthenticated() && (
          <>
            <hr className="separation-line" />
            <Link
              className="menu-link no-border "
              to={ROUTE_PATH.GETSTARTED}
              onClick={removeCookiesData}
            >
              <img
                src={LogoutIcon}
                className="sidebar-main-icon mr-3"
                alt="signupIcon"
              />
              {TRANSLATIONS.SIDE_BAR.LOG_OUT}
            </Link>
          </>
        )}
      </div>
    </nav>
  );
}
Sidebar.propTypes = {
  handleToggle: PropTypes.func,
};
export default Sidebar;
