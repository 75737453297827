import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const storeCookies = (key, value) => {
  cookies.set(key, value, {
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });
};
export const getCookiesValue = (key) => {
  return cookies.get(key);
};

export const storeToken = (token, isAdmin) => {
  if (token) {
    isAdmin
      ? cookies.set("token", token, {
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ),
        })
      : cookies.set("nonAdminToken", token, {
          expires: new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ),
        });
  }
};

export const isUserAuthenticated = () => {
  let userLoggedInData = cookies.get("token");
  if (userLoggedInData) {
    return userLoggedInData;
  }
  return false;
};

export const removeCookiesData = () => {
  cookies.remove("token");
  cookies.remove("nonAdminToken");
  cookies.remove("login-email");
  cookies.remove("dashboardDataAvailable");
  cookies.remove("teamName");
  cookies.remove("teamMembersAvailable");
  localStorage.clear();
};
