//External packages
import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

//local files
import "./RequestAccess.css";
import chevronUp from "../assets/chevronUp.svg";
import TRANSLATIONS from "../translations/en.json";
import chevronDown from "../assets/chevronDown.svg";
import { requestAccessRequest, teamSettingsRequest } from "../redux/actions";
import { PRO, TEAM_MEMBER_STATUS } from "../common/constants";
import acceptCheckIcon from "../assets/acceptCheckIcon.svg";
import suspendCloseIcon from "../assets/suspendCloseIcon.svg";
import notificationAlertHexa from "../assets/notificationAlertHexa.svg";
import notificationTriangle from "../assets/notificationTriangle.svg";
import { showError, showSuccess } from "../utils";

function RequestAccess({ teamAccessRequests }) {
  const [externalRequests, setExternalRequests] = useState([]);
  const dispatch = useDispatch();
  const [seeAll, setSeeAll] = useState(false);

  const { requestAccessData, requestAccessError } = useSelector(
    (state) => state.RequestAccess
  );
  const isAccessLoading = useRef(true);
  useEffect(() => {
    if (isAccessLoading.current) {
      isAccessLoading.current = false;
      return;
    }
    if (requestAccessData?.members?.length) {
      showSuccess(TRANSLATIONS.COMMON.ACCESS_UPDATED);
      requestAccessData.members.map((member) => {
        externalRequests.map((item) => {
          if (item.userUUID === member.uuid) {
            item.status = member.status;
            setExternalRequests([...externalRequests]);
          }
        });
        setExternalRequests([...externalRequests]);
        dispatch(teamSettingsRequest({ isMember: false }));
      });
    } else if (requestAccessError?.data?.length) {
      requestAccessError.data.forEach((item) => {
        showError(item.Error);
      });
    }
  }, [requestAccessData?.members, requestAccessError?.data]);

  useEffect(() => {
    setExternalRequests(teamAccessRequests);
  }, [teamAccessRequests]);

  const handleAccept = (member) => {
    externalRequests?.map((id) => {
      if (id.userUUID === member.userUUID) {
        setExternalRequests([...externalRequests]);
      }
    });
    dispatch(
      requestAccessRequest({
        members: [
          {
            status: TEAM_MEMBER_STATUS.ACCEPTED,
            uuid: member.userUUID,
          },
        ],
      })
    );
  };

  const handleDeny = (member) => {
    dispatch(
      requestAccessRequest({
        members: [
          {
            status: TEAM_MEMBER_STATUS.SUSPENDED,
            uuid: member.userUUID,
          },
        ],
      })
    );
  };
  const handleAcceptAll = () => {
    let acceptedList = [];
    externalRequests?.map((member) => {
      if (member.status === TEAM_MEMBER_STATUS.REQUESTED) {
        acceptedList.push({
          status: TEAM_MEMBER_STATUS.ACCEPTED,
          uuid: member.userUUID,
        });
      }
    });
    dispatch(
      requestAccessRequest({ members: acceptedList, isSelectAll: true })
    );
  };
  const handleDenyAll = () => {
    let denyAllList = [];
    externalRequests?.map((member) => {
      if (member.status === TEAM_MEMBER_STATUS.REQUESTED) {
        denyAllList.push({
          status: TEAM_MEMBER_STATUS.SUSPENDED,
          uuid: member.userUUID,
        });
      }
    });
    dispatch(requestAccessRequest({ members: denyAllList, isSelectAll: true }));
  };
  const renderActions = (status, member) => {
    switch (status) {
      case TEAM_MEMBER_STATUS.REQUESTED:
        return (
          <>
            <span>
              <button
                className="deny-btn mr-2"
                onClick={() => handleDeny(member)}
              >
                {TRANSLATIONS.REQUEST_ACCESS.DENY}
              </button>
            </span>
            <span>
              <button
                className="accept-btn  m-0"
                onClick={() => handleAccept(member)}
              >
                {TRANSLATIONS.REQUEST_ACCESS.ACCEPT}
              </button>
            </span>
          </>
        );
      case TEAM_MEMBER_STATUS.ACCEPTED:
        return (
          <button className="acceptedCheck-btn cursor-text">
            {TRANSLATIONS.REQUEST_ACCESS.ACCEPTED}
            <img
              src={acceptCheckIcon}
              alt="acceptCheckIcon"
              className="img-acceptCheckIcon pl-2"
            />
          </button>
        );
      case TEAM_MEMBER_STATUS.SUSPENDED:
        return (
          <button className="denied-btn cursor-text">
            {TRANSLATIONS.REQUEST_ACCESS.DENIED}
            <img
              src={suspendCloseIcon}
              alt="suspendCloseIcon"
              className="img-suspendCloseIcon pl-2"
            />
          </button>
        );
      default:
        return (
          <span className="text-danger">
            {TEAM_MEMBER_STATUS.INVALID_MEMBER}
          </span>
        );
    }
  };
  const renderRequest = (member) => {
    return (
      <>
        <div
          className={`each-notification-section mt-1 ${
            externalRequests?.length === 1 ? "px-0 " : ""
          }`}
          key={member.name}
        >
          <div className="w-100">
            <p
              className={`d-flex align-items-start mb-0 ${
                externalRequests?.length === 1 ? "mb-3" : ""
              }`}
            >
              {externalRequests?.length === 1 && (
                <img
                  src={notificationAlertHexa}
                  alt="notificationAlertHexa"
                  className="img-notification-alert-hexa"
                />
              )}
              <span>
                {member.name}
                {localStorage.getItem("subscription") === PRO
                  ? TRANSLATIONS.REQUEST_ACCESS.PRO_REQUESTS_JOINTEAM
                  : TRANSLATIONS.REQUEST_ACCESS.MEMBER_REQUESTS_JOINTEAM}
              </span>
            </p>
          </div>
          <div className="accept-deny-actions">
            {renderActions(member.status, member)}
          </div>
        </div>
      </>
    );
  };
  return (
    <div
      className={
        !externalRequests?.length ||
        (externalRequests?.length &&
          !externalRequests?.filter(
            (e) => e.status === TEAM_MEMBER_STATUS.REQUESTED
          )?.length)
          ? "d-none"
          : ""
      }
    >
      <Row>
        <Col lg={12} md={12} sm={12} xs={12} className="mx-auto px-0">
          <Card className="notifications-popup">
            <div className="pt-3 unfinishied-tasks">
              <p className="">
                <img
                  src={notificationTriangle}
                  alt="notificationTriangle"
                  className="img-notification-triangle"
                />
                <span>
                  {" "}
                  {TRANSLATIONS.REQUEST_ACCESS.YOU_HAVE}
                  <span className="font-family-bold">
                    {" "}
                    {externalRequests?.length}
                    {TRANSLATIONS.REQUEST_ACCESS.UNFINISHED_TASK}
                  </span>{" "}
                  {TRANSLATIONS.REQUEST_ACCESS.TO_ADDRESS}
                </span>
              </p>
              {externalRequests?.length > 1 && (
                <p className="d-flex justify-content-between external-requests">
                  <div className="d-flex">
                    <img
                      src={notificationAlertHexa}
                      alt="notificationAlertHexa"
                      className="img-notification-alert-hexa hexagon-mobile"
                    />
                    <div className="mb-2">
                      <img
                        src={notificationAlertHexa}
                        alt="notificationAlertHexa"
                        className="img-notification-alert-hexa hexagon-web"
                      />
                      <span className="font-family-bold">
                        {" "}
                        {externalRequests?.length}
                        {TRANSLATIONS.REQUEST_ACCESS.EXTERNAL_REQUESTS}{" "}
                      </span>
                      {localStorage.getItem("subscription") === PRO
                        ? TRANSLATIONS.REQUEST_ACCESS.JOIN_GROUP
                        : TRANSLATIONS.REQUEST_ACCESS.JOIN_TEAM}
                      <a
                        className="view-all mt-1 d-none"
                        onClick={() => setSeeAll(!seeAll)}
                      >
                        {TRANSLATIONS.REQUEST_ACCESS.SEE_ALL}
                      </a>
                    </div>
                  </div>
                  <span
                    className="request-alert"
                    onClick={() => setSeeAll(!seeAll)}
                  >
                    <img
                      src={seeAll ? chevronUp : chevronDown}
                      alt="chevronUp"
                      className=" cursor-pointer "
                    />
                  </span>
                </p>
              )}{" "}
            </div>
            {externalRequests?.length === 1 &&
              renderRequest(externalRequests[0])}
            {seeAll && externalRequests?.length > 1 && (
              <>
                <div className="animated-card">
                  {externalRequests?.map((member) => {
                    if (member) {
                      return renderRequest(member);
                    }
                  })}
                </div>
                {externalRequests.filter(
                  (member) => member.status === TEAM_MEMBER_STATUS.REQUESTED
                )?.length > 1 && (
                  <div className="d-flex flex-row justify-align-center  py-2 mx-lg-5">
                    <span>
                      <button
                        className="denyAll-btn mr-2 mt-1"
                        onClick={() => handleDenyAll()}
                      >
                        {TRANSLATIONS.REQUEST_ACCESS.DENYALL}
                      </button>
                      <button
                        className="acceptAll-btn  mt-1"
                        onClick={() => handleAcceptAll()}
                      >
                        {TRANSLATIONS.REQUEST_ACCESS.ACCEPTALL}
                      </button>
                    </span>
                  </div>
                )}
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

RequestAccess.propTypes = {
  teamAccessRequests: PropTypes.array,
};

export default memo(RequestAccess);
