import PropTypes from "prop-types";
import React, { memo } from "react";
import { Input } from "reactstrap";

import { ReactComponent as BackArrow } from "../../../assets/blackArrow.svg";
import TRANSLATIONS from "../../../translations/en.json";
import "../index.css";
import "./CardActivity.css";

function CardActivity({ cardDescription, setActiveStep, setCardDescription }) {
  const handleSetDesc = (e) => {
    e.preventDefault();
    setCardDescription(e.target.value);
  };

  const handleButtonClick = () => {
    setActiveStep((prev) => prev + 1);
  };
  return (
    <>
      <div>
        <Input
          type="textarea"
          rows="8"
          className="w-100 mb-card-activity"
          placeholder={TRANSLATIONS.CUSTOM_CARD_CREATION.CARD_TEXT_PLACEHOLDER}
          value={cardDescription}
          onChange={(e) => handleSetDesc(e)}
          style={{ background: "#F4F4F3" }}
        />
        <p className="mb-text-note">
          {TRANSLATIONS.CUSTOM_CARD_CREATION.MB_TEXT_NOTE}
        </p>
      </div>
      <div
        className="d-flex justify-content-end"
        style={{ padding: "8px 0px" }}
      >
        <button
          className="stepper-continue-btn"
          onClick={handleButtonClick}
          disabled={!cardDescription?.length}
        >
          {TRANSLATIONS.CUSTOM_CARD_CREATION.ADD_DEETS} <BackArrow />
        </button>
      </div>
    </>
  );
}

CardActivity.propTypes = {
  activeStep: PropTypes.number.isRequired,
  cardDescription: PropTypes.string.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  setCardDescription: PropTypes.func.isRequired,
};

export default memo(CardActivity);
