import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  adminProfileActivityError,
  adminProfileActivitySuccess,
} from "./actions";

import { ADMIN_PROFILE_ACTIVITY_REQUEST } from "./actionTypes";

import { checkHttpStatus } from "../../api/apiUtils";

function* adminProfileActivity(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/customization_request`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data,
      };
      yield put(adminProfileActivitySuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(adminProfileActivityError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(adminProfileActivityError(errorData));
  }
}
export function* watchAdminProfileActivity() {
  yield takeEvery(ADMIN_PROFILE_ACTIVITY_REQUEST, adminProfileActivity);
}

function* adminProfileSaga() {
  yield all([fork(watchAdminProfileActivity)]);
}
export default adminProfileSaga;
