import React, { memo } from "react";
import PropTypes from "prop-types";

import DefaultImage from "../../assets/AdminProfile/DefaultProfile.svg";
import { ReactComponent as AssignedIcon } from "../../assets/AssignedIcon.svg";
import TRANSLATIONS from "../../translations/en.json";

import "./AssignedDetails.css";

function AssignedDetails({ userDetails }) {
  return (
    <div className="assigned-details-container">
      <p className="assigned-text m-0">
        {TRANSLATIONS.CUSTOM_CARD_CREATION.TASK_ASSIGNED_TO}
      </p>
      <div className="assigned-user-details">
        <AssignedIcon width={24} height={24} />
        <div className="assigned-user">
          <img
            src={
              userDetails?.profileImageURL
                ? userDetails?.profileImageURL
                : DefaultImage
            }
            alt="user_image"
            className="assigned-user-profile-img"
          />
          <p className="assigned-user-name m-0">{userDetails.name}</p>
        </div>
      </div>
    </div>
  );
}

AssignedDetails.propTypes = {
  userDetails: PropTypes.object,
};

export default memo(AssignedDetails);
