import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./AuthLayout.css";
import "../../authentication/DashBoardSetUp.css";
import "../../authentication/GetStarted.css";
import BlackSun from "../../assets/BlackSun.svg";
import { GetStarted } from "../../authentication";
import Header from "./Navigation";
import Hpy from "../../assets/Hpy.svg";
import { isUserAuthenticated, updateDocumentTitle } from "../../utils";
import { ROUTE_PATH } from "../../common/constants";
function AuthLayout({ component: Component, pageTitle }) {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    /*update document title based on route */
    updateDocumentTitle(pageTitle);
  }, [pageTitle, window.location.pathname]);

  useEffect(() => {
    if (isUserAuthenticated()) {
      localStorage.setItem("subscription", "PRO");
      history.push(ROUTE_PATH.PRO_HOME);
    }
    document.body.classList.add("auth-grey-section");
    return () => {
      document.body.classList.remove("auth-grey-section");
    };
  }, []);

  return (
    <>
      <Header />
      <div className="logo-branding">
        <img src={BlackSun} className="sun-logo mb-3" alt="black-sun" />
        <img src={Hpy} className="hpy-logo pb-4" alt="hpy" />
      </div>
      {Component ? <Component /> : <GetStarted />}
    </>
  );
}
AuthLayout.propTypes = {
  component: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};
export default AuthLayout;
