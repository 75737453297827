import {
  REQUEST_ACCESS_ERROR,
  REQUEST_ACCESS_REQUEST,
  REQUEST_ACCESS_SUCCESS,
} from "./actionTypes";

export const requestAccessRequest = (payload) => {
  return {
    type: REQUEST_ACCESS_REQUEST,
    payload,
  };
};

export const requestAccessSuccess = (user, history) => {
  return {
    type: REQUEST_ACCESS_SUCCESS,
    payload: { user, history },
  };
};

export const requestAccessError = (error) => {
  return {
    type: REQUEST_ACCESS_ERROR,
    payload: { error },
  };
};
