import "./EntPgeHeader.css";
import { useDispatch } from "react-redux";

import { ANALYTICS_EVENTS_JSON, PRO, ROUTE_PATH } from "../common/constants";
import { AnimatedButton } from "../common/components";
import BlackSun from "../../src/assets/BlackSun.svg";
import {
  isUserAuthenticated,
  isDashboardDataAvailable,
  logAnalyticsEvent,
  removeCookiesData,
} from "../utils";
import ChemicalsLine from "../../src/assets/EnterpriseHomePageWebps/ChemicalsLine.webp";
import TRANSLATIONS from "../translations/en.json";
import { useHistory } from "react-router-dom";
import { logout } from "../redux/actions";

function EntPgeHeader() {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGetStarted = () => {
    localStorage.setItem("login", "FREE");
    history.push(ROUTE_PATH.GETSTARTED);
  };
  const handleDashboard = () => {
    if (localStorage.getItem("subscription") === PRO) {
      if (isUserAuthenticated()) {
        dispatch(logout());
        removeCookiesData();
        history.push(ROUTE_PATH.GETSTARTED);
      }
    } else {
      if (isDashboardDataAvailable()) {
        localStorage.setItem("login", "FREE");
        history.push(ROUTE_PATH.DASHBOARD);
      } else {
        history.push(ROUTE_PATH.TEAM_MEMBERS);
      }
    }
  };
  return (
    <div className="headercontainer text-center ent-header-container mb-0">
      <img src={BlackSun} alt="happyLogo" className="entpge-happy-logo" />
      <br />
      <div className="ent-header-text-cards">
        <p className="d-inline header2-text mb-0">
          {TRANSLATIONS.ENT_HEADER.HEADER2_TEXT}
        </p>
        <p className="d-sm-block make-happy-text mb-0 mx-md-5 ">
          {TRANSLATIONS.ENT_HEADER.HEADER_DESC_TEXT}
        </p>
      </div>
      <div className="m-2 mb-5 mt-4 work-mail-cards">
        {isUserAuthenticated() ? (
          <AnimatedButton
            className="btn btn-small take-me-to-dashboard-btn mb-2"
            onClick={() => {
              handleDashboard();
              logAnalyticsEvent(ANALYTICS_EVENTS_JSON.GET_STARTED);
            }}
          >
            {TRANSLATIONS.COMMON.TAKE_ME_TO_DASHBOARD}
          </AnimatedButton>
        ) : (
          <p className="work-mail-text">
            <span className="text-decoration-none">
              {TRANSLATIONS.ENT_HEADER.ENTER_WORK_MAIL}
            </span>
            <AnimatedButton
              className="btn btn-small btn-sign-up"
              onClick={() => {
                handleGetStarted();
                logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SIGNUP_BTN);
              }}
            >
              {TRANSLATIONS.GET_STARTED.BUTTON}
            </AnimatedButton>
          </p>
        )}
        <div id="header_image">
          <img
            src={ChemicalsLine}
            alt="HeaderLogo"
            className={
              isUserAuthenticated()
                ? "entpge-dashboard-logo take-me-dashboard-logo"
                : "entpge-dashboard-logo"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default EntPgeHeader;
