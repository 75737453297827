import {
  UPDATE_ENTERPRISE_LOGO_ERROR,
  UPDATE_ENTERPRISE_LOGO_REQUEST,
  UPDATE_ENTERPRISE_LOGO_SUCCESS,
} from "./actionTypes";

export const updateEnterpriseLogo = (payload) => {
  return {
    type: UPDATE_ENTERPRISE_LOGO_REQUEST,
    payload,
  };
};

export const updateEnterpriseLogoSuccess = (data) => {
  return {
    type: UPDATE_ENTERPRISE_LOGO_SUCCESS,
    data,
  };
};

export const updateEnterpriseLogoError = (error) => {
  return {
    type: UPDATE_ENTERPRISE_LOGO_ERROR,
    error,
  };
};
