import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import TRANSLATIONS from "../../translations/en.json";
import { getRoundedPercentage } from "../../utils";
import { StackedChartOptions } from "./StackedChartOptions";
import "./Charts.css";
import { useProSidebar } from "react-pro-sidebar";
import { PRO } from "../../common/constants";

function StackedChart() {
  const { collapsed } = useProSidebar();
  const [activitiesPerTeamData, setActivitiesPerTeamData] = useState({});
  const [teamMembers, setTeamMembers] = useState([]);
  const [series, setSeries] = useState([]);

  const { isActivitiesPerTeamMemberDataLoading, activitiesPerTeamMemberData } =
    useSelector((state) => state.ActivitiesPerTeamMember);

  useEffect(() => {
    if (activitiesPerTeamMemberData) {
      setActivitiesPerTeamData(activitiesPerTeamMemberData);
    }
  }, [isActivitiesPerTeamMemberDataLoading]);

  useEffect(() => {
    const teamMembersData = [],
      membersActivitiesData = [],
      memCompletedData = [],
      memNotCommitedData = [];
    if (activitiesPerTeamData?.length) {
      for (let item of activitiesPerTeamData) {
        if (item) {
          teamMembersData.push(`${item?.name} `);
          const completedActivities = item?.completed_activities;
          const inCompletedActivities = item?.incompleted_activities;
          const totalActivities = completedActivities + inCompletedActivities;
          const percentageOfCompletedActivities = getRoundedPercentage(
            completedActivities,
            totalActivities
          );

          memCompletedData.push(percentageOfCompletedActivities);

          if (percentageOfCompletedActivities > 0) {
            memNotCommitedData.push(100 - percentageOfCompletedActivities);
          } else if (percentageOfCompletedActivities <= 0) {
            memNotCommitedData.push(100);
          }
        }
      }
      if (memCompletedData?.length || memNotCommitedData?.length) {
        membersActivitiesData.push(memCompletedData, memNotCommitedData);
      }
      if (membersActivitiesData?.length) {
        let chartSeries = [];
        membersActivitiesData.forEach((item, index) => {
          if (index == 0) {
            chartSeries.push({ data: item, name: "Completed" });
          } else {
            chartSeries.push({ data: item, name: "Not Committed" });
          }
        });
        setSeries(chartSeries);
      }
      setTeamMembers(teamMembersData);
    }
  }, [activitiesPerTeamData]);

  const StackedChartData = {
    series: series,
    options: {
      ...StackedChartOptions,
      activitiesPerTeamData,
      xaxis: {
        ...StackedChartOptions.xaxis,
        categories: teamMembers,
      },
    },
  };

  const getBarGraphHeight = (members) => {
    return members > 1 ? members * 50 + 50 : "130";
  };
  const getGraphWidth = () => {
    if (
      document.documentElement.clientWidth > 1000 &&
      teamMembers.length <= 1 &&
      !collapsed
    ) {
      return "83%";
    }
    return "95%";
  };
  return (
    <Card className="dashboard-graph-card stacked-graph" id="stacked-graph">
      <h1 className="graph-title">
        {localStorage.getItem("subscription") === PRO
          ? TRANSLATIONS.USER_DASHBOARD.COMPLETION_ACTIVITIES_GROUP
          : TRANSLATIONS.USER_DASHBOARD.COMPLETION_ACTIVITIES}
      </h1>
      <div className="app">
        <div className="row">
          {!isActivitiesPerTeamMemberDataLoading && (
            <div
              className={
                teamMembers.length > 9
                  ? "mixed-chart stacked-chart-scroll"
                  : "mixed-chart "
              }
            >
              <Chart
                options={StackedChartData.options}
                series={StackedChartData.series}
                type="bar"
                className="bar-chart"
                width={getGraphWidth()}
                height={getBarGraphHeight(teamMembers.length)}
              />
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}

export default StackedChart;
