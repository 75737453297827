import {
  MOODS_BREAKDOWN_REQUEST,
  MOODS_BREAKDOWN_SUCCESS,
  MOODS_BREAKDOWN_ERROR,
  LONGEST_STREAK_CARD_REQUEST,
  LONGEST_STREAK_CARD_SUCCESS,
  LONGEST_STREAK_CARD_ERROR,
  PAST_LONGEST_STREAK_CARD_REQUEST,
  PAST_LONGEST_STREAK_CARD_SUCCESS,
  PAST_LONGEST_STREAK_CARD_ERROR,
  DAILY_REFLECTIONS_CARD_REQUEST,
  DAILY_REFLECTIONS_CARD_SUCCESS,
  DAILY_REFLECTIONS_CARD_ERROR,
  PAST_DAILY_REFLECTIONS_CARD_REQUEST,
  PAST_DAILY_REFLECTIONS_CARD_SUCCESS,
  PAST_DAILY_REFLECTIONS_CARD_ERROR,
} from "./actionsTypes";

export const moodsBreakdownRequest = (user) => {
  return {
    type: MOODS_BREAKDOWN_REQUEST,
    payload: user,
  };
};

export const MoodsBreakdownSuccess = (user, history) => {
  return {
    type: MOODS_BREAKDOWN_SUCCESS,
    payload: { user, history },
  };
};

export const MoodsBreakdownError = (history) => {
  return {
    type: MOODS_BREAKDOWN_ERROR,
    payload: { history },
  };
};

export const LongestStreakCardRequest = (user) => {
  return {
    type: LONGEST_STREAK_CARD_REQUEST,
    payload: user,
  };
};

export const LongestStreakCardSuccess = (user, history) => {
  return {
    type: LONGEST_STREAK_CARD_SUCCESS,
    payload: { user, history },
  };
};

export const LongestStreakCardError = (history) => {
  return {
    type: LONGEST_STREAK_CARD_ERROR,
    payload: { history },
  };
};

export const PastLongestStreakCardRequest = (user) => {
  return {
    type: PAST_LONGEST_STREAK_CARD_REQUEST,
    payload: user,
  };
};

export const PastLongestStreakCardSuccess = (user, history) => {
  return {
    type: PAST_LONGEST_STREAK_CARD_SUCCESS,
    payload: { user, history },
  };
};

export const PastLongestStreakCardError = (history) => {
  return {
    type: PAST_LONGEST_STREAK_CARD_ERROR,
    payload: { history },
  };
};

export const DailyReflectionsCardRequest = (user) => {
  return {
    type: DAILY_REFLECTIONS_CARD_REQUEST,
    payload: user,
  };
};

export const DailyReflectionsCardSuccess = (user, history) => {
  return {
    type: DAILY_REFLECTIONS_CARD_SUCCESS,
    payload: { user, history },
  };
};

export const DailyReflectionsCardError = (history) => {
  return {
    type: DAILY_REFLECTIONS_CARD_ERROR,
    payload: { history },
  };
};

export const PastDailyReflectionsCardRequest = (user) => {
  return {
    type: PAST_DAILY_REFLECTIONS_CARD_REQUEST,
    payload: user,
  };
};

export const PastDailyReflectionsCardSuccess = (user, history) => {
  return {
    type: PAST_DAILY_REFLECTIONS_CARD_SUCCESS,
    payload: { user, history },
  };
};

export const PastDailyReflectionsCardError = (history) => {
  return {
    type: PAST_DAILY_REFLECTIONS_CARD_ERROR,
    payload: { history },
  };
};
