// GET NOTES
export const GET_NOTE_REQUEST = "GET_NOTE_REQUEST";
export const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS";
export const GET_NOTE_ERROR = "GET_NOTE_ERROR";

// CREATE NOTES
export const CREATE_NOTES_REQUEST = "CREATE_NOTES_REQUEST";
export const CREATE_NOTES_SUCCESS = "CREATE_NOTES_SUCCESS";
export const CREATE_NOTES_ERROR = "CREATE_NOTES_ERROR";

// UPDATE NOTES
export const UPDATE_NOTES_REQUEST = "UPDATE_NOTES_REQUEST";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_ERROR = "UPDATE_NOTES_ERROR";
