import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Header from "../AuthLayout/Navigation";
import EntFooter from "./Footer";
import { updateDocumentTitle } from "../../utils";

import "./index.css";
function EnterpriseLayout({ component: Component, pageTitle }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    /*update document title based on route */
    updateDocumentTitle(pageTitle);
  }, [pageTitle, window.location.pathname]);

  return (
    <div className="ent-layout">
      <Header />
      {Component ? <Component /> : <></>}
      <EntFooter />
    </div>
  );
}

EnterpriseLayout.propTypes = {
  component: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
};
export default EnterpriseLayout;
