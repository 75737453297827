import React, { memo } from "react";
import "./Insights.css";
import "./Happyplace.css";

import BlackSun from "../assets/BlackSun.svg";
import insightsImage from "../assets/WebpImages/dashboard-mockup.webp";
import List from "../assets/InsightLogo.svg";
import TRANSLATIONS from "../translations/en.json";
import { ReactComponent as WhiteSwoosh } from "../assets/white_swoosh.svg";

const Insights = () => {
  const insightList = [
    { text: "Track mood trends on a weekly basis", icon: List },
    { text: "Breakdown of activities by chemical", icon: List },
    { text: "Completion rate of activities", icon: List },
    { text: "Catalog of favorite activities", icon: List },
    { text: "Emotions breakdown per week", icon: List },
    { text: "Ability to look back and reflect", icon: List },
    { text: "Current activity streak", icon: List },
  ];
  return (
    <div
      id="insights"
      className="container-fluid p-0 insightContain mvb-1 simple-science-section"
    >
      <hr className="line" />
      <div className="row headline place pt-0">
        <h1 className="happy-place-header">
          {TRANSLATIONS.HOME_PAGE.INSIGHTS}{" "}
        </h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-3 insightImage d-none d-md-block">
          <img src={insightsImage} alt="tbdImage" className="" />
        </div>
        <div className="col-12 col-md-6 space">
          {insightList.map((insight) => (
            <ul className="insightList" key={insight.text}>
              <li className="mb-0">
                <img src={BlackSun} alt={List} />
                <h3 className="textSpace">{insight.text}</h3>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <WhiteSwoosh className="white-swoosh-bottom" />
    </div>
  );
};

export default memo(Insights);
