import React, { memo } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import { AnimatedButton } from "../common/components";
import Sc_image1 from "../../src/assets/Science/ScImage1.svg";
import Sc_image2 from "../../src/assets/Science/ScImage2.webp";
import Sc_image3 from "../../src/assets/Science/ScImage3.webp";
import Sc_image4 from "../../src/assets/Science/ScImage4.webp";
import ScWave from "../../src/assets/Science/ScWave.webp";
import TRANSLATIONS from "../translations/en.json";
import "./BenefitsByStudies.css";

function BenefitsByStudies({ demoToggle }) {
  const scienceVariables = {
    productivityPercentage: "30%",
    unhappyEmployees: "87% less",
    creativityGrade: "x3",
    performanceImprovement: "202%",
  };
  const benefits = [
    {
      description: TRANSLATIONS.SCIENCE_PAGE.HIGHER_PRODUCTIVITY,
      image: Sc_image1,
      primaryValue: scienceVariables.productivityPercentage,
      className: "",
    },
    {
      className: "d-sm-block d-none",
      description: TRANSLATIONS.SCIENCE_PAGE.COMPARISION,
      image: Sc_image2,
      primaryValue: scienceVariables.unhappyEmployees,
    },
    {
      className: "d-sm-block d-none",
      description: TRANSLATIONS.SCIENCE_PAGE.CREATIVITY,
      image: Sc_image3,
      primaryValue: scienceVariables.creativityGrade,
    },
    {
      className: "d-sm-block d-none",
      description: TRANSLATIONS.SCIENCE_PAGE.PERFORMANCE_IMPROVEMENT,
      image: Sc_image4,
      primaryValue: scienceVariables.performanceImprovement,
    },
  ];
  return (
    <>
      <div className="studies-container">
        <AnimatedButton className="btn demo-btn" onClick={() => demoToggle()}>
          {TRANSLATIONS.SCIENCE_PAGE.REQUEST_DEMO}
        </AnimatedButton>
        <div className="text-box">
          <p className="studies-title mb-0">
            {TRANSLATIONS.SCIENCE_PAGE.STUDIES_SHOWN}
          </p>
          <p className="studies-text mb-0">
            {TRANSLATIONS.SCIENCE_PAGE.HPY_EMPLOYEES}
          </p>
        </div>
        <Row className="row-branding">
          {benefits.map((item) => {
            return (
              <Col
                lg={3}
                md={3}
                sm={12}
                xs={12}
                key={item.primaryValue}
                className={`studies-col ${item.className}`}
              >
                <img
                  className="studies-image"
                  src={item.image}
                  alt="studies_image"
                />
                <p className="text-center sc-description">
                  <b className="sc-bold-text">{item.primaryValue} </b>
                  {item.description}
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
      <img src={ScWave} alt="ScMainLogo" className="science-main-logo" />
    </>
  );
}
BenefitsByStudies.propTypes = {
  demoToggle: PropTypes.func.isRequired,
};
export default memo(BenefitsByStudies);
