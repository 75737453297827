import { SIGN_UP_ERROR, SIGN_UP_REQUEST, SIGN_UP_SUCCESS } from "./actionTypes";

export const signUpAction = (user) => {
  return {
    type: SIGN_UP_REQUEST,
    payload: user,
  };
};

export const signUpSuccess = (user, history) => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: { user, history },
  };
};

export const signUpError = (history) => {
  return {
    type: SIGN_UP_ERROR,
    payload: { history },
  };
};
