import {
  VERIFY_ACCESS_ERROR,
  VERIFY_ACCESS_REQUEST,
  VERIFY_ACCESS_SUCCESS,
} from "./actionTypes";

export const verifyAccessAction = (user) => {
  return {
    type: VERIFY_ACCESS_REQUEST,
    payload: user,
  };
};

export const verifyAccessSuccess = (user, history) => {
  return {
    type: VERIFY_ACCESS_SUCCESS,
    payload: { user, history },
  };
};

export const verifyAccessError = (history) => {
  return {
    type: VERIFY_ACCESS_ERROR,
    payload: { history },
  };
};
