// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../../node_modules/postcss-loader/src/index.js??postcss!./SidebarContent.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../../node_modules/postcss-loader/src/index.js??postcss!./SidebarFooter.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ref--5-oneOf-4-1!../../../../node_modules/postcss-loader/src/index.js??postcss!./SidebarHeader.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".side-menu {\n  position: fixed !important;\n  height: 100%;\n  z-index: 1000;\n  border: none !important;\n}\n\n@media screen and (min-width: 1000px) {\n  .side-menu.ps-broken {\n    left: 0 !important;\n  }\n}\n@media screen and (max-width: 1000px) {\n  .side-menu {\n    display: none !important;\n    animation: slideInLeft 2s backwards;\n    -webkit-animation: slideInLeft 2s backwards;\n  }\n  .side-menu.ps-broken {\n    z-index: 10001 !important;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/layouts/DashboardLayout/Sidebar/Sidebar.css"],"names":[],"mappings":"AAGA;EACE,0BAA0B;EAC1B,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;AACA;EACE;IACE,wBAAwB;IACxB,mCAAmC;IACnC,2CAA2C;EAC7C;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["@import \"./SidebarContent.css\";\n@import \"./SidebarFooter.css\";\n@import \"./SidebarHeader.css\";\n.side-menu {\n  position: fixed !important;\n  height: 100%;\n  z-index: 1000;\n  border: none !important;\n}\n\n@media screen and (min-width: 1000px) {\n  .side-menu.ps-broken {\n    left: 0 !important;\n  }\n}\n@media screen and (max-width: 1000px) {\n  .side-menu {\n    display: none !important;\n    animation: slideInLeft 2s backwards;\n    -webkit-animation: slideInLeft 2s backwards;\n  }\n  .side-menu.ps-broken {\n    z-index: 10001 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
