import React, { memo } from "react";
import { Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";

import { PRO, ROUTE_PATH } from "../../../common/constants";
import { dashboardRoutes, hpyProDashboardRoutes } from "../../../routes";
import { isDashboardDataAvailable } from "../../../utils";
import "./SidebarContent.css";

function SidebarContent() {
  const routes =
    localStorage.getItem("subscription") === PRO
      ? hpyProDashboardRoutes
      : dashboardRoutes;

  return (
    <Menu className="menu-content">
      {routes.map(({ hide, icon: Icon, isDashboard, name, path }) => {
        if (!hide) {
          return (
            <MenuItem
              title={name}
              className={
                [
                  "/member-profile",
                  "/task-assignment",
                  "/custom-card-creation",
                ].includes(window.location.pathname)
                  ? path === "/team-members"
                    ? "menu-link active-link"
                    : "menu-link"
                  : window.location.pathname === path
                  ? "menu-link active-link"
                  : "menu-link"
              }
              key={path}
              icon={<Icon className={"menu-icon"} />}
              component={
                <Link
                  to={
                    isDashboard
                      ? (isDashboardDataAvailable() && path) ||
                        ROUTE_PATH.TEAM_MEMBERS
                      : path
                  }
                />
              }
            >
              {name}
            </MenuItem>
          );
        }
      })}
    </Menu>
  );
}

export default memo(SidebarContent);
