import React from "react";
import BlackSun from "../../assets/WebpImages/BlackSun.webp";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="teammembers-footer">
      <img src={BlackSun} alt="black-sun" />
    </div>
  );
}
