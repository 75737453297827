import {
  AVG_HPY_SCORE_ERROR,
  AVG_HPY_SCORE_REQUEST,
  AVG_HPY_SCORE_SUCCESS,
} from "./actionTypes";

const initialState = {};

const avgHpyScore = (state = initialState, action) => {
  switch (action.type) {
    case AVG_HPY_SCORE_REQUEST:
      return {
        ...state,
        isAvgHpyScoreLoading: true,
        avgHpyScoreData: false,
        avgHpyScoreError: false,
      };
    case AVG_HPY_SCORE_SUCCESS:
      return {
        ...state,
        isAvgHpyScoreLoading: false,
        avgHpyScoreData: action.data?.response,
        avgHpyScoreError: false,
      };
    case AVG_HPY_SCORE_ERROR:
      return {
        ...state,
        isAvgHpyScoreLoading: false,
        avgHpyScoreData: false,
        avgHpyScoreError: action.error.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default avgHpyScore;
