export const GET_TASKS_REQUEST = "GET_TASKS_REQUEST";
export const GET_TASKS_SUCCESS = "GET_TASKS_SUCCESS";
export const GET_TASKS_ERROR = "GET_TASKS_ERROR";

// ASSIGN TASK
export const ASSIGN_TASKS_REQUEST = "ASSIGN_TASKS_REQUEST";
export const ASSIGN_TASKS_SUCCESS = "ASSIGN_TASKS_SUCCESS";
export const ASSIGN_TASKS_ERROR = "ASSIGN_TASKS_ERROR";

// GET ALL INCLUSIVITY OPTIONS
export const GET_ALL_INCLUSIVITY_REQUEST = "GET_ALL_INCLUSIVITY_REQUEST";
export const GET_ALL_INCLUSIVITY_SUCCESS = "GET_ALL_INCLUSIVITY_SUCCESS";
export const GET_ALL_INCLUSIVITY_ERROR = "GET_ALL_INCLUSIVITY_ERROR";

// GET ALL TIME OPTIONS
export const GET_ALL_TIME_REQUEST = "GET_ALL_TIME_REQUEST";
export const GET_ALL_TIME_SUCCESS = "GET_ALL_TIME_SUCCESS";
export const GET_ALL_TIME_ERROR = "GET_ALL_TIME_ERROR";

// GET ALL JOUNRAL OPTIONS
export const GET_ALL_JOURNAL_REQUEST = "GET_ALL_JOURNAL_REQUEST";
export const GET_ALL_JOURNAL_SUCCESS = "GET_ALL_JOURNAL_SUCCESS";
export const GET_ALL_JOURNAL_ERROR = "GET_ALL_JOURNAL_ERROR";

// GET ALL PURPOSE OPTIONS
export const GET_ALL_PURPOSE_REQUEST = "GET_ALL_PURPOSE_REQUEST";
export const GET_ALL_PURPOSE_SUCCESS = "GET_ALL_PURPOSE_SUCCESS";
export const GET_ALL_PURPOSE_ERROR = "GET_ALL_PURPOSE_ERROR";

// CREATE CUSTOM CARD
export const CREATE_CUSTOM_CARD_REQUEST = "CREATE_CUSTOM_CARD_REQUEST";
export const CREATE_CUSTOM_CARD_SUCCESS = "CREATE_CUSTOM_CARD_SUCCESS";
export const CREATE_CUSTOM_CARD_ERROR = "CREATE_CUSTOM_CARD_ERROR";
