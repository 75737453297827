import React from "react";
import { Col, Row } from "reactstrap";

import "./PersonalizedSection.css";
import HappyCard from "./HappyCard";
import { PERSONALIZED_SECTION_CARD_DETAILS } from "../common/constants/howItWorks";
import TRANSLATIONS from "../translations/en.json";

function PersonalizedSection() {
  return (
    <div className="personalized-container">
      <Row>
        <Col sm={12}>
          <h1 className="text-center personalized-text">
            {TRANSLATIONS.HOW_IT_WORKS_PAGE.PERSONALIZED_TEXT}
          </h1>
        </Col>
      </Row>
      <Row className="personalized-cards-container">
        {PERSONALIZED_SECTION_CARD_DETAILS?.map((details, key) => (
          <Col xs={12} lg={6} key={key} className="cards-spcaing">
            <HappyCard details={details} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
export default PersonalizedSection;
