import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import ActivityCard from "./ActivityCard";
import TRANSLATIONS from "../translations/en.json";
import { ReactComponent as DopamineIcon } from "../assets/DoseIcons/dopamineIcon.svg";
import { ReactComponent as OxytocinIcon } from "../assets/DoseIcons/oxytocin.svg";
import { ReactComponent as SerotoninIcon } from "../assets/DoseIcons/serotonin.svg";
import { ReactComponent as EndorphinIcon } from "../assets/DoseIcons/endorphin.svg";
import { ReactComponent as Circle } from "../assets/Dashboard/Circle.svg";
import {
  activitiesPerChemicalRequest,
  pastActivitiesPerChemicalRequest,
} from "../redux/actions";
import {
  comparePopularChemical,
  getPercentageText,
  getTotalChemicalsData,
  getPercentageByDataComparision,
} from "../utils";

function PopularChemicalCard({ isEmptyDashboard, periodDates }) {
  const dispatch = useDispatch();
  const chemicalsIcons = {
    Dopamine: DopamineIcon,
    Oxytocin: OxytocinIcon,
    Endorphin: EndorphinIcon,
    Serotonin: SerotoninIcon,
  };

  const [currentChemicalData, setCurrentChemicalData] = useState([]);
  const [pastChemicalData, setPastChemicalData] = useState([]);
  const [popularChemicalPercentage, setPopularChemicalPercentage] = useState(0);

  const fecthActivitiesData = () => {
    dispatch(
      activitiesPerChemicalRequest({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );
    dispatch(
      pastActivitiesPerChemicalRequest({
        start_date: periodDates.previousStartDate,
        end_date: periodDates.previousEndDate,
      })
    );
  };
  useEffect(() => {
    fecthActivitiesData(periodDates);
  }, [periodDates]);

  const { activitiesPerChemicalData, pastActivitiesPerChemicalData } =
    useSelector((state) => state.ActivitiesPerChemical);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (activitiesPerChemicalData) {
      setCurrentChemicalData(activitiesPerChemicalData);
    }
    if (pastActivitiesPerChemicalData) {
      setPastChemicalData(pastActivitiesPerChemicalData);
    }
  }, [activitiesPerChemicalData, pastActivitiesPerChemicalData]);

  const getChemicalData = () => {
    const { chemicals, total } = getTotalChemicalsData(currentChemicalData);

    let highestChemicalValue = 0;
    const chemicalValues = Object.values(chemicals).sort((a, b) => a - b);
    highestChemicalValue = chemicalValues.pop();
    const popularChemical = Object.keys(chemicals).find(
      (e) => highestChemicalValue && chemicals[e] === highestChemicalValue
    );
    const previousHighestChemicalValue = comparePopularChemical(
      pastChemicalData,
      popularChemical
    );

    return {
      highestChemicalValue,
      popularChemical,
      previousHighestChemicalValue,
      total,
    };
  };

  const {
    highestChemicalValue,
    popularChemical,
    previousHighestChemicalValue,
    total,
  } = getChemicalData();

  useEffect(() => {
    if (highestChemicalValue >= 0 && previousHighestChemicalValue >= 0) {
      setPopularChemicalPercentage(
        getPercentageByDataComparision(
          highestChemicalValue,
          previousHighestChemicalValue
        )
      );
    }
  }, [total, previousHighestChemicalValue, highestChemicalValue]);

  const { percentageText, icon } = getPercentageText(
    { highestChemicalValue },
    {
      highestChemicalValue: previousHighestChemicalValue,
    },
    "highestChemicalValue"
  );
  return (
    <ActivityCard
      header={TRANSLATIONS.USER_DASHBOARD.POPULAR_CHEMICAL}
      Icon={
        isEmptyDashboard || !popularChemical
          ? Circle
          : chemicalsIcons[popularChemical]
      }
      value={popularChemical}
      description={`${icon}${
        popularChemicalPercentage > 0 ? popularChemicalPercentage : ""
      }${percentageText}`}
      isEmptyDashboard={isEmptyDashboard}
    />
  );
}
PopularChemicalCard.propTypes = {
  isEmptyDashboard: PropTypes.bool,
  periodDates: PropTypes.Object,
};
export default PopularChemicalCard;
