import {
  ACTIVITIES_PER_TEAM_MEMBER_REQUEST,
  ACTIVITIES_PER_TEAM_MEMBER_SUCCESS,
  ACTIVITIES_PER_TEAM_MEMBER_ERROR,
} from "./actionTypes";

const initialState = {};

const ActivitiesPerTeamMember = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVITIES_PER_TEAM_MEMBER_REQUEST:
      return {
        ...state,
        isActivitiesPerTeamMemberDataLoading: true,
        activitiesPerTeamMemberData: false,
        activitiesPerTeamMemberError: false,
      };
    case ACTIVITIES_PER_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        isActivitiesPerTeamMemberDataLoading: false,
        activitiesPerTeamMemberData: action.payload.user.data,
        activitiesPerTeamMemberError: false,
      };
    case ACTIVITIES_PER_TEAM_MEMBER_ERROR:
      return {
        ...state,
        isActivitiesPerTeamMemberDataLoading: false,
        activitiesPerTeamMemberData: false,
        activitiesPerTeamMemberError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ActivitiesPerTeamMember;
