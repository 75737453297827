import PropTypes from "prop-types";
import React, { memo } from "react";
import { Col, Row } from "reactstrap";

import JoinTeam from "../../assets/TeamMembers/JoinTeam.webp";
import JoinTeamMobile from "../../assets/TeamMembers/JoinTeamMobile.webp";
import { PRO } from "../../common/constants";
import TRANSLATIONS from "../../translations/en.json";
import SendCodeCard from "./SendCodeCard";
import "./SendCodeCard.css";
import "./index.css";

function WelcomeScreen({ inviteModaltoggle, userDetails }) {
  return (
    <div className="welcome-screen">
      <Row className="titles">
        <Col lg={8} md={8} sm={8} xs={8} className="text-column">
          <div className="invite-team-text">
            {userDetails?.subscription !== PRO ? (
              <>
                <div className="welcome-header">
                  {" "}
                  {TRANSLATIONS.WELCOME_SCREEN.EMPLOYEE_ENGAGEMENT_1}
                  <span className="happy">
                    {TRANSLATIONS.WELCOME_SCREEN.HAPPY}
                  </span>
                  {TRANSLATIONS.WELCOME_SCREEN.EMPLOYEE_ENGAGEMENT_2}
                </div>
                <p className="subheader">
                  {TRANSLATIONS.WELCOME_SCREEN.EMPOYEE_EXPERIENCE}
                </p>
              </>
            ) : (
              <div className="welcome-header">
                {" "}
                <span className="happy">
                  {TRANSLATIONS.HAPPY_PRO.WELCOME_HPY_PRO}
                </span>
                {TRANSLATIONS.HAPPY_PRO.EMPLOYEE_ENGAGEMENT_2}
              </div>
            )}
          </div>
          <div className="web-section">
            <SendCodeCard
              teamCode={userDetails?.teamCode}
              inviteModaltoggle={inviteModaltoggle}
              createdAt={userDetails?.teamCreatedDate}
            />
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} className="image-column">
          <img
            src={JoinTeam}
            alt="join-team"
            className="join-team-img web-section"
          />
          <img
            src={JoinTeamMobile}
            alt="join-team"
            className="join-team-img mobile-section"
          />
        </Col>
      </Row>
      <div className="mobile-section">
        <SendCodeCard
          teamCode={userDetails?.teamCode}
          inviteModaltoggle={inviteModaltoggle}
          createdAt={userDetails?.createdAt}
        />
      </div>
    </div>
  );
}
WelcomeScreen.propTypes = {
  userDetails: PropTypes.object,
  inviteModaltoggle: PropTypes.func,
};
export default memo(WelcomeScreen);
