import {
  REQUEST_DEMO_ERROR,
  REQUEST_DEMO_REQUEST,
  REQUEST_DEMO_SUCCESS,
} from "./actionTypes";

export const requestDemo = (payload) => {
  return {
    type: REQUEST_DEMO_REQUEST,
    payload,
  };
};

export const requestDemoSuccess = (data) => {
  return {
    type: REQUEST_DEMO_SUCCESS,
    data,
  };
};

export const requestDemoError = (error) => {
  return {
    type: REQUEST_DEMO_ERROR,
    error,
  };
};
