import React from "react";
import { Col, Row } from "reactstrap";

import "./ClockSupport.css";
import { CLOCK_SUPPORT_CONTENT } from "../common/constants/howItWorks";
import { ReactComponent as TickIcon } from "../../src/assets/HowItWorks/TickIcon.svg";
import RectangleScience from "../../src/assets/RectangleScience.svg";
import TRANSLATIONS from "../translations/en.json";

function ClockSupport() {
  const HeaderSection = () => {
    return (
      <>
        {" "}
        <h1 className="clock-support-header mb-3">
          {TRANSLATIONS.HOW_IT_WORKS_PAGE.CLOCK_SUPPORT_MAIN_HEADER}
        </h1>
        <h2 className="clock-support-sub-header">
          {TRANSLATIONS.HOW_IT_WORKS_PAGE.CLOCK_SUPPORT_SUB_HEADER}
        </h2>
      </>
    );
  };

  const ContentSection = () => {
    return (
      <>
        <div className="clock-support-content-container">
          {CLOCK_SUPPORT_CONTENT?.map((item, key) => (
            <div className="content" key={key}>
              <div>
                <TickIcon className="tick-icon" />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span className="clock-support-content-text">{item.text}</span>
            </div>
          ))}
        </div>
        <h1 className="clock-support-final-text">
          {TRANSLATIONS.HOW_IT_WORKS_PAGE.STREESS_FREE}
        </h1>
      </>
    );
  };

  return (
    <div className="clock-support-container">
      <Row>
        <Col xs={12} className="clock-support-header-container">
          {HeaderSection()}
        </Col>
      </Row>
      <Row className="with-you-row main-container">
        {/* For larger and adove screens image will be visible / below will not be visible */}
        <Col md={12} sm={12} lg={5} className="pl-0 d-lg-block d-none">
          <div className="clock-support-side-img">
            <img
              src={RectangleScience}
              alt="RectangleScience"
              className="clock-support-logo-overlay-bonds"
            />
          </div>
        </Col>
        <Col md={12} sm={12} lg={7}>
          <div className="clock-support-text-section">{ContentSection()}</div>
        </Col>
      </Row>
    </div>
  );
}
export default ClockSupport;
