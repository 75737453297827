import React from "react";

import "./EntPgeMatters.css";
import TRANSLATIONS from "../translations/en.json";
import { Row } from "reactstrap";
import { Col } from "react-bootstrap";

export default function ProPgeMatters() {
  const MATTER_CARDS = [
    {
      header: TRANSLATIONS.HAPPY_PRO.NUM1,
      discription: TRANSLATIONS.HAPPY_PRO.CARD1,
    },
    {
      header: TRANSLATIONS.HAPPY_PRO.NUM2,
      discription: TRANSLATIONS.HAPPY_PRO.CARD2,
    },
    {
      header: TRANSLATIONS.HAPPY_PRO.NUM3,
      discription: TRANSLATIONS.HAPPY_PRO.CARD3,
    },
  ];
  return (
    <>
      <div className="matter-container">
        <div className="text-container mb-0">
          <p className="why-hpy-matters mb-0">
            {TRANSLATIONS.HAPPY_PRO.MATTERS}
          </p>
        </div>
        <Row className="happy-hour-desc">
          {MATTER_CARDS.map((item) => {
            return (
              <Col lg={4} md={4} sm={4} xs={12} key={item.header}>
                <div className="matters-card">
                  <p className="matter-card-header mb-0">{item.header}</p>
                  <p className="matters-description mb-0">{item.discription}</p>
                </div>
              </Col>
            );
          })}
        </Row>

        <div className="text-container">
          <p className="matters-text mb-0">
            {TRANSLATIONS.HAPPY_PRO.MATTERS_TEXT}
          </p>
        </div>
      </div>
    </>
  );
}
