import React, { Fragment } from "react";

import { CustomInput, FormFeedback, FormText, Input } from "reactstrap";
import DropdownIcon from "../../assets/DropdownIcon.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export * from "./validation";

export const inputField = ({
  checked,
  disabled,
  defaultChecked,
  input,
  id,
  min,
  maxlength,
  placeholder,
  required,
  type,
  isShowError,
  isError,
  errorClassName,
  parentDivClassName,
  rows,
  meta: { touched, error, warning },
}) => {
  return (
    <div className={parentDivClassName}>
      <Input
        {...input}
        min={min}
        className={
          (isShowError && isError) || (touched && error)
            ? "name-info-input form-control error-inputs"
            : "name-info-input form-control"
        }
        type={type}
        required={required}
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
        maxLength={maxlength}
        placeholder={placeholder}
        autoComplete="off"
        id={id}
        rows={rows}
        cols="40"
        title={error}
      />
      {touched &&
        (error ? (
          <span className={`mr-5 form-error d-flex ${errorClassName}`}>
            {error}
          </span>
        ) : warning ? (
          <span>{warning}</span>
        ) : null)}
    </div>
  );
};

export const renderCustomField = ({
  checked,
  defaultChecked,
  input,
  meta: { touched, error, warning },
  ...custom
}) => (
  <Fragment>
    <CustomInput
      {...(touched ? { valid: error } : {})}
      {...input}
      {...custom}
      defaultChecked={defaultChecked}
      checked={checked}
    />
    {error && <FormFeedback className="text-danger">{error}</FormFeedback>}
    {!error && warning && (
      <FormText className="text-danger">{warning}</FormText>
    )}
  </Fragment>
);

export const phoneNumberField = ({
  defaultCountry,
  input,
  className,
  international,
  placeholder,
  errorClassName,
  meta: { touched, error, warning },
}) => {
  const setNumericInputMode = (event) => {
    event.target.setAttribute("inputmode", "text");
  };
  return (
    <Fragment>
      <PhoneInput
        className={className}
        international={international}
        placeholder={placeholder}
        defaultCountry={defaultCountry}
        value={input.value || ""}
        onChange={input.onChange}
        country="US"
        onFocus={setNumericInputMode} // Set inputmode attribute to 'numeric' on focus
        onBlur={(e) => e.target.removeAttribute("inputmode")} // Remove inputmode attribute on blur
      />
      {touched &&
        (error ? (
          <span className={`mr-5 form-error d-flex ${errorClassName}`}>
            {error}
          </span>
        ) : warning ? (
          <span>{warning}</span>
        ) : null)}
    </Fragment>
  );
};
export const selectField = ({
  children,
  disabled,
  input,
  id,
  min,
  maxlength,
  placeholder,
  required,
  isError,
  errorClassName,
  parentDivClassName,
  meta: { touched, error, warning },
}) => {
  return (
    <>
      <div className={parentDivClassName}>
        <Input
          {...input}
          min={min}
          className={
            isError || (touched && error)
              ? "name-info-input form-control error-inputs"
              : "name-info-input form-control"
          }
          type="select"
          required={required}
          disabled={disabled}
          maxLength={maxlength}
          placeholder={placeholder}
          autoComplete="off"
          id={id}
        >
          <option selected value={""}>
            {placeholder}
          </option>
          {children}
        </Input>
        {touched &&
          (error ? (
            <span className={`mr-5 form-error d-flex ${errorClassName}`}>
              {error}
            </span>
          ) : warning ? (
            <span>{warning}</span>
          ) : null)}
      </div>
      <img src={DropdownIcon} alt="dropdown" className="dropdown-icon" />
    </>
  );
};
