import {
  CREATE_CHATBOT_ERROR,
  CREATE_CHATBOT_REQUEST,
  CREATE_CHATBOT_SUCCESS,
} from "./actionTypes";

export const createChatbotAction = (user) => {
  return {
    type: CREATE_CHATBOT_REQUEST,
    payload: user,
  };
};

export const createChatbotSuccess = (user, history) => {
  return {
    type: CREATE_CHATBOT_SUCCESS,
    payload: { user, history },
  };
};

export const createChatbotError = (history) => {
  return {
    type: CREATE_CHATBOT_ERROR,
    payload: { history },
  };
};
