import React, { memo } from "react";
import { ButtonGroup } from "reactstrap";
import PropTypes from "prop-types";

import { AnimatedButton } from "../common/components";
import { logAnalyticsEvent } from "../utils";
import TRANSLATIONS from "../translations/en.json";
import "./ButtonSwitch.css";

function ButtonSwitch({ eventName, isActive, index, handleToggle }) {
  return (
    <ButtonGroup className="btn-toggle">
      <AnimatedButton
        type="button"
        onClick={() => {
          logAnalyticsEvent(eventName);
          handleToggle(index);
        }}
        className={`switch-btn ${isActive ? "active-btn" : "grey-btn"}`}
      >
        {TRANSLATIONS.ACCOUNT_SETTINGS.ON}{" "}
      </AnimatedButton>
      <AnimatedButton
        type="button"
        onClick={() => {
          logAnalyticsEvent(eventName);
          handleToggle(index);
        }}
        className={`switch-btn ${!isActive ? "active-btn" : "grey-btn"}`}
      >
        {TRANSLATIONS.ACCOUNT_SETTINGS.OFF}
      </AnimatedButton>
    </ButtonGroup>
  );
}
ButtonSwitch.propTypes = {
  eventName: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
  index: PropTypes.number,
  handleToggle: PropTypes.func,
};
export default memo(ButtonSwitch);
