import {
  CREATE_NOTES_ERROR,
  CREATE_NOTES_REQUEST,
  CREATE_NOTES_SUCCESS,
  GET_NOTE_ERROR,
  GET_NOTE_REQUEST,
  GET_NOTE_SUCCESS,
  UPDATE_NOTES_ERROR,
  UPDATE_NOTES_REQUEST,
  UPDATE_NOTES_SUCCESS,
} from "./actionTypes";

const initialState = {};

const UserNotes = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTE_REQUEST:
      return {
        ...state,
        isGetUserNotesLoading: true,
        userNotesData: false,
        userNotesError: false,
      };
    case GET_NOTE_SUCCESS:
      return {
        ...state,
        isGetUserNotesLoading: false,
        userNotesData: action.data?.response,
        userNotesError: false,
      };
    case GET_NOTE_ERROR:
      return {
        ...state,
        isGetUserNotesLoading: false,
        userNotesData: false,
        userNotesError: action.error.error,
      };
    case CREATE_NOTES_REQUEST:
      return {
        ...state,
        isCreateUserNotesLoading: true,
        createNotesData: false,
        createNotesError: false,
      };
    case CREATE_NOTES_SUCCESS:
      return {
        ...state,
        isCreateUserNotesLoading: false,
        createNotesData: action.data?.response,
        createNotesError: false,
      };
    case CREATE_NOTES_ERROR:
      return {
        ...state,
        isCreateUserNotesLoading: false,
        createNotesData: false,
        createNotesError: action.error.error,
      };
    case UPDATE_NOTES_REQUEST:
      return {
        ...state,
        isUpdateUserNotesLoading: true,
        updateNotesData: false,
        updateNotesError: false,
      };
    case UPDATE_NOTES_SUCCESS:
      return {
        ...state,
        isUpdateUserNotesLoading: false,
        updateNotesData: action.data?.response,
        updateNotesError: false,
      };
    case UPDATE_NOTES_ERROR:
      return {
        ...state,
        isUpdateUserNotesLoading: false,
        updateNotesData: false,
        updateNotesError: action.error.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserNotes;
