import {
  USER_DETAILS_ERROR,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
} from "./actionTypes";

const initialState = {};

const UserDetails = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        userDetailsRequestData: true,
        userDetailsData: false,
        userDetailsError: false,
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetailsRequestData: false,
        userDetailsData: action.payload.user.data,
        userDetailsError: false,
      };
    case USER_DETAILS_ERROR:
      return {
        ...state,
        userDetailsRequestData: false,
        userDetailsData: false,
        userDetailsError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UserDetails;
