import {
  INVITE_TEAM_REQUEST,
  INVITE_TEAM_SUCCESS,
  INVITE_TEAM_ERROR,
} from "./actionTypes";

const initialState = {};

const InviteTeamMembers = (state = initialState, action) => {
  switch (action.type) {
    case INVITE_TEAM_REQUEST:
      return {
        ...state,
        inviteTeamMembersRequestData: true,
        inviteTeamMembersData: false,
        inviteTeamMembersError: false,
      };
    case INVITE_TEAM_SUCCESS:
      return {
        ...state,
        inviteTeamMembersRequestData: false,
        inviteTeamMembersData: action.payload.user.data,
        inviteTeamMembersError: false,
      };
    case INVITE_TEAM_ERROR:
      return {
        ...state,
        inviteTeamMembersRequestData: false,
        inviteTeamMembersData: false,
        inviteTeamMembersError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InviteTeamMembers;
