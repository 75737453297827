import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./Landing.css";

import { AnimatedButton } from "../../common/components";
import { CHEMICALS, ROUTE_PATH } from "../../common/constants";
import Righticon from "../../assets/RightArrow.svg";
import Slider from "./Slider";
import TRANSLATIONS from "../../translations/en.json";

function Landing() {
  const history = useHistory();
  const [activeChemical, setActiveChemical] = useState(0);

  const onChange = (index) => {
    setActiveChemical(Number(index));
  };
  const handleChemical = (index) => {
    setTimeout(() => {
      document
        .getElementById("chemicals-desc")
        .scrollIntoView({ behavior: "smooth" });
    }, 100);
    setTimeout(() => {
      setActiveChemical(Number(index));
    }, 800);
  };

  const boldActiveChemical = (key, item) => {
    if (Number(activeChemical === key)) {
      return (
        <h6 className="boldChemicalNameLand mt-2 text-center text-capitalize">
          {item.alt}
        </h6>
      );
    } else {
      return (
        <h6 className="chemicalNameLand mt-2 text-center text-capitalize">
          {item.alt}
        </h6>
      );
    }
  };
  const handleDose = () => {
    window.scrollTo(0, 0);
    history.push(ROUTE_PATH.FAQS);
  };
  return (
    <>
      <div className="" id="dose-page">
        <div>
          <div className="container-fluid p-0 dose-overlay-img">
            <div className="row dose-overlay">
              <div className="col-12 col-lg-10 col-md-12 margin">
                <div className="branding-section header">
                  {TRANSLATIONS.WHAT_IS_DOSE.TITLE_PART1}
                  <br />
                  {TRANSLATIONS.WHAT_IS_DOSE.TITLE_PART2}
                </div>
                <div className="branding-section description">
                  {TRANSLATIONS.WHAT_IS_DOSE.DESCRIPTION}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid p-0 doseText">
            <div className="row">
              <div className="col-12">
                <div className="happy-dose-text">
                  {TRANSLATIONS.WHAT_IS_DOSE.HAPPYDOSE_TEXT}
                  <br />
                  <br />
                  {TRANSLATIONS.WHAT_IS_DOSE.TAKE_DEEP_DIVE}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0 land-section">
          <Row>
            <Col lg={10} className="mx-auto">
              <Row>
                {CHEMICALS.map((item, key) => (
                  <Col
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    key={item.src}
                    className={`dose-chem ${
                      Number(activeChemical) === key
                        ? "pointer-events-none"
                        : " cursor-pointer"
                    }`}
                    onClick={() => {
                      handleChemical(key);
                    }}
                  >
                    <AnimatedButton
                      disabled={Number(activeChemical) === key}
                      className="transparent-btn"
                    >
                      <img
                        src={item.src}
                        alt={`${item.alt}`}
                        className="landChemical"
                      />
                      {boldActiveChemical(key, item)}
                    </AnimatedButton>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
        <div className="" id="chemicals-desc">
          <Slider onChange={onChange} activeChemical={activeChemical} />
        </div>

        <div className="justify-align-center my-5">
          <AnimatedButton
            onClick={() => handleDose()}
            color="secondary"
            className="flow-button"
          >
            {TRANSLATIONS.HOME_PAGE.WHAT_IS_DOSE}
            <img src={Righticon} alt="icon" className="" />
          </AnimatedButton>
        </div>
      </div>
    </>
  );
}

export default Landing;
