import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ActivityCard from "./ActivityCard";
import TRANSLATIONS from "../translations/en.json";
import { ReactComponent as TotalActivities } from "../assets/Dashboard/Total_Activities.svg";
import { ReactComponent as Check } from "../assets/Dashboard/Empty_Check.svg";
import { useSelector } from "react-redux";
import {
  getPercentageText,
  getTotalChemicalsData,
  getPercentageByDataComparision,
} from "../utils";

function TotalActivitiesCard({ isEmptyDashboard }) {
  const [currentchemicalData, setCurrentChemicalData] = useState([]);
  const [pastchemicalData, setPastChemicalData] = useState([]);
  const [activitiesCardPercentage, setActivitiesCardPercentage] = useState(0);

  const { activitiesPerChemicalData, pastActivitiesPerChemicalData } =
    useSelector((state) => state.ActivitiesPerChemical);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (activitiesPerChemicalData) {
      setCurrentChemicalData(activitiesPerChemicalData);
    }
    if (pastActivitiesPerChemicalData) {
      setPastChemicalData(pastActivitiesPerChemicalData);
    }
  }, [activitiesPerChemicalData, pastActivitiesPerChemicalData]);

  const pastWeekChemicalsData = getTotalChemicalsData(pastchemicalData);
  const { total } = getTotalChemicalsData(currentchemicalData);

  useEffect(() => {
    if (total >= 0 && pastWeekChemicalsData) {
      setActivitiesCardPercentage(
        getPercentageByDataComparision(total, pastWeekChemicalsData.total)
      );
    }
  }, [total, pastWeekChemicalsData]);

  const { percentageText, icon } = getPercentageText(
    { total },
    pastWeekChemicalsData,
    "total"
  );

  return (
    <ActivityCard
      header={TRANSLATIONS.USER_DASHBOARD.TOTAL_ACTIVITIES}
      Icon={isEmptyDashboard ? Check : TotalActivities}
      value={total}
      isEmptyDashboard={isEmptyDashboard}
      description={`${icon}${
        activitiesCardPercentage > 0 ? activitiesCardPercentage : ""
      }${percentageText}`}
    />
  );
}
TotalActivitiesCard.propTypes = {
  isEmptyDashboard: PropTypes.bool,
};
export default TotalActivitiesCard;
