import { all } from "redux-saga/effects";
import ActivitiesPerChemicalSaga from "./activitiesPerChemical/saga";
import ActivitiesPerTeamMemberSaga from "./activitiesPerTeamMember/saga";
import AdminProfileSaga from "./adminProfile/saga";
import CheckInSaga from "./checkIn/saga";
import GetStartedSaga from "./combineLoginSignup/saga";
import ContactUsSaga from "./contactUs/saga";
import DailyUsersSaga from "./dailyUsers/saga";
import DashBoardSetupSaga from "./dashBoardSetup/saga";
import DashboardActivitiesSaga from "./dashboardActivities/saga";
import DownloadSaga from "./download/saga";
import EnterpriseLogoSaga from "./enterpriseLogo/saga";
import HpyScoreSaga from "./hpyScore/saga";
import InviteTeamMembersSage from "./inviteTeam/saga";
import UserNotesSaga from "./keyNotes/saga";
import LoginSaga from "./login/saga";
import UpdateProfileSaga from "./myProfile/saga";
import VerifyOtpSaga from "./otp/saga";
import VerifyAccessSaga from "./otp/userOrAdmin/saga";
import UserDetailsSaga from "./overallScreen/saga";
import ProfilePictureSaga from "./profilePicture/saga";
import RequestAccessSaga from "./requestAccess/saga";
import RequestDemoSaga from "./requestDemo/saga";
import SignUpSaga from "./signUp/saga";
import TasksSaga from "./task/saga";
import TeamSettingsSaga from "./teamSettings/saga";
import TimelineSaga from "./timeline/saga";
import ChatBotSaga from "./chatbot/saga";

export default function* rootSaga() {
  yield all([
    AdminProfileSaga(),
    ActivitiesPerChemicalSaga(),
    ActivitiesPerTeamMemberSaga(),
    CheckInSaga(),
    DailyUsersSaga(),
    ContactUsSaga(),
    DashBoardSetupSaga(),
    DashboardActivitiesSaga(),
    DownloadSaga(),
    EnterpriseLogoSaga(),
    GetStartedSaga(),
    HpyScoreSaga(),
    InviteTeamMembersSage(),
    LoginSaga(),
    ProfilePictureSaga(),
    RequestAccessSaga(),
    RequestDemoSaga(),
    SignUpSaga(),
    TasksSaga(),
    TeamSettingsSaga(),
    TimelineSaga(),
    UserDetailsSaga(),
    UserNotesSaga(),
    UpdateProfileSaga(),
    VerifyAccessSaga(),
    VerifyOtpSaga(),
    ChatBotSaga(),
  ]);
}
