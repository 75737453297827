import {
  INVITE_TEAM_ERROR,
  INVITE_TEAM_REQUEST,
  INVITE_TEAM_SUCCESS,
} from "./actionTypes";

export const inviteTeamRequest = (user) => {
  return {
    type: INVITE_TEAM_REQUEST,
    payload: user,
  };
};

export const inviteTeamSuccess = (user, history) => {
  return {
    type: INVITE_TEAM_SUCCESS,
    payload: { user, history },
  };
};

export const inviteTeamError = (history) => {
  return {
    type: INVITE_TEAM_ERROR,
    payload: { history },
  };
};
