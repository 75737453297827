import { isValidPhoneNumber } from "react-phone-number-input";
import { EMAIL_REGEX, MOBILE_REGEX, NAME_REGEX } from "../constants";
import TRANSLATIONS from "./../../translations/en.json";

export const validateEmail = (value) =>
  value && !EMAIL_REGEX.test(value)
    ? TRANSLATIONS.COMMON.INVALID_EMAIL
    : undefined;

export const PhoneInputValidation = (value) => {
  return value && !isValidPhoneNumber(value)
    ? TRANSLATIONS.COMMON.INVALID_NUMBER
    : undefined;
};
export const mobileValidation = (value) => {
  return value && !MOBILE_REGEX.test(value.replace(/\u202c/g, ""))
    ? TRANSLATIONS.COMMON.INVALID_NUMBER
    : undefined;
};
export const required = (value) => {
  return !value ? TRANSLATIONS.ADMIN_PROFILE_FORM.REQUIRED : undefined;
};
export const validateName = (value) => {
  return value && NAME_REGEX.test(value)
    ? TRANSLATIONS.ADMIN_PROFILE_FORM.NUMERIC_ERROR
    : undefined;
};
