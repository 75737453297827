import {
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  TEAM_SETTINGS_REQUEST,
  TEAM_SETTINGS_SUCCESS,
  TEAM_SETTINGS_ERROR,
  DELETE_TEAM_REQUEST,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
  DELETE_TEAM_QUESTIONS_REQUEST,
  DELETE_TEAM_QUESTIONS_SUCCESS,
  DELETE_TEAM_QUESTIONS_ERROR,
  NOTIFICATIONS_SETTINGS_REQUEST,
  NOTIFICATIONS_SETTINGS_SUCCESS,
  NOTIFICATIONS_SETTINGS_ERROR,
} from "./actionTypes";

const initialState = {};

const teamSettings = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MEMBER_REQUEST:
      return {
        ...state,
        deleteMemberRequestData: true,
        deleteMemberData: false,
        deleteMemberError: false,
      };
    case DELETE_MEMBER_SUCCESS:
      return {
        ...state,
        deleteMemberRequestData: false,
        deleteMemberData: action.payload.user.data,
        deleteMemberError: false,
      };
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        deleteMemberRequestData: false,
        deleteMemberData: false,
        deleteMemberError: action.payload.history.error,
      };
    case TEAM_SETTINGS_REQUEST:
      return {
        ...state,
        teamSettigsRequestData: true,
        teamSettigsData: false,
        teamSettigsError: false,
      };
    case TEAM_SETTINGS_SUCCESS:
      return {
        ...state,
        teamSettigsRequestData: false,
        teamSettigsData: action.payload.user.data,
        teamSettigsError: false,
      };
    case TEAM_SETTINGS_ERROR:
      return {
        ...state,
        teamSettigsRequestData: false,
        teamSettigsData: false,
        teamSettigsError: action.payload.history.error,
      };
    case DELETE_TEAM_REQUEST:
      return {
        ...state,
        isDeleteTeaRequestDataLoading: true,
        deleteTeamData: false,
        deleteTeamError: false,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        isDeleteTeaRequestDataLoading: false,
        deleteTeamData: action.payload.user.data,
        deleteTeamError: false,
      };
    case DELETE_TEAM_ERROR:
      return {
        ...state,
        isDeleteTeaRequestDataLoading: false,
        deleteTeamData: false,
        deleteTeamError: action.payload.history.error,
      };
    case DELETE_TEAM_QUESTIONS_REQUEST:
      return {
        ...state,
        deleteTeamQuestionsRequestData: true,
        deleteTeamQuestionsData: false,
        deleteTeamQuestionsError: false,
      };
    case DELETE_TEAM_QUESTIONS_SUCCESS:
      return {
        ...state,
        deleteTeamQuestionsRequestData: false,
        deleteTeamQuestionsData: action.payload.user.data,
        deleteTeamQuestionsError: false,
      };
    case DELETE_TEAM_QUESTIONS_ERROR:
      return {
        ...state,
        deleteTeamQuestionsRequestData: false,
        deleteTeamQuestionsData: false,
        deleteTeamQuestionsError: action.payload.history.error,
      };
    case NOTIFICATIONS_SETTINGS_REQUEST:
      return {
        ...state,
        notificationSettigsRequestData: true,
        notificationSettigsData: false,
        notificationSettigsError: false,
      };
    case NOTIFICATIONS_SETTINGS_SUCCESS:
      return {
        ...state,
        notificationSettigsRequestData: false,
        notificationSettigsData: action.payload.user.data,
        notificationSettigsError: false,
      };
    case NOTIFICATIONS_SETTINGS_ERROR:
      return {
        ...state,
        notificationSettigsRequestData: false,
        notificationSettigsData: false,
        notificationSettigsError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default teamSettings;
