import * as Sentry from "@sentry/react";

export const checkHttpStatus = async (response) => {
  if (response.status >= 400 && response.status < 600) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  } else {
    Sentry.captureException(response);
    return response;
  }
};
