export const DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_ERROR = "DELETE_MEMBER_ERROR";

export const TEAM_SETTINGS_REQUEST = "TEAM_SETTINGS_REQUEST";
export const TEAM_SETTINGS_SUCCESS = "TEAM_SETTINGS_SUCCESS";
export const TEAM_SETTINGS_ERROR = "TEAM_SETTINGS_ERROR";

export const DELETE_TEAM_REQUEST = "DELETE_TEAM_REQUEST";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";
export const DELETE_TEAM_ERROR = "DELETE_TEAM_ERROR";

export const DELETE_TEAM_QUESTIONS_REQUEST = "DELETE_TEAM_QUESTIONS_REQUEST";
export const DELETE_TEAM_QUESTIONS_SUCCESS = "DELETE_TEAM_QUESTIONS_SUCCESS";
export const DELETE_TEAM_QUESTIONS_ERROR = "DELETE_TEAM_QUESTIONS_ERROR";

export const NOTIFICATIONS_SETTINGS_REQUEST = "NOTIFICATIONS_SETTINGS_REQUEST";
export const NOTIFICATIONS_SETTINGS_SUCCESS = "NOTIFICATIONS_SETTINGS_SUCCESS";
export const NOTIFICATIONS_SETTINGS_ERROR = "NOTIFICATIONS_SETTINGS_ERROR";
