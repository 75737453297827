import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import WelcomeTitle from "./WelcomeTitle";
import { capitalize, getDates } from "../utils";
import { Col, Row, Spinner } from "reactstrap";
import AverageHpyScoreCard from "./AverageHpyScoreCard";
import "./index.css";
import LineChart from "./Charts/LineChart";
import StackedChart from "./Charts/StackedCharts";
import RadialBar from "./Charts/RadialBar";
import Users from "./Users";
import {
  activitiesPerChemicalRequest,
  activitiesPerChemicalSuccess,
  activitiesPerTeamMemberRequest,
  getAvgHpyScore,
  getAvgHpyScoreSuccess,
  moodsBreakdownRequest,
  MoodsBreakdownSuccess,
  getAllDailyUsers,
  getAllDailyUsersSuccess,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import LongestStreakCard from "./LongestStreakCard";
import TotalActivitiesCard from "./TotalActivitiesCard";
import PopularChemicalCard from "./PopularChemicalCard";
import DailyReflectionsCard from "./DailyReflectionsCard";
import { TIMEPERIODS } from "../common/constants";


const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Dashboard({ userDetails }) {
  const query = useQuery();
  const dispatch = useDispatch();
  const [timePeriod, setTimePeriod] = useState(TIMEPERIODS.WEEKLY);
  const [periodDates, setPeriodDates] = useState(getDates(timePeriod));
  const [isEmptyDashboard, setIsEmptyDashboard] = useState(true);

  const { isDailyUsersLoading, dailyUsersData } = useSelector(
    (state) => state.DailyUsers
  );

  const clearStoreData = () => {
    dispatch(getAllDailyUsersSuccess({}));
    dispatch(MoodsBreakdownSuccess({}));
    dispatch(activitiesPerChemicalSuccess({}));
    dispatch(getAvgHpyScoreSuccess({}));
  };
  //unmounting
  useEffect(() => {
    // Store subscription status in local storage if present in query parameters
    const subscription = query.get('subscription');
    if (subscription) {
      localStorage.setItem("subscription", subscription);
      localStorage.setItem("login", subscription);
    }
    return () => {
      clearStoreData();
    };
  }, []);

  const { activitiesPerTeamMemberData, isActivitiesPerTeamMemberDataLoading } =
    useSelector((state) => ({
      activitiesPerTeamMemberData:
        state.ActivitiesPerTeamMember.activitiesPerTeamMemberData,
      activitiesPerTeamMemberError:
        state.ActivitiesPerTeamMember.activitiesPerTeamMemberError,
      isActivitiesPerTeamMemberDataLoading:
        state.ActivitiesPerTeamMember.isActivitiesPerTeamMemberDataLoading,
    }));
  useEffect(() => {
    if (
      dailyUsersData?.length ||
      (activitiesPerTeamMemberData?.length === 1 &&
        activitiesPerTeamMemberData.find(
          (e) => e.completed_activities > 0 || e.incompleted_activities > 0
        ))
    ) {
      setIsEmptyDashboard(false);
    } else {
      setIsEmptyDashboard(true);
    }
  }, [dailyUsersData, activitiesPerTeamMemberData]);

  const { isAvgHpyScoreLoading, avgHpyScoreData } = useSelector(
    (state) => state.HpyScore
  );
  const {
    longestStreakRequestData,
    pastLongestStreakRequestData,
    dailyReflectionsRequestData,
    pastDailyReflectionsRequestData,
  } = useSelector((state) => state.DashboardActivities);

  const {
    activitiesPerChemicalRequestData,
    pastActivitiesPerChemicalRequestData,
  } = useSelector((state) => state.ActivitiesPerChemical);

  const refreshDashboard = () => {
    dispatch(
      getAvgHpyScore({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );
    dispatch(
      activitiesPerTeamMemberRequest({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );
    dispatch(
      moodsBreakdownRequest({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );
    dispatch(
      activitiesPerChemicalRequest({
        start_date: periodDates.currentStartDate,
        end_date: periodDates.currentEndDate,
      })
    );

    dispatch(getAllDailyUsers({ isMember: true }));
  };

  useEffect(() => {
    refreshDashboard();
  }, [periodDates]);

  useEffect(() => {
    setPeriodDates(getDates(timePeriod));
  }, [timePeriod]);

  /*------to handle welcome title actions------------*/
  const handleActions = (value) => {
    setTimePeriod(value);
  };
  const activityCardComponents = [
    {
      Card: (
        <LongestStreakCard
          periodDates={periodDates}
          isEmptyDashboard={isEmptyDashboard}
        />
      ),
      className: "activity-left-column pl-0",
    },
    {
      Card: (
        <TotalActivitiesCard
          periodDates={periodDates}
          isEmptyDashboard={isEmptyDashboard}
        />
      ),
      className: "activity-right-column",
    },
    {
      Card: (
        <PopularChemicalCard
          periodDates={periodDates}
          isEmptyDashboard={isEmptyDashboard}
        />
      ),
      className: "activity-left-column activity-col",
    },
    {
      Card: (
        <DailyReflectionsCard
          periodDates={periodDates}
          isEmptyDashboard={isEmptyDashboard}
        />
      ),
      className: "activity-right-column activity-col pr-0",
    },
  ];
  return (
    <div>
      {(activitiesPerChemicalRequestData ||
        dailyReflectionsRequestData ||
        isActivitiesPerTeamMemberDataLoading ||
        isAvgHpyScoreLoading ||
        longestStreakRequestData ||
        pastLongestStreakRequestData ||
        pastDailyReflectionsRequestData ||
        pastActivitiesPerChemicalRequestData ||
        isDailyUsersLoading) && (
        <div className="m-auto">
          <Spinner className="loader-container dashboard-loader overlay-adminScreen" />
        </div>
      )}

      <WelcomeTitle
        isDisabled={isEmptyDashboard}
        timePeriod={timePeriod}
        handleActions={handleActions}
        refreshDashboard={() => {
          clearStoreData();
          setPeriodDates(getDates(timePeriod));
          refreshDashboard();
          setIsEmptyDashboard(true);
          dispatch(getAllDailyUsers({ isMember: true }));
        }}
        teamName={userDetails?.teamName ? capitalize(userDetails.teamName) : ""}
      />
      <div className="dashboard">
        <Row className="cards-row">
          {activityCardComponents.map(({ Card, className }) => {
            return (
              <Col
                key={className}
                lg={3}
                md={3}
                sm={6}
                xs={6}
                className={className}
              >
                {Card}
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12} className="graph-left-columns">
            <AverageHpyScoreCard
              score={avgHpyScoreData?.averageHappinessScore}
              isEmptyDashboard={isEmptyDashboard}
            />
            <div className="mobile-section">
              <Users dailyUsersData={dailyUsersData} />
            </div>
            <LineChart timePeriod={timePeriod} />
            <div className="mt-3">
              <RadialBar />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12} className="graph-right-columns">
            <div className="web-section">
              <Users dailyUsersData={dailyUsersData} />
            </div>
            <StackedChart />
          </Col>
        </Row>
      </div>
    </div>
  );
}
Dashboard.propTypes = {
  userDetails: PropTypes.object,
};
export default Dashboard;
