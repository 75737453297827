import {
  DASHBOARD_SETUP_ERROR,
  DASHBOARD_SETUP_REQUEST,
  DASHBOARD_SETUP_SUCCESS,
} from "./actionTypes";

export const dashBoardSetupRequest = (user) => {
  return {
    type: DASHBOARD_SETUP_REQUEST,
    payload: user,
  };
};

export const dashBoardSetupSuccess = (user, history) => {
  return {
    type: DASHBOARD_SETUP_SUCCESS,
    payload: { user, history },
  };
};

export const dashBoardSetupError = (history) => {
  return {
    type: DASHBOARD_SETUP_ERROR,
    payload: { history },
  };
};
