import React from "react";
import { useHistory } from "react-router-dom";

import "./EntPgeSignUp.css";
import { AnimatedButton } from "../common/components";
import {
  isDashboardDataAvailable,
  isUserAuthenticated,
  logAnalyticsEvent,
} from "../utils";
import { ANALYTICS_EVENTS_JSON, ROUTE_PATH } from "../common/constants";
import TRANSLATIONS from "../translations/en.json";

export default function EntSignUp() {
  const history = useHistory();

  const handleSignUp = () => {
    if (isUserAuthenticated()) {
      logAnalyticsEvent(ANALYTICS_EVENTS_JSON.GET_STARTED);
      history.push(
        isDashboardDataAvailable()
          ? ROUTE_PATH.DASHBOARD
          : ROUTE_PATH.TEAM_MEMBERS
      );
    } else {
      logAnalyticsEvent(ANALYTICS_EVENTS_JSON.SIGNUP_BTN);
      history.push(ROUTE_PATH.GETSTARTED);
    }
  };
  return (
    <div className="signup-container">
      <div className="signup-intro-text mb-0">
        <p className="signup-text">{TRANSLATIONS.ENT_PGE_SIGNUP.SIGNUP_TEXT}</p>
      </div>
      <div className="signup-button">
        <AnimatedButton
          className="btn btn-small btn-signUp"
          onClick={() => handleSignUp()}
        >
          {isUserAuthenticated()
            ? TRANSLATIONS.COMMON.TAKE_ME_TO_DASHBOARD
            : TRANSLATIONS.COMMON.SIGN_UP_ENTERPRISE}
        </AnimatedButton>
      </div>
    </div>
  );
}
