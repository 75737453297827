import React, { useEffect, useRef } from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import RequestDemo from "./RequestDemo";
import "./RequestDemoModal.css";
import { ReactComponent as Close } from "../assets/closeIcon.svg";
function RequestDemoModal({ isOpen, toggle }) {
  const { isRequestDemoLoading, requestDemoData } = useSelector(
    (state) => state.RequestDemo
  );

  const isRequestingDemo = useRef(true);
  useEffect(() => {
    if (isRequestingDemo.current) {
      isRequestingDemo.current = false;
      return;
    }
    if (!isRequestDemoLoading && requestDemoData) {
      toggle();
    }
  }, [isRequestDemoLoading, requestDemoData]);

  return (
    <Modal className="request-demo-modal" isOpen={isOpen} toggle={toggle}>
      <Close
        className="request-demo-close"
        fill={"#fff"}
        stroke="#fff"
        onClick={() => toggle()}
      />
      <RequestDemo toggle={toggle} />
    </Modal>
  );
}
RequestDemoModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};
export default RequestDemoModal;
