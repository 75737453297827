import { PROFILE_REQUEST, PROFILE_SUCCESS, PROFILE_ERROR } from "./actionTypes";

const initialState = {};

const ProfilePicture = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_REQUEST:
      return {
        ...state,
        profilePictureRequestData: true,
        profilePictureData: false,
        profilePictureError: false,
      };
    case PROFILE_SUCCESS:
      return {
        ...state,
        profilePictureRequestData: false,
        profilePictureData: action.payload.user.data,
        profilePictureError: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        profilePictureRequestData: false,
        profilePictureData: false,
        profilePictureError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ProfilePicture;
