import { showError } from "./toastify";

export async function startRecording(setRecorderState) {
  let stream;
  try {
    stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });

    setRecorderState((prevState) => {
      return {
        ...prevState,
        initRecording: true,
        mediaStream: stream,
      };
    });
  } catch (err) {
    console.log("Error accessing the microphone:", err);
    showError(
      "Please enable microphone permissions to access the voice recorder in your browser."
    );

    setRecorderState((prevState) => {
      return {
        ...prevState,
        initRecording: false,
      };
    });
  }
}

export function saveRecording(recorder) {
  if (recorder && recorder.state !== "inactive") {
    recorder.stop();
  }
}
