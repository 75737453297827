import PropTypes from "prop-types";
import React, { lazy, memo, Suspense } from "react";
import { Col, Input, Row } from "reactstrap";

import { ReactComponent as SearchIcon } from "../../assets/SearchIcon.svg";
import { CHEMICALS } from "../../common/constants";
import TRANASLATION from "../../translations/en.json";
import "./index.css";

// lazy loading components
const TaskScreenList = lazy(() => import("./TaskScreenList"));

function TaskScreen({
  assignedTask,
  getListingApi,
  handleSearchText,
  searchText,
  setAssignedTask,
  selectedFiltersPayload,
  setTaskListData,
  taskListData,
}) {
  return (
    <Suspense fallback="">
      <div className="tasks-container mt-4 m-3">
        <Row className="task-header-container">
          <Col xs={12} md={5} lg={6} className="task-list-header p-0 mb-1">
            {TRANASLATION.ASSIGN_TASK.FILTER_FOR_TASK}
          </Col>
          <Col
            xs={12}
            md={7}
            lg={6}
            className="task-main-chemical-container p-0 mb-1"
          >
            {CHEMICALS?.map((item, key) => (
              <div key={key} className="task-chemical-container">
                <img src={item.src} alt={item?.alt} width={20} height={20} />
                <h3 className="task-chemical-name m-0">{item.alt}</h3>
              </div>
            ))}
          </Col>
        </Row>
        <div className="search-container">
          <Input
            placeholder={TRANASLATION.ASSIGN_TASK.SEARCH}
            className="search-input"
            value={searchText}
            onChange={(e) => handleSearchText(e.target.value)}
          />
          <SearchIcon className="task-search-icons" width={18} height={18} />
        </div>
        <TaskScreenList
          assignedTask={assignedTask}
          getListingApi={getListingApi}
          setAssignedTask={setAssignedTask}
          selectedFiltersPayload={selectedFiltersPayload}
          setTaskListData={setTaskListData}
          taskListData={taskListData}
        />
      </div>
    </Suspense>
  );
}

TaskScreen.propTypes = {
  assignedTask: PropTypes.array,
  getListingApi: PropTypes.func,
  handleSearchText: PropTypes.func,
  searchText: PropTypes.string,
  setAssignedTask: PropTypes.func,
  selectedFiltersPayload: PropTypes.object,
  setTaskListData: PropTypes.func,
  taskListData: PropTypes.array.isRequired,
};
export default memo(TaskScreen);
