import {
  DAILY_USERS_REQUEST,
  DAILY_USERS_SUCCESS,
  DAILY_USERS_ERROR,
} from "./actionTypes";

const initialState = {};

const teamSettings = (state = initialState, action) => {
  switch (action.type) {
    case DAILY_USERS_REQUEST:
      return {
        ...state,
        isdailyUsersLoading: true,
        dailyUsersData: false,
        dailyUsersError: false,
      };
    case DAILY_USERS_SUCCESS:
      return {
        ...state,
        isdailyUsersLoading: false,
        dailyUsersData: action.response?.data,
        dailyUsersError: false,
      };
    case DAILY_USERS_ERROR:
      return {
        ...state,
        isdailyUsersLoading: false,
        dailyUsersData: false,
        dailyUsersError: action.error.error,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default teamSettings;
