import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { Field, reduxForm, reset } from "redux-form";
import PropTypes from "prop-types";

import "./Main.css";

import { ANALYTICS_EVENTS_JSON } from "../common/constants";
import AnimatedButton from "../common/components/AnimatedButton";
import BlackSun from "../assets/WebpImages/BlackSun.webp";
import { downloadRequest } from "../redux/actions";
import HappyPlaceScreen from "../assets/WebpImages/HappyPlaceScreen.webp";
import HappyScreenMobile from "../assets/WebpImages/HappyScreenMobile.webp";
import { phoneNumberField } from "../common/reduxFields";
import { ReactComponent as WhiteSwoosh } from "../assets/white_swoosh.svg";
import Righticon from "../assets/RightArrow.svg";
import TRANSLATIONS from "../translations/en.json";
import { showError, showSuccess, logAnalyticsEvent } from "../utils";

const Main = function (props) {
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const dispatch = useDispatch();

  const { handleSubmit } = props;

  const onSubmit = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.TRY_FREE_BTN);
    const formData = {
      mobileNumber: mobileNumber.nationalNumber,
      countryCode: countryCode,
    };
    dispatch(downloadRequest(formData));
  };

  //state which works as map state to props
  const { downloadData, downloadRequestData, downloadError } = useSelector(
    (state) => state.Download
  );

  const FirstRun = useRef(true);

  //to download api response
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (downloadRequestData) {
      setIsLoading(true);
    } else if (downloadData) {
      dispatch(reset("Main"));
      setIsLoading(false);
      showSuccess(downloadData);
      setMobileNumber("");
    } else if (downloadError) {
      setIsLoading(false);
      showError(TRANSLATIONS.COMMON.MOBILE_WRONG_NUMBER_ERROR);
    }
  }, [downloadRequestData, downloadData]);

  const handleChange = (value) => {
    let phoneNumber;
    if (value) {
      phoneNumber = parsePhoneNumber(value);
    }
    if (phoneNumber) {
      setCountryCode(phoneNumber.country);
    }

    setMobileNumber(phoneNumber);
  };

  const renderHeader = () => {
    return (
      <>
        {" "}
        <h2 className="simple-science-header">
          {TRANSLATIONS.HOME_PAGE.SIMPLE_SCIENCE}
        </h2>
        <h2 className="simple-science-header">
          {TRANSLATIONS.HOME_PAGE.HAPPINESS}
        </h2>
        <br />
        <h2 className="sub-header">{TRANSLATIONS.HOME_PAGE.MAKE_HAPPINESS}</h2>
      </>
    );
  };
  return (
    <>
      <div id="home-page">
        <div className="simple-science-section">
          <Row className="hpy-row web-section">
            <Col md={7} sm={6} lg={7} className="hpy-text-section">
              {renderHeader()}
              <h2 className="input-header">
                {" "}
                {TRANSLATIONS.HOME_PAGE.HAPPINESS_IN_HANDS}
              </h2>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="phone-input-section d-sm-flex pt-1"
              >
                <div>
                  <Field
                    international={true}
                    countryCallingCodeEditable={false}
                    name="mobileNumber"
                    placeholder={
                      TRANSLATIONS.DASHBOARD_SETUP.PHONE_NUMBER_WITH_CODE
                    }
                    defaultCountry="US"
                    value={mobileNumber?.nationalNumber}
                    component={phoneNumberField}
                    onChange={handleChange}
                  />
                  {mobileNumber && !isValidPhoneNumber(mobileNumber.number) && (
                    <span className="form-error">
                      {TRANSLATIONS.COMMON.INVALID_NUMBER}
                    </span>
                  )}
                </div>

                <AnimatedButton
                  type="submit"
                  disabled={
                    isLoading ||
                    !mobileNumber ||
                    (mobileNumber && !isValidPhoneNumber(mobileNumber.number))
                  }
                  className="download-btn try-free-btn"
                >
                  {TRANSLATIONS.HOME_PAGE.TRY_FOR_FREE}

                  {isLoading ? (
                    <Spinner
                      className="spinner-mv"
                      size="md"
                      color="outline secondary"
                    />
                  ) : (
                    <img
                      src={Righticon}
                      alt="icon"
                      className="try-free-flow-icon"
                    />
                  )}
                </AnimatedButton>
              </form>
            </Col>

            <Col md={5} sm={6} lg={5}>
              <div className="white-sun-img">
                <img
                  src={HappyPlaceScreen}
                  alt="rectangle"
                  className="hpy-place-img"
                />
              </div>
            </Col>
          </Row>
          <div className="mobile-section">
            <div className="hpy-row ">
              <Col md={12} sm={12} lg={12} className="hpy-text-section">
                {renderHeader()}
              </Col>
            </div>
            <Row className="hpy-col">
              <Col xs={7} className="pl-0">
                <img src={BlackSun} alt="logo" className="home-black-sun " />
                <h2 className="input-header mb-3">
                  {" "}
                  {TRANSLATIONS.HOME_PAGE.HAPPINESS_IN_HANDS}
                </h2>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="phone-input-section d-sm-flex pt-1"
                >
                  <div>
                    <Field
                      international={true}
                      countryCallingCodeEditable={false}
                      name="mobileNumber"
                      placeholder={
                        TRANSLATIONS.DASHBOARD_SETUP.PHONE_NUMBER_WITH_CODE
                      }
                      defaultCountry="US"
                      value={mobileNumber?.nationalNumber}
                      component={phoneNumberField}
                      onChange={handleChange}
                    />
                    {mobileNumber &&
                      !isValidPhoneNumber(mobileNumber.number) && (
                        <span className="form-error">
                          {TRANSLATIONS.COMMON.INVALID_NUMBER}
                        </span>
                      )}
                  </div>

                  <Button
                    type="submit"
                    disabled={
                      isLoading ||
                      !mobileNumber ||
                      (mobileNumber && !isValidPhoneNumber(mobileNumber.number))
                    }
                    className="download-btn try-free-btn"
                  >
                    {TRANSLATIONS.HOME_PAGE.TRY_FOR_FREE}

                    {isLoading ? (
                      <Spinner
                        className="spinner-mv"
                        size="md"
                        color="outline secondary"
                      />
                    ) : (
                      <img
                        src={Righticon}
                        alt="icon"
                        className="try-free-flow-icon "
                      />
                    )}
                  </Button>
                </form>
              </Col>

              <Col xs={5} className="pr-0">
                <img
                  src={HappyScreenMobile}
                  alt="hero"
                  className=" hero-img-mobile "
                />
              </Col>
            </Row>
          </div>
          <WhiteSwoosh className="white-swoosh" />
        </div>
      </div>
    </>
  );
};

Main.propTypes = {
  handleSubmit: PropTypes.func,
};

export default connect()(
  reduxForm({
    form: "Main",
  })(memo(Main))
);
