import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";

import "./Navigation.css";

import {
  ANALYTICS_EVENTS_JSON,
  PRO_HEADER_LINKS,
  ROUTE_PATH,
  SUBSCRIPTION_PLAN,
  TEAMS_HEADER_LINKS,
} from "../../common/constants";
import { AnimatedButton } from "../../common/components";
import {
  isDashboardDataAvailable,
  isUserAuthenticated,
  logAnalyticsEvent,
} from "../../utils";
import Hpy from "../../assets/Hpy.svg";
import MenuIcon from "../../assets/hamburgerIcon.svg";
import Sidebar from "./MobileSideBar";
import TRANSLATIONS from "../../translations/en.json";

function NavigationBar() {
  const history = useHistory();
  const DASHBOARD = isUserAuthenticated()
    ? isDashboardDataAvailable()
      ? ROUTE_PATH.DASHBOARD
      : ROUTE_PATH.TEAM_MEMBERS
    : ROUTE_PATH.GETSTARTED;
  const subscription = localStorage.getItem("subscription");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const headerLinks =
    subscription === SUBSCRIPTION_PLAN.FREE
      ? TEAMS_HEADER_LINKS
      : PRO_HEADER_LINKS;

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="light"
      className="authlayout justify-content-center auth-header"
    >
      <Container fluid>
        {/* for mobile devices */}
        {subscription === SUBSCRIPTION_PLAN.FREE ? (
          <div className="p-menu d-lg-none">
            <img
              src={MenuIcon}
              onClick={() => {
                handleToggle();
                logAnalyticsEvent(
                  ANALYTICS_EVENTS_JSON.ENT_HOME_HAMBURGER_MENU
                );
              }}
              className="hamburger-1"
            />
            {sidebarOpen && (
              <div>
                <Sidebar handleToggle={handleToggle} />
                <div className="overlay-sidebar" onClick={handleToggle}></div>
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        <Navbar.Brand className="navBrand ml-lg-5" href="#home">
          {/** COMMENT THIS WHEN DEPLOYING TO PRODUCTION */}
          <Link
            className="navbar-brand ent-nav mr-2 d-flex align-items-center"
            to={ROUTE_PATH.HOME}
          >
            <div className="navbar-brand-icon-text mt-0">
              <img src={Hpy} alt="logoText" className="enterprise-hpy-logo" />
            </div>
          </Link>

          {/** UNCOMMENT THIS WHEN DEPLOYING TO PRODUCTION */}
          {/* <a
            className="navbar-brand ent-nav mr-2 d-flex align-items-center"
            href={"https://www.thinkhpy.com"}
            target="_self" // Optional: Opens the link in a new tab
          >
            <div className="navbar-brand-icon-text mt-0">
              <img src={Hpy} alt="logoText" className="enterprise-hpy-logo" />
            </div>
          </a> */}
        </Navbar.Brand>

        <Navbar.Collapse
          className="justify-content-end mr-5"
          id="responsive-navbar-nav"
        >
          <Nav className="">
            {/** Navigation bar hidding for PRO Login */}
            {localStorage.getItem("subscription") === SUBSCRIPTION_PLAN.FREE ? (
              <>
                {headerLinks.map((item) => {
                  return (
                    <Nav.Link
                      key={item.name}
                      onClick={() => history.push(item.pathname)}
                      className={
                        window.location.pathname === item.pathname
                          ? "nav-tag-bold"
                          : ""
                      }
                    >
                      {item.name}
                    </Nav.Link>
                  );
                })}
                <AnimatedButton
                  className="img-fluid btn ent-hpy-btn ml-4 "
                  onClick={() => {
                    history.push(DASHBOARD);
                    localStorage.setItem("login", "FREE");
                  }}
                >
                  {TRANSLATIONS.COMMON.DASHBOARD}
                </AnimatedButton>
              </>
            ) : (
              ""
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
