import React, { memo } from "react";
import PropTypes from "prop-types";
import "./DashboardWelcome.css";

function DashboardWelcome({ headerTitle, actions, isTask, isMobile }) {
  return (
    <div
      className={isTask ? "task-row" : "welcome-row"}
      style={
        isMobile
          ? {
              flexDirection: "row",
              justifyContent: "start",
              padding: "16px 0px",
            }
          : isTask
          ? { alignItems: "flex-start" }
          : {}
      }
      id="Dashboard-welcome"
    >
      <div className="w-100" style={isMobile ? { flex: 1 } : {}}>
        {headerTitle}
      </div>
      <div
        className="welcome-actions w-100"
        style={isMobile ? { flex: 1 } : {}}
      >
        {actions}
      </div>
    </div>
  );
}
DashboardWelcome.propTypes = {
  actions: PropTypes.node,
  headerTitle: PropTypes.node,
  isTask: PropTypes.bool,
  isMobile: PropTypes.bool,
};
export default memo(DashboardWelcome);
