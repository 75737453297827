import {
  ACTIVITIES_PER_CHEMICAL_REQUEST,
  PAST_ACTIVITIES_PER_CHEMICAL_REQUEST,
} from "./actionTypes";
import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import {
  activitiesPerChemicalError,
  activitiesPerChemicalSuccess,
  pastActivitiesPerChemicalError,
  pastActivitiesPerChemicalSuccess,
} from "./actions";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { checkHttpStatus } from "../../api/apiUtils";

function* activitiesPerChemical(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/dashboard_activity_completion_per_chemical`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(activitiesPerChemicalSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(activitiesPerChemicalError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(activitiesPerChemicalError(errorData));
  }
}

function* pastActivitiesPerChemical(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/dashboard_activity_completion_per_chemical`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(pastActivitiesPerChemicalSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(pastActivitiesPerChemicalError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(pastActivitiesPerChemicalError(errorData));
  }
}

export function* watchActivitiesPerChemical() {
  yield takeEvery(ACTIVITIES_PER_CHEMICAL_REQUEST, activitiesPerChemical);
  yield takeEvery(
    PAST_ACTIVITIES_PER_CHEMICAL_REQUEST,
    pastActivitiesPerChemical
  );
}

function* ActivitiesPerChemicalSaga() {
  yield all([fork(watchActivitiesPerChemical)]);
}
export default ActivitiesPerChemicalSaga;
