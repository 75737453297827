import { AXIOS_INSTANCE, CONTACT_US_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { verifyOtpError, verifyOtpSuccess } from "./actions";

import { VERIFY_OTP_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* verifyOtp(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CONTACT_US_API}/verify`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(verifyOtpSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(verifyOtpError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(verifyOtpError(errorData));
  }
}

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP_REQUEST, verifyOtp);
}

function* VerifyOtpSaga() {
  yield all([fork(watchVerifyOtp)]);
}
export default VerifyOtpSaga;
