import {
  UPDATE_PROFILE_ERROR,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
} from "./actionTypes";

const initialState = {};

const UpdateProfile = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileRequestData: true,
        updateProfileData: false,
        updateProfileError: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updateProfileRequestData: false,
        updateProfileData: action.payload.user.data,
        updateProfileError: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        updateProfileRequestData: false,
        updateProfileData: false,
        updateProfileError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UpdateProfile;
