import React from "react";
import "./EntPgeHero.css";
import entpge_swoosh from "../../src/assets/EnterpriseHomePageWebps/entpge_swoosh.webp";
import TRANSLATIONS from "../translations/en.json";

function EntPgeHero() {
  return (
    <div className="container-fluid heroSection">
      <div className="hero-header">
        <p className="hero-header-text">
          {TRANSLATIONS.ENT_HERO.HAPPY_HOUR_TEXT}
        </p>
      </div>
      <div className="row happy-hour-desc">
        <div className="hero-card-container">
          <div className="hero-card1 mb-0">
            <p className="hero-text-2 mb-0">
              {TRANSLATIONS.ENT_HERO.HAPPY_HOUR_TEXT_2}
            </p>
          </div>
          <div className="happy-hour-image">
            <img
              src={entpge_swoosh}
              alt="happyHourLogo"
              className="entpge-happyHour-logo"
            />
          </div>
          <div className="hero-card2 mb-0">
            <p className="hero-text-3 mb-0">
              {TRANSLATIONS.ENT_HERO.HAPPY_HOUR_TEXT_3}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EntPgeHero;
