import React, { memo } from "react";
import PropTypes from "prop-types";

import DashboardWelcome from "../common/components/DashboardWelcome";
import { getTeamName } from "../utils";
import TRANSLATIONS from "../translations/en.json";

function WelcomeTitle({ teamName }) {
  function renderTitle() {
    return (
      <p className="tech-team-title">
        {TRANSLATIONS.WELCOME_SCREEN.WELCOME_HEADER_1}{" "}
        <span className="font-weight-bold">{teamName || getTeamName()}</span>
        {" Account Settings"}!
      </p>
    );
  }

  return <DashboardWelcome actions={<></>} headerTitle={renderTitle()} />;
}
WelcomeTitle.propTypes = {
  teamName: PropTypes.string.isRequired,
};
export default memo(WelcomeTitle);
