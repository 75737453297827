import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";

import { ANALYTICS_EVENTS_JSON } from "../common/constants";
import { AnimatedButton } from "../common/components";
import { logAnalyticsEvent } from "../utils";
import TRANSLATIONS from "../translations/en.json";
import "./RequestSupportCard.css";

function RequestSupportCard() {
  return (
    <Card className="request-support-card">
      <CardBody>
        <h2 className="activity-header">
          {TRANSLATIONS.ACCOUNT_SETTINGS.CONTACT_SUPPORT}
        </h2>
      </CardBody>
      <CardFooter>
        <Link
          to="#"
          onClick={(e) => {
            window.location.href = `mailto:${process.env.REACT_APP_CONTACT_US_EMAIL}`;
            e.preventDefault();
            logAnalyticsEvent(ANALYTICS_EVENTS_JSON.REQUEST_SUPPORT);
          }}
        >
          {" "}
          <AnimatedButton className="request-btn">
            {TRANSLATIONS.ACCOUNT_SETTINGS.REQUEST_SUPPORT}
          </AnimatedButton>
        </Link>
      </CardFooter>
    </Card>
  );
}
export default RequestSupportCard;
