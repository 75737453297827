import React, { memo } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import "./AnimatedButton.css";
import { COMPONENT_NAME } from "../constants";

function AnimatedButton({
  children,
  className,
  component,
  disabled,
  onClick,
  type,
}) {
  return (
    <motion.button
      whileHover={
        component === COMPONENT_NAME && disabled ? {} : { scale: 1.1 }
      }
      //   whileTap={{ scale: 0.9 }}
      className={className}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </motion.button>
  );
}
AnimatedButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default memo(AnimatedButton);
