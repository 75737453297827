import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { dashBoardSetupError, dashBoardSetupSuccess } from "./actions";

import { DASHBOARD_SETUP_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* dashBoardSetup(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/create`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(dashBoardSetupSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };

      yield put(dashBoardSetupError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        errorResponse: error.response?.data,
        netWorkError: true,
      },
    };
    yield put(dashBoardSetupError(errorData));
  }
}

export function* watchDashBoardSetup() {
  yield takeEvery(DASHBOARD_SETUP_REQUEST, dashBoardSetup);
}

function* DashBoardSetupSaga() {
  yield all([fork(watchDashBoardSetup)]);
}
export default DashBoardSetupSaga;
