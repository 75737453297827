import axios from "axios";
import { Cookies } from "react-cookie";
import * as Sentry from "@sentry/react";
import { ROUTE_PATH } from "../common/constants";
// creating global instance for the axios to call apis

export const AXIOS_INSTANCE = axios.create();

AXIOS_INSTANCE.defaults.headers["Content-Type"] = "application/json";
const cookies = new Cookies();

AXIOS_INSTANCE.interceptors.request.use(function (config) {
  if (cookies.get("token")) {
    config.headers.Authorization = `Bearer  ${cookies.get("token")}`;
    config.headers.ContentType = "multipart/form-data";
  } else if (cookies.get("nonAdminToken")) {
    config.headers.Authorization = `Bearer  ${cookies.get("nonAdminToken")}`;
    config.headers.ContentType = "multipart/form-data";
  }
  return config;
});

AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      Sentry.captureException(error);
      return error;
    } else {
      const code = error?.response?.status;
      const originalRequest = error?.config;

      if (code === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        window.location.href = ROUTE_PATH.GETSTARTED;
      }
      Sentry.captureException(error);
      return Promise.reject(error);
    }
  }
);

// base url
export const BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;

//contact us Api
export const CONTACT_US_API = `${BASE_URL}/user`;

//enterprise Api
export const ENTERPRISE_API = `${BASE_URL}/enterprise`;

export const CMS_API = `${BASE_URL}/cms`;

export const CHECKIN_API = `${BASE_URL}/check_in`;

export const ACTIVTY_API = `${BASE_URL}/activity_time`;
export const INCLUSIVITY_API = `${BASE_URL}/inclusivity`;
export const JOURNAL_API = `${BASE_URL}/journal`;
export const CHEMICAL_API = `${BASE_URL}/chemical_type`;
export const USER_ACTIVTY_API = `${BASE_URL}/activity`;
export const CHATBOT_API = `${BASE_URL}/wellbot`;
