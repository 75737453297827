import {
  REQUEST_DEMO_ERROR,
  REQUEST_DEMO_REQUEST,
  REQUEST_DEMO_SUCCESS,
} from "./actionTypes";

const initialState = {};

const requestDemo = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DEMO_REQUEST:
      return {
        ...state,
        isRequestDemoLoading: true,
        requestDemoData: false,
        requestDemoError: false,
      };
    case REQUEST_DEMO_SUCCESS:
      return {
        ...state,
        isRequestDemoLoading: false,
        requestDemoData: action.data?.response,
        requestDemoError: false,
      };
    case REQUEST_DEMO_ERROR:
      return {
        ...state,
        isRequestDemoLoading: false,
        requestDemoData: false,
        requestDemoError: action.error.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default requestDemo;
