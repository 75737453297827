import { AXIOS_INSTANCE, CHECKIN_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  createCheckInError,
  createCheckInSuccess,
  getVoiceNotesError,
  getVoiceNotesSuccess,
  updateVoiceNotesError,
  updateVoiceNotesSuccess,
  sendVoiceNotesError,
  sendVoiceNotesSuccess,
} from "./actions";

import {
  CREATE_CHECKIN_REQUEST,
  GET_VOICE_NOTES_REQUEST,
  UPDATE_VOICE_NOTES_REQUEST,
  SEND_VOICE_NOTES_REQUEST,
} from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* createCheckInForUser(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CHECKIN_API}/create`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response?.data && response.data?.length) {
      const responseData = {
        data: response.data,
      };
      yield put(createCheckInSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        error: response.data.error ? response.data.error : response.data,
      };
      yield put(createCheckInError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(createCheckInError(errorData));
  }
}

function* getVoiceNotesById(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CHECKIN_API}/get_voice_notes`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response?.data && response.data?.length) {
      const responseData = {
        data: response.data,
      };
      yield put(getVoiceNotesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        error: response.data.error ? response.data.error : response.data,
      };
      yield put(getVoiceNotesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(getVoiceNotesError(errorData));
  }
}

function* updateVoiceNotesById(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CHECKIN_API}/update_voice_note`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response?.data && response.data?.length) {
      const responseData = {
        data: response.data,
      };
      yield put(updateVoiceNotesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        error: response.data.error ? response.data.error : response.data,
      };
      yield put(updateVoiceNotesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(updateVoiceNotesError(errorData));
  }
}
function* sendVoiceNotes(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CHECKIN_API}/send_voice_note`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response?.data && response.data?.length) {
      const responseData = {
        data: response.data,
      };
      yield put(sendVoiceNotesSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
        error: response.data.error ? response.data.error : response.data,
      };
      yield put(sendVoiceNotesError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(sendVoiceNotesError(errorData));
  }
}

export function* watchCheckIn() {
  yield takeLatest(CREATE_CHECKIN_REQUEST, createCheckInForUser);
  yield takeLatest(GET_VOICE_NOTES_REQUEST, getVoiceNotesById);
  yield takeLatest(UPDATE_VOICE_NOTES_REQUEST, updateVoiceNotesById);
  yield takeLatest(SEND_VOICE_NOTES_REQUEST, sendVoiceNotes);
}

function* CheckInSaga() {
  yield all([fork(watchCheckIn)]);
}
export default CheckInSaga;
