import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getAvgHpyScoreError, getAvgHpyScoreSuccess } from "./actions";

import { AVG_HPY_SCORE_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* avgHpyScore(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/get_team_happiness_score`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data,
      };
      yield put(getAvgHpyScoreSuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(getAvgHpyScoreError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(getAvgHpyScoreError(errorData));
  }
}

export function* watchAvgHpyScore() {
  yield takeEvery(AVG_HPY_SCORE_REQUEST, avgHpyScore);
}

function* avgHpyScoreSaga() {
  yield all([fork(watchAvgHpyScore)]);
}
export default avgHpyScoreSaga;
