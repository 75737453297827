import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";

import AnimatedButton from "./AnimatedButton";
import { srcToFile } from "../../utils";
import TRANSLATIONS from "../../translations/en.json";

const videoConstraints = {
  facingMode: { exact: "user" },
};

const Camera = (props) => {
  const { handleTakePhoto, isCameraOn, setIsCameraOn } = props;
  const webcamRef = useRef(null);
  const [showActions, setShowActions] = useState(false);

  const capturePhoto = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    srcToFile(imageSrc, "photo.png", "image/png")
      .then(function (file) {
        const fd = new FormData();
        fd.append("file1", file);
        handleTakePhoto(file);
        return file;
      })
      .then(function (res) {
        return res.text();
      })
      .catch(console.error);

    setIsCameraOn(false);
  }, [webcamRef]);

  const onUserMedia = (e) => {
    if (e) {
      setShowActions(true);
    }
  };

  return (
    <>
      {isCameraOn && (
        <div className="camera">
          <Webcam
            ref={webcamRef}
            audio={false}
            screenshotFormat="image/png"
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            mirrored={true}
            screenshotQuality={1}
          />
          {showActions && (
            <div className="d-flex justify-content-start">
              <AnimatedButton
                onClick={capturePhoto}
                className="btn btn-success m-2"
              >
                {TRANSLATIONS.DASHBOARD_SETUP.CAPTURE}
              </AnimatedButton>
              <AnimatedButton
                onClick={() => {
                  setIsCameraOn(false);
                }}
                className="btn btn-warning m-2"
              >
                {TRANSLATIONS.DASHBOARD_SETUP.CLOSE}
              </AnimatedButton>
            </div>
          )}
        </div>
      )}
    </>
  );
};
Camera.propTypes = {
  url: PropTypes.string,
  handleTakePhoto: PropTypes.func,
  capturePhoto: PropTypes.func,
  onUserMedia: PropTypes.func,
  isCameraOn: PropTypes.bool,
  setIsCameraOn: PropTypes.bool,
};
export default Camera;
