import { AXIOS_INSTANCE, ENTERPRISE_API } from "../../api/apiEndPoint";
import {
  DAILY_REFLECTIONS_CARD_REQUEST,
  LONGEST_STREAK_CARD_REQUEST,
  MOODS_BREAKDOWN_REQUEST,
  PAST_DAILY_REFLECTIONS_CARD_REQUEST,
  PAST_LONGEST_STREAK_CARD_REQUEST,
} from "./actionsTypes";
import {
  DailyReflectionsCardError,
  DailyReflectionsCardSuccess,
  LongestStreakCardError,
  LongestStreakCardSuccess,
  MoodsBreakdownError,
  MoodsBreakdownSuccess,
  PastDailyReflectionsCardError,
  PastDailyReflectionsCardSuccess,
  PastLongestStreakCardError,
  PastLongestStreakCardSuccess,
} from "./actions";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { checkHttpStatus } from "../../api/apiUtils";

function* moodsBreakdown(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/mood_breakdown`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(MoodsBreakdownSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(MoodsBreakdownError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(MoodsBreakdownError(errorData));
  }
}

function* longestStreakCard(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/longest_streak_card`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(LongestStreakCardSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(LongestStreakCardError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(LongestStreakCardError(errorData));
  }
}

function* pastLongestStreakCard(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/longest_streak_card`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(PastLongestStreakCardSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(PastLongestStreakCardError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(PastLongestStreakCardError(errorData));
  }
}

function* dailyReflectionsCard(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/daily_reflections_card`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(DailyReflectionsCardSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(DailyReflectionsCardError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(DailyReflectionsCardError(errorData));
  }
}

function* pastDailyReflectionsCard(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${ENTERPRISE_API}/daily_reflections_card`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);

    if (response && response.data) {
      const responseData = {
        data: response.data,
      };
      yield put(PastDailyReflectionsCardSuccess(responseData));
    } else {
      const responseData = {
        data: response.data.error,
      };
      yield put(PastDailyReflectionsCardError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: {
        statusText: error,
        netWorkError: true,
      },
    };
    yield put(PastDailyReflectionsCardError(errorData));
  }
}

export function* watchDashboardActivities() {
  yield takeEvery(MOODS_BREAKDOWN_REQUEST, moodsBreakdown);
  yield takeEvery(LONGEST_STREAK_CARD_REQUEST, longestStreakCard);
  yield takeEvery(PAST_LONGEST_STREAK_CARD_REQUEST, pastLongestStreakCard);
  yield takeEvery(DAILY_REFLECTIONS_CARD_REQUEST, dailyReflectionsCard);
  yield takeEvery(
    PAST_DAILY_REFLECTIONS_CARD_REQUEST,
    pastDailyReflectionsCard
  );
}

function* DashboardActivitiesSaga() {
  yield all([fork(watchDashboardActivities)]);
}
export default DashboardActivitiesSaga;
