import { toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Checked } from "../assets/GreenChecked.svg";
import { ReactComponent as ErrorTriangle } from "../assets/notificationTriangle.svg";
import CustomNotification from "../common/components/CustomNotification";

const options = {
  position: "top-center",
  transition: Zoom,
  autoClose: 3000,
  hideProgressBar: true,
  pauseOnHover: true,
  closeOnClick: true,
};

let currentToastId = null;

export const showError = (msg) => {
  if (currentToastId) {
    toast.dismiss(currentToastId); // Dismiss the current toast if exists
  }
  currentToastId = toast.error(
    <CustomNotification icon={ErrorTriangle} msg={msg} isError />,
    options
  );
};

export const showSuccess = (msg) => {
  if (currentToastId) {
    toast.dismiss(currentToastId); // Dismiss the current toast if exists
  }
  currentToastId = toast.success(
    <CustomNotification icon={Checked} msg={msg} />,
    options
  );
};
