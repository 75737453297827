import React, { memo, useEffect, useRef, useState } from "react";
import { Field, reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { Spinner } from "reactstrap";

import { AnimatedButton } from "../common/components";
import BlackSun from "../assets/WebpImages/BlackSun.webp";
import { contactUsRequest } from "../redux/actions";
import {
  phoneNumberField,
  inputField,
  validateEmail,
  validateName,
} from "../common/reduxFields";
import PropTypes from "prop-types";
import Righticon from "../assets/RightArrow.svg";
import TRANSLATIONS from "../translations/en.json";
import { showError, showSuccess } from "../utils";
import WhiteSun from "../assets/WebpImages/WhiteSun.webp";
import "./Contact.css";

const Contact = function (props) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const { handleSubmit, reset } = props;

  const onSubmit = (formProps) => {
    const formData = {
      name: formProps.Name,
      message: formProps.Message,
      email: formProps.Email,
      mobileNumber: mobileNumber.nationalNumber,
      countryCode: countryCode,
    };
    dispatch(contactUsRequest(formData));
  };

  //state which works as map state to props
  const { contactUsData, contactUsRequestData, contactUsError } = useSelector(
    (state) => state.ContactUs
  );

  const FirstRun = useRef(true);
  // effect for contact us api response
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (contactUsRequestData) {
      setIsLoading(true);
    } else if (contactUsData) {
      setIsLoading(false);
      showSuccess(contactUsData);
      reset();
      setMobileNumber("");
    } else if (contactUsError) {
      setIsLoading(false);
      showError("Something went wrong");
    }
  }, [contactUsRequestData, contactUsData]);

  const handleChange = (value) => {
    let phoneNumber;
    if (value) {
      phoneNumber = parsePhoneNumber(value);
    }
    if (phoneNumber) {
      setCountryCode(phoneNumber.country);
    }
    setMobileNumber(phoneNumber);
  };
  return (
    <div className="container-fluid">
      <div className="row mvb-1">
        <div className="col-md-12 contact-section">
          <img
            src={WhiteSun}
            alt="logo"
            className="contact-img d-none d-sm-block"
          />
          <img
            src={BlackSun}
            alt="logo"
            className="contact-img d-block d-sm-none"
          />
          <div className="contact-us">{TRANSLATIONS.CONTACT_US.TITLE}</div>

          <div className="mr-4 pr-4 d-sm-block d-lg-none">
            <div className="contact-question">
              {TRANSLATIONS.CONTACT_US.FURTHUR_QUESTIONS}
            </div>
            <div className="contact-info">
              {TRANSLATIONS.CONTACT_US.FILL_OUT_FORM}
            </div>
          </div>
        </div>
      </div>
      <div className="contact-section-1 row">
        <div className="col-lg-4 col-sm-6 col-md-12 contact-info-section d-none d-lg-block">
          <div className="contact-question">
            {TRANSLATIONS.CONTACT_US.FURTHUR_QUESTIONS}
          </div>
          <div className="contact-info">
            {TRANSLATIONS.CONTACT_US.FILL_OUT_FORM}
          </div>
          <div className="contact-tb-section">
            <div className="contact-info-1">
              {TRANSLATIONS.CONTACT_US.SALES_TEAM}
            </div>
            <div className="contact-info-text">
              {TRANSLATIONS.CONTACT_US.EMAIL_1}
            </div>
            <br />
            <div className="contact-info-1">
              {TRANSLATIONS.CONTACT_US.SUPPORT_TEAM}
            </div>
            <div className="contact-info-text">
              {TRANSLATIONS.CONTACT_US.SUPPORT_EMAIL}
            </div>
          </div>
        </div>
        <div className="col-lg-6  col-md-12 contact-info-section-1">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group form-info-input name-info-input mb-4">
              <Field
                name="Name"
                id="Name"
                type="text"
                component={inputField}
                className="name-info-input name-info-input-2"
                placeholder="Full Name*"
                required
                errorClassName="contact-form-error"
                validate={[validateName]}
              />
            </div>
            <div className="form-group form-info-input name-info-input mb-4">
              <Field
                type="email"
                name="Email"
                id="EmailId"
                errorClassName="contact-form-error"
                component={inputField}
                className="name-info-input name-info-input-2"
                placeholder="Email*"
                required
                validate={[validateEmail]}
              />
            </div>
            <div className="contact-phone-input form-group form-info-input name-info-input mb-4">
              <Field
                international={true}
                countryCallingCodeEditable={false}
                name="mobileNumber"
                placeholder={
                  TRANSLATIONS.DASHBOARD_SETUP.PHONE_NUMBER_WITH_CODE
                }
                defaultCountry="US"
                value={mobileNumber?.nationalNumber}
                component={phoneNumberField}
                onChange={handleChange}
              />
              {mobileNumber && !isValidPhoneNumber(mobileNumber.number) && (
                <span className="form-error contact-phone-error">
                  {TRANSLATIONS.COMMON.INVALID_NUMBER}
                </span>
              )}
            </div>
            <div className="form-group form-info-input name-info-input mb-4">
              <Field
                rows="5"
                cols="50"
                name="Message"
                component={inputField}
                className="name-info-input"
                type="textarea"
                placeholder="Message*"
                required
              />
            </div>
            <div className="contact-button-direction">
              <AnimatedButton
                type="submit"
                disabled={
                  isLoading ||
                  (mobileNumber && !isValidPhoneNumber(mobileNumber.number))
                }
                className="btn btn-secondary flow-button  submit-button  mt-2 justify-align-center"
              >
                {TRANSLATIONS.CONTACT_US.SUBMIT}
                {isLoading ? (
                  <Spinner
                    size="md"
                    className="ml-1"
                    color="outline secondary"
                  />
                ) : (
                  <img src={Righticon} alt="icon" className="ml-4 mt-md-1" />
                )}
              </AnimatedButton>
            </div>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 contact-mbv-section">
          <div className="contact-info-1">
            {TRANSLATIONS.CONTACT_US.SALES_TEAM}
          </div>
          <div className="contact-info-text">
            {TRANSLATIONS.CONTACT_US.EMAIL_1}
          </div>
          <br />
          <div className="contact-info-1">
            {TRANSLATIONS.CONTACT_US.SUPPORT_TEAM}
          </div>
          <div className="contact-info-text">
            {TRANSLATIONS.CONTACT_US.SUPPORT_EMAIL}
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
};
export default connect()(
  reduxForm({
    form: "Contact",
  })(memo(Contact))
);
