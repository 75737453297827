import DataImage from "../../assets/HowItWorks/data.webp";
import MobileImage from "../../assets/HowItWorks/MobileImage.webp";
import MediumMobileImage from "../../assets/HowItWorks/MobileImage-md.webp";

import TRANSLATIONS from "../../translations/en.json";

export const CLOCK_SUPPORT_CONTENT = [
  {
    text: TRANSLATIONS.HOW_IT_WORKS_PAGE.DEDICATED_TEXT,
  },
  {
    text: TRANSLATIONS.HOW_IT_WORKS_PAGE.GUIDANCE_TEXT,
  },
  {
    text: TRANSLATIONS.HOW_IT_WORKS_PAGE.OPTIMIZATION_TEXT,
  },
];

export const PERSONALIZED_SECTION_CARD_DETAILS = [
  {
    button: TRANSLATIONS.HOW_IT_WORKS_PAGE.CONNECT_HPY,
    bunttonContainer: "happy-button-container",
    classname: "card-1",
    description: TRANSLATIONS.HOW_IT_WORKS_PAGE.DATA_DESCRIPTION,
    extraSpace: "",
    header: TRANSLATIONS.HOW_IT_WORKS_PAGE.DATA_HEADER,
    image: DataImage,
    imageClassName: "happy-data-image",
    imageDivClassName: "happy-data-image-div",
    isImageChanged: false,
    orderCard: "",
  },
  {
    button: TRANSLATIONS.HOW_IT_WORKS_PAGE.PERMIMUM,
    bunttonContainer: "premium-button-container",
    classname: "card-2",
    description: TRANSLATIONS.HOW_IT_WORKS_PAGE.BUILT_DESCRIPTION,
    extraSpace: "happy-extra-space-top",
    header: TRANSLATIONS.HOW_IT_WORKS_PAGE.BUILT_PERSONAL,
    image: MobileImage,
    imageClassName: "happy-mobiles-image d-none d-md-block",
    imageDivClassName: "happy-mobiles-image-div",
    isImageChanged: true,
    mobileDeviceImageClassName: "happy-mobiles-image d-block d-md-none",
    mobileDeviceImage: MediumMobileImage,
    orderCard: "happy-card-order",
  },
];
