import React from "react";
import PropTypes from "prop-types";

const Card = ({ label, className, handleClick }) => {
  return (
    <div
      style={{}}
      onClick={() => handleClick()}
      className={`funnelCard ${className}`}
    >
      {label}
    </div>
  );
};
Card.propTypes = {
  label: PropTypes.object,
  className: PropTypes.string,
  handleClick: PropTypes.function,
};
export default Card;
