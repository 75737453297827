import _ from "lodash";
import moment from "moment";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";

import CalenderIcon from "../../../assets/CalendarIcon.svg";
import { ALL, THIS_WEEK, TODAY } from "../../../common/constants/timeline";
import {
  getAssignedTaskTimelineAction,
  getTimelineAction,
} from "../../../redux/actions";
import TRANSLATIONS from "../../../translations/en.json";
import "./index.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  handleSortDataBasedOnDate,
  lazyRetry,
  showError,
  showSuccess,
} from "../../../utils";

// lazy loading components
const DatesContainer = lazy(() => lazyRetry(() => import("./DatesContainer"), "datesContainer"));
const FilterOptions = lazy(() => lazyRetry(() => import("./FilterOptions"), "filterOptions"));
const TimelineList = lazy(() => lazyRetry(() => import("./TimelineList"), "timelineList"));

function Timeline() {
  const dispatch = useDispatch();
  const locationData = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [isDateRange, setIsDateRange] = useState(false);
  const [selectedOption, setSelectedOption] = useState(TODAY);
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#ffdb43",
    },
  ]);
  const [timelineData, setTimelineData] = useState([]);

  const {
    getTimelineData,
    getTimelineRequestData,
    getAssignedTaskTimelineData,
    remindTaskRequestData,
    remindTaskData,
    remindTaskError,
  } = useSelector((state) => state.Timeline);

  const { voiceNotesRequestData } = useSelector((state) => state.CheckIn);
  const { isGetUserNotesLoading } = useSelector((state) => state.UserNotes);

  useEffect(() => {
    if (!_.isEmpty(locationData?.state)) {
      setUserDetails(locationData?.state);
    }
  }, [locationData]);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (getTimelineData) {
      setTimelineData((prev) => [...prev, ...getTimelineData]);
    } else {
      setTimelineData([]);
    }
  }, [getTimelineData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (getAssignedTaskTimelineData) {
      setTimelineData((prev) => [...prev, ...getAssignedTaskTimelineData]);
    } else {
      setTimelineData([]);
    }
  }, [getAssignedTaskTimelineData]);

  const isThirdRun = useRef(true);
  useEffect(() => {
    if (isThirdRun.current) {
      isThirdRun.current = false;
      return;
    }
    if (!remindTaskRequestData && remindTaskData) {
      showSuccess(TRANSLATIONS.MEMBER_PROFILE.REMINDER_NOTIFICATION);
    } else if (!remindTaskRequestData && remindTaskError) {
      showError(TRANSLATIONS.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [remindTaskRequestData, remindTaskData]);

  useEffect(() => {
    if (
      selectedDates[0]?.startDate !== "" &&
      selectedDates[0]?.endDate !== "" &&
      !_.isEmpty(userDetails)
    ) {
      const payload = {
        memberUUID: userDetails?.userUUID,
        startDate: moment(selectedDates[0].startDate).format("YYYY-MM-DD"),
        endDate: moment(selectedDates[0].endDate).format("YYYY-MM-DD"),
      };

      dispatch(getTimelineAction(payload));
      dispatch(getAssignedTaskTimelineAction(payload));
    }
  }, [userDetails, selectedDates]);

  // SETTING DATES BASED ON FILTER OPTIONS
  useEffect(() => {
    if (selectedOption !== "" && !_.isEmpty(userDetails)) {
      if (selectedOption === ALL) {
        setTimelineData([]);
        setSelectedDates((prevSelectedDates) => {
          const updatedSelectedDates = prevSelectedDates.map((dateRange) => ({
            ...dateRange,
            startDate: new Date(userDetails?.joinedAt),
            endDate: new Date(),
          }));
          return updatedSelectedDates;
        });
      } else if (selectedOption === TODAY) {
        setTimelineData([]);
        setSelectedDates((prevSelectedDates) => {
          const updatedSelectedDates = prevSelectedDates.map((dateRange) => ({
            ...dateRange,
            startDate: new Date(),
            endDate: new Date(),
          }));
          return updatedSelectedDates;
        });
      } else if (selectedOption === THIS_WEEK) {
        setTimelineData([]);
        const currentDate = new Date();
        // Subtract 7 days
        currentDate.setDate(currentDate.getDate() - 6);

        setSelectedDates((prevSelectedDates) => {
          const updatedSelectedDates = prevSelectedDates.map((dateRange) => ({
            ...dateRange,
            startDate: currentDate,
            endDate: new Date(),
          }));
          return updatedSelectedDates;
        });
      }
    }
  }, [selectedOption]);

  const handleShowDateRange = () => {
    setIsDateRange(!isDateRange);
  };

  const handleSelectedOption = (filterOption) => {
    setSelectedOption(filterOption);
  };

  const handleDateChange = (range) => {
    const { selection } = range;
    setSelectedDates([selection]);
  };

  const onClickClear = () => {
    setSelectedDates([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
        color: "#ffdb43",
      },
    ]);
    setIsDateRange(true);
    // setSelectedOption(TODAY);
  };

  return (
    <Suspense fallback="">
      {(getTimelineRequestData || remindTaskRequestData) &&
        !isGetUserNotesLoading &&
        !voiceNotesRequestData && (
          <div className="m-auto">
            <Spinner className="loader-container timeline-loader overlay-adminScreen" />
          </div>
        )}
      <div className="timline-header-content">
        <h2 className="timeline-header">
          {TRANSLATIONS.MEMBER_PROFILE.TIMELINE}
        </h2>
        <img
          src={CalenderIcon}
          alt="Calender_Icon"
          className="cursor-pointer"
          onClick={handleShowDateRange}
        />
        <div
          className={
            isDateRange
              ? "show-date-range shadow d-inline-block"
              : "hide-date-range"
          }
        >
          <DateRange
            editableDateInputs={true}
            onChange={(range) => handleDateChange(range)}
            moveRangeOnFirstSelection={false}
            ranges={selectedDates}
            minDate={
              !_.isEmpty(userDetails) ? new Date(userDetails?.joinedAt) : ""
            }
            maxDate={new Date()}
            showDateDisplay={false}
            format="YYYY/MM/DD"
          />
          <div className="text-right position-relative dates-btn-conatiner">
            <button
              className="date-done-btn"
              onClick={() => setIsDateRange(false)}
            >
              {TRANSLATIONS.MEMBER_PROFILE.DONE}
            </button>
            <button className="date-clr-btn" onClick={onClickClear}>
              {TRANSLATIONS.MEMBER_PROFILE.CLEAR}
            </button>
          </div>
        </div>
      </div>
      <FilterOptions
        selectedOption={selectedOption}
        handleSelectedOption={handleSelectedOption}
      />
      <DatesContainer
        selectedOption={selectedOption}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        userDetails={userDetails}
      />
      <TimelineList
        selectedOption={selectedOption}
        timelineData={handleSortDataBasedOnDate(timelineData)}
        userDetails={userDetails}
      />
    </Suspense>
  );
}

export default Timeline;
