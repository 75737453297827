import React from "react";
import { Col, Row } from "reactstrap";

import AnimatedButton from "../common/components/AnimatedButton";
import TRANSLATIONS from "../translations/en.json";
import RectangleScience from "../../src/assets/RectangleScience.svg";
import "./WithYouSection.css";

function WithYouSection() {
  const HeaderSection = () => {
    return (
      <>
        {" "}
        <p className="with-you-header">
          {TRANSLATIONS.HOW_IT_WORKS_PAGE.WITH_YOU_HEADER_1}
        </p>
        <p className="with-you-header">
          {TRANSLATIONS.HOW_IT_WORKS_PAGE.WITH_YOU_HEADER_2}
        </p>
        <p className="with-you-sub-header">
          {TRANSLATIONS.HOW_IT_WORKS_PAGE.WITH_YOU_SUB_HEADER}
        </p>
      </>
    );
  };

  // Scroll into Request Demo Component
  const handleScrollToRequestDemo = () => {
    setTimeout(() => {
      document
        .getElementById("request-demo")
        .scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  return (
    <>
      <Row className="with-you-row with-you-main-container">
        {/* For larger and adove screens image will be visible / below will not be visible */}
        <Col md={12} sm={12} lg={5} className="pl-0 d-lg-block d-none">
          <div className="with-you-main-img">
            <img
              src={RectangleScience}
              alt="rectangle"
              className="with-you-logo-overlay"
            />
          </div>
        </Col>
        <Col md={12} sm={12} lg={7} className="with-you-text-container">
          <div className="with-you-text-section">{HeaderSection()}</div>
          <div className="with-you-button-container ">
            <AnimatedButton
              type="button"
              className="request-btn"
              onClick={() => {
                handleScrollToRequestDemo();
              }}
            >
              {TRANSLATIONS.HOW_IT_WORKS_PAGE.REQUEST_DEMO}
            </AnimatedButton>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default WithYouSection;
