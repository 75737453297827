import React, { memo, useEffect, useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "../../authentication/DashBoardSetUp.css";
import "./UploadProfileModal.css";

import AnimatedButton from "./AnimatedButton";
import blackArrow from "../../assets/blackArrow.svg";
import Camera from "./Camera";
import {
  ACCEPTED_IMAGE_FORMATS,
  ANALYTICS_EVENTS_JSON,
  ROUTE_PATH,
} from "../constants";
import GreenChecked from "../../assets/GreenChecked.svg";
import { getBase64, logAnalyticsEvent, srcToFile } from "../../utils";
import ImageCropper from "./ImageCropper";
import { profileRequest } from "../../redux/actions";
import TRANSLATIONS from "../../translations/en.json";
import { ReactComponent as CrossIcon } from "../../assets/CrossIcon.svg";

function UploadProfileModal({
  modal,
  toggle,
  url,
  setUrl,
  setIsUpdatePic,
  setIsUpdateProfilePic,
  setNewUpdatedImg,
  isUpdateProfilePic,
}) {
  const location = useLocation();
  const [isCameraOn, setIsCameraOn] = useState(false);
  // const cropperRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [takePhoto, setTakePhoto] = useState(false);

  const [cropper, setCropper] = useState();

  const [profilePicture, setProfilePicture] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [photoAction, setPhotoAction] = useState(0);

  let initialUrl;
  if (location.pathname === ROUTE_PATH.DASHBOARD_SETUP) {
    initialUrl = url;
  }
  if (location.pathname !== ROUTE_PATH.DASHBOARD_SETUP) {
    initialUrl = isUpdateProfilePic;
  }

  const [uploadedImage, setUploadedImage] = useState(initialUrl);

  const { profilePictureRequestData, profilePictureError } = useSelector(
    (state) => state.ProfilePicture
  );

  const FirstRun = useRef(true);
  useEffect(() => {
    if (FirstRun.current) {
      FirstRun.current = false;
      return;
    }
    if (profilePictureRequestData) {
      setIsLoading(true);
    } else if (!profilePictureRequestData) {
      setIsLoading(false);
      if (location.pathname !== ROUTE_PATH.DASHBOARD_SETUP) {
        setIsUpdateProfilePic(uploadedImage);
      }
      toggle();
    } else if (profilePictureError) {
      setIsLoading(false);
    }
  }, [profilePictureRequestData]);

  const displayedPicture = new FormData();

  const dispatch = useDispatch();

  const handelProfilePicture = () => {
    if (profilePicture.pictureAsFile) {
      displayedPicture.append("profile_picture", profilePicture.pictureAsFile);
    } else if (uploadedImage) {
      displayedPicture.append("profile_picture", uploadedImage);
    }
    if (location.pathname === ROUTE_PATH.DASHBOARD_SETUP) {
      setUrl(uploadedImage);
      setIsUpdatePic(true);
      setIsSaveDisabled(false);
    }
    if (location.pathname !== ROUTE_PATH.DASHBOARD_SETUP) {
      setNewUpdatedImg(true);
      setIsSaveDisabled(false);
    }
    dispatch(profileRequest(displayedPicture));
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.UPLOAD_PHOTO_BTN);
  };
  const removePhoto = () => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.REMOVE_PHOTO_BTN);
    setIsSaveDisabled(false);
    displayedPicture.append("profile_picture", "");
    setUploadedImage("");
  };

  const handleUploadImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    getBase64(files[0], setSelectedImage);
  };
  const handleTakePhoto = (photoTaken) => {
    logAnalyticsEvent(ANALYTICS_EVENTS_JSON.TAKE_PHOTO_BTN);
    getBase64(photoTaken, setSelectedImage);
    setTakePhoto(true);
  };
  const onCropComplete = (isCropped) => {
    if (typeof cropper !== "undefined") {
      const imageSrc = isCropped
        ? cropper.getCroppedCanvas().toDataURL()
        : selectedImage;
      //converting base64 to file instance
      srcToFile(imageSrc, "photo.png", "image/png")
        .then(function (file) {
          const fd = new FormData();
          fd.append("file1", file);
          if (takePhoto) {
            setProfilePicture({
              pictureAsFile: URL.createObjectURL(file),
            });
            setPhotoAction(2);
          } else {
            setPhotoAction(1);
            setProfilePicture({
              picturePreview: URL.createObjectURL(file),
              pictureAsFile: file,
            });
          }
          setUploadedImage(imageSrc);
          setIsSaveDisabled(false);
          setSelectedImage("");
          setTakePhoto(false);
          return file;
        })
        .then(function (res) {
          return res.text();
        })
        .catch(console.error);
    }
  };
  return (
    <Modal
      isOpen={modal}
      className="upload-profile"
      modalTransition={{ timeout: 1000 }}
    >
      <ModalHeader className=" no-border">
        <CrossIcon
          className="close-svg"
          onClick={() => {
            toggle();
          }}
        />
      </ModalHeader>
      <ModalBody className="m-0 px-0">
        <h5 className="text-center add-profile">
          {" "}
          {TRANSLATIONS.DASHBOARD_SETUP.ADD_PROFILE_PICTURE}
        </h5>
        <p className="text-center para-profile ">
          {TRANSLATIONS.DASHBOARD_SETUP.TAKE_OR_SELECT_PHOTO}
        </p>
        {selectedImage ? (
          <ImageCropper
            image={selectedImage}
            onCropComplete={onCropComplete}
            setCropper={setCropper}
            setSelectedImage={setSelectedImage}
            aspectRatio={1 / 1}
          />
        ) : (
          <div className="d-flex flex-row justify-content-center mt-5">
            <div id="outline-frame">
              <span className="profile-centerer"></span>
              {uploadedImage && (
                <img
                  src={uploadedImage}
                  alt="profile"
                  className="uploadedImg"
                />
              )}{" "}
            </div>
          </div>
        )}
        <hr className="model-line-color mt-5" />
        <div className="d-flex align-items-center justify-content-between">
          <span className="file-wrapper ml-3">
            <input
              type="file"
              name="photo"
              id="photo"
              accept={ACCEPTED_IMAGE_FORMATS}
              onChange={(e) => {
                setIsCameraOn(false);
                handleUploadImage(e);
              }}
            />

            <span
              className="upload-photo cursor-pointer button"
              htmlFor="upload-profile"
            >
              {TRANSLATIONS.DASHBOARD_SETUP.UPLOAD_PHOTO}
            </span>
          </span>
          {!isSaveDisabled && photoAction === 1 && (
            <span className="pr-4">
              <img src={GreenChecked} alt="checked" />
            </span>
          )}
        </div>
        <hr className="model-line-color" />
        <div className="d-flex align-items-center justify-content-between">
          <Button
            className=" ml-3 para-profile take-photo cursor-pointer"
            onClick={() => {
              setIsCameraOn(true);
            }}
          >
            {TRANSLATIONS.DASHBOARD_SETUP.TAKE_PHOTO}
          </Button>
          {!isSaveDisabled && photoAction === 2 && (
            <span className="pr-4">
              <img src={GreenChecked} alt="checked" />
            </span>
          )}
        </div>
        {isCameraOn && (
          <Camera
            handleTakePhoto={handleTakePhoto}
            target="_blank"
            isCameraOn={isCameraOn}
            setIsCameraOn={setIsCameraOn}
          />
        )}

        <hr className="model-line-color" />

        {isUpdateProfilePic && (
          <>
            <div className="d-flex align-items-center">
              <Button
                className=" ml-3 para-profile take-photo"
                onClick={() => {
                  removePhoto();
                }}
              >
                {TRANSLATIONS.DASHBOARD_SETUP.REMOVE_PHOTO}
              </Button>
            </div>
            <hr className="model-line-color" />
          </>
        )}
        <div className="justify-align-center">
          <AnimatedButton
            className="btn save-btn mb-3"
            onClick={handelProfilePicture}
            disabled={isSaveDisabled}
          >
            {TRANSLATIONS.DASHBOARD_SETUP.SAVE_BTN}

            {isLoading ? (
              <Spinner size="sm" color="outline secondary" className="ml-1" />
            ) : (
              <img src={blackArrow} alt="icon" className="ml-2 mt-1" />
            )}
          </AnimatedButton>
        </div>
      </ModalBody>
    </Modal>
  );
}

UploadProfileModal.propTypes = {
  displayedPicture: PropTypes.object,
  modal: PropTypes.bool,
  setUrl: PropTypes.func,
  setIsUpdatePic: PropTypes.func,
  setIsUpdateProfilePic: PropTypes.func,
  setNewUpdatedImg: PropTypes.bool,
  toggle: PropTypes.bool,
  url: PropTypes.string,
  isUpdateProfilePic: PropTypes.string,
};

export default memo(UploadProfileModal);
