import React from "react";
import { Carousel } from "react-responsive-carousel";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Dopamine from "../../assets/WebpImages/dopamine.webp";
import Endorphin from "../../assets/WebpImages/endorphin.webp";
import Oxytocin from "../../assets/Oxytocin.svg";
import Serotonin from "../../assets/Serotonin.svg";

function Slider({ onChange, activeChemical }) {
  const chemicalDescriptions = {
    dopamine: {
      description1: `Often called the “reward center” of your brain, dopamine is that high we get after beating a tough level of candy crush...and the need to beat another. You know that mood bump you get after someone likes your instagram post? That’s dopamine in action. Enjoying that tasty second piece of cake? Dopamine.`,
      description2: `Often linked to short-term pleasure and gratification, dopamine is responsible for creating feedback loops in the brain that make us want to do more dopamine-inducing activities over and over again.`,
      icon: Dopamine,
      Title: "The Reward Chemical",
    },
    oxytocin: {
      description1: `Known fondly as the “love hormone,” oxytocin is often released when we bond with another person. The warm fuzzies you get from hugging a friend or sharing an intimate moment with someone? That’s your oxytocin firing. The effects of oxytocin are most commonly experienced during childbirth, when showing empathy, and during sex. 
      `,
      description2: `Oxytocin is produced by the hypothalamus and is largely responsible for our feelings of social connectedness. Strong feelings of trust, intimacy, and yes, love, are often associated with this hormone.`,
      icon: Oxytocin,
      Title: "The Love Hormone",
    },
    serotonin: {
      description1: `Serotonin is seen as a full-body stabilizer. It plays a key role in regulating your mood and ensuring healthy digestion. It also helps with sleep and overall brain function. So the feeling of accomplishment you get after completing a big project, the calm that comes over you from a deep meditation, and the feeling you get from a great night’s sleep can all be traced back to this nifty chemical.
      `,
      description2: `Because serotonin is key to your sense of well being, treatments for mood disorders such as depression or anxiety involve increasing serotonin levels in the brain.`,
      icon: Serotonin,
      Title: "The Mood Stabilizer",
    },
    endorphin: {
      description1: `Classified as the relief hormone, endorphins are often produced in response to intense physical activity or to reduce pain. Like oxytocin, endorphins are released by the hypothalamus and are the main culprits behind the infamous “runner’s high.”
      `,
      description2: `Endorphin production increases from exercise (especially cardio), laughter, and eating spicy foods. Exercise is also used as a common countermeasure for depression and anxiety because it stimulates the production of this feel-good chemical. Laughter really is the best medicine.`,
      icon: Endorphin,
      Title: "The Relief Chemical",
    },
  };
  return (
    <Carousel
      className="carousel carousel-mbv"
      autoPlay={false}
      showStatus={false}
      swipeable={true}
      showThumbs={false}
      onChange={onChange}
      infiniteLoop={true}
      useKeyboardArrows={true}
      emulateTouch={true}
      selectedItem={activeChemical}
      stopOnHover={true}
      transitionTime={1500}
    >
      {Object.keys(chemicalDescriptions).map((item) => {
        return (
          <div key={item} className="sliderText">
            <img
              src={chemicalDescriptions[item].icon}
              alt="dopamineIcon"
              id="sliderIcon"
            />
            <h2 className="sliderHead">{chemicalDescriptions[item].Title}</h2>
            <br />
            <p className="">{chemicalDescriptions[item].description1}</p>
            <p>{chemicalDescriptions[item].description2}</p>
          </div>
        );
      })}
    </Carousel>
  );
}
Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  activeChemical: PropTypes.number.isRequired,
};
export default Slider;
