import React, { memo } from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
function ImgLazyLoadComponent({ src, alt, className }) {
  return (
    <div>
      <LazyLoadImage
        alt={alt}
        src={`${src}`} // use normal <img> attributes as props
        className={className}
        effect="blur"
      />
    </div>
  );
}
ImgLazyLoadComponent.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
};
export default memo(ImgLazyLoadComponent);
