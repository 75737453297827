import React from "react";
import "./GetStarted.css";
import appstoreIcon from "../assets/applestoreIcon.svg";
import playIcon from "../assets/googleplaystore.svg";
import TRANSLATIONS from "../translations/en.json";
import { ANALYTICS_EVENTS_JSON, EXTERNAL_URLS } from "../common/constants";
import { logAnalyticsEvent } from "../utils";
import AnimatedButton from "../common/components/AnimatedButton";
const GetStarted = () => {
  return (
    <div className="container-fluid p-0 get-started ">
      <div className="row centerText">
        <h1 className="get-text">{TRANSLATIONS.HOME_PAGE.GET_HPY} </h1>
      </div>
      <div className="row buttonholder">
        <AnimatedButton className="transparent-btn">
          {" "}
          <a href={EXTERNAL_URLS.IOS_URL}>
            <img
              src={appstoreIcon}
              className="play-buttons apple-icon"
              onClick={() => {
                logAnalyticsEvent(ANALYTICS_EVENTS_JSON.IOS_STORE_BTN);
              }}
            />
          </a>
        </AnimatedButton>
        <AnimatedButton className="transparent-btn">
          <a href={EXTERNAL_URLS.GOOGLE_URL}>
            <img
              src={playIcon}
              className="play-buttons"
              onClick={() => {
                logAnalyticsEvent(ANALYTICS_EVENTS_JSON.GOOGLE_PLAYSTORE_BTN);
              }}
            />
          </a>
        </AnimatedButton>
      </div>
    </div>
  );
};

export default GetStarted;
