import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import Chart from "react-apexcharts";
import { floatNumberWithTwoDecimal } from "../../utils";
import { RadialBarOptions } from "./RadialBarOptions";
import TRANSLATIONS from "../../translations/en.json";
import "./Charts.css";

const RadialBar = () => {
  const percentages = {
    dopaminePercentage: 0,
    oxytocinPercentage: 0,
    serotoninPercentage: 0,
    endorphinPercentage: 0,
  };
  const [chemicalPercentages, setChemicalPercentages] = useState(percentages);

  const { isActivitiesPerChemicalLoading, activitiesPerChemicalData } =
    useSelector((state) => state.ActivitiesPerChemical);

  useEffect(() => {
    if (activitiesPerChemicalData?.length) {
      let dopamine, endorphin, oxytocin, serotonin;
      dopamine = endorphin = oxytocin = serotonin = 0;
      for (let item of activitiesPerChemicalData) {
        dopamine += item?.dopamine;
        endorphin += item?.endorphin;
        oxytocin += item?.oxytocin;
        serotonin += item?.serotonin;
      }

      const total = dopamine + endorphin + oxytocin + serotonin;
      setChemicalPercentages({
        dopaminePercentage: floatNumberWithTwoDecimal(dopamine, total) || 0,
        oxytocinPercentage: floatNumberWithTwoDecimal(oxytocin, total) || 0,
        serotoninPercentage: floatNumberWithTwoDecimal(serotonin, total) || 0,
        endorphinPercentage: floatNumberWithTwoDecimal(endorphin, total) || 0,
      });
    } else {
      setChemicalPercentages(percentages);
    }
  }, [isActivitiesPerChemicalLoading]);
  const RadialBarData = {
    options: {
      ...RadialBarOptions,
      labels: [
        `Dopamine &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${chemicalPercentages?.dopaminePercentage}%`,
        `Oxytocin  &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${chemicalPercentages?.oxytocinPercentage}%`,
        `Serotonin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${chemicalPercentages?.serotoninPercentage}%`,
        `Endorphin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${chemicalPercentages?.endorphinPercentage}%`,
      ],
      responsive: [
        {
          breakpoint: 1400,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 1,
                  size: "40%",
                },
              },
            },
          },
        },
        {
          breakpoint: 1250,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "20%",
                },
              },
            },
          },
        },
        {
          breakpoint: 767,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "50%",
                },
              },
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "30%",
                },
              },
            },
            labels: [
              `Dopamine &nbsp;&nbsp;&nbsp;${chemicalPercentages?.dopaminePercentage}%`,
              `Oxytocin  &nbsp;&nbsp;&nbsp; &nbsp;${chemicalPercentages?.oxytocinPercentage}%`,
              `Serotonin &nbsp;&nbsp;&nbsp;&nbsp;${chemicalPercentages?.serotoninPercentage}%`,
              `Endorphin &nbsp;&nbsp;&nbsp;${chemicalPercentages?.endorphinPercentage}%`,
            ],
            legend: {
              fontSize: "12px",
            },
          },
        },
        {
          breakpoint: 440,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "20%",
                },
              },
            },
            labels: [
              `Dopamine &nbsp;&nbsp;&nbsp;${chemicalPercentages?.dopaminePercentage}%`,
              `Oxytocin  &nbsp;&nbsp;&nbsp; &nbsp;${chemicalPercentages?.oxytocinPercentage}%`,
              `Serotonin &nbsp;&nbsp;&nbsp;&nbsp;${chemicalPercentages?.serotoninPercentage}%`,
              `Endorphin &nbsp;&nbsp;&nbsp;${chemicalPercentages?.endorphinPercentage}%`,
            ],
            legend: {
              fontSize: "12px",
            },
          },
        },
        {
          breakpoint: 321,
          options: {
            plotOptions: {
              radialBar: {
                hollow: {
                  margin: 0,
                  size: "2%",
                },
              },
            },
            labels: [
              `Dopamine &nbsp;&nbsp;${chemicalPercentages?.dopaminePercentage}%`,
              `Oxytocin  &nbsp;&nbsp;&nbsp; ${chemicalPercentages?.oxytocinPercentage}%`,
              `Serotonin &nbsp;&nbsp;&nbsp;${chemicalPercentages?.serotoninPercentage}%`,
              `Endorphin &nbsp;&nbsp;${chemicalPercentages?.endorphinPercentage}%`,
            ],
            legend: {
              fontSize: "12px",
            },
          },
        },
      ],
    },
    series: Object.values(chemicalPercentages),
  };
  return (
    <Card className=" dashboard-graph-card">
      <h1 className="graph-title">
        {TRANSLATIONS.USER_DASHBOARD.TOTAL_ACTIVITIES_PER_CHEMICAL}
      </h1>
      <div className="radial-chart pr-3 pb-3">
        <Chart
          options={RadialBarData.options}
          series={RadialBarData.series}
          type="radialBar"
          width={"100%"}
        />
      </div>
    </Card>
  );
};

export default RadialBar;
