import React from "react";
import "./index.css";

import EntPgeBonds from "./EntPgeBonds";
import EntPgeHeader from "./EntPgeHeader";
import EntPgeHero from "./EntPgeHero";
import EntPgeInsights from "./EntPgeInsights";
import EntPgeIntro from "./EntPgeIntro";
import EntPgeMatters from "./EntPgeMatters";
import EntPgeReflections from "./EntPgeReflections";
import EntPgeReminders from "./EntPgeReminders";
import EntPgeScience from "./EntPgeScience";
import EntPgeSignUp from "./EntPgeSignUp";
import GetStarted from "../home/GetStarted";

function EntPge() {
  return (
    <div className="entpage ">
      <EntPgeHeader />
      <EntPgeHero />
      <EntPgeIntro />
      <EntPgeMatters />
      <EntPgeReminders />
      <EntPgeReflections />
      <EntPgeScience />
      <EntPgeInsights />
      <EntPgeBonds />
      <EntPgeSignUp />
      <GetStarted />
    </div>
  );
}
export default EntPge;
