import {
  ACTIVITIES_PER_CHEMICAL_ERROR,
  ACTIVITIES_PER_CHEMICAL_REQUEST,
  ACTIVITIES_PER_CHEMICAL_SUCCESS,
  PAST_ACTIVITIES_PER_CHEMICAL_ERROR,
  PAST_ACTIVITIES_PER_CHEMICAL_REQUEST,
  PAST_ACTIVITIES_PER_CHEMICAL_SUCCESS,
} from "./actionTypes";

export const activitiesPerChemicalRequest = (user) => {
  return {
    type: ACTIVITIES_PER_CHEMICAL_REQUEST,
    payload: user,
  };
};

export const activitiesPerChemicalSuccess = (user, history) => {
  return {
    type: ACTIVITIES_PER_CHEMICAL_SUCCESS,
    payload: { user, history },
  };
};

export const activitiesPerChemicalError = (history) => {
  return {
    type: ACTIVITIES_PER_CHEMICAL_ERROR,
    payload: { history },
  };
};

export const pastActivitiesPerChemicalRequest = (user) => {
  return {
    type: PAST_ACTIVITIES_PER_CHEMICAL_REQUEST,
    payload: user,
  };
};

export const pastActivitiesPerChemicalSuccess = (user, history) => {
  return {
    type: PAST_ACTIVITIES_PER_CHEMICAL_SUCCESS,
    payload: { user, history },
  };
};

export const pastActivitiesPerChemicalError = (history) => {
  return {
    type: PAST_ACTIVITIES_PER_CHEMICAL_ERROR,
    payload: { history },
  };
};
