export const RadialBarOptions = {
  chart: {
    height: 350,
    type: "radialBar",
    zoom: {
      enabled: false,
    },
  },
  colors: ["#09C28E", "#FF7F52", "#00CEC3", "#33A0D7"],
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: true,
    showForZeroSeries: true,
    position: "right",
    fontSize: "14px",
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  stroke: {
    lineCap: "round",
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 1,
        size: "50%",
        background: "transparent",
      },
      dataLabels: {
        show: false,
      },
    },
  },
};
