import _ from "lodash";
import PropTypes from "prop-types";
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import DownArrow from "../../../assets/Down-arrow.svg";
import MicrophoneIcon from "../../../assets/MicrophoneIcon.svg";
import UpArrow from "../../../assets/Up-arrow.svg";
import SendIcon from "../../../assets/sendIcon.svg";
import { VOICE_NOTES_STATUS } from "../../../common/constants";
import {
  createCheckInAction,
  getVoiceNotesAction,
  sendVoiceNotesAction,
} from "../../../redux/actions";
import TRANSLATION from "../../../translations/en.json";
import { requestDateFormat, showError, showSuccess } from "../../../utils";
import useRecorder from "../../../utils/useRecorder";
import "./index.css";

// lazy loading components
const CheckInUserList = lazy(() => import("./CheckInUserList"));
const VoiceRecorder = lazy(() =>
  import("../../../common/components/VoiceRecorder")
);

function CheckInSection({ isRefresh }) {
  const dispatch = useDispatch();
  const locationData = useLocation();
  const [userDetails, setUserDetails] = useState({});
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [isAccordianOpen, setIsAccordianOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const { recorderState, ...handlers } = useRecorder();

  const {
    voiceNotesData,
    sendVoiceNotesRequestData,
    sendVoiceNotesData,
    sendVoiceNotesError,
    checkInUserRequestData,
    checkInUserData,
    checkInUserError,
  } = useSelector((state) => state.CheckIn);

  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (voiceNotesData) {
      setVoiceNotes(voiceNotesData);
    }
  }, [voiceNotesData]);

  const isSecondRun = useRef(true);
  useEffect(() => {
    if (isSecondRun.current) {
      isSecondRun.current = false;
      return;
    }
    if (!sendVoiceNotesRequestData && sendVoiceNotesData) {
      showSuccess(sendVoiceNotesData);
    } else if (!sendVoiceNotesRequestData && sendVoiceNotesError) {
      showError(TRANSLATION.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [sendVoiceNotesRequestData, sendVoiceNotesData]);

  const isThirdRun = useRef(true);
  useEffect(() => {
    if (isThirdRun.current) {
      isThirdRun.current = false;
      return;
    }
    if (!checkInUserRequestData && checkInUserData) {
      showSuccess(TRANSLATION.MEMBER_PROFILE.CHECKIN_SUCCESSFUL);
    } else if (!checkInUserRequestData && checkInUserError) {
      showError(TRANSLATION.COMMON.SOMETHING_WENT_WRONG);
    }
  }, [checkInUserRequestData, checkInUserData]);

  useEffect(() => {
    if (!_.isEmpty(locationData?.state)) {
      setUserDetails(locationData?.state);
      const payload = {
        senderUUID: locationData?.state?.userUUID,
      };
      dispatch(getVoiceNotesAction(payload));
    }
  }, [locationData, isRefresh]);

  useEffect(() => {
    if (recorderState?.audio !== null) {
      setIsRecording(!isRecording);
      const payload = new FormData();
      payload.append("voiceNote", recorderState?.audio);
      payload.append(
        "receiverUUID",
        voiceNotes?.length ? voiceNotes[0]?.sender_uuid : userDetails?.userUUID
      );
      payload.append("duration", Number(recorderState?.duration));
      payload.append("senderType", "COACH");
      dispatch(sendVoiceNotesAction(payload));
    }
  }, [recorderState.audio]);

  const handleSendCheckIn = () => {
    const payload = {
      secondaryUserUUID: userDetails?.userUUID,
      checkInDate: requestDateFormat(new Date()),
    };
    dispatch(createCheckInAction(payload));
  };

  const handleclick = () => {
    setIsAccordianOpen(!isAccordianOpen);
  };

  const handleRecord = () => {
    if (!recorderState?.initRecording) {
      setIsRecording(!isRecording);
      handlers.startRecording();
    }
  };

  return (
    <Suspense fallback="">
      {/* MOBILE VIEW */}
      <div
        className={`accordian d-md-none ${
          isAccordianOpen ? "checkin-is-open" : ""
        }`}
      >
        <div
          className="accordian-header-container"
          onClick={() => handleclick()}
        >
          <div className="header-container">
            <span className="checkin-accordain-header">
              {TRANSLATION.MEMBER_PROFILE.VOICE_NOTE}
            </span>
            {voiceNotesData?.length &&
            voiceNotesData?.filter(
              (voice) => voice?.read_status === VOICE_NOTES_STATUS?.UNREAD
            )?.length ? (
              <span className="new-checkin-msg">
                {`${
                  voiceNotesData?.length &&
                  voiceNotesData?.filter(
                    (voice) => voice?.read_status === VOICE_NOTES_STATUS?.UNREAD
                  )?.length
                } ${TRANSLATION.MEMBER_PROFILE.NEW_VOICE_NOTES}`}
              </span>
            ) : (
              ""
            )}
          </div>
          <img
            src={isAccordianOpen ? UpArrow : DownArrow}
            alt={isAccordianOpen ? "up_icon" : "down_icon"}
            className="cursor-pointer accordion-arrow-icon"
          />
        </div>
        <div className={isAccordianOpen ? "section in" : "section"}>
          <div className="checkin-header-container">
            <p className="checkIn-section-header m-0">
              {TRANSLATION.MEMBER_PROFILE.CHECKIN_SECTION}
            </p>
            <img
              src={SendIcon}
              alt="Seacrch_icon"
              className="cursor-pointer send-notification-icon"
              onClick={handleSendCheckIn}
            />
          </div>
          <div className="voice-recorder">
            <div className="mx-3">
              <button
                type="button"
                className={
                  recorderState.initRecording
                    ? "voice-recorder-btn recording-btn"
                    : "voice-recorder-btn"
                }
                onClick={handleRecord}
                disabled={recorderState?.initRecording}
              >
                {TRANSLATION.MEMBER_PROFILE.RECORD_VOICE_NOTE}
                <img
                  src={MicrophoneIcon}
                  alt="record_icon"
                  className="record-icon-btn"
                />
              </button>
            </div>
            {recorderState.initRecording ? (
              <VoiceRecorder
                recorderState={recorderState}
                handlers={handlers}
              />
            ) : (
              ""
            )}
          </div>
          <div className="checkin-user-scroll">
            <CheckInUserList voiceNotesData={voiceNotes} />
          </div>
        </div>
      </div>
      {/* WEB VIEW */}
      <Card className="checkin-section-card d-none d-md-block">
        <CardBody className="p-0">
          <div className="checkin-header-container">
            <p className="checkIn-section-header">
              {TRANSLATION.MEMBER_PROFILE.CHECKIN_SECTION}
            </p>
            <img
              src={SendIcon}
              alt="Seacrch_icon"
              className="cursor-pointer send-notification-icon"
              onClick={handleSendCheckIn}
            />
          </div>
          <div className="voice-recorder">
            <div className="mx-3">
              <button
                type="button"
                className={
                  recorderState.initRecording
                    ? "voice-recorder-btn recording-btn"
                    : "voice-recorder-btn"
                }
                onClick={handleRecord}
              >
                {TRANSLATION.MEMBER_PROFILE.RECORD_VOICE_NOTE}
                <img
                  src={MicrophoneIcon}
                  alt="record_icon"
                  className="record-icon-btn"
                />
              </button>
            </div>
            {recorderState.initRecording ? (
              <VoiceRecorder
                recorderState={recorderState}
                handlers={handlers}
              />
            ) : (
              ""
            )}
          </div>
          <hr className="hr-voice-notes" />
          <div className="checkin-user-scroll">
            <CheckInUserList voiceNotesData={voiceNotes} />
          </div>
        </CardBody>
      </Card>
    </Suspense>
  );
}

CheckInSection.propTypes = {
  isRefresh: PropTypes.bool.isRequired,
};
export default CheckInSection;
