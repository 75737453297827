import React from "react";
import PropTypes from "prop-types";

import { ANALYTICS_EVENTS_JSON, PRO } from "../../../common/constants";
import { AnimatedButton } from "../../../common/components";
import BellIcon from "../../../assets/TeamMembers/BellIcon.svg";
import { logAnalyticsEvent } from "../../../utils";
import Messages from "../../../assets/Messages.svg";
import TRANSLATIONS from "../../../translations/en.json";
import "./Header.css";

function Header({
  enterpriseLogoURL,
  headerText,
  inviteModaltoggle,
  isLoading,
  teamSettigsRequestData,
}) {
  return (
    <div className="page-topbar">
      <h2 className="page-header">{headerText}</h2>
      <div className="d-flex align-items-center">
        {!teamSettigsRequestData && (
          <AnimatedButton
            type="button"
            className="send-code-btn"
            onClick={() => {
              inviteModaltoggle();
              logAnalyticsEvent(
                ANALYTICS_EVENTS_JSON.SHARE_WITH_TEAM_POPUP_OPEN_BTN
              );
            }}
          >
            {localStorage.getItem("subscription") === PRO
              ? TRANSLATIONS.POPUP.SEND_COACH_CODE
              : TRANSLATIONS.POPUP.SEND_TEAM_CODE}
          </AnimatedButton>
        )}
        <div className="justify-align-center">
          <img src={Messages} alt="msg-icon" className="msg-icon d-none" />
          <div className="header-notification d-none">
            <img src={BellIcon} alt="bellicon" className="noti-icon" />
            <span className="noti-count"></span>
          </div>

          {!isLoading &&
            localStorage.getItem("subscription") !== PRO &&
            (enterpriseLogoURL ? (
              <img
                className="ent-logo"
                src={enterpriseLogoURL}
                alt="ent-logo"
              />
            ) : (
              <div className="company-logo">
                {TRANSLATIONS.ADMIN_PROFILE.LOGO}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
Header.propTypes = {
  enterpriseLogoURL: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  inviteModaltoggle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  teamSettigsRequestData: PropTypes.bool.isRequired,
};
export default Header;
