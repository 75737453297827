import React, { memo } from "react";
import "./CustomNotification.css";
import PropTypes from "prop-types";

function CustomNotification({ icon: Icon, isError, msg }) {
  return (
    <div className={`custom-notification ${isError ? "error" : ""} `}>
      <p className="message mb-0">
        <Icon className="message-icon" />
        <span className="">{msg}</span>
      </p>
    </div>
  );
}
CustomNotification.propTypes = {
  icon: PropTypes.node.isRequired,
  isError: PropTypes.bool,
  msg: PropTypes.string.isRequired,
};
export default memo(CustomNotification);
