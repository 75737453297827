import {
  USER_DETAILS_ERROR,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
} from "./actionTypes";

export const userDetailsRequest = (user) => {
  return {
    type: USER_DETAILS_REQUEST,
    payload: user,
  };
};

export const userDetailsSuccess = (user, history) => {
  return {
    type: USER_DETAILS_SUCCESS,
    payload: { user, history },
  };
};

export const userDetailsError = (history) => {
  return {
    type: USER_DETAILS_ERROR,
    payload: { history },
  };
};
