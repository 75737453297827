import {
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_ERROR,
} from "./actionTypes";

const initialState = {};

const contactUs = (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_US_REQUEST:
      return {
        ...state,
        contactUsRequestData: true,
        contactUsData: false,
        contactUsError: false,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUsRequestData: false,
        contactUsData: action.payload.user.data,
        contactUsError: false,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        contactUsRequestData: false,
        contactUsData: false,
        contactUsError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default contactUs;
