import React, { memo } from "react";
import { EXTERNAL_URLS } from "../../common/constants";
import appstoreIcon from "../../assets/AdminSideIcons/AppleAppStore.svg";
import playIcon from "../../assets/AdminSideIcons/PlayStore.svg";

function AppStoreIcons() {
  return (
    <>
      <a href={EXTERNAL_URLS.IOS_URL}>
        <img
          src={appstoreIcon}
          alt="appstore"
          className="img-fluid app-store-icons "
        />
      </a>
      <a href={EXTERNAL_URLS.GOOGLE_URL}>
        <img className="img-fluid" alt="Get it on Google Play" src={playIcon} />
      </a>
    </>
  );
}
export default memo(AppStoreIcons);
