import React from "react";

import "./EntPgeIntro.css";
import Hpy from "../assets/EnterpriseHomePageWebps/hpy.webp";
import TRANSLATIONS from "../translations/en.json";

export default function EntPgeIntro() {
  return (
    <div className="introHeadContain">
      <div className="brand-icon-text ">
        <img src={Hpy} alt="logoText" className="enterprise-hpy-head" />
      </div>
      <div className="title-intro-text mb-0">
        <p className="introText mb-0">
          {TRANSLATIONS.ENT_PGE_INTRO.INTRO_TEXT}
        </p>
      </div>
    </div>
  );
}
