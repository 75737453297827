import {
  DASHBOARD_SETUP_REQUEST,
  DASHBOARD_SETUP_SUCCESS,
  DASHBOARD_SETUP_ERROR,
} from "./actionTypes";

const initialState = {};

const DashBoardSetup = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_SETUP_REQUEST:
      return {
        ...state,
        dashBoardSetupRequestData: true,
        dashBoardSetupData: false,
        dashBoardSetupError: false,
      };
    case DASHBOARD_SETUP_SUCCESS:
      return {
        ...state,
        dashBoardSetupRequestData: false,
        dashBoardSetupData: action.payload.user.data,
        dashBoardSetupError: false,
      };
    case DASHBOARD_SETUP_ERROR:
      return {
        ...state,
        dashBoardSetupRequestData: false,
        dashBoardSetupData: false,
        dashBoardSetupError: action.payload.history.error,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default DashBoardSetup;
