import PropTypes from "prop-types";
import React, { lazy, memo, Suspense } from "react";

import { ReactComponent as CrossIcon } from "../../assets/CrossIcon.svg";
import { ReactComponent as FiltersIcon } from "../../assets/FiltersIcon.svg";
import TRANSLATIONS from "../../translations/en.json";
import "./index.css";

const FilterSettings = lazy(() => import("./FilterSettings"));

function Filter({
  filterOptions,
  handleCallFiltersApi,
  handleSelectOptions,
  isFilterOpen,
  isTimeSelected,
  setIsFilterOpen,
}) {
  return (
    <Suspense fallback="">
      <div className="task-filter-sidebar">
        <div className="task-filter-conatiner">
          <div className="task-filter-header d-flex justify-content-between align-items-center">
            <div>
              {TRANSLATIONS.ASSIGN_TASK.FILTERS}
              <FiltersIcon width={16} height={16} className="ml-1" />
            </div>
            <CrossIcon
              width={12}
              height={12}
              className="cursor-pointer"
              onClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            />
          </div>
          <FilterSettings
            filterOptions={filterOptions}
            handleSelectOptions={handleSelectOptions}
            isTimeSelected={isTimeSelected}
          />
        </div>
        <div className="d-flex justify-content-center save-btn-container">
          <button
            className="task-filter-save-btn"
            onClick={() => handleCallFiltersApi()}
          >
            {TRANSLATIONS.DASHBOARD_SETUP.SAVE_BTN}
          </button>
        </div>
      </div>
    </Suspense>
  );
}

Filter.propTypes = {
  filterOptions: PropTypes.array.isRequired,
  handleCallFiltersApi: PropTypes.func,
  handleSelectOptions: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  isTimeSelected: PropTypes.bool,
  setIsFilterOpen: PropTypes.func,
};
export default memo(Filter);
