import {
  AVG_HPY_SCORE_ERROR,
  AVG_HPY_SCORE_REQUEST,
  AVG_HPY_SCORE_SUCCESS,
} from "./actionTypes";

export const getAvgHpyScore = (payload) => {
  return {
    type: AVG_HPY_SCORE_REQUEST,
    payload,
  };
};

export const getAvgHpyScoreSuccess = (data) => {
  return {
    type: AVG_HPY_SCORE_SUCCESS,
    data,
  };
};

export const getAvgHpyScoreError = (error) => {
  return {
    type: AVG_HPY_SCORE_ERROR,
    error,
  };
};
