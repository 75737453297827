import { getAnalytics, logEvent } from "@firebase/analytics";
import { GLOBAL_DOCUMENT_TITLES, ROUTE_PATH } from "../common/constants";
import {
  authRoutes,
  consumerRoutes,
  dashboardFooterRoutes,
  dashboardRoutes,
  enterpriseRoutes,
} from "../routes";

export const getDocumentTitle = () => {
  const DocumentsTitles = [
    {
      title: GLOBAL_DOCUMENT_TITLES.HPY,
      routes: [...consumerRoutes, { path: "/" }],
    },
    {
      title: GLOBAL_DOCUMENT_TITLES.DASH,
      routes: [...dashboardRoutes, ...dashboardFooterRoutes],
    },
    {
      title: GLOBAL_DOCUMENT_TITLES.ENT,
      routes: [...authRoutes, ...enterpriseRoutes],
    },
  ];
  let title;
  DocumentsTitles.forEach((item) => {
    const index = item.routes.findIndex(
      (e) => e.path === window.location.pathname
    );
    if (index !== -1) {
      title = item.title;
    } else if (window.location.pathname.includes(ROUTE_PATH.RESULTS)) {
      title = GLOBAL_DOCUMENT_TITLES.HPY;
    }
  });
  return title;
};
/*update app title based on route */
export const updateDocumentTitle = (pageTitle) => {
  const locationTitle = window.location.pathname.substring(1);
  const title = pageTitle ? pageTitle : locationTitle;
  document.title = `${GLOBAL_DOCUMENT_TITLES.DEFAULT} | ${title}`;
};
export const logAnalyticsEvent = (eventConfig) => {
  const analytics = getAnalytics();
  logEvent(
    analytics,
    `${GLOBAL_DOCUMENT_TITLES.DEFAULT} ${eventConfig.title}`,
    eventConfig.config
  );
};
