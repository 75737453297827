import { AXIOS_INSTANCE, CMS_API } from "../../api/apiEndPoint";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { requestDemoError, requestDemoSuccess } from "./actions";

import { REQUEST_DEMO_REQUEST } from "./actionTypes";
import { checkHttpStatus } from "../../api/apiUtils";

function* requestDemo(action) {
  try {
    const apiResponse = AXIOS_INSTANCE.post(
      `${CMS_API}/request_demo`,
      action.payload
    );

    const response = yield call(checkHttpStatus, apiResponse);
    if (response) {
      const responseData = {
        response: response.data,
      };
      yield put(requestDemoSuccess(responseData));
    } else {
      const responseData = {
        error: response?.data?.error,
      };
      yield put(requestDemoError(responseData));
    }
  } catch (error) {
    const errorData = {
      error: error.response || true,
    };
    yield put(requestDemoError(errorData));
  }
}

export function* watchRequestDemo() {
  yield takeEvery(REQUEST_DEMO_REQUEST, requestDemo);
}

function* requestDemoSaga() {
  yield all([fork(watchRequestDemo)]);
}
export default requestDemoSaga;
